import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import MessageConstant from './../../constants/MessageConstant';

class RedirectedUnapprovedUser extends Component {

    render() {
        return (
            <div>
                <Card className="alert-box-error">
                    <div className="card-header-title">
                        <Card.Img variant="top" className="logo-size" src="/Images/Logo.svg" />
                        <Card.Text><h4 className="logo-title">MEDINFO CASE EVALUATION</h4></Card.Text>
                    </div>
                    <Card.Body className="msg-body">{MessageConstant.REG_MESSAGE_FOR_USER}</Card.Body>
                </Card>

            </div>
        );
    }
}

export default RedirectedUnapprovedUser;
