import React, { Component } from 'react';
import { Accordion, Card,Image, Button, Form,Col,Modal, Row} from 'react-bootstrap';
import './DataModelCurrentAcceptanceCriteria.css';
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import PopupModels from '../PopupModels/PopupModels';
import MessageConstant from './../../constants/MessageConstant';
import RoleConstant from "./../../constants/RoleConstant";
import { FileUploader } from '..';
import moment from 'moment';
// import AcceptanceCriteriaHistory from './AcceptanceCriteriaHistory';

const dataModel = {

        "accuracy": {

       "AE": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             },

       "OSF": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             },

       "PC": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             }

         },

           "auc": {

          "AE": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             },

           "OSF": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             },

         "PC": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             }

        },

          "precision": {

         "AE": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             },

           "OSF": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             },

          "PC": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             }

         },

          "sensitivity": {

         "AE": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             },

          "OSF": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             },

         "PC": {
               "lowerLimit": '',
               "median": '',
               "upperLimit": ''
             }

        }
        }
 


class DataModelCurrentAcceptanceCriteria extends Component {
    constructor(props) {
        super(props);
        this.state = {
          employers:'',
          iconName: 'icon-drop-down',
          // newiconName: 'icon-drop-down',
          isEditing: false,
          acceptanceCriteria: dataModel,
          success: false,
          showEditButton: false,
          errorFail: false,
          attachmentCheck: false,
          viewAttachment: 2,
          showComment: false,
          showAcceptance: false,
          black: true,
          showtext: true,
          showDatasetNameInput: false,
          allowAttachCheck: true,
          fileName: '',
          fileType: '',
          // tempAcceptanceDetails:
          history1:[],
          tempAcceptanceCriteria1:[],
          cancelAcceptance: {},
          acceptanceModalId:'',
          dummy: {},
          dummy1: {},
          tempAcceptanceCriteria: [{
            modifiedAt:'10/8/2020',
            modifiedBy: 'aaa'
          },
          {
            modifiedAt:'9/8/2020',
            modifiedBy: 'bbb'
          },
          {
            modifiedAt:'9/8/2020',
            modifiedBy: 'ccc'
          },
          {
            modifiedAt:'9/8/2020',
            modifiedBy: 'ddd'
          },
          {
            modifiedAt:'10/8/2020',
            modifiedBy: 'eee'
          }],
         history: [{
            modifiedAt:'10/8/2020',
            modifiedBy: 'aaa'
          },
          {
            modifiedAt:'9/8/2020',
            modifiedBy: 'bbb'
          },
          {
            modifiedAt:'9/8/2020',
            modifiedBy: 'ccc'
          },
          {
            modifiedAt:'9/8/2020',
            modifiedBy: 'ddd'
          },
          {
            modifiedAt:'10/8/2020',
            modifiedBy: 'eee'
          },
          {
            modifiedAt:'10/8/2020',
            modifiedBy: 'fff'
          }],
         
          
        };
        this.cancelAcceptance = this.cancelAcceptance.bind(this);
        this.downloadAttachment = this.downloadAttachment.bind(this);
        this.hideHistory = this.hideHistory.bind(this);
        this.showHistory = this.showHistory.bind(this);
        this.hideAcceptance = this.hideAcceptance.bind(this);
        this.showDatasetNameInput = this.showDatasetNameInput.bind(this);
        this.iconChange = this.iconChange.bind(this);
        this.toggleState = this.toggleState.bind(this);
        this.getConfiguredValuesFromSiteInfo = this.getConfiguredValuesFromSiteInfo.bind(this);
        this.UpdateAcceptance = this.UpdateAcceptance.bind(this);
        this.hideAcceptanceNameInput = this.hideAcceptanceNameInput.bind(this);
        this.handlefileNameInputClose = this.handlefileNameInputClose.bind(this);
        this.ShowAcceptanceCriteria = this.ShowAcceptanceCriteria.bind(this);
        this.downloadAcceptanceCriteria = this.downloadAcceptanceCriteria.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.hideInput = this.hideInput.bind(this);
        this.isFormValid = false;

    }

 
    componentDidMount() {
        this.getConfiguredValuesFromSiteInfo();
        // this.setState({black: !this.state.black});
      }
      hideAcceptanceNameInput() {
        this.setState({ showDatasetNameInput: false });
       
      }
      cancelAcceptance(evt) {
        evt.preventDefault();
         this.toggleState(); 
         this.getConfiguredValuesFromSiteInfo();
      // console.log("cancel check",this.prevState);
         // console.log('dummyvalues',this.state.dummy,this.state.dummy1)
      //   let cancelAcceptanceChange = this.state.cancelAcceptance;
      //  this.setState({acceptanceCriteria: cancelAcceptanceChange});
      }
      changeHandler(e) {
        e.preventDefault();
        // let fileName = [];
        // fileName=this.state.fileName;
        // let fileName=e.target.value;
        this.setState({fileName:[e.target.value]});
      }
      hideInput() {
        this.setState({allowAttachCheck:false});
      }
      downloadAcceptanceCriteria(name,filename) {
        const data = {
          attachmentName: name,
          filename:filename
        };
        utils.makeRequest(this, EndpointConstant.ACCEPTANCE_CRITERIA, JSON.stringify(data), function (result) {
    
          // console.log(JSON.stringify(result));
    
          window.open(result.url, 'Download');
    
        });
      }
      showDatasetNameInput(id, files, fileType) {
        let fileName = [];
        if (fileType) {
          fileName = files.map((data)=>{
            return (
              data.name.split('.')[0]
            )
          });
          this.setState({
            fileName: fileName,
            showDatasetNameInput: true,
            currentAcceptanceInput: 'upload',
            files: files,
            fileType,
            id: id,
          });
        } else {
          this.setState({
            fileName: files.split('.')[0],
            fileType: files.split('.')[1],
            showDatasetNameInput: true,
            currentAcceptanceInput: 'editName',
            // datasetId: id,
    
          });
        }
      }
      handlefileNameInputClose(){
        let self = this;
       
        if(this.state.fileName[0]==='' || this.state.fileName[0]===undefined) {
          alert("Please enter File name");
            return;
        } 
        let fileNameExist = false;
        if(this.state.acceptanceCriteria.hasOwnProperty('attachment') && this.state.acceptanceCriteria.attachment.length>0 ) {
          this.state.acceptanceCriteria.attachment.forEach((data) => {
            const fileName = data.attachmentName;
            if (fileName.toString().toLowerCase() === self.state.fileName.toString().toLowerCase()) {
              fileNameExist = true;
            }
          });
        }
       
        if (fileNameExist) {
          alert("File name already exist!");
          return;
        }
        if(!this.state.allowAttachCheck) {
          this.setState({allowAttachCheck: true});
          }
          this.setState({ showDatasetNameInput: false });

      }
      performFileUpload(files, fileType, id, data) {
        let self = this;
        let array1=[];
        let originalArray1 = [];
        let tempArray1 = [];
        this.setState({
          showfileNameInput: false,
        });
        // console.log("data check",files, fileType ,data);
        utils.uploadAcceptanceCriteriaFiles(this, files[0], fileType[0], id, data, function (result) {
          // console.log("finalResult",JSON.stringify(result.acceptanceCriteria));
          result.acceptanceCriteria.forEach(function (data) {
              if (data.active === true) {
                self.setState({ cancelAcceptance: data });
                // console.log("update cancel check", self.state.cancelAcceptance);
                self.setState({ acceptanceCriteria: data });

                self.setState({ isEditing: false });
                self.isFormValid = false;
                self.props.details(data);

              } if (data.active === false) {
                array1.push(data);
                // console.log("array check", array1);
                if (array1.length <= 5) {
                  // console.log("tempAcceptanceCriteria1 update", self.state.tempAcceptanceCriteria1);
                  tempArray1.push(data);
                  // console.log("tempArray check", tempArray1);
                }

                originalArray1.push(data);
                self.setState({ history1: originalArray1 });
              }
            });
          self.setState({success: true});
          if(self.state.tempAcceptanceCriteria1.length<=5) {
          self.setState({tempAcceptanceCriteria1:tempArray1});
        }
       self.setState({fileName: ''});
      }, this.state.fileName);
      }
    toggleState(){
        const { isEditing } = this.state;
        this.setState({
          isEditing:!isEditing
        });
        
      }
      downloadAttachment(name,filename) {
        const data = {
          attachmentName: name,
          filename:filename
        };
        utils.makeRequest(this, EndpointConstant.ACCEPTANCE_CRITERIA, JSON.stringify(data), function (result) {
    
          // console.log(JSON.stringify(result));
    
          window.open(result.url, 'Download');
    
        });
      }
      ShowAcceptanceCriteria(id) {
        // let self = this;
        this.setState({showAcceptance: true});
      //let AcceptanceHistory = this.state.acceptanceCriteria;
      //console.log("AcceptanceHistory", id);
      let modalId = id;
      this.setState({acceptanceModalId:modalId});

      }
      showHistory() {
      
        let array = [];
        array = this.state.history1;
        // console.log("tempAcceptanceCriteria",array);
        this.setState({tempAcceptanceCriteria1: array});
        this.setState({showtext: !this.state.showtext});
        this.setState({black: true});
        // this.setState({showComment: true});
      }
      hideHistory() {
        this.setState({showComment:false});
      }
      hideAcceptance() {
        this.setState({showAcceptance: false});
       
      }
      handleErrorFailClose() {
        this.setState({ errorFail: false })
      }
      handleAttachmentErrorFailClose() {
        this.setState({ attachmentCheck: false })
      }

      UpdateAcceptance(id,event) {
        event.preventDefault();
        let self = this;
        // console.log('A name was submitted: ' + this.state.employers);
          // console.log("statue va;lue",this.state.acceptanceCriteria);
          let data = {};
          data = { 
              id: id,
              type: "edit",
              acceptanceCriteria: {
                medianLimitRange: '',
                accuracy:{
                    AE: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''

                    },
                    PC: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    },
                    OSF: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    }
                },
                precision:{
                    AE: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    },
                     PC: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    },
                    OSF: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    }
                },
                sensitivity:{
                    AE: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    },
                    PC: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    },
                    OSF: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    }
                },
                auc:{
                    AE: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    },
                    PC: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    },
                    OSF: {
                      lowerLimit:'',
                      median:'',
                      upperLimit:''
                    }
                }
              }
          };
          this.checkEmptyValues();
          // console.log("this.state.errorFial",this.checkEmptyValues());
          if(!this.checkEmptyValues()){
            if(this.state.acceptanceCriteria.hasOwnProperty('medianLimitRange')) {
              if(this.state.acceptanceCriteria.medianLimitRange!== '') {
                data.acceptanceCriteria.medianLimitRange = "0.03";
              }
            } else {
              data.acceptanceCriteria.medianLimitRange = "0.03";
            }
            if(this.state.acceptanceCriteria.hasOwnProperty('accuracy')) {
              if(this.state.acceptanceCriteria.accuracy.hasOwnProperty('AE')) {
                if(this.state.acceptanceCriteria.accuracy.AE.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.accuracy.AE.median!== '') {
                    data.acceptanceCriteria.accuracy.AE.median = this.state.acceptanceCriteria.accuracy.AE.median;
                  } 
                } else {
                  data.acceptanceCriteria.accuracy.AE.median = this.state.acceptanceCriteria.accuracy.AE.median;
                }
                  if(this.state.acceptanceCriteria.accuracy.AE.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.accuracy.AE.lowerLimit!== '') {
                      data.acceptanceCriteria.accuracy.AE.lowerLimit = this.state.acceptanceCriteria.accuracy.AE.lowerLimit;
                    }
                   
                  } else {
                    data.acceptanceCriteria.accuracy.AE.lowerLimit = this.state.acceptanceCriteria.accuracy.AE.lowerLimit
                  }
                  if(this.state.acceptanceCriteria.accuracy.AE.hasOwnProperty('upperLimit')){
                    if(this.state.acceptanceCriteria.accuracy.AE.upperLimit!== '') {
                      data.acceptanceCriteria.accuracy.AE.upperLimit = this.state.acceptanceCriteria.accuracy.AE.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.accuracy.AE.upperLimit = this.state.acceptanceCriteria.accuracy.AE.upperLimit
                  }
                  
               
               
              } else {
                data.acceptanceCriteria.accuracy.AE.median = this.state.acceptanceCriteria.accuracy.AE.median;
                data.acceptanceCriteria.accuracy.AE.lowerLimit = this.state.acceptanceCriteria.accuracy.AE.lowerLimit;
                data.acceptanceCriteria.accuracy.AE.upperLimit = this.state.acceptanceCriteria.accuracy.AE.upperLimit;
              }
              if(this.state.acceptanceCriteria.accuracy.hasOwnProperty('OSF')) {
                if(this.state.acceptanceCriteria.accuracy.OSF.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.accuracy.OSF.median!== '') {
                    data.acceptanceCriteria.accuracy.OSF.median = this.state.acceptanceCriteria.accuracy.OSF.median;
                  }
                } else {
                  data.acceptanceCriteria.accuracy.OSF.median = this.state.acceptanceCriteria.accuracy.OSF.median;
                }
                  if(this.state.acceptanceCriteria.accuracy.OSF.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.accuracy.OSF.lowerLimit!== '') {
                      data.acceptanceCriteria.accuracy.OSF.lowerLimit = this.state.acceptanceCriteria.accuracy.OSF.lowerLimit;
                    }
                   
                  } else {
                    data.acceptanceCriteria.accuracy.OSF.lowerLimit = this.state.acceptanceCriteria.accuracy.OSF.lowerLimit;
                  }
                  if(this.state.acceptanceCriteria.accuracy.OSF.hasOwnProperty('upperLimit')) {
                    if(this.state.acceptanceCriteria.accuracy.OSF.upperLimit!== '') {
                      data.acceptanceCriteria.accuracy.OSF.upperLimit = this.state.acceptanceCriteria.accuracy.OSF.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.accuracy.OSF.upperLimit = this.state.acceptanceCriteria.accuracy.OSF.upperLimit
                  }
               
              } else {
                data.acceptanceCriteria.accuracy.OSF.median = this.state.acceptanceCriteria.accuracy.OSF.median;
                data.acceptanceCriteria.accuracy.OSF.lowerLimit = this.state.acceptanceCriteria.accuracy.OSF.lowerLimit;
                data.acceptanceCriteria.accuracy.OSF.upperLimit = this.state.acceptanceCriteria.accuracy.OSF.upperLimit
              }
              if(this.state.acceptanceCriteria.accuracy.hasOwnProperty('PC')) {
                if(this.state.acceptanceCriteria.accuracy.PC.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.accuracy.PC.median!== '') {
                    data.acceptanceCriteria.accuracy.PC.median = this.state.acceptanceCriteria.accuracy.PC.median;
                  }
                } else {
                  data.acceptanceCriteria.accuracy.PC.median = this.state.acceptanceCriteria.accuracy.PC.median;
                }
                  if(this.state.acceptanceCriteria.accuracy.PC.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.accuracy.PC.lowerLimit!== '') {
                      data.acceptanceCriteria.accuracy.PC.lowerLimit = this.state.acceptanceCriteria.accuracy.PC.lowerLimit;
                    }
                    
                  } else {
                    data.acceptanceCriteria.accuracy.PC.lowerLimit = this.state.acceptanceCriteria.accuracy.PC.lowerLimit;
                  }
                  if(this.state.acceptanceCriteria.accuracy.PC.hasOwnProperty('upperLimit')) {
                    if(this.state.acceptanceCriteria.accuracy.PC.upperLimit!== '') {
                      data.acceptanceCriteria.accuracy.PC.upperLimit = this.state.acceptanceCriteria.accuracy.PC.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.accuracy.PC.upperLimit = this.state.acceptanceCriteria.accuracy.PC.upperLimit
                  }
               
              } else {
                data.acceptanceCriteria.accuracy.PC.median = this.state.acceptanceCriteria.accuracy.PC.median;
                  data.acceptanceCriteria.accuracy.PC.lowerLimit = this.state.acceptanceCriteria.accuracy.PC.lowerLimit;
                  data.acceptanceCriteria.accuracy.PC.upperLimit = this.state.acceptanceCriteria.accuracy.PC.upperLimit
              } 
            } else {
              data.acceptanceCriteria.accuracy.AE.median = this.state.acceptanceCriteria.accuracy.AE.median;
              data.acceptanceCriteria.accuracy.AE.lowerLimit = this.state.acceptanceCriteria.accuracy.AE.lowerLimit;
              data.acceptanceCriteria.accuracy.AE.upperLimit = this.state.acceptanceCriteria.accuracy.AE.upperLimit;
              data.acceptanceCriteria.accuracy.OSF.median = this.state.acceptanceCriteria.accuracy.OSF.median;
              data.acceptanceCriteria.accuracy.OSF.lowerLimit = this.state.acceptanceCriteria.accuracy.OSF.lowerLimit;
              data.acceptanceCriteria.accuracy.OSF.upperLimit = this.state.acceptanceCriteria.accuracy.OSF.upperLimit;
              data.acceptanceCriteria.accuracy.PC.median = this.state.acceptanceCriteria.accuracy.PC.median;
              data.acceptanceCriteria.accuracy.PC.lowerLimit = this.state.acceptanceCriteria.accuracy.PC.lowerLimit;
              data.acceptanceCriteria.accuracy.PC.upperLimit = this.state.acceptanceCriteria.accuracy.PC.upperLimit
            }
            // console.log("accuracy AE",data.acceptanceCriteria.accuracy);
            if(this.state.acceptanceCriteria.hasOwnProperty('precision')) {
              if(this.state.acceptanceCriteria.precision.hasOwnProperty('AE')) {
                if(this.state.acceptanceCriteria.precision.AE.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.precision.AE.median!== '') {
                    data.acceptanceCriteria.precision.AE.median = this.state.acceptanceCriteria.precision.AE.median;
                  }
                } else {
                  data.acceptanceCriteria.precision.AE.median = this.state.acceptanceCriteria.precision.AE.median;
                }
                  if(this.state.acceptanceCriteria.precision.AE.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.precision.AE.lowerLimit!== '') {
                      data.acceptanceCriteria.precision.AE.lowerLimit = this.state.acceptanceCriteria.precision.AE.lowerLimit;
                    }
                   
                  } else {
                    data.acceptanceCriteria.precision.AE.lowerLimit = this.state.acceptanceCriteria.precision.AE.lowerLimit
                  }
                  if(this.state.acceptanceCriteria.precision.AE.hasOwnProperty('upperLimit')){
                    if(this.state.acceptanceCriteria.precision.AE.upperLimit!== '') {
                      data.acceptanceCriteria.precision.AE.upperLimit = this.state.acceptanceCriteria.precision.AE.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.precision.AE.upperLimit = this.state.acceptanceCriteria.precision.AE.upperLimit
                  }
                  
               
               
              } else {
                data.acceptanceCriteria.precision.AE.median = this.state.acceptanceCriteria.precision.AE.median;
                data.acceptanceCriteria.precision.AE.lowerLimit = this.state.acceptanceCriteria.precision.AE.lowerLimit;
                data.acceptanceCriteria.precision.AE.upperLimit = this.state.acceptanceCriteria.precision.AE.upperLimit;
              }
              if(this.state.acceptanceCriteria.precision.hasOwnProperty('OSF')) {
                if(this.state.acceptanceCriteria.precision.OSF.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.precision.OSF.median!== '') {
                    data.acceptanceCriteria.precision.OSF.median = this.state.acceptanceCriteria.precision.OSF.median;
                  }
                } else {
                  data.acceptanceCriteria.precision.OSF.median = this.state.acceptanceCriteria.precision.OSF.median;
                }
                  if(this.state.acceptanceCriteria.precision.OSF.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.precision.OSF.lowerLimit!== '') {
                      data.acceptanceCriteria.precision.OSF.lowerLimit = this.state.acceptanceCriteria.precision.OSF.lowerLimit;
                    }
                   
                  } else {
                    data.acceptanceCriteria.precision.OSF.lowerLimit = this.state.acceptanceCriteria.precision.OSF.lowerLimit;
                  }
                  if(this.state.acceptanceCriteria.precision.OSF.hasOwnProperty('upperLimit')) {
                    if(this.state.acceptanceCriteria.precision.OSF.upperLimit!== '') {
                      data.acceptanceCriteria.precision.OSF.upperLimit = this.state.acceptanceCriteria.precision.OSF.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.precision.OSF.upperLimit = this.state.acceptanceCriteria.precision.OSF.upperLimit
                  }
               
              } else {
                data.acceptanceCriteria.precision.OSF.median = this.state.acceptanceCriteria.precision.OSF.median;
                data.acceptanceCriteria.precision.OSF.lowerLimit = this.state.acceptanceCriteria.precision.OSF.lowerLimit;
                data.acceptanceCriteria.precision.OSF.upperLimit = this.state.acceptanceCriteria.precision.OSF.upperLimit
              }
              if(this.state.acceptanceCriteria.precision.hasOwnProperty('PC')) {
                if(this.state.acceptanceCriteria.precision.PC.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.precision.PC.median!== '') {
                    data.acceptanceCriteria.precision.PC.median =this.state.acceptanceCriteria.precision.PC.median;
                  }
                } else {
                  data.acceptanceCriteria.precision.PC.median = this.state.acceptanceCriteria.precision.PC.median;
                }
                  if(this.state.acceptanceCriteria.precision.PC.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.precision.PC.lowerLimit!== '') {
                      data.acceptanceCriteria.precision.PC.lowerLimit = this.state.acceptanceCriteria.precision.PC.lowerLimit;
                    }
                    
                  } else {
                    data.acceptanceCriteria.precision.PC.lowerLimit = this.state.acceptanceCriteria.precision.PC.lowerLimit;
                  }
                  if(this.state.acceptanceCriteria.precision.PC.hasOwnProperty('upperLimit')) {
                    if(this.state.acceptanceCriteria.precision.PC.upperLimit!== '') {
                      data.acceptanceCriteria.precision.PC.upperLimit = this.state.acceptanceCriteria.precision.PC.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.precision.PC.upperLimit = this.state.acceptanceCriteria.precision.PC.upperLimit
                  }
               
              } else {
                data.acceptanceCriteria.precision.PC.median = this.state.acceptanceCriteria.precision.PC.median;
                  data.acceptanceCriteria.precision.PC.lowerLimit = this.state.acceptanceCriteria.precision.PC.lowerLimit;
                  data.acceptanceCriteria.precision.PC.upperLimit = this.state.acceptanceCriteria.precision.PC.upperLimit
              } 
            } else {
              data.acceptanceCriteria.precision.AE.median = this.state.acceptanceCriteria.precision.AE.median;
              data.acceptanceCriteria.precision.AE.lowerLimit = this.state.acceptanceCriteria.precision.AE.lowerLimit;
              data.acceptanceCriteria.precision.AE.upperLimit = this.state.acceptanceCriteria.precision.AE.upperLimit;
              data.acceptanceCriteria.precision.OSF.median = this.state.acceptanceCriteria.precision.OSF.median;
              data.acceptanceCriteria.precision.OSF.lowerLimit = this.state.acceptanceCriteria.precision.OSF.lowerLimit;
              data.acceptanceCriteria.precision.OSF.upperLimit = this.state.acceptanceCriteria.precision.OSF.upperLimit;
              data.acceptanceCriteria.precision.PC.median = this.state.acceptanceCriteria.precision.PC.median;
              data.acceptanceCriteria.precision.PC.lowerLimit = this.state.acceptanceCriteria.precision.PC.lowerLimit;
              data.acceptanceCriteria.precision.PC.upperLimit = this.state.acceptanceCriteria.precision.PC.upperLimit
            }

            // console.log("presion",data.acceptanceCriteria.precision);
            if(this.state.acceptanceCriteria.hasOwnProperty('sensitivity')) {
              if(this.state.acceptanceCriteria.sensitivity.hasOwnProperty('AE')) {
                if(this.state.acceptanceCriteria.sensitivity.AE.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.sensitivity.AE.median!== '') {
                    data.acceptanceCriteria.sensitivity.AE.median = this.state.acceptanceCriteria.sensitivity.AE.median;
                  }
                } else {
                  data.acceptanceCriteria.sensitivity.AE.median = this.state.acceptanceCriteria.sensitivity.AE.median;
                }
                  if(this.state.acceptanceCriteria.sensitivity.AE.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.sensitivity.AE.lowerLimit!== '') {
                      data.acceptanceCriteria.sensitivity.AE.lowerLimit = this.state.acceptanceCriteria.sensitivity.AE.lowerLimit;
                    }
                   
                  } else {
                    data.acceptanceCriteria.sensitivity.AE.lowerLimit = this.state.acceptanceCriteria.sensitivity.AE.lowerLimit
                  }
                  if(this.state.acceptanceCriteria.sensitivity.AE.hasOwnProperty('upperLimit')){
                    if(this.state.acceptanceCriteria.sensitivity.AE.upperLimit!== '') {
                      data.acceptanceCriteria.sensitivity.AE.upperLimit = this.state.acceptanceCriteria.sensitivity.AE.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.sensitivity.AE.upperLimit = this.state.acceptanceCriteria.sensitivity.AE.upperLimit
                  }
                  
               
               
              } else {
                data.acceptanceCriteria.sensitivity.AE.median = this.state.acceptanceCriteria.sensitivity.AE.median;
                data.acceptanceCriteria.sensitivity.AE.lowerLimit = this.state.acceptanceCriteria.sensitivity.AE.lowerLimit;
                data.acceptanceCriteria.sensitivity.AE.upperLimit = this.state.acceptanceCriteria.sensitivity.AE.upperLimit;
              }
              if(this.state.acceptanceCriteria.sensitivity.hasOwnProperty('OSF')) {
                if(this.state.acceptanceCriteria.sensitivity.OSF.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.sensitivity.OSF.median!== '') {
                    data.acceptanceCriteria.sensitivity.OSF.median = this.state.acceptanceCriteria.sensitivity.OSF.median;
                  }
                } else {
                  data.acceptanceCriteria.sensitivity.OSF.median = this.state.acceptanceCriteria.sensitivity.OSF.median;
                }
                  if(this.state.acceptanceCriteria.sensitivity.OSF.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.sensitivity.OSF.lowerLimit!== '') {
                      data.acceptanceCriteria.sensitivity.OSF.lowerLimit = this.state.acceptanceCriteria.sensitivity.OSF.lowerLimit;
                    }
                   
                  } else {
                    data.acceptanceCriteria.sensitivity.OSF.lowerLimit = this.state.acceptanceCriteria.sensitivity.OSF.lowerLimit;
                  }
                  if(this.state.acceptanceCriteria.sensitivity.OSF.hasOwnProperty('upperLimit')) {
                    if(this.state.acceptanceCriteria.sensitivity.OSF.upperLimit!== '') {
                      data.acceptanceCriteria.sensitivity.OSF.upperLimit = this.state.acceptanceCriteria.sensitivity.OSF.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.sensitivity.OSF.upperLimit = this.state.acceptanceCriteria.sensitivity.OSF.upperLimit
                  }
               
              } else {
                data.acceptanceCriteria.sensitivity.OSF.median = this.state.acceptanceCriteria.sensitivity.OSF.median;
                data.acceptanceCriteria.sensitivity.OSF.lowerLimit = this.state.acceptanceCriteria.sensitivity.OSF.lowerLimit;
                data.acceptanceCriteria.sensitivity.OSF.upperLimit = this.state.acceptanceCriteria.sensitivity.OSF.upperLimit
              }
              if(this.state.acceptanceCriteria.sensitivity.hasOwnProperty('PC')) {
                if(this.state.acceptanceCriteria.sensitivity.PC.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.sensitivity.PC.median!== '') {
                    data.acceptanceCriteria.sensitivity.PC.median = this.state.acceptanceCriteria.sensitivity.PC.median;
                  }
                } else {
                  data.acceptanceCriteria.sensitivity.PC.median = this.state.acceptanceCriteria.sensitivity.PC.median;
                }
                  if(this.state.acceptanceCriteria.sensitivity.PC.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.sensitivity.PC.lowerLimit!== '') {
                      data.acceptanceCriteria.sensitivity.PC.lowerLimit = this.state.acceptanceCriteria.sensitivity.PC.lowerLimit;
                    }
                    
                  } else {
                    data.acceptanceCriteria.sensitivity.PC.lowerLimit = this.state.acceptanceCriteria.sensitivity.PC.lowerLimit;
                  }
                  if(this.state.acceptanceCriteria.sensitivity.PC.hasOwnProperty('upperLimit')) {
                    if(this.state.acceptanceCriteria.sensitivity.PC.upperLimit!== '') {
                      data.acceptanceCriteria.sensitivity.PC.upperLimit = this.state.acceptanceCriteria.sensitivity.PC.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.sensitivity.PC.upperLimit = this.state.acceptanceCriteria.sensitivity.PC.upperLimit
                  }
               
              } else {
                data.acceptanceCriteria.sensitivity.PC.median = this.state.acceptanceCriteria.sensitivity.PC.median;
                  data.acceptanceCriteria.sensitivity.PC.lowerLimit = this.state.acceptanceCriteria.sensitivity.PC.lowerLimit;
                  data.acceptanceCriteria.sensitivity.PC.upperLimit = this.state.acceptanceCriteria.sensitivity.PC.upperLimit
              } 
            } else {
              data.acceptanceCriteria.sensitivity.AE.median = this.state.acceptanceCriteria.sensitivity.AE.median;
              data.acceptanceCriteria.sensitivity.AE.lowerLimit = this.state.acceptanceCriteria.sensitivity.AE.lowerLimit;
              data.acceptanceCriteria.sensitivity.AE.upperLimit = this.state.acceptanceCriteria.sensitivity.AE.upperLimit;
              data.acceptanceCriteria.sensitivity.OSF.median = this.state.acceptanceCriteria.sensitivity.OSF.median;
              data.acceptanceCriteria.sensitivity.OSF.lowerLimit = this.state.acceptanceCriteria.sensitivity.OSF.lowerLimit;
              data.acceptanceCriteria.sensitivity.OSF.upperLimit = this.state.acceptanceCriteria.sensitivity.OSF.upperLimit;
              data.acceptanceCriteria.sensitivity.PC.median = this.state.acceptanceCriteria.sensitivity.PC.median;
              data.acceptanceCriteria.sensitivity.PC.lowerLimit = this.state.acceptanceCriteria.sensitivity.PC.lowerLimit;
              data.acceptanceCriteria.sensitivity.PC.upperLimit = this.state.acceptanceCriteria.sensitivity.PC.upperLimit
            }
            // console.log("sensitivity",data.acceptanceCriteria.sensitivity);
            if(this.state.acceptanceCriteria.hasOwnProperty('auc')) {
              if(this.state.acceptanceCriteria.auc.hasOwnProperty('AE')) {
                if(this.state.acceptanceCriteria.auc.AE.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.auc.AE.median!== '') {
                    data.acceptanceCriteria.auc.AE.median = this.state.acceptanceCriteria.auc.AE.median;
                  }
                } else {
                  data.acceptanceCriteria.auc.AE.median = this.state.acceptanceCriteria.auc.AE.median;
                }
                  if(this.state.acceptanceCriteria.auc.AE.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.auc.AE.lowerLimit!== '') {
                      data.acceptanceCriteria.auc.AE.lowerLimit = this.state.acceptanceCriteria.auc.AE.lowerLimit;
                    }
                   
                  } else {
                    data.acceptanceCriteria.auc.AE.lowerLimit = this.state.acceptanceCriteria.auc.AE.lowerLimit
                  }
                  if(this.state.acceptanceCriteria.auc.AE.hasOwnProperty('upperLimit')){
                    if(this.state.acceptanceCriteria.auc.AE.upperLimit!== '') {
                      data.acceptanceCriteria.auc.AE.upperLimit = this.state.acceptanceCriteria.auc.AE.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.auc.AE.upperLimit = this.state.acceptanceCriteria.auc.AE.upperLimit
                  }
                  
               
               
              } else {
                data.acceptanceCriteria.auc.AE.median = this.state.acceptanceCriteria.auc.AE.median;
                data.acceptanceCriteria.auc.AE.lowerLimit = this.state.acceptanceCriteria.auc.AE.lowerLimit;
                data.acceptanceCriteria.auc.AE.upperLimit = this.state.acceptanceCriteria.auc.AE.upperLimit;
              }
              if(this.state.acceptanceCriteria.auc.hasOwnProperty('OSF')) {
                if(this.state.acceptanceCriteria.auc.OSF.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.auc.OSF.median!== '') {
                    data.acceptanceCriteria.auc.OSF.median = this.state.acceptanceCriteria.auc.OSF.median;
                  }
                } else {
                  data.acceptanceCriteria.auc.OSF.median = this.state.acceptanceCriteria.auc.OSF.median;
                }
                  if(this.state.acceptanceCriteria.auc.OSF.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.auc.OSF.lowerLimit!== '') {
                      data.acceptanceCriteria.auc.OSF.lowerLimit = this.state.acceptanceCriteria.auc.OSF.lowerLimit;
                    }
                   
                  } else {
                    data.acceptanceCriteria.auc.OSF.lowerLimit = this.state.acceptanceCriteria.auc.OSF.lowerLimit;
                  }
                  if(this.state.acceptanceCriteria.auc.OSF.hasOwnProperty('upperLimit')) {
                    if(this.state.acceptanceCriteria.auc.OSF.upperLimit!== '') {
                      data.acceptanceCriteria.auc.OSF.upperLimit = this.state.acceptanceCriteria.auc.OSF.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.auc.OSF.upperLimit = this.state.acceptanceCriteria.auc.OSF.upperLimit
                  }
               
              } else {
                data.acceptanceCriteria.auc.OSF.median = this.state.acceptanceCriteria.auc.OSF.median;
                data.acceptanceCriteria.auc.OSF.lowerLimit = this.state.acceptanceCriteria.auc.OSF.lowerLimit;
                data.acceptanceCriteria.auc.OSF.upperLimit = this.state.acceptanceCriteria.auc.OSF.upperLimit
              }
              if(this.state.acceptanceCriteria.auc.hasOwnProperty('PC')) {
                if(this.state.acceptanceCriteria.auc.PC.hasOwnProperty('median')) {
                  if(this.state.acceptanceCriteria.auc.PC.median!== '') {
                    data.acceptanceCriteria.auc.PC.median = this.state.acceptanceCriteria.auc.PC.median;
                  }
                } else {
                  data.acceptanceCriteria.auc.PC.median = this.state.acceptanceCriteria.auc.PC.median;
                }
                  if(this.state.acceptanceCriteria.auc.PC.hasOwnProperty('lowerLimit')) {
                    if(this.state.acceptanceCriteria.auc.PC.lowerLimit!== '') {
                      data.acceptanceCriteria.auc.PC.lowerLimit = this.state.acceptanceCriteria.auc.PC.lowerLimit;
                    }
                    
                  } else {
                    data.acceptanceCriteria.auc.PC.lowerLimit = this.state.acceptanceCriteria.auc.PC.lowerLimit;
                  }
                  if(this.state.acceptanceCriteria.auc.PC.hasOwnProperty('upperLimit')) {
                    if(this.state.acceptanceCriteria.auc.PC.upperLimit!== '') {
                      data.acceptanceCriteria.auc.PC.upperLimit = this.state.acceptanceCriteria.auc.PC.upperLimit;
                    }
                  } else {
                    data.acceptanceCriteria.auc.PC.upperLimit = this.state.acceptanceCriteria.auc.PC.upperLimit
                  }
               
              } else {
                data.acceptanceCriteria.auc.PC.median = this.state.acceptanceCriteria.auc.PC.median;
                  data.acceptanceCriteria.auc.PC.lowerLimit = this.state.acceptanceCriteria.auc.PC.lowerLimit;
                  data.acceptanceCriteria.auc.PC.upperLimit = this.state.acceptanceCriteria.auc.PC.upperLimit
              } 
            } else {
              data.acceptanceCriteria.auc.AE.median = this.state.acceptanceCriteria.auc.AE.median;
              data.acceptanceCriteria.auc.AE.lowerLimit = this.state.acceptanceCriteria.auc.AE.lowerLimit;
              data.acceptanceCriteria.auc.AE.upperLimit = this.state.acceptanceCriteria.auc.AE.upperLimit;
              data.acceptanceCriteria.auc.OSF.median = this.state.acceptanceCriteria.auc.OSF.median;
              data.acceptanceCriteria.auc.OSF.lowerLimit = this.state.acceptanceCriteria.auc.OSF.lowerLimit;
              data.acceptanceCriteria.auc.OSF.upperLimit = this.state.acceptanceCriteria.auc.OSF.upperLimit;
              data.acceptanceCriteria.auc.PC.median = this.state.acceptanceCriteria.auc.PC.median;
              data.acceptanceCriteria.auc.PC.lowerLimit = this.state.acceptanceCriteria.auc.PC.lowerLimit;
              data.acceptanceCriteria.auc.PC.upperLimit = this.state.acceptanceCriteria.auc.PC.upperLimit
            }
            if(this.state.fileName==='') {
              // attachmentCheck = true;
              self.setState({attachmentCheck:true});
              // self.setState({errorFail:true});
            } else {
              data.filename = Array.isArray(this.state.fileName)?this.state.fileName[0]:this.state.fileName;
              // console.log("fileName acceptance", this.state.fileName[0]);
            }
           
          if (this.state.currentAcceptanceInput === 'upload') {
            this.performFileUpload(this.state.files, this.state.fileType, this.state.id,data);
          }
           }
          // utils.uploadFiles(this, fileDetails, fileTypesData, id, function (result)
        // utils.makeRequest(this, EndpointConstant.ACCEPTANCE_CRITERIA, data, function (result) {
        //     if (result.error === 'false' || result.error === false) {
        //         console.log("edit acceptance criteria", result);
        //         let updatedDetails = result.acceptanceCriteria[0];
                // self.setState({acceptanceCriteria:updatedDetails},()=>{
                //     self.setState({success: true});
                //     self.setState({isEditing:false});
                    
                // });
        //         // 
        //     }
        // });
    // }
    
    }

    checkEmptyValues() {
      let checkEmpty= false;
      let self = this;
      if(this.state.hasOwnProperty('acceptanceCriteria')) {
      if(this.state.acceptanceCriteria.hasOwnProperty('accuracy')) {
            if(this.state.acceptanceCriteria.accuracy.hasOwnProperty('AE')) {
              if(this.state.acceptanceCriteria.accuracy.AE.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.accuracy.AE.median==='') {
                  checkEmpty=true;
                self.setState({errorFail:true});
                }
                
                
              }
              if(this.state.acceptanceCriteria.accuracy.AE.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.accuracy.AE.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.accuracy.AE.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.accuracy.AE.upperLimit==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }

            }
            if(this.state.acceptanceCriteria.accuracy.hasOwnProperty('OSF')) {
              if(this.state.acceptanceCriteria.accuracy.OSF.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.accuracy.OSF.median==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.accuracy.OSF.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.accuracy.OSF.lowerLimit==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }
              if(this.state.acceptanceCriteria.accuracy.OSF.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.accuracy.OSF.upperLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }

            } if(this.state.acceptanceCriteria.accuracy.hasOwnProperty('PC')) {
              if(this.state.acceptanceCriteria.accuracy.PC.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.accuracy.PC.median==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }
              if(this.state.acceptanceCriteria.accuracy.PC.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.accuracy.PC.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.accuracy.PC.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.accuracy.PC.upperLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }

            }
          }
          if(this.state.acceptanceCriteria.hasOwnProperty('precision')) {
            if(this.state.acceptanceCriteria.precision.hasOwnProperty('AE')) {
              if(this.state.acceptanceCriteria.precision.AE.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.precision.AE.median==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.precision.AE.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.precision.AE.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.precision.AE.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.precision.AE.upperLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
              
              }

            } if(this.state.acceptanceCriteria.precision.hasOwnProperty('OSF')) {
              if(this.state.acceptanceCriteria.precision.OSF.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.precision.OSF.median==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.precision.OSF.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.precision.OSF.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.precision.OSF.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.precision.OSF.upperLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }

            } if(this.state.acceptanceCriteria.precision.hasOwnProperty('PC')) {
              if(this.state.acceptanceCriteria.precision.PC.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.precision.PC.median==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }
              if(this.state.acceptanceCriteria.precision.PC.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.precision.PC.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.precision.PC.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.precision.PC.upperLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }

            }
          }
          if(this.state.acceptanceCriteria.hasOwnProperty('sensitivity')) {
            if(this.state.acceptanceCriteria.sensitivity.hasOwnProperty('AE')) {
              if(this.state.acceptanceCriteria.sensitivity.AE.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.sensitivity.AE.median==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }
              if(this.state.acceptanceCriteria.sensitivity.AE.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.sensitivity.AE.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.sensitivity.AE.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.sensitivity.AE.upperLimit==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }

            } if(this.state.acceptanceCriteria.sensitivity.hasOwnProperty('OSF')) {
              if(this.state.acceptanceCriteria.sensitivity.OSF.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.sensitivity.OSF.median==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.sensitivity.OSF.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.sensitivity.OSF.lowerLimit==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }
              if(this.state.acceptanceCriteria.sensitivity.OSF.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.sensitivity.OSF.upperLimit==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }

            } if(this.state.acceptanceCriteria.sensitivity.hasOwnProperty('PC')) {
              if(this.state.acceptanceCriteria.sensitivity.PC.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.sensitivity.PC.median==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.sensitivity.PC.hasOwnProperty('lowerLimit') ) {
                if(this.state.acceptanceCriteria.sensitivity.PC.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
              
              }
              if(this.state.acceptanceCriteria.sensitivity.PC.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.sensitivity.PC.upperLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }

            }
          }
          if(this.state.acceptanceCriteria.hasOwnProperty('auc')) {
            if(this.state.acceptanceCriteria.auc.hasOwnProperty('AE')) {
              if(this.state.acceptanceCriteria.auc.AE.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.auc.AE.median==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.auc.AE.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.auc.AE.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
              
              }
              if(this.state.acceptanceCriteria.auc.AE.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.auc.AE.upperLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
             
              }

            } if(this.state.acceptanceCriteria.auc.hasOwnProperty('OSF')) {
              if(this.state.acceptanceCriteria.auc.OSF.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.auc.OSF.median==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }
              if(this.state.acceptanceCriteria.auc.OSF.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.auc.OSF.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.auc.OSF.hasOwnProperty('upperLimit')) {
                if(this.state.acceptanceCriteria.auc.OSF.upperLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }

            } if(this.state.acceptanceCriteria.auc.hasOwnProperty('PC')) {
              if(this.state.acceptanceCriteria.auc.PC.hasOwnProperty('median')) {
                if(this.state.acceptanceCriteria.auc.PC.median==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }
              if(this.state.acceptanceCriteria.auc.PC.hasOwnProperty('lowerLimit')) {
                if(this.state.acceptanceCriteria.auc.PC.lowerLimit==='') {
                  self.setState({errorFail:true});
                  checkEmpty=true;
                }
               
              }
              if(this.state.acceptanceCriteria.auc.PC.hasOwnProperty('upperLimit') ) {
                if(this.state.acceptanceCriteria.auc.PC.upperLimit==='') {
                self.setState({errorFail:true});
                checkEmpty=true;
                }
                
              }

            }
          }
        } 
        // else {
        //   self.setState({errorFail:true});
        //   checkEmpty=true;
        // }
        if(this.state.acceptanceCriteria.hasOwnProperty('attachment') && this.state.acceptanceCriteria.attachment.length>0 ) {
          this.state.acceptanceCriteria.attachment.forEach((data) => {
            const fileName = data.attachmentName;
            if (fileName === this.state.fileName[0]) {
              // self.setState({errorFail:true});
              self.setState({attachmentCheck: true});
              checkEmpty=true;
            }
          });
        }
        if(!Array.isArray(this.state.fileName)==='') {
          // self.setState({errorFail:true});
          self.setState({attachmentCheck: true});
          checkEmpty=true;
        }
          return checkEmpty;
    }
    iconChange() {
        if (this.state.iconName === 'icon-drop-up') {
          this.setState({ iconName: 'icon-drop-down' })
        } else {
          this.setState({ iconName: 'icon-drop-up' })
        }
      }
      getConfiguredValuesFromSiteInfo() {
        let data = {};
        let self = this;
        let array=[];
        let tempAcceptanceCriteriaarray=[];
        let originalArray = [];
        utils.makeRequest(this, EndpointConstant.ACCEPTANCE_CRITERIA, data, function (result) {
            if (result.error === 'false' || result.error === false) {
            // console.log("acceptance criteria load", result);
            let siteInfo;
            if(result.acceptanceCriteria.length===1) {
             siteInfo=result.acceptanceCriteria[0];
            //  self.setState({cancelAcceptance: siteInfo});
             
            self.setState({acceptanceCriteria:siteInfo, cancelAcceptance: siteInfo});
            // console.log("1st load cancel check",self.state.cancelAcceptance);
            self.props.details(siteInfo);
            
            } else {
              let test ={'name':'abc','id':'aaa'}
            // self.state.dummy1.name = 'abc',
            // self.state.dummy1.id = '1',
            self.setState({dummy1 : test})
            self.setState({dummy: test});
              // console.log("check Acceptancecriteria", result.acceptanceCriteria);
              result.acceptanceCriteria.forEach((data)=>{
                // console.log("check criteria", data);
                if(data.active===true) {
                  siteInfo = data;
                  // console.log("check criteria", data);
                  self.setState({cancelAcceptance: data});
                  // console.log("multi load cancel check",self.state.cancelAcceptance);
                  self.setState({acceptanceCriteria:siteInfo});
                  self.props.details(siteInfo);
                
                } 
                else if(data.active===false) {
                  array.push(data);
                  if(array.length<=5) {
                    tempAcceptanceCriteriaarray.push(data);
                    // this.state.tempAcceptanceCriteria.push(data);
                    self.setState({tempAcceptanceCriteria1: tempAcceptanceCriteriaarray});
                  }
                    // else {
                    originalArray.push(data);
                    self.setState({history1:originalArray});
                  // }
                  // console.log("array",array);
    
                }
              });
              // if(result.acceptanceCriteria.length<=5) {
              //   self.setState()
              // }
            }
           
            

            
        
         } });
       }
       handleAddTeamClose(event) {
        event.preventDefault();
        
        this.setState({
          success: false,
          
        });
      }

    render() {
        // let file = this.state.fileName;
        // console.log("file",this.state.fileName);
        //let attachmentCheck;
        let btn_class = this.state.black ? "acceptanceHistoryscrollbar" : "acceptanceHistoryNoscrollbar";
        let show_class = this.state.showtext ? 'showCmt': 'hideCmt';
         const {isEditing,acceptanceCriteria}=this.state;
        //  console.log("acceptanceCriteria",this.state.acceptanceCriteria);
         let configuredValues = acceptanceCriteria;
         
        //  console.log("configuredValues",configuredValues.id);
         let acceptanceId = configuredValues.id;

        //  console.log("history",this.state.history1);
        //  console.log("tempAcceptanceCriteria1",this.state.tempAcceptanceCriteria1);
         let checkIndex1;
         let checkIndex0;
         if(this.state.tempAcceptanceCriteria1 && this.state.tempAcceptanceCriteria1.length > 2) {
          // this.state.tempAcceptanceCriteria1.sort(function (a, b) {
          //   return b.modifiedAt - a.modifiedAt;
          // });
          
          this.state.tempAcceptanceCriteria1.sort(function(a,b){
            return new Date(b.modifiedAt) - new Date(a.modifiedAt);
          })
          // console.log("sort",this.state.tempAcceptanceCriteria1);
        } 
         if(this.state.tempAcceptanceCriteria1 && this.state.tempAcceptanceCriteria1.length===2) {
          this.state.tempAcceptanceCriteria1.forEach((check, index)=>{
            if(check!==undefined) {
            if(!check.hasOwnProperty('modifiedBy')) {
              checkIndex0 = check;
              // console.log("index0 check",check)
            } else {
              checkIndex1 = check;
              // console.log("index1 check",check)
            }
          }
           
          });
          var temp = this.state.tempAcceptanceCriteria1;
          temp[1] = checkIndex0;
          temp[0] = checkIndex1
          this.setState( {
            tempAcceptanceCriteria1: temp
          }) 
         
        }
         let commentHist = this.state.history1;
         let commentHistory = '';
         let modifiedAt;
         if(this.state.history1.length>0) {
          
          // console.log(this.state.tempAcceptanceCriteria1,'index')
          // this.state.tempAcceptanceCriteria1.map((indexCheck, index)=>{
          //   if(!indexCheck.modifiedBy && !indexCheck.modifiedAt) {
          //     console.log(indexCheck,index,"check index");
          //   }
          // })
          
         commentHistory = this.state.tempAcceptanceCriteria1.forEach((data,index)=>{
           if(data!==undefined) {
           if(data.hasOwnProperty('modifiedAt')) {
           modifiedAt = moment(data.modifiedAt).format('MM/D/YYYY');
        }
    
        // if(index<5 && this.state.black){
            return (
                <Card className="mb-2 commentHistory CardHeight">
                    {data.modifiedBy?<div className="cmt">
                        <span className="acceptanceUpdatedBy">Updated By: {data.modifiedBy}</span>
                        <span className="acceptanceUpdatedAt">Updated At: {modifiedAt}</span>
                        </div>:''}
                        <Card.Body ><p className={data.modifiedBy?"acceptanceHistory showCmt" :"firstAccep showCmt"} onClick={() => this.ShowAcceptanceCriteria(data.id)}>Click Here To View Details</p></Card.Body>
                        {/* <Card.Body><p className="singleAcceptance showCmt" onClick={() => this.ShowAcceptanceCriteria(data.id)}>Click Here To View Details</p></Card.Body> */}
                </Card>
            )
          }
        }); 
     
      } else {
        commentHistory = (
          <Card className="mb-2">
              <Card.Body>AcceptanceCriteria Not Available</Card.Body>
          </Card>
      );
      }
   
      let showAcceptanceHistory='';
        // if(AcceptanceHistory.length>0) {}
        //console.log("accepatnceId",this.state.acceptanceModalId);
        showAcceptanceHistory = this.state.history1.forEach((data,index)=>{
          // console.log("table data",data);
          const modifiedAt = moment(data.modifiedAt).format('MM/D/YYYY')
          if(this.state.acceptanceModalId!=='' && data.id===this.state.acceptanceModalId) { 
          return(
            
          <div>
          {data.modifiedBy?<span className="updatedBy-text">Updated By: {data.modifiedBy}</span>:''}
          {data.modifiedBy?<span className="updatedAt-text">Updated At: {modifiedAt}</span>:<div className="emptyDiv"></div>}
          <table className="borders">
                                      <thead>
                                      <tr >
                                          <th className="borders-right"></th>
                                          <th></th>
                                          <th>Accuracy</th>
                                          <th>Precision</th>
                                          <th>Sensitivity</th>
                                          <th>AUC</th>
                                      </tr>
                                      </thead>
  
                                      <tbody>
                                   <tr>
                                      <th rowSpan="3" className="borders">AE</th>
  
                                          <td>Mean</td>
                                          <td>{data.accuracy.AE.median}</td>
                                          <td>{data.precision.AE.median}</td>
                                          <td>{data.sensitivity.AE.median} </td>
                                          <td>{data.auc.AE.median} </td>
  
                                          </tr>
                                      <tr>
                                          <td>Upper Limit</td>
                                          <td>{data.accuracy.AE.upperLimit} </td>
                                          <td>{data.precision.AE.upperLimit} </td>
                                          <td>{data.sensitivity.AE.upperLimit} </td>
                                          <td>{data.auc.AE.upperLimit} </td>
                                      </tr>
                                      <tr>
                                          <td className="borders-bottom">Lower Limit</td>
                                          <td className="borders-bottom">{data.accuracy.AE.lowerLimit} </td>
                                          <td className="borders-bottom">{data.precision.AE.lowerLimit} </td>
                                          <td className="borders-bottom">{data.sensitivity.AE.lowerLimit} </td>
                                          <td className="borders-bottom">{data.auc.AE.lowerLimit} </td>
                                      </tr>
                                      <tr>
                                          <th rowSpan="3" className="borders">PC</th>
                                          <td>Mean</td>
                                          <td>{data.accuracy.PC.median} </td>
                                          <td>{data.precision.PC.median} </td>
                                          <td>{data.sensitivity.PC.median} </td>
                                          <td>{data.auc.PC.median} </td>
                                          </tr>
                                      <tr>
                                          <td>Upper Limit</td>
                                          <td>{data.accuracy.PC.upperLimit} </td>
                                          <td>{data.precision.PC.upperLimit} </td>
                                          <td>{data.sensitivity.PC.upperLimit}</td>
                                          <td>{data.auc.PC.upperLimit}</td>
                                      </tr>
                                      <tr>
                                          <td className="borders-bottom">Lower Limit</td>
                                          <td className="borders-bottom">{data.accuracy.PC.lowerLimit} </td>
                                          <td className="borders-bottom">{data.precision.PC.lowerLimit} </td>
                                          <td className="borders-bottom">{data.sensitivity.PC.lowerLimit}</td>
                                          <td className="borders-bottom">{data.auc.PC.lowerLimit}</td>
                                      </tr>
                                      <tr>
                                          <th rowSpan="3" className="borders">OSF</th>
                                          <td>Mean</td>
                                          <td>{data.accuracy.OSF.median} </td>
                                          <td>{data.precision.OSF.median} </td>
                                          <td>{data.sensitivity.OSF.median}</td>
                                          <td>{data.auc.OSF.median}</td>
                                          </tr>
                                      <tr>
                                          <td>Upper Limit</td>
                                          <td>{data.accuracy.OSF.upperLimit} </td>
                                          <td>{data.precision.OSF.upperLimit} </td>
                                          <td>{data.sensitivity.OSF.upperLimit}</td>
                                          <td>{data.auc.OSF.upperLimit}</td>
                                      </tr>
                                      <tr>
                                          <td className="borders-bottom">Lower Limit</td>
                                          <td className="borders-bottom">{data.accuracy.OSF.lowerLimit} </td>
                                          <td className="borders-bottom">{data.precision.OSF.lowerLimit} </td>
                                          <td className="borders-bottom">{data.sensitivity.OSF.lowerLimit} </td>
                                          <td className="borders-bottom">{data.auc.OSF.lowerLimit} </td>
                                      </tr>
                                      </tbody>
                                  </table>
                                  {data.hasOwnProperty('attachment') && Array.isArray(data.attachment)? <div>
                                 <p className="attchment">Attachment:</p>
                                 <p className="attchmentFile showCmt" onClick={() => this.downloadAttachment(data.attachment[0].attachmentPath,data.attachment[0].attachmentName)}>{data.attachment[0].attachmentName}</p>
                                 </div>:''}
                                
                                 </div>

          )
        }  
              
          });
          // let modifiedAt;
          if(configuredValues!==undefined) {
          if(configuredValues.hasOwnProperty('modifiedAt')) {
             modifiedAt = moment(configuredValues.modifiedAt).format('MM/D/YYYY');
          }
       
     
      // console.log("moment",modifiedAt)
        return (
            <div>
                <Accordion className='cardPadding'>
                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
                            <span className="search-title">CURRENT ACCEPTANCE CRITERIA</span><Image src="/Images/ico-drop-up.png" className={this.state.iconName} aria-hidden="true"></Image>
                          {configuredValues && configuredValues.modifiedBy?<span className="updatedByHeading-text">Updated By: {configuredValues.modifiedBy}</span>:''}
                          
                          {configuredValues && configuredValues.modifiedBy?<span className="updatedAtHeading-text">Updated At: {modifiedAt}</span>:''}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className='cardBodyStyle cardBodyStyle-padding'>
                                {   isEditing ? 
                                 <Form>
                                <table className="borders">
                                    <thead>
                                    <tr>
                                        <th className="borders-right"></th>
                                        <th></th>
                                        <th>Accuracy</th>
                                        <th>Precision</th>
                                        <th>Sensitivity</th>
                                        <th>AUC</th>
                                    </tr>
                                    </thead>
                                    
                                    <tbody>
                                        
                                 <tr>
                                    <th rowSpan="3" className="borders">AE</th>

                                        <td>Mean</td>
                                        <td><Form.Control size="sm" type="text" className="w-25" ref={this.input} defaultValue={configuredValues.accuracy.AE.median} onChange={(e) => { 
                                        //  if(e.target.value!='') {
                                          let configuredValues1=this.state.acceptanceCriteria;
                                          configuredValues1.accuracy.AE.median=e.target.value
                                          this.setState({ acceptanceCriteria: configuredValues1});this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.precision.AE.median} onChange={(e) => { 
                                        let configuredValues1 = this.state.acceptanceCriteria
                                        configuredValues1.precision.AE.median=e.target.value
                                        this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.sensitivity.AE.median} onChange={(e) => { 
                                         let configuredValues1=this.state.acceptanceCriteria;
                                         configuredValues1.sensitivity.AE.median=e.target.value
                                        this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.auc.AE.median} onChange={(e) => { 
                                          let configuredValues1=this.state.acceptanceCriteria;
                                          configuredValues1.auc.AE.median=e.target.value
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                          </tr>
                                    <tr>
                                        <td>Upper Limit</td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.accuracy.AE.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.accuracy.AE.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.precision.AE.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.precision.AE.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.sensitivity.AE.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.sensitivity.AE.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.auc.AE.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.auc.AE.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                    </tr>
                                    <tr>
                                        <td className="borders-bottom">Lower Limit</td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25"  defaultValue={configuredValues.accuracy.AE.lowerLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.accuracy.AE.lowerLimit =  e.target.value;
                                          let dummy = this.state.dummy
                                          dummy.name =  'nameeeeeee';
                                         this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}  /></td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.precision.AE.lowerLimit} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.precision.AE.lowerLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.sensitivity.AE.lowerLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.sensitivity.AE.lowerLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.auc.AE.lowerLimit} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.auc.AE.lowerLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                    </tr>
                                    <tr>
                                        <th rowSpan="3" className="borders">PC</th>
                                        <td>Mean</td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.accuracy.PC.median} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.accuracy.PC.median =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.precision.PC.median} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.precision.PC.median =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.sensitivity.PC.median} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.sensitivity.PC.median =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.auc.PC.median} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.auc.PC.median =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                         </tr>
                                    <tr>
                                        <td>Upper Limit</td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.accuracy.PC.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.accuracy.PC.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.precision.PC.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.precision.PC.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.sensitivity.PC.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.sensitivity.PC.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.auc.PC.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.auc.PC.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                    </tr>
                                    <tr>
                                        <td className="borders-bottom">Lower Limit</td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.accuracy.PC.lowerLimit} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.accuracy.PC.lowerLimit =  e.target.value; 
                                         this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.precision.PC.lowerLimit} onChange={(e) => { 
                                           let configuredValues1 = this.state.acceptanceCriteria
                                           configuredValues1.precision.PC.lowerLimit =  e.target.value; 
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.sensitivity.PC.lowerLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.sensitivity.PC.lowerLimit =  e.target.value; 
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.auc.PC.lowerLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.auc.PC.lowerLimit =  e.target.value; 
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                    </tr>
                                    <tr>
                                        <th rowSpan="3" className="borders">OSF</th>
                                        <td>Mean</td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.accuracy.OSF.median} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.accuracy.OSF.median =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.precision.OSF.median} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.precision.OSF.median =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.sensitivity.OSF.median} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.sensitivity.OSF.median =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.auc.OSF.median} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.auc.OSF.median =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        </tr>
                                    <tr>
                                        <td>Upper Limit</td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.accuracy.OSF.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.accuracy.OSF.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.precision.OSF.upperLimit} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.precision.OSF.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.sensitivity.OSF.upperLimit} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.sensitivity.OSF.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.auc.OSF.upperLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.auc.OSF.upperLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                    </tr>
                                    <tr>
                                        <td className="borders-bottom">Lower Limit</td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.accuracy.OSF.lowerLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.accuracy.OSF.lowerLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.precision.OSF.lowerLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.precision.OSF.lowerLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}} /></td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.sensitivity.OSF.lowerLimit} onChange={(e) => { 
                                          let configuredValues1 = this.state.acceptanceCriteria
                                          configuredValues1.sensitivity.OSF.lowerLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                        <td className="borders-bottom"><Form.Control size="sm" type="text" className="w-25" defaultValue={configuredValues.auc.OSF.lowerLimit} onChange={(e) => { 
                                         let configuredValues1 = this.state.acceptanceCriteria
                                         configuredValues1.auc.OSF.lowerLimit =  e.target.value;
                                          this.setState({ acceptanceCriteria: configuredValues1 });this.isFormValid=true}}/></td>
                                    </tr>
    
                                    </tbody>
                                        
                                </table>
                        <div className="layout-2">
                        <Row>

                          <Col md={12} sm={12}><label className="mb-2 mt-3 text-left">Files</label>

                            <div className="fileLayout mt-n2">

                              {this.state.acceptanceCriteria.attachment===undefined && this.state.allowAttachCheck && this.state.fileName && Array.isArray(this.state.fileName) && this.state.fileName.length>0? 
                                <div className={(utils.getSessionItem("role") === RoleConstant.DataScientist)
                                ? "file" : "max-file"} >{this.state.fileName.map((fileAttach, index)=>{
                                // console.log("fileAttach", fileAttach);
                                return (
                                  <div className="file-boxes" key={index}>
                                    <span className=" file-box">
                                      <p className="file-name">{fileAttach}</p>
                                      {/* <span className="file-download"><img src="Images/ico-download.png" alt='Amgen' onClick={() => this.downloadAcceptanceCriteria(data.attachmentPath,data.attachmentName)}/>
                                        {(utils.getSessionItem('role') === RoleConstant.DataScientist)
                                          }
                                      </span> */}
                                    </span>
                                  </div>
                                )
                              })
                            }
                            </div>:
                               this.state.acceptanceCriteria.attachment ?

                                <div className={(utils.getSessionItem("role") === RoleConstant.DataScientist)
                                  ? "file" : "max-file"} >
                                  {
                                    this.state.allowAttachCheck && this.state.fileName && Array.isArray(this.state.fileName) && this.state.fileName.length>0? this.state.fileName.map((fileAttach, index)=>{
                                      // console.log("fileAttach", fileAttach);
                                      return (
                                        <div className="file-boxes" key={index}>
                                          <span className=" file-box">
                                            <p className="file-name">{fileAttach}</p>
                                            {/* <span className="file-download"><img src="Images/ico-download.png" alt='Amgen' onClick={() => this.downloadAcceptanceCriteria(data.attachmentPath,data.attachmentName)}/>
                                              {(utils.getSessionItem('role') === RoleConstant.DataScientist)
                                                }
                                            </span> */}
                                          </span>
                                        </div>
                                      )
                                    }):
                                    this.state.acceptanceCriteria.attachment && this.state.acceptanceCriteria.attachment.map((data, index) => {
                                      // console.log("sdfdsf", data);
                                      // let split=data.attachmentPath.split('.')[0];
                                      return (
                                        <div className="file-boxes" key={index}>
                                          <span className=" file-box">
                                            <p className="file-name">{data.attachmentName}</p>
                                            <span className="file-download"><img src="Images/ico-download.png" alt='Amgen' onClick={() => this.downloadAcceptanceCriteria(data.attachmentPath,data.attachmentName)}/>
                                              {(utils.getSessionItem('role') === RoleConstant.DataScientist)
                                                }
                                            </span>
                                          </span>
                                        </div>
                                      )
                                    })
                                  }
                                </div>

                                : <h5 className="upload mt-3 ml-2">No Attachments</h5>}

                              {
                                (utils.getSessionItem("role") === RoleConstant.DataScientist) &&
                                <div className="select" style={{ "float": "right" }}><FileUploader onDrop={this.onDrop} self={this} id={acceptanceId}  showDatasetNameInput={this.showDatasetNameInput} hideDatasetInput={this.hideAcceptanceNameInput} hideInput={this.hideInput}/></div>
                              }
                            </div>

                          </Col>
                        </Row>
                        </div>


                                <Button variant="secondary" type="submit" className='scorecardSearch button-color userSearch float-right mt-2 mb-2' disabled={!this.isFormValid} onClick={() => this.UpdateAcceptance(acceptanceId,event)}>Update</Button>
                                <Button variant="secondary" className='clear userSearch float-right m-2' onClick={() => this.cancelAcceptance(event)} 
                                // onClick={(evt) => {evt.preventDefault(); this.toggleState(); 
                                // // console.log("cancel check",this.prevState);
                                // // console.log('dummyvalues',this.state.dummy,this.state.dummy1)
                                // let cancelAcceptanceChange = this.state.cancelAcceptance;
                                // this.setState({acceptanceCriteria: cancelAcceptanceChange});}}
                                 >Cancel</Button>
                                </Form>


                                : 

                                <Form>
                                  
                                 
                                <table className="borders">
                                    <thead>
                                    <tr>
                                        <th className="borders-right"></th>
                                        <th></th>
                                        <th>Accuracy</th>
                                        <th>Precision</th>
                                        <th>Sensitivity</th>
                                        <th>AUC</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                 <tr>
                                    <th rowSpan="3" className="borders">AE</th>

                                        <td>Mean</td>
                                        <td>{configuredValues.accuracy.AE.median}</td>
                                        <td>{configuredValues.precision.AE.median}</td>
                                        <td>{configuredValues.sensitivity.AE.median} </td>
                                        <td>{configuredValues.auc.AE.median} </td>

                                        </tr>
                                    <tr>
                                        <td>Upper Limit</td>
                                        <td>{configuredValues.accuracy.AE.upperLimit} </td>
                                        <td>{configuredValues.precision.AE.upperLimit} </td>
                                        <td>{configuredValues.sensitivity.AE.upperLimit} </td>
                                        <td>{configuredValues.auc.AE.upperLimit} </td>
                                    </tr>
                                    <tr>
                                        <td className="borders-bottom">Lower Limit</td>
                                        <td className="borders-bottom">{configuredValues.accuracy.AE.lowerLimit} </td>
                                        <td className="borders-bottom">{configuredValues.precision.AE.lowerLimit} </td>
                                        <td className="borders-bottom">{configuredValues.sensitivity.AE.lowerLimit} </td>
                                        <td className="borders-bottom">{configuredValues.auc.AE.lowerLimit} </td>
                                    </tr>
                                    <tr>
                                        <th rowSpan="3" className="borders">PC</th>
                                        <td>Mean</td>
                                        <td>{configuredValues.accuracy.PC.median} </td>
                                        <td>{configuredValues.precision.PC.median} </td>
                                        <td>{configuredValues.sensitivity.PC.median} </td>
                                        <td>{configuredValues.auc.PC.median} </td>
                                        </tr>
                                    <tr>
                                        <td>Upper Limit</td>
                                        <td>{configuredValues.accuracy.PC.upperLimit} </td>
                                        <td>{configuredValues.precision.PC.upperLimit} </td>
                                        <td>{configuredValues.sensitivity.PC.upperLimit}</td>
                                        <td>{configuredValues.auc.PC.upperLimit}</td>
                                    </tr>
                                    <tr>
                                        <td className="borders-bottom">Lower Limit</td>
                                        <td className="borders-bottom">{configuredValues.accuracy.PC.lowerLimit} </td>
                                        <td className="borders-bottom">{configuredValues.precision.PC.lowerLimit} </td>
                                        <td className="borders-bottom">{configuredValues.sensitivity.PC.lowerLimit}</td>
                                        <td className="borders-bottom">{configuredValues.auc.PC.lowerLimit}</td>
                                    </tr>
                                    <tr>
                                        <th rowSpan="3" className="borders">OSF</th>
                                        <td>Mean</td>
                                        <td>{configuredValues.accuracy.OSF.median} </td>
                                        <td>{configuredValues.precision.OSF.median} </td>
                                        <td>{configuredValues.sensitivity.OSF.median}</td>
                                        <td>{configuredValues.auc.OSF.median}</td>
                                        </tr>
                                    <tr>
                                        <td>Upper Limit</td>
                                        <td>{configuredValues.accuracy.OSF.upperLimit} </td>
                                        <td>{configuredValues.precision.OSF.upperLimit} </td>
                                        <td>{configuredValues.sensitivity.OSF.upperLimit}</td>
                                        <td>{configuredValues.auc.OSF.upperLimit}</td>
                                    </tr>
                                    <tr>
                                        <td className="borders-bottom">Lower Limit</td>
                                        <td className="borders-bottom">{configuredValues.accuracy.OSF.lowerLimit} </td>
                                        <td className="borders-bottom">{configuredValues.precision.OSF.lowerLimit} </td>
                                        <td className="borders-bottom">{configuredValues.sensitivity.OSF.lowerLimit} </td>
                                        <td className="borders-bottom">{configuredValues.auc.OSF.lowerLimit} </td>
                                    </tr>
                                    </tbody>
                                </table>
                                {(utils.getSessionItem("role") === RoleConstant.DataScientist) ? <Button variant="secondary" className='scorecardSearch button-color userSearch float-right mt-2 mb-2' onClick={(evt) => {evt.preventDefault(); this.toggleState() }}>Edit</Button>: ''}
                                </Form>
                             }   
                             <PopupModels showPopup={this.state.success} iconFlag="success" message={MessageConstant.ACCEPTANCE_CRITERIA_UPDATE} handleClosePopup={this.handleAddTeamClose.bind(this)} /> 
                             <PopupModels showPopup={this.state.errorFail} iconFlag="error" message={MessageConstant.ERROR_FAIL} handleClosePopup={this.handleErrorFailClose.bind(this)} />
                             <PopupModels showPopup={this.state.attachmentCheck} iconFlag="error" message={MessageConstant.ATTACHMENT_ERROR_FAIL} handleClosePopup={this.handleAttachmentErrorFailClose.bind(this)} />
              
                            </Card.Body>

                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion className='cardPadding historyHeading' >
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
                            <span className="search-title">ACCEPTANCE CRITERIA HISTORY</span><Image src="/Images/ico-drop-up.png" className={this.state.iconName} aria-hidden="true"></Image>

                        </Accordion.Toggle>
                        {/* ACCEPTANCE CRITERIA HISTORY */}
                        <Accordion.Collapse eventKey="0">
                          {/* <Card> */}
                        <Card.Body className={btn_class}>
                        {commentHistory} 
                        {commentHist.length >5 && <span className={show_class} onClick={this.showHistory}>Show AcceptanceCriteria History</span>}
                        </Card.Body>
                        {/* </Card> */}
                          </Accordion.Collapse>
                          </Card>
                </Accordion>
      <Modal show={this.state.showDatasetNameInput} onHide={this.hideAcceptanceNameInput} className="dataset-list-screen-dialog">
          <Modal.Header closeButton>
            <Modal.Title>FILE NAME</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="dataset-name-entry-content">
              <label>File Name</label><br />
              <input type="text" className="datasetname-input" value={this.state.fileName} name="datasetName" onChange={this.changeHandler}></input>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handlefileNameInputClose}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      <Modal
        size="lg"
        show={this.state.showAcceptance}
        onHide={this.hideAcceptance}
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className="historyModelHeading">
            AcceptanceCriteria 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBodypadding">
          {showAcceptanceHistory}
        </Modal.Body>
      </Modal>
      </div>
        )
      }


}
}

export default DataModelCurrentAcceptanceCriteria;