import React from "react";
const MessageConstant = {};

MessageConstant.REG_MESSAGE_FOR_USER =
  "Your registration request has been sent for approval and you will be notified via email about the decision. You can start using the website once your request is approved.";
MessageConstant.ADMIN_REGISTRATION_MESSAGE = (data) => {
  return (
    <div>
      Invitation has been sent to <b>{data}</b>. Registration process will be
      complete once user accepts the invite.
    </div>
  );
};
MessageConstant.USER_ALREADY_EXISTS = (data) => {
  return (
    <div>
      <b>{data}</b> is already a registered user of MedInfo Case Evaluation,
      please ask the user to login to the website using their Amgen credentials
    </div>
  );
};
MessageConstant.USER_ALREADY_EXISTS_NON_ADMIN =
  "You are already a registered user of MedInfo Case Evaluation, please login to the website using your Amgen credentials";
MessageConstant.REG_FIRSTNAME_VALIDATION = "Please enter your First Name";
MessageConstant.REG_LASTNAME_VALIDATION = "Please enter your Last Name";
MessageConstant.REG_ROLE_VALIDATION = "Please select your role";
MessageConstant.REG_TEAM_VALIDATION = "Please select a team";
MessageConstant.REG_EMAIL_VALIDATION = "Please enter your Amgen email address";
MessageConstant.REG_EMAIL_NOTVALID = "Enter valid email address";
MessageConstant.REG_SPECIAL_NOT_ALLOWED = "Special characters are not allowed";
MessageConstant.REG_JUSTIFICATION_VALIDATION =
  "Please provide Justification for access";

MessageConstant.REG_MAX_LENGTH_FIELD = 16;
MessageConstant.REG_MAX_LENGTH_TEXTAREA = 250;
MessageConstant.USER_DELETETION = (data) => {
  return (
    <div>
      <span>Are you sure you want to remove</span> <b> {data} </b>
      <span>from MICE?</span>
    </div>
  );
};
MessageConstant.USER_EDIT = (data) => {
	return (
		<div><span>Are you sure you want to update </span> <b>{data}</b><span>'s Role?</span></div>)
};
MessageConstant.USER_DELETION_SUCCESS =
  "User has been successfully removed from MICE.";
MessageConstant.USER_REJECCTION = "Do you really want to reject the user?";
MessageConstant.USER_NOTIFICATION = "User Notified Successfully";
MessageConstant.USER_COMMENT = "Please provide the comment";
MessageConstant.ADD_USER_SUCCESS = "User Added Successfully to the system";
MessageConstant.TEAM_ASSIGN_SUCCESS = "Team has been assigned successfully";
MessageConstant.QM_ASSIGN_SUCCESS =
  "Interaction has been assigned successfully";
MessageConstant.QUALITYMONITOR_ASSIGN_TO_TRANSLATIONVENDOR =
  "Interaction has been assigned to Translation Vendor for review";
MessageConstant.ASSIGN_TO_QUALITYMONITOR =
  "Interaction has been assigned to Quality Monitor for review";
MessageConstant.EDIT_ERROR = "Error has been edited successfully";
MessageConstant.ADD_ERROR = "Error has been created successfully";
MessageConstant.UPDATE_SCORECARD = "Score Card has been modified successfully.";
MessageConstant.DELETING_ERROR = "Do you really want to delete the record?";
MessageConstant.ERROR_DELETED = "Record has been deleted successfully";
MessageConstant.LOGOUT_CONFIRMATION =
  "Are you sure, Do you really want to logout from MICE";
MessageConstant.LOGOUT_MESSAGE = "You have been logged out.";
MessageConstant.USER_DOESNT_EXISTS_NON_ADMIN =
  "Your are not part of Amgen, only amgen users can register themselves into MICE";
MessageConstant.DELETING_DATASET =
  "Are you sure you want to delete the Dataset?";
MessageConstant.DATASET_DELETED = "Dataset has been deleted successfully";
MessageConstant.DELETE_CONFIRMATION =
  "Are you sure you want to delete the Attachment?";
MessageConstant.ATTACHMENT_DELETED = "Attachment has been deleted successfully";
MessageConstant.DEPLOY_PROD_CONFIRMATION =
  "Do you really want to deploy model into production environment?";
MessageConstant.RECENT_TRAINING_ERR_CONFIRMATION =
  "Training results show that the models didn't meet the acceptence criteria to move to production, Do you still want to want to deploy model into production environment";
MessageConstant.COMMENT_EDIT_SUCCESS = "Comments has been edited successfully";
MessageConstant.SCORECARD_ERROR = "Please provide all the required fields ";
MessageConstant.COMMENT_EDIT_SUCCESS = "Comments has been edited successfully";
MessageConstant.EMAIL_SUCCESS_MESSAGE = "Email Sent Successfully";
MessageConstant.EMAIL_FAILED_MESSAGE = "Email Sending Failed";
MessageConstant.ADD_SCORECARD = "Score Card has been added successfully";
MessageConstant.COMPLETED_SCORECARD = "Score Card completed successfully";
MessageConstant.ADD_QUESTIONARIES = " Questionset has been added successfully";
MessageConstant.EDIT_QUESTIONARIES = "Question has been updated successfully";
MessageConstant.DELETE_QUESTIONARIES = "Question has been deleted successfully";
MessageConstant.EDIT_QUESTIONSET = "Questionset has been updated successfully";
MessageConstant.DELETE_QUESTIONSET =
  "Questionset has been deleted successfully";
MessageConstant.INVALID_DATE = "Invalid Date range";
MessageConstant.START_DATE_EMPTY = "Please provide the Start date";
MessageConstant.END_DATE_EMPTY = "Please provide the End date";
MessageConstant.DATASET_ERRORS = "Dataset has error, you cannot delete it.";
MessageConstant.DATASET_SCORCARDS =
  "Dataset has scorecard, you cannot delete it.";
MessageConstant.NO_SAMPLING = "No scorecards available for Sampling";
MessageConstant.DELETE_ERROR_PROCESSING =
  "Deletion not allowed while processing";
MessageConstant.DOWNLOAD_ERROR_PROCESSING =
  "Download not allowed while processing";
MessageConstant.EDIT_ERROR_PROCESSING = "Edit not allowed while processing";
MessageConstant.CCU_400 = "No user found in the team";
MessageConstant.ERROR_FAIL = "Please provide the required fields";
MessageConstant.ATTACHMENT_ERROR_FAIL =
  "Please provide the required Attachment";
MessageConstant.QUALITYMONITOR_ASSIGN_TO_CALLCENTERADMIN =
  "Interaction has been assigned to Call center Agent for review";
MessageConstant.UPDATE_USER_SUCCESS = "User has been updated successfully";
MessageConstant.UPDATE_USER_ERROR = "User updation failed";
MessageConstant.DATASET_EXTRACTED_MESSAGE =
  "Your data set has been uploaded and processed successfully. You will be able to see new set of master cases in the home screen.";
MessageConstant.ACCEPTANCE_CRITERIA_UPDATE =
  "Acceptance Criteria is updated successfully";
MessageConstant.NO_ATTACH_PERMISSION = "No attach permission";
MessageConstant.FILENAME_VALIDATION =
  "Name should be 3 to 63 characters long and should have only lower-case characters, numbers, periods, and dashes. It must start with a lowercase letter or number. It cannot contain underscores, end with a dash, have consecutive periods, or use dashes adjacent to periods and cannot be formatted as an IP address";
MessageConstant.QUESTIONARIE_DELETE_CONFIRMATION =
  "Are you sure you want to delete this Question?";
MessageConstant.QUESTIONSET_DELETE_CONFIRMATION =
  "Are you sure you want to delete this Questionset?";
MessageConstant.SCORECARD_NO_USER_ERROR = "User has been deleted";
MessageConstant.SAMPLE_COUNT_CONFIRMATION =
  "The extract count is less than the Created by Team length / Call center Agent / Master Case Number / Channel / Product / Team Assignment / Country / Created By length, records will be picked up randomly. Do you want to proceed?";
MessageConstant.GET_CCADMIN_SUCCESS =
  "Created by Team details fetched successfully";
MessageConstant.SCORECARD_EXIST =
  "Scorecards are assigned to this user. If you change the role then those scorecard will be assigned to all the admins. Are you sure you want to change the role?";
MessageConstant.DOWNLOADED_INTERACTION =
  "Sub-Cases have been downloaded successfully";
MessageConstant.DOWNLOADED_MICE_REPORT =
  "Mice Report have been downloaded successfully";
MessageConstant.DULICATE_SCORECARD = "Scorecard name already exists";
MessageConstant.INPROGRESS_JSON_UPDATE = "Home Page Data is getting update, Please wait for actual data";
MessageConstant.SCORECARD_EXISTREVIEWER =
  "Scorecards are pending with this user for review. To change the role, user should complete/reassign the scorecards assigned to them";
MessageConstant.REVIEWER_DELETION = (data) => {
  return (
    <div>
      <span>
        Scorecards are pending with this user for review. If you choose to
        remove the user, scorecards will be re-assigned to the users who
        assigned those scorecards to the user. Are you sure you want to remove
      </span>{" "}
      <b> {data} </b>
      <span>from MICE?</span>
    </div>
  );
};

export default MessageConstant;
