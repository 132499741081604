import React, { Component } from 'react';
import { Button, Modal, Image } from 'react-bootstrap';
// import { withAuth } from '@okta/okta-react';
import './Header.css';
import utils from './../../utils';
import PropTypes from 'prop-types';
import EndpointConstant from './../../constants/EndpointConstant';
import MessageConstant from '../../constants/MessageConstant';

const issuer = window.okta.issuer;
const redirectUri = `${window.location.origin}${window.okta.logoutUri}`;

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alertBody: '',
      alertTitle: '',
      showAlert: false,
      showLogoutConfirmation: false
    };
    this.role = '';
    this.showAlertDialog = this.showAlertDialog.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogoutClose = this.handleLogoutClose.bind(this);
    this.logout = this.logout.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
  }
  handleLogoutClose() {
    this.setState({ showLogoutConfirmation: !this.state.showLogoutConfirmation })
  }
  // showLogoutConfirmation(){
  //   this.setState({showLogoutConfirmation:false})
  // }
  showAlertDialog(text, title) {

    // console.log("showAlertDialog ============= ");

    this.setState({
      alertBody: text,
      alertTitle: title ? title : 'Alert',
      showAlert: true
    });
  }
  logoutUser() {
    // Read idToken before local session is cleared
    // const idToken = await this.props.auth.getIdToken();
    const idToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
    const emailId = localStorage.getItem("registerE");
    window.localStorage.clear();
    localStorage.setItem("registerE", emailId);
    // alert(idToken.accessToken.accessToken);
    // Clear local session
    // await this.props.auth.logout('/');

    // Clear remote session
    window.location.href = `${issuer}/v1/logout?id_token_hint=${idToken.idToken.idToken}&post_logout_redirect_uri=${redirectUri}`;
  }
  logout() {
    let self = this;
    let data = {};
    utils.makeRequest(this, EndpointConstant.LOGOUT, data, function (result) {
      //console.log(result, 'result');
      if (result.error === false) {
        utils.showLoader();
        // utils.removeSessionItem('userId')
        // utils.removeSessionItem('sessionToken')
        // utils.removeSessionItem('role')
        // utils.removeSessionItem('firstName')
        window.sessionStorage.clear();
        self.logoutUser();
        // window.localStorage.removeItem('secureRouterReferrerPath')
        // window.localStorage.removeItem('okta-cache-storage')
        // window.localStorage.removeItem('okta-token-storage')
        // window.localStorage.removeItem('okta-pkce-storage')
        // window.localStorage.clear();
        // utils.gotoPage(RouterConstant.LOGOUT)}
        // self.setState({showLogoutConfirmation:false},()=>{ utils.gotoPage(RouterConstant.LANDING_SCREEN)})

      }

    });
  }
  handleClose() {
    this.setState({ showAlert: false });
  }
  render() {
    const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const currentRole = utils.getSessionItem("role");
    if (siteInfo.hasOwnProperty("UserRoles")) {
      this.role = siteInfo.UserRoles[currentRole];
    }

    return (
      <div className="header">
        <div className="header-title"><Image src="/Images/Logo.png" fluid />
          <h4 className="logo"> <span> | </span> {"   MedInfo Case Evaluation"}</h4>
        </div>
        <div className={this.props.hideWelcomeMsg ? 'hidden' : 'header-right'}>
        <div className="header-label mr-4">
           <a className='user-guide' href={siteInfo.UserGuideLink} target='_blank'>User Training Guide</a>
        </div>
          {/* <Image src=" " className='imageStyle' ></Image> */}
          <i className="far fa-user-circle imageStyle"></i>

          <div className="header-label mr-4">
            {utils.hasSessionItem("firstName") ? <span className='firstName'>{utils.getSessionItem("firstName")} {utils.getSessionItem("lastName")}</span> : ''}<br />
            {utils.hasSessionItem("role") ? <span className='roleStyles'>{this.role}</span> : ''}

          </div>
          <i className="fas fa-power-off text-danger power" onClick={this.handleLogoutClose}></i>
        </div>
        <div>
          <Modal size="sm" show={this.state.showAlert} onHide={this.handleClose} aria-labelledby="example-modal-sizes-title-sm">
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                {this.state.alertTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.alertBody}</Modal.Body>
            <Modal.Footer>
              <Button active variant="secondary" onClick={this.handleClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            dialogClassName="deletionModal"
            show={this.state.showLogoutConfirmation}
            onHide={this.handleLogoutClose}
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header className='confirmation-header'>
              <Modal.Title id="example-modal-sizes-title-sm">
                Confirmation
          </Modal.Title>
            </Modal.Header>
            <Modal.Body className='confirmation-body'> {MessageConstant.LOGOUT_CONFIRMATION}</Modal.Body>
            <Modal.Footer className='confirmation-footer'>
              <Button onClick={this.handleLogoutClose} className='clear'>
                No
          </Button>
              <Button active onClick={this.logout}>
                Yes
    </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
Header.propTypes = {
  hideWelcomeMsg: PropTypes.string,
  auth: PropTypes.object

}
export default Header;
