import React, { Component } from "react";
import "./ScoreCard.css";
import { Sidebar, Header } from "..";
//import PerformSampling from './PerformSampling';
import ScoreCardSearch from "./ScoreCardSearch";
import ViewScoreCardDetails from "./ViewScoreCardDetails";
import { Button, Row, Col, Modal } from "react-bootstrap";
import EndpointConstant from "./../../constants/EndpointConstant";
import utils from "./../../utils";
import Pagination from "../Pagination/Pagination";
import RoleConstant from "../../constants/RoleConstant";
import { v4 as uuidV4 } from "uuid";
import PopupModels from "../PopupModels/PopupModels";
import { ProgressBar } from "primereact/progressbar";

class ScoreCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scorecardDetails: [],
      scoreCards: [],
      scoreCardsSort: [],
      sampleSize: 3,
      sampleName: "",
      sampledScorecards: [],
      viewDetails: false,
      showpagination: false,
      currentTodos: [],
      sId: "",
      downloadRequest: "Processing",
      showDataSync: false,
      extractValue: 0,
      extractSuccess: false,
    };
    // this.getScoreCardDetails=this.getScoreCardDetails.bind(this);
    this.onSortInteraction = this.onSortInteraction.bind(this);
    this.viewScoreCardDetails = this.viewScoreCardDetails.bind(this);
    this.hideViewDetails = this.hideViewDetails.bind(this);
    this.getScoreCardList = this.getScoreCardList.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.searchResults = this.searchResults.bind(this);
    this.setSampleName = this.setSampleName.bind(this);
    this.componentRef = React.createRef();
    this.handleOnClear = this.handleOnClear.bind(this);
    this.setScorecardList = this.setScorecardList.bind(this);
    this.downloadScorecard = this.downloadScorecard.bind(this);
    this.clearState = this.clearState.bind(this);
    this.getdownloadScorecardData = this.getdownloadScorecardData.bind(this);
    this.getdownloadScorecardDataFromDB =
      this.getdownloadScorecardDataFromDB.bind(this);
    this.hideDataSyncOnCancel = this.hideDataSyncOnCancel.bind(this);
  }
  componentDidMount() {
    this.getScoreCardList();
    if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
      this.getScoreCardList("stage1");
    }
  }
  clearState() {
    this.setState({ scoreCards: [] });
  }
  getScoreCardList(stage) {
    // let data = {};
    let data = {
      datasetId: "All",
      stage: stage,
    };
    // let data={}
    let self = this;
    // utils.makeRequest(this, EndpointConstant.SCORECARD_LIST, data, function (result) {
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARDS_LISTS_FROM_DATASET,
      data,
      async function (result) {
        if (!result.error) {
          utils.showLoader();
          await fetch(result.scorecards, {
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          method: "GET",
          body: null,
        })
          .then((response) => response.json())
          .then((s3ResponseJson) => {
            utils.hideLoader();
            const scorecards = s3ResponseJson;
            // let errors = [];
            self.loading = false;
            // let newscorecards=self.state.scoreCards.length>0?self.state.scoreCards.concat(scorecards):scorecards;
            // const end = Math.ceil((self.state.sampleSize/100)*scorecards.length);
            // const sampledScorecards = scorecards.slice(0, end);
  
            let datasetId = [];
            let options = [];
            let scorecardsShow = []
            if (utils.getSessionItem("role") == RoleConstant.Reviewer) {
              scorecards.forEach((item) => {                
                if ((item.assignedToId == utils.getSessionItem("userId")) || (item.assignedToAdmin == utils.getSessionItem("userId")) || item.isAssignedToReviewer) {
                  scorecardsShow.push(item)
                  if (!datasetId.includes(item.datasetId) && item.datasetName) {
                    datasetId.push(item.datasetId);
                    options.push({
                      id: item.datasetId,
                      datasetName: item.datasetName,
                      status: "Complete",
                    });
                  }
                }
              });
            } else if (utils.getSessionItem("role") == RoleConstant.Admin) {
              let username = utils.getSessionItem("firstName") + ' ' + utils.getSessionItem("lastName")
              scorecards.forEach((item) => {
                if (((item.assignedToId == utils.getSessionItem("userId")) && item.assignedToAdmin == "" && (item.amgenQCBy == username)) || ((item.assignedToId == utils.getSessionItem("userId")) && item.assignedToAdmin && item.status == "Completed" && (item.createdBy == utils.getSessionItem("userId"))) || (item.createdBy == utils.getSessionItem("userId")) || (item.assignedToAdmin == utils.getSessionItem("userId")) || item.isAssignedToReviewer) {
                  scorecardsShow.push(item)
                }
              });
            } else {
              scorecardsShow = scorecards
            }
            self.setState(
              {
                scoreCards: scorecardsShow,
                scoreCardsSort: scorecards,
                datasets: options,
              },
              () => self.setState({ showpagination: true })
            );
          })
          .catch((error) => {
            let er;
            // console.error(error);
            // console.log("Testing 12345 Error");
          });
        } else {
          self.getScoreCardList("stage1");
        }
      }
    );
  }
  setScorecardList(scorecards) {
    let self = this;
    self.setState(
      {
        scoreCards: scorecards,
        sampledScorecards: scorecards,
        scoreCardsSort: scorecards,
      },
      () => self.setState({ showpagination: true })
    );
  }
  viewScoreCardDetails(id, datasetName) {
    //  debugger
    let self = this;
    let data = {
      id: id,
    };

    // this.setState({show:true})
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARD_DETAILS,
      data,
      function (result) {
        const scorecardDetails = result.scorecardDetails;
        self.loading = false;
        scorecardDetails[0].datasetName = datasetName;
        self.setState({
          scorecardDetails: scorecardDetails,
          viewDetails: true,
        });
      }
    );
  }
  // componentDidMount(){
  //   this.getScoreCardDetails()
  // }
  // getScoreCardDetails(){
  //   this.setState({scorecardDetails:scorecardDetails,scoreCardsSort:scorecardDetails});
  // }
  // viewScoreCardDetails(id){
  // return(
  //   <Link to="ideas" params={{ testvalue: "hello" }}>Create Idea</Link>
  // )
  // this.setState({viewDetails:true})
  // }
  hideViewDetails() {
    this.setState({ viewDetails: false });
    this.getScoreCardList();
  }
  sortInteration(prop, sortId) {
    return (a, b) => {
      if (sortId.sortId === "sort") {
        return a[prop].toString().localeCompare(b[prop].toString());
      } else if (sortId.sortId === "sort-up") {
        return b[prop].toString().localeCompare(a[prop].toString());
      } else if (sortId.sortId === "sort-down") {
        return a;
      }
    };
  }
  onSortInteraction(data, e) {
    e.preventDefault();
    //console.log(e.target.className);
    let sortId = e.target.id;
    // let className = e.target.className;
    if (sortId === "sort") {
      e.target.id = "sort-up";
      e.target.className = "fas fa-sort-up";
    } else if (sortId === "sort-up") {
      e.target.id = "sort-down";
      e.target.className = "fas fa-sort-down";
    } else if (sortId === "sort-down") {
      e.target.id = "sort";
      e.target.className = "fas fa-sort";
    }
    const { scoreCardsSort } = this.state;
    switch (data) {
      case "caseno":
        // let interactionId = [...scoreCardsSort].sort(this.sortInteration('interactionId', { sortId: sortId }));
        this.setState({
          scorecards: [...scoreCardsSort].sort(
            this.sortInteration("interactionId", { sortId: sortId })
          ),
        });
        break;
      case "ifrtype":
        // let ifrtype = [...scoreCardsSort].sort(this.sortInteration('ifrtype', { sortId: sortId }));
        this.setState({
          scorecards: [...scoreCardsSort].sort(
            this.sortInteration("ifrtype", { sortId: sortId })
          ),
        });
        break;
      case "channel":
        // let channel = [...scoreCardsSort].sort(this.sortInteration('channel', { sortId: sortId }));
        this.setState({
          scorecards: [...scoreCardsSort].sort(
            this.sortInteration("channel", { sortId: sortId })
          ),
        });
        break;
      case "attachemnt":
        // let attachemnt = [...scoreCardsSort].sort(this.sortInteration('attachemnt', { sortId: sortId }));
        this.setState({
          scorecards: [...scoreCardsSort].sort(
            this.sortInteration("attachemnt", { sortId: sortId })
          ),
        });
        break;
      case "qmassociate":
        // let qmassociate = [...scoreCardsSort].sort(this.sortInteration('qmassociate', { sortId: sortId }));
        this.setState({
          scorecards: [...scoreCardsSort].sort(
            this.sortInteration("qmassociate", { sortId: sortId })
          ),
        });
        break;
      case "tcsqc":
        // let tcsqc = [...scoreCardsSort].sort(this.sortInteration('tcsqc', { sortId: sortId }));
        this.setState({
          scorecards: [...scoreCardsSort].sort(
            this.sortInteration("tcsqc", { sortId: sortId })
          ),
        });
        break;
      case "amgenqc":
        // let amgenqc = [...scoreCardsSort].sort(this.sortInteration('amgenqc', { sortId: sortId }));
        this.setState({
          scorecards: [...scoreCardsSort].sort(
            this.sortInteration("amgenqc", { sortId: sortId })
          ),
        });
        break;
      default:
        break;
    }
  }
  onChangePage(currentTodos) {
    this.setState({ currentTodos: currentTodos }, () => {
      this.setState({ showpagination: true });
    });
  }
  searchResults(searchData) {
    // debugger
    this.setState({ scoreCards: searchData }, () => {
      this.setState({ showpagination: true });
    });
  }
  setSampleName(sampleName) {
    this.setState({ sampleName: sampleName });
  }
  handleOnClear(flag) {
    if (
      utils.getSessionItem("role") === RoleConstant.Admin ||
      utils.getSessionItem("role") === RoleConstant.QualityMonitor
    ) {
      this.componentRef.current.handleOnClear(flag);
    }
  }

  // getdownloadScorecardData() {
  //   let { scoreCards } = this.state;
  //   let data = {};
  //   data.scorecardIds = scoreCards.map(ssc => ssc.id);
  //   utils.makeRequest(this, EndpointConstant.DOWNLOAD_SCORECARD, data, function (result) {
  //     // console.log(JSON.stringify(result));
  //     window.open(result.downloadUrl, 'Download');
  //   })
  // }

  getdownloadScorecardData() {
    let { scoreCards } = this.state;
    let self = this;
    let payload = {
      scorecardIds: scoreCards.map((ssc) => ssc.id),
    };
    payload.sId = uuidV4();
    this.setState({
      sId: payload.sId,
      scorecardIds: scoreCards.map((ssc) => ssc.id),
      downloadRequest: "Processing",
    });

    // console.log("in 257:"+JSON.stringify(payload));
    //console.log("in 258:"+this.state.sId);
    utils.makeRequestInBackground(
      this,
      EndpointConstant.DOWNLOAD_SCORECARD,
      payload,
      function (result) {
        //console.log("in 247:"+JSON.stringify(result));
        //   self.setState({
        //     downloadRequest: "Processing"
        //  });
      }
    );
  }

  getdownloadScorecardDataFromDB() {
    let data = {
      sId: this.state.sId,
      //scorecardIds: scoreCards.map(ssc => ssc.id),
      downloadRequest: this.state.downloadRequest,
    };
    // console.log("in 244 data:"+JSON.stringify(data));

    let self = this;
    utils.makeRequestInBackground(
      this,
      EndpointConstant.DOWNLOAD_SCORECARD,
      data,
      function (result) {
        //console.log("in 247:"+JSON.stringify(result));
        if (result.downloadRequest == "Completed") {
          self.setState({ downloadRequest: result.downloadRequest });
          window.open(result.url, "Download");
        } else {
          self.setState({ downloadRequest: result.downloadRequest });
        }
      }
    );
  }

  downloadScorecard(event) {
    event.preventDefault();
    // console.log("extract Data from bio connect");
    //  clearInterval(this.interval);
    // console.log("this state",this.state);
    this.setState({
      showDataSync: true,
    });
    this.getdownloadScorecardData();
    this.interval = setInterval(() => {
      let val = this.state.extractValue;
      val += Math.floor(Math.random() * 5) + 1;
      if (val >= 99) {
        val = 99;
      }

      this.setState({
        extractValue: val,
      });
      //   console.log("downloadss",this.state.downloadRequest1, this.state.downloadRequest1 === "Processing")
      this.state.downloadRequest == "Processing"
        ? this.getdownloadScorecardDataFromDB()
        : "";
      // console.log("download Request 290:"+this.state.downloadRequest);
      if (this.state.downloadRequest == "Completed") {
        this.hideDataSyncOnCancel();
        this.setState({
          extractSuccess: true,
          extractValue: 0,
          extractResponseMsg: MessageConstant.DOWNLOADED_INTERACTION,
          extractResponseFlagIcon: "success",
        });
        clearInterval(this.interval);
        this.interval = null;
      }
    }, 10000);
  }

  handleExtractSuccessClose() {
    this.setState({
      extractSuccess: false,
    });
  }

  hideDataSyncOnCancel() {
    //event.preventDefault();
    this.setState({
      showDataSync: false,
      extractValue: 0,
      // downloadRequest: 'Processing',
      downloadUrl: "",
      sId: "",
      // extractSuccess: true,
      // extractResponseMsg: MessageConstant.DOWNLOADED_INTERACTION,
      // extractResponseFlagIcon: 'success'
    });
    clearInterval(this.interval);
    this.interval = null;
  }

  add3Dots(string, limit) {
    var dots = "...";
    if (string && string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }
  render() {
    const { viewDetails, scorecardDetails, datasets } = this.state;
    // const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    //const role = utils.getSessionItem("role")
    //// console.log(siteinfo.ScorecardLabels)
    // console.log(datasets,datasets)
    const scorecardList = this.state.currentTodos.map((data, index) => {
      return (
        <tr key={index}>
          <td>{data.subcaseNumber && data.subcaseNumber.toUpperCase()}</td>
          {/* <td>{data.IFRType}</td> */}
          <td title={data.datasetName}>
            {this.add3Dots(data.datasetName, 18)}
          </td>
          <td>{data.ScorecardName}</td>
          <td>{data.channel}</td>
          {/* for camel case letter */}
          <td>
            {data.questionStatus.charAt(0).toUpperCase() +
              data.questionStatus.slice(1)}
          </td>
          {/* <td>{data.attachmentIncluded}</td> */}
          <td>{data.QMAssociate}</td>
          {/* <td>{data.qualityMonitorQCBy}</td> */}
          {/* <td>{data.selectedForQCBy}</td> */}
          <td>{data.amgenQCBy ? data.amgenQCBy : "-"}</td>
          <td>
            {" "}
            <a
              className="view-details"
              onClick={() =>
                this.viewScoreCardDetails(data.id, data.datasetName)
              }
            >
              <i className="fa fa-eye"></i>&nbsp;View Details
            </a>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <Header />
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="menu-content">
          {/* {viewDetails ? '' : (role === RoleConstant.Admin || role === RoleConstant.SuperAdmin) && <PerformSampling ref={this.componentRef} getSampleName={this.setSampleName} getScorecardList={this.setScorecardList} getSearchResult={this.searchResults} clearState={this.clearState}/>} */}
          {viewDetails ? (
            ""
          ) : (
            <ScoreCardSearch
              sampleName={this.state.sampleName}
              datasets={this.state.datasets}
              getScoreCardList={this.getScoreCardList}
              getSearchResult={this.searchResults}
              clearState={this.clearState}
            />
          )}
          {viewDetails ? (
            <ViewScoreCardDetails
              scorecard="old"
              details={scorecardDetails}
              hideDetails={this.hideViewDetails}
            />
          ) : (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Sub-Case Number</th>
                    {/* <th'ifrtype', e)}>IFR Type</th> */}
                    <th>Dataset</th>
                    <th>Scorecard Name</th>
                    <th>Channel</th>
                    <th>Question Status</th>
                    {/* <th>Attachment</th> */}
                    <th>QM Associate</th>
                    {/* <th>Quality Monitor QC By</th> */}
                    {/* <th'amgenqc', e)}>Selected for QC By</th> */}
                    <th>Amgen QC By</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{scorecardList}</tbody>
              </table>
              <Row className="padding-top">
                <Col>
                  {this.state.showpagination ? (
                    <Pagination
                      data={this.state.scoreCards}
                      onChangePage={this.onChangePage}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col className="right-align">
                  <Button
                    className="button-color download-font-size"
                    onClick={this.downloadScorecard}
                  >
                    Download Score Card
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <Modal
          show={this.state.showDataSync}
          onHide={this.hideDataSyncOnCancel}
          className="dataset-extract-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>DOWNLOAD SCORE CARD</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              style={{
                height: "10px",
                borderRadius: "5px",
                border: "1px solid #0063C3",
                background: "#F2F2F2",
              }}
              showValue={false}
              value={this.state.extractValue}
            />
            <p className="progressMsg">Downloading is in progress...</p>
            <p className="progressMsg">{this.state.extractValue}% completed</p>
          </Modal.Body>
          <Modal.Footer className="confirm-popup-header">
            <Button
              variant="primary"
              className="clear "
              onClick={this.hideDataSyncOnCancel}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <PopupModels
          showPopup={this.state.extractSuccess}
          iconFlag={this.state.extractResponseFlagIcon}
          message={this.state.extractResponseMsg}
          handleClosePopup={this.handleExtractSuccessClose.bind(this)}
        />
      </div>
    );
  }
}

export default ScoreCard;

