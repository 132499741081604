const RoleConstant = {};

RoleConstant.Admin = "User";
RoleConstant.CallCenterAdmin = "CallCenterAdmin";
RoleConstant.QualityMonitor = "QualityMonitor";
RoleConstant.TranslationVendor = "TranslationVendor";
RoleConstant.DataScientist = "DataScientist";
RoleConstant.Reviewer = "Reviewer";
RoleConstant.SuperAdmin = "Admin";
RoleConstant.SAdmin = 'SuperAdmin';

export default RoleConstant;