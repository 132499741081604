const RouterConstant = {};
RouterConstant.HOME_LINK = '/home';
RouterConstant.LOGIN_LINK = '/login';
RouterConstant.REG_LINK = '/register';
RouterConstant.REGISTRATION_LINK = '/registration';
RouterConstant.USERS_LINK = '/users';
RouterConstant.FILEUPLOAD_LINK = '/dataset';
RouterConstant.VIEWDETAILS_LINK = '/viewDetails';
RouterConstant.ADMINAPPROVALPENDING_LINK = '/AdminApprovalPending';
RouterConstant.DASHBOARD_LINK = '/dashBoard';
RouterConstant.ADDUSER_LINK = '/adduser';
RouterConstant.RESET_AUTH = '/sso-callback';
RouterConstant.PAGE_NOT_FOUND = '/page-not-found';
RouterConstant.IMPLICIT_CALLBACK = '/implicit/callback';
RouterConstant.LANDING_SCREEN = '/landing-screen';
RouterConstant.GIMS_REPORTS_LINK = '/mice-reports';
RouterConstant.DATA_MODEL_REPORTS_LINK = '/data-model-reports';
RouterConstant.ERROR_LIST_LINK = '/error-list';
RouterConstant.SCORE_CARD_LINK = '/score-card';
RouterConstant.SCORE_CARD_DETAILS_LINK = '/score-card-details/:details';
RouterConstant.REDIRECTED_UNAPPROVED_USER = '/redirected-unapproved-user';
RouterConstant.LOGOUT = '/logout';
RouterConstant.DOWNLOAD = '/download';
RouterConstant.SCORE_CARD_QUESTIONARIES = '/ScorecardQuestionaries';
export default RouterConstant;
