import React, { Component } from 'react';
// import './ScoreCard.css';
import { Accordion, Card, Button, Form, Col, Image } from 'react-bootstrap';
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import PropTypes from 'prop-types';
import RoleConstant from '../../constants/RoleConstant';
import MultiSelect from "@khanacademy/react-multi-select";
class ScorecardQuestionariesSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questionName: [],
            createdBy: '',
            iconName: 'icon-drop-up',
            selectedQuestion: [],
            selectedNames: []
        }
        this.iconChange = this.iconChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnClear = this.handleOnClear.bind(this);
       
    }

    iconChange() {
        if (this.state.iconName === 'icon-drop-up') {
            this.setState({ iconName: 'icon-drop-down' })
        } else {
            this.setState({ iconName: 'icon-drop-up' })
        }
    }

    handleOnChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleOnClear() { 
        this.setState({
            selectedQuestion: [],
            selectedNames: []
        });
        this.searchResults({})
    }

    handleSearch(event) {
        event.preventDefault();
        let data = {};
        if(this.state.selectedQuestion.length) {
            data.questionsetName = this.state.selectedQuestion;
        }
        if(this.state.selectedNames.length) {
            data.createdBy = this.state.selectedNames;
        }
       this.searchResults(data)
    }

    searchResults(data) {
        let self = this;
        utils.makeRequest(this, EndpointConstant.SCORECARD_QUESTIONARIE_LIST, data, function (result) {
          utils.hideLoader();
            if (result.error === false) {
            self.loading = false;
            self.props.searchResult(result.Questionaries)
          }
        })
    }

    customFilter = (options, filter) => {
        const optionIncludesText = (option) => {
            const label = option.label.replace(/ /g, "") || "";
            const searchData = filter.toLowerCase().replace(/ /g, "") || "";
            return label.toLowerCase().startsWith(searchData);
        };
        return options.filter(optionIncludesText);
      };

    render() {
        const questionList = [];
        const createdByList = [];
       this.props.questionDetails.forEach(val => {
        questionList.push({label: val.questionsetName, value: val.id});
        if(!createdByList.some(values => values.value === val.createdBy)) {
            createdByList.push({label: val.createdByName, value: val.createdBy})
        }       
       });
      

        return (
            <Accordion defaultActiveKey="0" className='cardPadding'>
                <Card >
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
                        <span className="search-title">SEARCH SCORE CARD QUESTIONNAIRES</span><Image src="/Images/ico-drop-up.png" className={this.state.iconName}></Image>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className='cardBodyStyle'>
                            <Form onSubmit={this.handleSearch}>
                                <Form.Row className='mb-2'>
                                    <Form.Group as={Col} md="3" controlId="name">
                                        <Form.Label className='lable-names'>Questionset</Form.Label>
                                        <MultiSelect
                                            options={questionList}
                                            selected={this.state.selectedQuestion}
                                            disableSearch={false}
                                            hasSelectAll={true}
                                            filterOptions={this.customFilter}
                                            onSelectedChanged={selectedQuestion => {
                                                this.setState({ selectedQuestion });
                                                return null;
                                            }
                                            }
                                            overrideStrings={{
                                                selectSomeItems: "--Select--",
                                                allItemsAreSelected: "All are Selected",
                                                selectAll: "Select All"
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="createdBy">
                                        <Form.Label className='lable-names'>Created By</Form.Label>
                                        <MultiSelect
                                            options={createdByList}
                                            selected={this.state.selectedNames}
                                            disableSearch={false}
                                            hasSelectAll={true}
                                            filterOptions={this.customFilter}
                                            onSelectedChanged={selectedNames => {
                                                this.setState({ selectedNames });
                                                return null;
                                            }
                                            }
                                            overrideStrings={{
                                                selectSomeItems: "--Select--",
                                                allItemsAreSelected: "All are Selected",
                                                selectAll: "Select All"
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="1.5" controlId="search">

                                        <Button variant="secondary" type="submit" className='scorecardQueSearch button-color '>Search</Button>

                                    </Form.Group>
                                    <Form.Group as={Col} md="1" controlId="clear">
                                        <Button variant="secondary" className='scorecardQueSearch clear' onClick={this.handleOnClear}>Clear</Button>
                                    </Form.Group>
                                </Form.Row>
                                {/* <Button variant="secondary" type="submit" className='scorecardSearch button-color '>Search</Button>
                                <Button variant="secondary" className='clear' onClick={this.handleOnClear}>Clear</Button> */}
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
}

ScorecardQuestionariesSearch.propTypes = {
    searchResult: PropTypes.func,
    questionDetails: PropTypes.array
}

export default ScorecardQuestionariesSearch;
