import React, { Component } from 'react';
// import './Notifictions.css';
import './Search.css';
import { Form, FormControl } from 'react-bootstrap';
import EndpointConstant from './../../constants/EndpointConstant';
import PropTypes from 'prop-types';
import utils from './../../utils';
/**
 * A class that can return some html
 */
class Search extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchKey: '',
      results: {},
      showList: this.props.showList
    }
    this.changeHandler = this.changeHandler.bind(this);
    this.getLdapUsers = this.getLdapUsers.bind(this);
  }

  changeHandler(event) {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  getLdapUsers(event) {
    event.preventDefault();
    //console.log("user");
    //console.log("state",this.state);
    let data = {
      "searchKey": this.state.searchKey
    };
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_LDAP_USERS, data, function (result) {
      //console.log("results ldap",result);
      if (!result.error) {
        self.setState({ results: result, showList: true });
        self.props.handleShowList(self.state);
      }
      else {
        self.setState({ results: result, showList: false });
        self.props.handleShowList(self.state);
      }
    })
  }

  /**
   *renders the ui
   *@return {Object} .
    */
  render() {

    return (
      <Form className="form-search" onSubmit={this.getLdapUsers}>
        <FormControl type="text" name="searchKey" value={this.state.searchKey} onChange={this.changeHandler} placeholder="Search" className="search-control" />
        <img src="/Images/ico-search.png" className="search-icon" onClick={this.getLdapUsers} alt='Amgen' />
      </Form>
    );
  }
}
Search.propTypes = {
  showList: PropTypes.string,
}
export default Search;
