
import React, { Component } from 'react';
import './ErrorList.css';
import utils from './../../utils';
import { Col, Button, Form,Modal } from 'react-bootstrap';
import EndpointConstant from './../../constants/EndpointConstant';
import PropTypes from 'prop-types';
import RoleConstant from "./../../constants/RoleConstant";
// import CommonService from '../../commonService/CommonService';
import PopupModels from '../PopupModels/PopupModels';
import MessageConstant from './../../constants/MessageConstant';
// import RouterConstant from './../../constants/RouterConstant';
import ErrorListViewDetails from './ErrorListViewDetails';
import moment from 'moment';
import CommentHistory from './CommentHistory';


/**
* A class that can return some html
*@return {Object} .
*@param {object} props
*/
class EditErrorDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errorId: 'ERR-' + this.props.errorDetails.subcaseNumber.replace(/-/g, ''),
      followUp: false,
      editError: false,
      comments: this.props.errorDetails.comments,
      errorType: this.props.errorDetails.errorType,
      associatedTeam: '',
      errorCriticality: this.props.errorDetails.errorCriticality,
      status: this.props.error === 'new' ? '' : this.props.errorDetails.status,
      success: false,
      errorDescription: this.props.errorDetails.errorDescription,
      country: '',
      owner: '',
      id: '',
      errorDetails: {},
      showDetails: false,
      errorFail: false,
      showComment: false,
      latestComments:null,
     // datasetName: this.props.errorDetails.datasetNameView,
    }
    this.showErrorDetails = this.showErrorDetails.bind(this);
    this.back = this.back.bind(this);
    this.showHistory = this.showHistory.bind(this);
    this.hideHistory = this.hideHistory.bind(this);
  }

  back() {
    if (this.props.error === 'new') {
      // this.props.close()
      this.props.showEditError()
    } else {
      if (this.props.error === 'edit') {
        this.props.hideEditError()
      } else {
        // utils.gotoPage(RouterConstant.ERROR_LIST_LINK)showEditError
        // this.setState({showDetails:true})
        this.setState({ showDetails: true, errorDetails: this.props.errorDetails })
      }

    }
  }
  showErrorDetails() {
    // this.props.showEditError()
    // console.log("id data",this.state)
    let data = {
      id: this.state.id
    }
    
    let self = this
    utils.makeRequest(this, EndpointConstant.ERROR_DETAILS, data, function (result) {
      let errors = result.errorsDetails[0];
      self.loading = false;
      errors.datasetName=self.props.error ==='new'? self.props.errorDetails.datasetNameView : self.props.errorDetails.datasetName;
      // console.log("editfnshow",errors);
      self.setState({ errorDetails: errors, editError: false, success: false, showDetails: true })
      // self.setState({ show: true })

    })
    // if(this.props.error!=='new'){
    //   this.props.showEditError(this.state.errorDetails); 
    //   // window.location.reload(false)
    //   }else{
    // utils.gotoPage(RouterConstant.ERROR_LIST_LINK)
    // this.setState({showDetails:true})
    // }
  }
  
  UpdateError(id) {
    let self = this
    //  this.state.latestComments;
    if (this.props.error !== 'new') {
    if (this.state.status==='' || this.state.errorType===''||
      this.state.errorCriticality=== ''  || this.state.latestComments==='' || this.state.latestComments===null){ 
        self.setState({ errorFail: true });
      } 
     else if (((this.state.status && this.state.status !== '') && (this.state.errorType && this.state.errorType !== '') && (this.state.errorCriticality && this.state.errorCriticality !== '') &&
      (this.state.comments && this.state.comments !== '')) || ((this.state.status && this.state.status !== '') && (this.state.errorType && this.state.errorType !== '') && (this.state.errorCriticality && this.state.errorCriticality !== '') &&
      (this.state.comments && Array.isArray(this.props.errorDetails.comments) && this.props.errorDetails.comments!==''))
        ) {
          
          let data;
      // if (this.props.error !== 'new') {
        // const role = utils.getSessionItem("role");
        if(Array.isArray(this.state.comments)){
           data = {
            id: id,
            comments: this.state.latestComments,
            errorDescription: this.state.errorDescription,
            errorType: this.state.errorType,
            // associatedTeam: this.state.associatedTeam,
            errorCriticality: this.state.errorCriticality,
            status: this.state.status
          }
        } 
       else {
          data = {
          id: id,
          comments: this.state.latestComments,
          errorDescription: this.state.errorDescription,
          errorType: this.state.errorType,
          // associatedTeam: this.state.associatedTeam,
          errorCriticality: this.state.errorCriticality,
          status: this.state.status
        }
       }
        utils.makeRequest(this, EndpointConstant.UPDATE_ERROR, data, function (result) {
          // self.loading = false;
          if (result.error === false) {
            // self.props.showEditError
            self.setState({ success: true, id: result.errorId });
          }
        })
      }
    } 
    // else {
    //   this.setState({ errorFail: true });
    // }
    else if (this.state.status && this.state.status !== '' && this.state.errorType && this.state.errorType !== '' && this.state.errorCriticality && this.state.errorCriticality !== '' &&
    this.state.latestComments && this.state.latestComments!=='' ) {
// if(this.props.error =='new'){
  let eddorDetails = this.props.errorDetails
  // console.log(eddorDetails.datasetNameView, 'eer')

  // if(this.state.status!==''&& this.state.errorType!==''&& this.state.errorCriticality!==''){
  let reqData = {
    errorId: 'ERR-' + this.props.errorDetails.subcaseNumber.replace(/-/g, ''),
    interactionId: eddorDetails.id,
    subcaseNumber: eddorDetails.subcaseNumber,
    
    country: eddorDetails.interactionCountry,
    owner: eddorDetails.interactionOwner,
    associatedTeam: eddorDetails.associatedTeam,
    status: this.state.status,
    errorType: this.state.errorType,
    errorCriticality: this.state.errorCriticality,
    comments: this.state.latestComments,
    // modifiedBy:'Arun',
    errorDescription: this.state.errorDescription,
    // interactionId:eddorDetails.id
  }
  //console.log(reqData, 'data')
  utils.makeRequest(this, EndpointConstant.CREATE_ERRORS, reqData, function (result) {
    self.loading = false;
    if (result.error === false) {
      // self.props.showEditError()
      self.setState({ success: true, id: result.errorId })
    }
  })
}
else {
  this.setState({ errorFail: true });
}
// }
  }
  handleErrorFailClose() {
    this.setState({ errorFail: false })
  }
  showHistory() {
    this.setState({showComment: true});
  }
  hideHistory() {
    this.setState({showComment:false});
  }
  render() {
    // const { followUp,editError,erroDescription,comments } = this.state
    let errodDetails = this.props.errorDetails;
    // console.log('EditerrorDet',this.props)
    const role = utils.getSessionItem("role");
    const name = utils.getSessionItem("firstName");
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const AssignedToOptions = Object.keys(siteinfo.CallCenterTeam).sort().map((role, i) => {
      return (
        <option key={i} value={role}>{siteinfo.CallCenterTeam[role]}</option>
      );
    });

    const errorTypeOptions = Object.keys(siteinfo.ErrorTypes).sort().map((errorType, i) => {
      return (
        <option key={i} value={errorType}>{siteinfo.ErrorTypes[errorType]}</option>
      )
    });
    const errorCriticalityOptions = Object.keys(siteinfo.ErrorCriticality).sort().map((errorCriticality, i) => {
      return (
        <option key={i} value={errorCriticality}>{siteinfo.ErrorCriticality[errorCriticality]}</option>
      )
    });
    const statusOptions = Object.keys(siteinfo.ErrorStatus).sort().map((status, i) => {
      return (
        <option key={i} value={status}>{siteinfo.ErrorStatus[status]}</option>
      )
    })
    // const errorDescriptionDisabled=()=>{if(this.props.error==='new'){
    //   return null;
    // }else{
    //   if(role===RoleConstant.QualityMonitor){
    //     return null;
    //   }else {
    //     return true}
    //   } }
    const today = new Date();
    const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
    // eslint-disable-next-line 
    const createdAt = moment.unix(parseInt(errodDetails.createdAt)).format("MM/D/YYYY HH:mm A");
    // eslint-disable-next-line 
    const modifiedAt = moment.unix(parseInt(errodDetails.modifiedAt)).format("MM/D/YYYY HH:mm A");
    //console.log("createdDate", createdAt);
    let commentHist = this.state.comments;
    return (
      <div>
        <div className="content-layout">
          {this.state.showDetails ? '' : <div className='error-details-size'>Error Details</div>}
          {this.state.showDetails ? <ErrorListViewDetails errorDetails={this.state.errorDetails} updateErrorDetails={this.showErrorDetails} error='created' /> :
            <div>
              <div className="edit-details-layout details-row error-details-row">
                <Form onSubmit={this.UpdateError}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="ErrorID">
                      <Form.Label>Error ID</Form.Label>
                      <Form.Control value={this.props.error === 'new' ? this.state.errorId.toUpperCase() : errodDetails.errorId.toUpperCase()} disabled />
                    </Form.Group>
                    <Form.Group as={Col} controlId="CaseID">
                      <Form.Label>Sub-Case Number</Form.Label>
                      <Form.Control value={errodDetails.subcaseNumber.toUpperCase()} disabled />
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="Region">
      <Form.Label>Region</Form.Label>
      <Form.Control value={errodDetails.region} disabled/>
    </Form.Group> */}<Form.Group as={Col} controlId="CaseID">
                      <Form.Label>Dataset</Form.Label>
                      <Form.Control value={this.props.error === 'new' ?errodDetails.datasetNameView:(this.props.error==='edit'?this.props.datasetName.datasetNameView:errodDetails.datasetName)} disabled />
                    </Form.Group>
                    <Form.Group as={Col} controlId="Country">
                      <Form.Label>Country</Form.Label>
                      <Form.Control value={this.props.error === 'new' ? errodDetails.interactionCountry : errodDetails.country} disabled />
                    </Form.Group>
                    
                  </Form.Row>
                  <Form.Row>
                  <Form.Group as={Col} controlId="CaseOwner">
                      <Form.Label>Owner</Form.Label>
                      <Form.Control value={this.props.error === 'new' ? errodDetails.interactionOwner : errodDetails.owner} disabled />
                    </Form.Group>
                    <Form.Group as={Col} controlId="CreatedOn">
                      <Form.Label>Created On </Form.Label>
                      <Form.Control value={this.props.error === 'new' ? date : createdAt} disabled />
                    </Form.Group>
                    <Form.Group as={Col} controlId="ModifiedOn">
                      <Form.Label>Modified On</Form.Label>
                      <Form.Control name="date"
                        value={this.props.error === 'new' ? date : modifiedAt} disabled />
                    </Form.Group>
                    <Form.Group as={Col} controlId="CreatedBy">
                      <Form.Label>Created By</Form.Label>
                      <Form.Control value={this.props.error === 'new' ? name : errodDetails.createdBy} disabled />
                    </Form.Group>
                    
                  </Form.Row>
                  <Form.Row>
                  <Form.Group as={Col} controlId="ModifiedBy">
                      <Form.Label>Modified By</Form.Label>
                      <Form.Control value={this.props.error === 'new' ? name : errodDetails.modifiedBy} disabled>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="associatedTeam">
                      <Form.Label>Associated Team</Form.Label>
                      <Form.Control as='select' name='associatedTeam' value={this.state.associatedTeam ? this.state.associatedTeam : errodDetails.associatedTeam}
                        onChange={e => { this.setState({ associatedTeam: e.target.value }) }} disabled>
                        <option value=''>--NA--</option>
                        {AssignedToOptions}
                      </Form.Control>
                      {/* <Form.Control value={errodDetails.assignedTo} disabled/> */}
                    </Form.Group>
                    <Form.Group as={Col} controlId="ErrorType">
                      <Form.Label>Error Type</Form.Label>
                      <Form.Control as='select' name='errorType' value={this.state.errorType}
                        onChange={e => { this.setState({ errorType: e.target.value }) }} disabled={role === RoleConstant.CallCenterAdmin ? true : null}>
                        <option value=''>--NA--</option>
                        {errorTypeOptions}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="ErrorCriticality">
                      <Form.Label>Error Criticality</Form.Label>
                      <Form.Control as='select' name='errorCriticality' value={this.state.errorCriticality}
                        onChange={e => { this.setState({ errorCriticality: e.target.value }) }} disabled={role === RoleConstant.CallCenterAdmin ? true : null}>
                        <option value=''>--NA--</option>
                        {errorCriticalityOptions}
                      </Form.Control>
                    </Form.Group>
                    
                  </Form.Row>
                  <Form.Row>
                  <Form.Group as={Col} controlId="Status">
                      <Form.Label>Status</Form.Label>
                      <Form.Control as='select' value={this.state.status}
                        onChange={e => { this.setState({ status: e.target.value }) }} disabled={role === RoleConstant.CallCenterAdmin ? true : null}>
                        <option value=''>--NA--</option>
                        {statusOptions}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      
                      
                      </Form.Group>
                    <Form.Group as={Col}></Form.Group>
                    <Form.Group as={Col}></Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="Comments">
                      <Form.Label>Comments</Form.Label>
                      { <Form.Control as="textarea" onChange={(e) => this.setState({ latestComments: e.target.value })}
                        value={this.state.latestComments} />}
                      {/* {Array.isArray(this.state.comments) && <Form.Control as="textarea" onChange={(e) => this.setState({ comments: e.target.value })}
                        value={this.state.comments.length===2?this.state.comments[1].comments:this.state.comments[0].comments} />}
                         {!Array.isArray(this.state.comments) && <Form.Control as="textarea" onChange={(e) => this.setState({ comments: e.target.value })}
                        value={this.state.comments} />} */}
                      {/* disabled={role===RoleConstant.QualityMonitor && this.props.error!=='new' ? true : null} */}
                      {this.props.error !== 'new' && Array.isArray(this.state.comments) && commentHist.length > 3 &&<span onClick={this.showHistory} className="showCmt">Show Comment History</span> }
                      {/* {this.props.error != 'new' && Array.isArray(this.state.comments) && <span onClick={this.showHistory} className="showCmt">Show Comment History</span> } */}
                    </Form.Group>
                   
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="ErrorDesciption">
                      <Form.Label>Error Description</Form.Label>
                      <Form.Control as="textarea" name='errorDescription'
                        value={this.state.errorDescription} onChange={(e) => this.setState({ errorDescription: e.target.value })}
                        disabled={role === RoleConstant.CallCenterAdmin ? true : null} />
                    </Form.Group>
                  </Form.Row>
                </Form>
              </div>
              <div className='pull-right'>
                <Button variant="secondary" className="mt-4 clear follow-up-back mr-2" onClick={this.back}>
                  <img src="Images/icon-arrow-left.png" className="btn-back" alt='Amgen' />Back</Button>
                <Button type="submit" onClick={() => this.UpdateError(errodDetails.id)} className='mt-4 button-color follow-up-back'>Submit</Button>
              </div>
            </div>}
        </div>
        <PopupModels showPopup={this.state.success} iconFlag="success" message={this.props.error === 'new' ? MessageConstant.ADD_ERROR : MessageConstant.EDIT_ERROR} handleClosePopup={this.showErrorDetails} />
        <PopupModels showPopup={this.state.errorFail} iconFlag="error" message={MessageConstant.ERROR_FAIL} handleClosePopup={this.handleErrorFailClose.bind(this)} />
        <Modal
        size="lg"
        show={this.state.showComment}
        onHide={this.hideHistory}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Comment History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollcommentpopup">
          <CommentHistory commentHist={commentHist} />
        </Modal.Body>
      </Modal>
      </div>
    )
  }
}
EditErrorDetails.propTypes = {
  showEditError: PropTypes.func,
  errorDetails: PropTypes.JSON,
  error: PropTypes.string,
  datasetName:PropTypes.string,
  hideEditError: PropTypes.func
}
export default EditErrorDetails;

