import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import './Dataset.css';
import { Image, Modal, Button } from 'react-bootstrap';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
import MessageConstant from './../../constants/MessageConstant'
import PopupModels from '../PopupModels/PopupModels';
import SamplingDataset from '../Dataset/SamplingDataset'
// import PopupModels from '../PopupModels/PopupModels';

/**
 * A class that can return some html
 *@return {Object} .
 */
class DatasetList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmationModal: false,
      datasetDeleteSuccess: false,
      datasetId: '',
      dataset: this.props.dataset,
      deleteMessage: '',
      deletefail: false,
      processing: false,
      showSamplingPopup: false,
      processingErrorFromDownload: 1,
    }
    this.downloadDataset = this.downloadDataset.bind(this);
    this.showDatasetNameInputDialog = this.showDatasetNameInputDialog.bind(this);
    this.deleteConfirmation = this.deleteConfirmation.bind(this);
    this.deleteDataSet = this.deleteDataSet.bind(this);
    this.deleteConfirmationHide = this.deleteConfirmationHide.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleProcessingClose = this.handleProcessingClose.bind(this);
    this.handlesamplingClose = this.handlesamplingClose.bind(this);
    this.samplingDataset = this.samplingDataset.bind(this);
  }


  downloadDataset(id, status) {

    //console.log(id);
    if(status === 'Processing') {
      this.setState({ processing: true, processingErrorFromDownload: 2 });
    } else {
      this.props.downloadDataset(id);
    }
  }


  colorChangeStatus(status) {
    const styles = {
      Completed: '#0F9D58',
      Pending: '#EA4335',
      Processing: '#F4B400'
    }
    switch (status) {
      case 'Complete':
        return { color: styles.Completed };
      case 'Pending':
        return { color: styles.Pending };
      case 'Processing':
        return { color: styles.Processing };
      default:
        return {};
    }
  }

  showDatasetNameInputDialog(id, name, status) {
    //console.log(id);
    if (status === 'Processing') {
      this.setState({ processing: true, processingErrorFromDownload: 3 });
    } else {
      this.props.showDatasetNameInput(id, name);
    }    
  }
  deleteConfirmation(id, data) {
    if (data.status === 'Processing') {
      this.setState({ processing: true, processingErrorFromDownload: 1 });
    }
    else {
      this.setState({ deleteConfirmationModal: true, datasetId: id })
    }
    return null;
  }
  samplingDataset(id){
    // console.log("data values",id);
    this.setState({
      showSamplingPopup:!this.state.showSamplingPopup, datasetId: id 
     });
  }
  
  deleteConfirmationHide() {
    this.setState({ deleteConfirmationModal: false })
  }
  handleDeleteClose() {
    this.setState({ datasetDeleteSuccess: false })
  }
  handleClose() {
    this.setState({ deletefail: false })
  }
  handleProcessingClose() {
    this.setState({ processing: false });
  }
  handlesamplingClose(){
    this.setState({showSamplingPopup: false});
  }

  deleteDataSet() {
    let self = this
    let data = {
      id: this.state.datasetId
    }
 
    let filterDataset = this.props.datasets.filter(item=> item.id!==this.state.datasetId);
    utils.makeRequest(this, EndpointConstant.DELETE_DATASET, data, function (result) {
      // console.log("result",result)
      if (result && result.error === false) {
        const dataset = result.datasets;
        // let errors = [];
        self.loading = false;
        self.setState({ deleteConfirmationModal: false })
        self.props.deleteDataSet(dataset);
      } else if(!result){
        self.loading = false;
        self.setState({ deleteConfirmationModal: false })
        self.props.deleteDataSet(filterDataset);
      } else {
        self.loading = false;
        if (result && result.errorCode === "D_IN_ERROR") {
          self.setState({ deleteMessage: MessageConstant.DATASET_ERRORS })
        }
        if ( result && result.errorCode === "D_IN_SCORECARD") {
          self.setState({ deleteMessage: MessageConstant.DATASET_SCORCARDS })
        }
        self.setState({ deleteConfirmationModal: false, deletefail: true })
      }
    }
    )

  }
  add3Dots(string, limit) {
    var dots = "...";
    if (string && string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }
  render() {
    const claimFiles = this.props.dataset && this.props.dataset.map((data) => {
    let deleteClass = 'delete-error-no-padding';
    if(data.status !== 'Error') {
       deleteClass = 'delete-error';
    }
      const userId = utils.getSessionItem('userId');
      return (      
        <tr key={data.id}>
          <td><img src="/Images/ico-doc-excel.png" className='dataset-icon' alt='Amgen' />{data.datasetName.split('.')[0]}{data.createdBy === userId?<img className="edit-case-owner" onClick={this.showDatasetNameInputDialog.bind(null, data.id, data.datasetName, data.status)} src="/Images/ico-edit.png" alt='Amgen' />:''}</td>
          <td>
            <Moment unix format="MM/D/YYYY">
              {data.createdAt}
            </Moment></td>
            {/* <td>{this.add3Dots(associatedTeam, 20)}</td> */}
          <td>{data.createdByName}</td>
          <td style={this.colorChangeStatus(data.status)}>{data.status}</td>
          <td className="dataset-tableheader">
          {(data.uploadType === "bioconnect" && data.status !== 'Error')?<a className="sampling" onClick={() => this.samplingDataset(data.id)}><img className="dataset-action" src="/Images/ico-dataset.png" alt='Amgen' />Sampling</a> : ""}
            {(data.status !== 'Error')?<a className="downloadLink" onClick={this.downloadDataset.bind(null, data.datasetName, data.status)}><img className="dataset-action" src="/Images/ico-download.png" alt='Amgen' />Download</a>: ""}
            {data.createdBy === utils.getSessionItem('userId')?<a className={deleteClass} onClick={() => this.deleteConfirmation(data.id, data)}>
              <Image src="/Images/ico-delete.png" className='del-image-padding'></Image>&nbsp;
              <span className='delete-align'>Delete</span></a>: ''}
          </td> 
        </tr>
      );
    });
    return (
      <div >
        
        <br />
        <h4 className="dataset-title">Claim Files</h4>
        <table>
          <tbody>
            <tr>
              <th>Data Set Name</th>
              <th>Date Added</th>
              {/* <th>Associated Teams</th> */}
              <th>Data Uploaded By</th>
              <th>Status</th>
              <th className="dataset-tableheader">Action</th>
            </tr>
            {claimFiles}
          </tbody>
        </table>
        
        {/* </aside> */}
        <Modal
          dialogClassName="deletionModal"
          show={this.state.deleteConfirmationModal}
          onHide={this.deleteConfirmationHide}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            <div>{MessageConstant.DELETING_DATASET}</div>
          </Modal.Body>
          <Modal.Footer className='confirmation-footer'>
            <Button onClick={this.deleteConfirmationHide} className='clear'>
              No
          </Button>
            <Button active onClick={this.deleteDataSet}>
              Yes
    </Button>
          </Modal.Footer>
        </Modal>

        <PopupModels showPopup={this.state.deletefail} iconFlag="error" message={this.state.deleteMessage} handleClosePopup={this.handleClose.bind(this)} />

        <PopupModels showPopup={this.state.processing} iconFlag="error" message={this.state.processingErrorFromDownload === 2 ? MessageConstant.DOWNLOAD_ERROR_PROCESSING : (this.state.processingErrorFromDownload === 3 ? MessageConstant.EDIT_ERROR_PROCESSING : MessageConstant.DELETE_ERROR_PROCESSING)} handleClosePopup={this.handleProcessingClose.bind(this)} />
        
        {this.state.showSamplingPopup?<SamplingDataset  showPopup={this.state.showSamplingPopup} myProp={this.state.datasetId} handleClosePopup={this.handlesamplingClose.bind(this)}/>:''}
        
      </div>
    );
  }
}
DatasetList.propTypes = {
  dataset: PropTypes.array,
  downloadDataset: PropTypes.func,
  onSortDataList: PropTypes.func,
  showDatasetNameInput: PropTypes.func,
  samplingDataset: PropTypes.func
}
export default DatasetList;
