import React, { Component } from 'react';
// import './Notifictions.css';
import './Users.css';
import { ListGroup, Modal, Button, Row, Col, Popover, Form, Image } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Sidebar, Header, Registration, UserSearch } from '../';
import EndpointConstant from './../../constants/EndpointConstant';
import MessageConstant from './../../constants/MessageConstant';
import utils from './../../utils';
import PropTypes from 'prop-types';
import moment from 'moment';
import RoleConstant from '../../constants/RoleConstant';

/**
 * A class that can return some html
 */
class Users extends Component {
  /**
   *renders the ui
   *@return {Object} .
    */
  constructor(props) {
    super(props);
    //  this.users = [];
    this.state = {
      users: [],
      userSort: [],
      currentPage: 1,
      todosPerPage: 10,
      upperPageBound: 3,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      pageBound: 3,
      show: false,
      showDeleteConfirmation: false,
      id: '',
      status: '',
      deleteName: '',
      approveModal: false,
      deleted: false,
      showRejectionConfirmation: false,
      rejectCommentModal: false,
      comment: '',
      errorMessage: '',
      showNotification: false,
      addUserDialog: false,
      addUserMsg: '',
      addUserTitle: '',
      showUpdate: false,
      updateUserdata: {},
      scorecardExistforReviewer:false
    }
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.firstclick = this.firstclick.bind(this);
    this.lastClick = this.lastClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getUsersList = this.getUsersList.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleapproveClose = this.handleapproveClose.bind(this);
    this.compare = this.compare.bind(this);
    this.handleDeletedClose = this.handleDeletedClose.bind(this);
    this.serachResult = this.serachResult.bind(this);
    this.handleRejectPopup = this.handleRejectPopup.bind(this);
    this.handleRejectClose = this.handleRejectClose.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleRejectCommentClose = this.handleRejectCommentClose.bind(this);
    this.handleRejectUser = this.handleRejectUser.bind(this);
    this.onSortUser = this.onSortUser.bind(this);
    this.handleAddUserClose = this.handleAddUserClose.bind(this);
    this.handleUpdateShow = this.handleUpdateShow.bind(this);
    this.handleUpdateClose = this.handleUpdateClose.bind(this);
  }
  componentDidMount() {
    this.getUsersList();
    //console.log("this.props", this.props);
    if (this.props.location.search.indexOf('showAddUser') !== -1) {
      this.setState({
        show: true
      });
    }
    // console.log(JSON.stringify(this.props.location.search));
  }
  handleShow() {
    this.setState({
      show: true
    });
  }
  handleClose(msg, popupTitle, flag) {
    this.setState({
      show: false,
      addUserDialog: flag ? true : false,
      addUserMsg: msg ? msg : '',
      addUserTitle: popupTitle ? popupTitle : ''
    });
    // if(msg != undefined){
    //   this.refs.header.showAlertDialog(msg, popupTitle);
    // }
  }

  handleAddUserClose() {
    this.setState({
      addUserDialog: false,
      addUserMsg: '',
      addUserTitle: ''
    })
  }

  handleDeleteClose() {
    this.setState({ showDeleteConfirmation: false, scorecardExistforReviewer:false })
  }

  handleClick(event) {
    let listid = Number(event.target.innerText);
    this.setState({
      currentPage: listid
    });
    this.setPrevAndNextBtnClass(listid);
  }
  setPrevAndNextBtnClass(listid) {
    let totalPage = Math.ceil(this.state.users.length / this.state.todosPerPage);
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isPrevBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: '' });
    }
    else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
    }
    else if (totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
      this.setState({ isPrevBtnActive: '' });
    }
  }
  firstclick() {
    this.setState({ currentPage: 1 });
    this.setState({ upperPageBound: 3 })
    this.setState({ lowerPageBound: 0 })
    this.setPrevAndNextBtnClass(1);
  }
  lastClick() {
    let totalpages = Math.ceil(this.state.users.length / this.state.todosPerPage)
    this.setState({ currentPage: totalpages });
    this.setState({ upperPageBound: totalpages })
    this.setState({ lowerPageBound: totalpages - 3 })
    this.setPrevAndNextBtnClass(totalpages);
  }
  btnIncrementClick() {
    this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

  }
  btnDecrementClick() {
    this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

  }
  btnPrevClick() {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - 1 });
      this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

  }
  btnNextClick() {
    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
      this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

  }
  getUsersList() {
    let data = {};
    let self = this
    utils.showLoader();
    utils.makeRequest(this, EndpointConstant.GET_USER_LIST, data, function (result) {
      result.users.sort(self.compare);
      //
      utils.hideLoader();
      const users = result.users;
      self.setState({ users: users, userSort: users }, () => {
        let totalpages = Math.ceil(self.state.users.length / self.state.todosPerPage)
        if (totalpages === self.state.currentPage) {
          self.setState({ isNextBtnActive: 'disabled' })
        }
      });
      // console.log(this.state,'useres')

    })
  }
  compare(a, b) {
    if (a.status < b.status) {
      return -1;
    }
    if (a.status > b.status) {
      return 1;
    }
    return 0;
  }


  handleAprrove(id, status, firstName, lastName) {
    let data = {
      id: id,
      status: status,
      firstName: firstName,
      lastName: lastName
    }
    if (status === '3' || status === '4') {
      data.comment = this.state.comment
    }
    let self = this
    utils.makeRequest(this, EndpointConstant.USER_STATUS_UPDATE, data, function (result) {
      // const status = result;
      // console.log(status,'status')
      utils.hideLoader();
      if (status === '2') {
        self.setState({ approveModal: true })
      }
      if (status === '4') {
        self.setState({ rejectCommentModal: false, deleted: true })
      }
      if (status === '3') {
        self.setState({ rejectCommentModal: false, deleted: true })
      }
      if (result.error === false) {
        result.users.sort(self.compare);
        const users = result.users;
        self.setState({ users: users });
        self.setState({ comment: '' })
        self.setState({ errorMessage: '' })
      }


    })
  }
  handleRejectUser() {

    if (this.state.status === '3') {
      this.setState({ rejectCommentModal: true, showRejectionConfirmation: false, errorMessage: '', comment: '', scorecardExistforReviewer: false })
    }
    else {
      this.setState({ rejectCommentModal: true, showDeleteConfirmation: false, errorMessage: '', comment: '', scorecardExistforReviewer: false })
    }
  }
  handleRejectCommentClose() {
    this.setState({ rejectCommentModal: false })
  }
  handleRejectClose() {
    this.setState({ showRejectionConfirmation: false })
  }
  handleReject() {

    let data = {
      id: this.state.id,
      status: this.state.status,
      comment: this.state.comment,
      firstName: this.state.firstName,
      lastName: this.state.lastName
    }
    if (this.state.comment === '') {
      this.setState({ errorMessage: MessageConstant.USER_COMMENT })
    } else {
      this.handleAprrove(data.id, data.status, data.firstName, data.lastName);
    }
  }
  handleRejectPopup(id, status, firstName, lastName) {

    this.setState({ showRejectionConfirmation: true, id: id, status: status, firstName: firstName, lastName: lastName })
  }
  handleDeleteModal(id, status, firstName, lastName, role) {

    if(role === RoleConstant.Reviewer){
      this.checkScorecardExistForReviewer(id, status, firstName, lastName, role)
    }
    else{
      let name = firstName + ' ' +lastName
      this.setState({ showDeleteConfirmation: true, deleteName: name })
      this.setState({ id: id, status: status, firstName:firstName, lastName:lastName })
    }
    
  }

  checkScorecardExistForReviewer(id, status, firstName, lastName, role) {    
    var self = this           
    utils.makeRequest(this, EndpointConstant.GET_SCORECARD_USER, {id:id}, function (result) {
      if(!result.error && result.isExist) {
        self.setState({scorecardExistforReviewer: true});
      }
      let name = firstName + ' ' +lastName
      self.setState({ showDeleteConfirmation: true, deleteName: name })
      self.setState({ id: id, status: status, firstName: firstName, lastName: lastName}) 

    });   
  } 
  handleapproveClose() {
    this.setState({ approveModal: false })
    // this.getUsersList();
  }
  handleDelete() {
    let data = {
      id: this.state.id,
      status: this.state.status,
      firstName: this.state.firstName,
      lastName: this.state.lastName
    }
    if (this.state.comment === '') {
      this.setState({ errorMessage: MessageConstant.USER_COMMENT })
    } else {
      this.handleAprrove(data.id, data.status, data.firstName, data.lastName);
    }
  }
  handleDeletedClose() {
    this.setState({ deleted: false });
    // this.getUsersList();
  }
  handleNotify(id, firstName, lastName) {
    let data = {
      id: id,
      firstName: firstName,
      lastName: lastName
    }
    let self = this
    utils.makeRequest(this, EndpointConstant.RESEND_INVITATION, data, function (result) {
      if (result.error === false) {
        //  let popupTitle='Success'
        // self.refs.header.showAlertDialog(MessageConstant.USER_NOTIFICATION, popupTitle);
        self.setState({ status: 'notify', deleted: true })
      }

    })
  }

  filterData(filteredUsers) {

    this.setState({ users: filteredUsers })
  }
  serachResult(searchResult) {
    searchResult.sort(this.compare);
    this.setState({
      users: searchResult, currentPage: 1, upperPageBound: 3,
      lowerPageBound: 0
    }, () => this.setPrevAndNextBtnClass(this.state.currentPage))
  }

  /*
   * on Sort functionality
   */
  sortUser(prop, sortId) {
    return (a, b) => {
      if (sortId.sortId === 'sort') {
        return a[prop].toString().localeCompare(b[prop].toString());
      }
      else if (sortId.sortId === 'sort-up') {
        return b[prop].toString().localeCompare(a[prop].toString());
      }
      else if (sortId.sortId === 'sort-down') {
        return a;
      }
    }
  }
  onSortUser(data, e) {
    e.preventDefault();
    //console.log(e.target.querySelector('i').className);
    let sortId = e.target.id;
    // let className = e.target.className;
    if (sortId === 'sort') {
      e.target.id = 'sort-up';
      e.target.className = 'fas fa-sort-up';
    }
    else if (sortId === 'sort-up') {
      e.target.id = 'sort-down';
      e.target.className = 'fas fa-sort-down';
    }
    else if (sortId === 'sort-down') {
      e.target.id = 'sort';
      e.target.className = 'fas fa-sort';
    }
    const { userSort } = this.state;
    switch (data) {
      case 'name':
        // let firstName = [...userSort].sort(this.sortUser('firstName',{sortId: sortId}));
        this.setState({
          users: [...userSort].sort(this.sortUser('firstName', { sortId: sortId }))
        });
        break;
      case 'email':
        // let email = [...userSort].sort(this.sortUser('email',{sortId: sortId}));
        this.setState({
          users: [...userSort].sort(this.sortUser('email', { sortId: sortId }))
        });
        break;
      case 'role':
        // let role = [...userSort].sort(this.sortUser('role',{sortId: sortId}));
        this.setState({
          users: [...userSort].sort(this.sortUser('role', { sortId: sortId }))
        });
        break;
      case 'status':
        // let status =[...userSort].sort(this.sortUser('status',{sortId: sortId}));
        this.setState({
          users: [...userSort].sort(this.sortUser('status', { sortId: sortId }))
        });
        break;
      default:
        break;
    }
  }

  handleUpdateShow(user) {
    // console.log("current user", user);
    this.setState({
      showUpdate: true,
      updateUserdata: user
    });
  }

  handleUpdateClose(msg, popupTitle, flag) {
    this.setState({
      showUpdate: false,
      addUserDialog: flag ? true : false,
      addUserMsg: msg ? msg : '',
      addUserTitle: popupTitle ? popupTitle : ''
    });
  }

  render() {
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const Users = this.state.users;
    //console.log("Users", Users);
    // console.log(Users)
    const { currentPage, todosPerPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = Users && Users.slice(indexOfFirstTodo, indexOfLastTodo);
    // console.log("currenttodos",currentTodos);
    const rows = currentTodos && currentTodos.map((todos) => {
      let date=todos.createdAt;
      let registrationDate = moment(new Date(date)).format('MM/DD/YYYY');
      let status = siteinfo.UserStatus[todos.status];
      const popover = (<Popover id="popover-positioned-bottom" className="pop">
        <Popover.Title as="h3" >
          <span>Justification</span>
          <button type="button" onClick={() => document.body.click()} className="close">&times;</button>
        </Popover.Title>
        <Popover.Content>
          {todos.justification}
        </Popover.Content>
      </Popover>)


      return (
        <tr key={todos.id}>
          <td>
            <div >
              <OverlayTrigger trigger="click" placement="bottom" rootClose ref={`overlay`} overlay={popover}  >
                <i className="iconStyle" aria-hidden="true"></i>
              </OverlayTrigger>{todos.firstName} {todos.lastName}
            </div>
          </td>
          <td>{todos.email}</td>
          <td>{siteinfo.UserRoles[todos.role]}</td>
          <td>{status}</td>
          <td>{registrationDate}</td>
          {todos.status === '0' ? <td >
            <Row className="justify-content-md-left editIcons">
              <Col xs lg="6" className=' pr-2 pl-2 edit-align-center' >
                <span onClick={() => this.handleAprrove(todos.id, '2', todos.firstName, todos.lastName)} className='approve-style'>
                <Image src="/Images/ico-approve.png" className='image-padding'></Image>Approve</span></Col>
              <Col xs lg="5" className='pr-0 pl-0'> <span onClick={() => this.handleRejectPopup(todos.id, '3', todos.firstName, todos.lastName)} className='reject-style'>
                <Image src="/Images/ico-reject.png" className='image-padding'></Image>Reject</span></Col></Row>
          </td> : [(todos.status === '2' ? <td>
            <Row className="justify-content-md-left editIcons">
              <Col xs lg="6" className='pr-2 pl-0 edit-align-center'>
              <span onClick={() => this.handleUpdateShow(todos)} className='edit-style'>
                <Image src="/Images/ico-edit.png" alt='Amgen' className='edit-user-icon edit-only-user'></Image>Edit</span>
                {/* <img className="edit-user-icon edit-only-user" onClick={() => this.handleUpdateShow(todos)} src="/Images/ico-edit.png" alt='Amgen' /> */}
              </Col>
              <Col xs lg="4" className='pr-0 pl-0 edit-align-right'><span onClick={() => this.handleDeleteModal(todos.id, '4', todos.firstName, todos.lastName, siteinfo.UserRoles[todos.role])} className='delete-style'>
                <Image src="/Images/ico-delete.png" className='image-padding'></Image>Delete</span></Col>
            </Row></td> :
            <td><Row className="justify-content-md-left">
             
              <Col xs lg="4" className='pr-0 pl-2'><span onClick={() => this.handleNotify(todos.id, todos.firstName, todos.lastName)} className=' notify-style'>
                <Image src="/Images/ico-notify.png" className='image-padding'></Image>Notify</span></Col>
                <Col xs lg="3" className='ml-2 pr-2 pl-0'>
                <span onClick={() => this.handleUpdateShow(todos)} className='edit-style'>
                <Image src="/Images/ico-edit.png" alt='Amgen' className='edit-user-icon edit-only-user'></Image>Edit</span>
                {/* <img className="edit-user-icon" onClick={() => this.handleUpdateShow(todos)} src="/Images/ico-edit.png" alt='Amgen' /> */}
              </Col>
              <Col xs lg="3" className='pr-0 pl-2 edit-align-center'><span onClick={() => this.handleDeleteModal(todos.id, '4', todos.firstName, todos.lastName, siteinfo.UserRoles[todos.role])} className='delete-style'>
                <Image src="/Images/ico-delete.png" className='image-padding'></Image>Delete</span></Col></Row></td>)]}
          {/* <td></td> */}
        </tr>
      );
    })
    const pageNumbers = [];
    if (Users) {
      for (let i = 1; i <= Math.ceil(Users.length / todosPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    const renderPageNumbers = pageNumbers.map(number => {
      if (number === 1 && currentPage === 1) {
        return (
          <ListGroup.Item key={number} className='active list-item-padding' id={number} onClick={this.handleClick}><a id={number} >{number}</a></ListGroup.Item>
        )
      }
      else if ((number < upperPageBound + 1) && number > lowerPageBound) {
        return (
          <ListGroup.Item key={number} className={number === currentPage ? 'active list-item-padding' : 'list-item-padding'} id={number} onClick={this.handleClick}><a id={number} >{number}</a></ListGroup.Item>
        )
      }
      return null;
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = <ListGroup.Item className='list-item-padding' onClick={this.btnIncrementClick}><a > &hellip; </a></ListGroup.Item>
    }
    let pageDecrementBtn = null;

    if (lowerPageBound >= 1) {
      pageDecrementBtn = <ListGroup.Item className='list-item-padding' onClick={this.btnDecrementClick}><a > &hellip; </a></ListGroup.Item>
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === 'disabled') {
      renderPrevBtn = <ListGroup.Item className={isPrevBtnActive}><span id="btnPrev"> <Image src="/Images/ico-arrow-left-disabled.png" className='arrow-left-right'></Image> </span></ListGroup.Item>
    }
    else {
      renderPrevBtn = <ListGroup.Item className={isPrevBtnActive} onClick={this.btnPrevClick}><a id="btnPrev" > <Image src="/Images/ico-arrow-left.png" className='arrow-left-right'></Image> </a></ListGroup.Item>
    }
    let renderNextBtn = null;
    if (isNextBtnActive === 'disabled') {
      renderNextBtn = <ListGroup.Item className={isNextBtnActive}><span id="btnNext"> <Image src="/Images/ico-arrow-right-disabled.png" className='arrow-left-right'></Image> </span></ListGroup.Item>
    }
    else {
      renderNextBtn = <ListGroup.Item className={isNextBtnActive} onClick={this.btnNextClick}><a id="btnNext" > <Image src="/Images/ico-arrow-right.png" className='arrow-left-right'></Image>  </a></ListGroup.Item>
    }
    let frstpage = null;
    if (isPrevBtnActive === 'disabled') {
      frstpage = <ListGroup.Item className={isPrevBtnActive}><span id="btnNext"><Image src="/Images/ico-arrow-left-end-disabled.png" className='arrow-left-right-end'></Image> </span></ListGroup.Item>
    } else {
      frstpage = <ListGroup.Item className={isPrevBtnActive} onClick={this.firstclick}><a > <Image src="/Images/ico-arrow-left-end.png" className='arrow-left-right-end'></Image> </a></ListGroup.Item>
    }
    let lastpage = null;
    if (isNextBtnActive === 'disabled') {
      lastpage = <ListGroup.Item className={isNextBtnActive}><span id="btnNext"> <Image src="/Images/ico-arrow-right-end-disabled.png" className='arrow-left-right-end'></Image> </span></ListGroup.Item>
    } else {
      lastpage = <ListGroup.Item className={isNextBtnActive} onClick={this.lastClick}><a > <Image src="/Images/ico-arrow-right-end.png" className='arrow-left-right-end'></Image> </a></ListGroup.Item>
    }
    return (
      <div>
        <Header ref={`header`} />
        <div className="sidebar">
          <Sidebar />
        </div>

        <Modal
          dialogClassName="deletionModal"
          show={this.state.approveModal}
          onHide={this.handleapproveClose}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header closeButton className='confirmation-header'>

          </Modal.Header>

          <Modal.Body className='confirmation-body'>
            <Image src="/Images/ico-success.png" className='success-img'></Image>
            <div className='success-styles'>Success!</div>
            {MessageConstant.ADD_USER_SUCCESS}</Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.handleapproveClose}>
              Ok
          </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName="rejectionCommentModal"
          show={this.state.rejectCommentModal}
          onHide={this.handleRejectCommentClose}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header closeButton className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Form.Group controlId="rejectionComment">
              <Form.Label>Comment</Form.Label>
              <Form.Control as="textarea" rows="3" name='comment' value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value })} />
              {this.state.errorMessage.length > 0 &&
                <div className="error"><span>{this.state.errorMessage}</span></div>}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.state.status === '3' ? this.handleReject : this.handleDelete}>
              Ok
          </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName="deletionModal"
          show={this.state.showRejectionConfirmation}
          onHide={this.handleRejectClose}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header closeButton className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'> {MessageConstant.USER_REJECCTION}</Modal.Body>
          <Modal.Footer className='confirmation-footer'>
            <Button onClick={this.handleRejectClose} className='clear'>
              No
          </Button>
            <Button active onClick={this.handleRejectUser}>
              Yes
    </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName="deletionModal"
          show={this.state.showDeleteConfirmation}
          onHide={this.handleDeleteClose}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'> {this.state.scorecardExistforReviewer ? MessageConstant.REVIEWER_DELETION(this.state.deleteName) : MessageConstant.USER_DELETETION(this.state.deleteName)}</Modal.Body>
          <Modal.Footer className='confirmation-footer'>
            <Button onClick={this.handleDeleteClose} className='clear'>
              No
          </Button>
            <Button active onClick={this.handleRejectUser}>
              Yes
    </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName="deletionModal"
          show={this.state.deleted}
          onHide={this.handleDeletedClose}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'>
            {/* <Modal.Title id="example-modal-sizes-title-sm">
           Confirmation
          </Modal.Title> */}
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Image src="/Images/ico-success.png" className='success-img'></Image>
            <div className='success-styles'>Success!</div>
            {this.state.status === 'notify' ? MessageConstant.USER_NOTIFICATION : MessageConstant.USER_DELETION_SUCCESS}</Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.handleDeletedClose} className='user-deletion-cancel'>
              Ok
          </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName="deletionModal"
          show={this.state.addUserDialog}
          onHide={this.handleAddUserClose}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'>
            {/* <Modal.Title id="example-modal-sizes-title-sm">
           Confirmation
          </Modal.Title> */}
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Image src="/Images/ico-success.png" className='success-img'></Image>
            <div className='success-styles'>Success!</div>
            {this.state.addUserMsg}</Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.handleAddUserClose} className='user-deletion-cancel'>
              Ok
          </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton className='add-user-header'>ADD USER
        </Modal.Header>
          <Registration self={this} adminRegister={true} currentUser={this.state.updateUserdata} adminUpdate={false} getUser={this.getUsersList} closePopup={this.handleClose} />
        </Modal>
        <Modal show={this.state.showUpdate} onHide={this.handleUpdateClose}>
          <Modal.Header closeButton className='add-user-header'>UPDATE USER
        </Modal.Header>
          <Registration self={this} adminRegister={true} adminUpdate={true} currentUser={this.state.updateUserdata} getUser={this.getUsersList} closePopup={this.handleUpdateClose} />

        </Modal>
        <div className="menu-content">
          <UserSearch userData={Users} searchData={this.serachResult} />
          <table>
            <thead className='table-border'>
              <tr>
                <th>Name </th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th>Registered On</th>
                <th colSpan="2" className='action-center'>Action</th>
              </tr>
            </thead>
            <tbody className='table-border'>
              {rows}
            </tbody>
          </table>
          <Row className='padding-top'>
            <Col><ListGroup className="pageNumbers float-left">
              {frstpage}
              {/* {renderfirst} */}
              {renderPrevBtn}
              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}
              {renderNextBtn}
              {lastpage}
            </ListGroup><span className='verticalAlign margin-left-10'>Page {this.state.currentPage} of {Math.ceil(this.state.users.length / this.state.todosPerPage)} ({Users.length} Records)</span></Col>
            <Col >
              <Button className='addUser button-color' onClick={this.handleShow} >
                Add User</Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
Users.propTypes = {
  location: PropTypes,
}

export default Users;
