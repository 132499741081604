import React, { Component } from "react";
import "./Home.css";
import { ListGroup, Row, Col, Image, Button, Modal } from "react-bootstrap";

// import CommonService from './../../commonService/CommonService';
import { Sidebar, Header } from "../";
import InteractionSearch from "../InteractionSearch/InteractionSearch";
import EndpointConstant from "./../../constants/EndpointConstant";
import utils from "./../../utils";
import ViewDetails from "../ViewDetails/ViewDetails";
import RoleConstant from "./../../constants/RoleConstant";
import { ProgressBar } from "primereact/progressbar";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { v4 as uuidV4 } from "uuid";
import PopupModels from "../PopupModels/PopupModels";
import MessageConstant from "./../../constants/MessageConstant";
import RouterConstant from "../../constants/RouterConstant";

/**
 * A class that can return some html
 */
class Home extends Component {
  /**
   *renders the ui
   *@return {Object} .
   */
  constructor(props) {
    super(props);
    this.state = {
      interactions: [],
      interactionsSort: [],
      interactionDetails: [],
      reqScoreCardIdforEdit: null,
      show: false,
      currentPage: 1,
      todosPerPage: 10,
      upperPageBound: 3,
      lowerPageBound: 0,
      paginationLength: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 3,
      lastEvaluatedKey: null,
      link: {
        errorLink: "",
        scorecardLink: "",
      },
      searchdata: null,
      filteredValues: [],
      datasetIds: [],
      tempInteractions: [],

      showDataSync: false,
      extractSuccess: false,
      extractValue: 0,
      downloadRequest: "Processing",
      sId: "",
      downloadUrl: "",
      userDetails: [],
      roleArr: {},
      scorecards: [],
      showInProgressModal: false,
    };
    this.setShow = this.setShow.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.firstclick = this.firstclick.bind(this);
    this.lastClick = this.lastClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    this.searchResults = this.searchResults.bind(this);
    this.onSortInteraction = this.onSortInteraction.bind(this);
    this.setLinkActive = this.setLinkActive.bind(this);
    this.setLinkInactive = this.setLinkInactive.bind(this);
    this.updateIntercations = this.updateIntercations.bind(this);
    this.setSearchData = this.setSearchData.bind(this);
    this.setLastEvaluatedKey = this.setLastEvaluatedKey.bind(this);
    this.downloadInteraction = this.downloadInteraction.bind(this);
    this.getdatasetIds = this.getdatasetIds.bind(this);
    this.getProcessingDatasetListForBioconnect =
      this.getProcessingDatasetListForBioconnect.bind(this);
    this.extractDataFromBioConnect = this.extractDataFromBioConnect.bind(this);
    this.hideDataSyncOnCancel = this.hideDataSyncOnCancel.bind(this);
    this.getDatasetListForBioconnect =
      this.getDatasetListForBioconnect.bind(this);
    this.interval = null;
    this.handleExtractSuccessClose = this.handleExtractSuccessClose.bind(this);
    this.getName = this.getName.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.getCreatedName = this.getCreatedName.bind(this);
    this.handleInprogressClose = this.handleInprogressClose.bind(this);
  }

  downloadInteraction(isDatasetNeeded) {
    // console.log("interactions",data);
    let data = {};
    if (isDatasetNeeded) {
      if (!this.state.searchdata) {
        data = {};
        data.datasetId = this.state.datasetIds;
      } else {
        if (this.state.searchdata.interactionId !== "") {
          data.caseNumber = this.state.searchdata.interactionId;
        }
        //  if(this.state.searchdata.datasetIds.length !== 0) {
        //    data.datasetId = this.state.datasetIds;
        //  }
        if (this.state.searchdata.subcaseNumber !== "") {
          data.subcaseNumber = this.state.searchdata.subcaseNumber;
        }
        if (this.state.searchdata.associatedTeam.length !== 0) {
          data.associatedTeam = this.state.searchdata.associatedTeam;
        }
        if (this.state.searchdata.interactionOwner !== "") {
          data.interactionOwner = this.state.searchdata.interactionOwner;
        }
        if (this.state.searchdata.selected.length !== 0) {
          data.datasetId = this.state.searchdata.selected;
        }
        if (this.state.searchdata.country.length !== 0) {
          data.interactionCountry = this.state.searchdata.country;
        }
        if (this.state.searchdata.channel.length !== 0) {
          data.channel = this.state.searchdata.channel;
        }
        if (this.state.searchdata.status.length !== 0) {
          data.status = this.state.searchdata.status;
        }
        if (this.state.searchdata.assignedTo.length !== 0) {
          data.assignedTo = this.state.searchdata.assignedTo;
        }
      }

      data.sId = uuidV4();
    } else {
      data = {
        sId: this.state.sId,
      };
    }
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.DOWNLOAD_INTERACTION_FROM_FILE,
      data,
      function (result) {
        // console.log(JSON.stringify(result),"abc");
        // window.open(result.downloadUrl, 'Download');
        self.setState({
          downloadRequest: "Completed",
          downloadUrl: result.downloadUrl,
        });
      }
    );
  }

  handleClick(event) {
    let listid = Number(event.target.innerText);
    // let totalPage = Math.ceil(this.state.interactions.length / this.state.todosPerPage);
    let totalPage = Math.ceil(
      this.state.paginationLength / this.state.todosPerPage
    );
    //console.log("in 160 handle click:"+ totalPage);

    //let data=this.state.searchdata
    if (listid === totalPage && this.state.searchdata === null) {
      this.lastClick();
    } else {
      this.setState({
        currentPage: listid,
      });
      this.setPrevAndNextBtnClass(listid);
    }
  }

  setPrevAndNextBtnClass(listid) {
    // let totalPage = Math.ceil(this.state.interactions.length / this.state.todosPerPage);
    let totalPage = Math.ceil(
      this.state.paginationLength / this.state.todosPerPage
    );
    //console.log("in prevnext ntotalPage:"+ ntotalPage);
    //console.log("in 178 handle click:"+ totalPage);
    this.setState({ isNextBtnActive: "disabled" });
    this.setState({ isPrevBtnActive: "disabled" });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: "" });
      if (this.state.lastEvaluatedKey !== null) {
        this.setState({ isNextBtnActive: "" });
      }
    } else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
    } else if (totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
      this.setState({ isPrevBtnActive: "" });
    }

    // else if (listid === totalPage ) {
    //   this.setState({ isNextBtnActive: '' });
    //   // this.lastClick();
    // }
  }
  firstclick() {
    this.setState({ currentPage: 1 });
    this.setState({ upperPageBound: 3 });
    this.setState({ lowerPageBound: 0 });
    this.setPrevAndNextBtnClass(1);
  }
  lastClick() {
    const { lastEvaluatedKey } = this.state;
    let totalpages = Math.ceil(
      this.state.paginationLength / this.state.todosPerPage
    );

    this.setState({ currentPage: totalpages });
    this.setState({ upperPageBound: totalpages });
    this.setState({ lowerPageBound: totalpages - 3 });

    this.setPrevAndNextBtnClass(totalpages);
    if (lastEvaluatedKey && this.state.searchdata === null) {
      this.getInteractionList(true);
    } else if (lastEvaluatedKey) {
      this.lastClickFilterInteration(true);
    }
  }
  lastClickFilterInteration(paginate) {
    // console.log("searchlastEvaluatedKey",this.state.lastEvaluatedKey);
    // console.log("searchData",this.state.searchdata);
    // let data = {}
    let data = {
      lastEvaluatedKey:
        paginate === "back" ? null : this.state.lastEvaluatedKey,
      //fortesting: true,
    };
    if (this.state.searchdata.interactionId !== "") {
      data.caseNumber = this.state.searchdata.interactionId;
    }
    if (this.state.searchdata.subcaseNumber !== "") {
      data.subcaseNumber = this.state.searchdata.subcaseNumber;
    }
    // if(this.state.createdBy!==''){
    //   data.createdBy=this.state.createdBy
    // }
    if (this.state.searchdata.associatedTeam.length !== 0) {
      data.associatedTeam = this.state.searchdata.associatedTeam;
    }
    if (this.state.searchdata.interactionOwner !== "") {
      data.interactionOwner = this.state.searchdata.interactionOwner;
    }
    if (this.state.searchdata.selected.length !== 0) {
      data.datasetId = this.state.searchdata.selected;
    }
    if (this.state.searchdata.country.length !== 0) {
      data.interactionCountry = this.state.searchdata.country;
    }
    if (this.state.searchdata.channel.length !== 0) {
      data.channel = this.state.searchdata.channel;
    }
    if (this.state.searchdata.status.length !== 0) {
      data.status = this.state.searchdata.status;
    }
    if (this.state.searchdata.assignedTo.length !== 0) {
      data.assignedTo = this.state.searchdata.assignedTo;
    }

    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.GET_INTERACTION_LIST_FROM_FILE,
      data,
      function (result) {
        let interactions = result.interactions;

        if (interactions.length > 0) {
          var joined =
            paginate === "back"
              ? interactions
              : self.state.interactions.concat(interactions);
          self.setState({ interactions: joined });
          // let filteredInteractions=self.state.interactions.concat(interactions)

          if (result.hasOwnProperty("lastEvaluatedKey")) {
            self.setState({ lastEvaluatedKey: result.lastEvaluatedKey });
          } else {
            self.setState({ lastEvaluatedKey: null }, function () {
              if (paginate) {
                self.updateLastPage();
              }
            });
          }
        } else {
          self.setState({ lastEvaluatedKey: null }, function () {
            if (paginate) {
              self.updateLastPage();
            }
          });
        }
      }
    );
  }
  updateLastPage() {
    // let totalpages = Math.ceil(this.state.interactions.length / this.state.todosPerPage)
    let listid = this.state.currentPage;
    this.setPrevAndNextBtnClass(listid);
  }
  btnIncrementClick() {
    this.setState({
      upperPageBound: this.state.upperPageBound + this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
    });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnDecrementClick() {
    this.setState({
      upperPageBound: this.state.upperPageBound - this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
    });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnPrevClick() {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - 1 });
      this.setState({
        lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnNextClick() {
    if (this.state.currentPage + 1 > this.state.upperPageBound) {
      this.setState({
        upperPageBound: this.state.upperPageBound + this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    let listidCall = this.state.currentPage;
    // let totalPage = Math.ceil(this.state.interactions.length / this.state.todosPerPage);
    let totalPage = Math.ceil(
      this.state.paginationLength / this.state.todosPerPage
    );
    // console.log("in 331 next:"+ ntotalPage);
    this.setPrevAndNextBtnClass(listid);
    if (listidCall === totalPage) {
      this.lastClick();
    }
  }

  getScoreCardList() {
    let data = {
      datasetId: "All",
    };
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARDS_LISTS_FROM_DATASET,
      data,
      async function (result) {
        //utils.showLoader();
          await fetch(result.scorecards, {
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            method: "GET",
            body: null,
          })
            .then((response) => response.json())
            .then((s3ResponseJson) => {
              const scorecards = s3ResponseJson;
              // let errors = [];
              self.loading = false;
              // console.log('scorecards',scorecards)
              self.setState({ scorecards: scorecards }, () =>
                self.setState({ showpagination: true })
              );
              utils.hideLoader();
            })
            .catch((error) => {
              let er;
              // console.error(error);
              // console.log("Testing 12345 Error");
            });
      }
    );
  }

  getInteractionList(paginate) {
    // utils.showLoader();
    let data = {
      lastEvaluatedKey:
        paginate === "back" ? null : this.state.lastEvaluatedKey,
      //fortesting: true
    };
    let self = this;
    // console.log("in in341:"+data, paginate);
    utils.makeRequest(
      this,
      EndpointConstant.GET_INTERACTION_LIST_FROM_FILE,
      data,
      async function (result) {
        if (utils.getSessionItem("role") === RoleConstant.CallCenterAdmin) {
          utils.showLoader();
        }

        let interactions = result.interactions;
        // console.log("Testing 12345", result.interactions)
        //let interactions;

        if (
          utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
          utils.getSessionItem("role") === RoleConstant.SAdmin ||
          utils.getSessionItem("role") === RoleConstant.Admin
        ) {
          utils.showLoader();
          await fetch(interactions, {
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            method: "GET",
            body: null,
          })
            .then((response) => response.json())
            .then((s3ResponseJson) => {
              // console.log("s3 response Data", s3ResponseJson);
              if (utils.getSessionItem("role") === RoleConstant.Admin) {
                interactions = s3ResponseJson.filter((data) => {
                  return data.createdBy === utils.getSessionItem("userId");
                });
              } else {
                interactions = s3ResponseJson;
              }
              // console.log("Testing 12345",self);
              utils.hideLoader();
            })
            .catch((error) => {
              let er;
              // console.error(error);
              // console.log("Testing 12345 Error");
            });
        }

        // console.log("Testing 12345", interactions);

        let allinteractions = self.state.tempInteractions.concat(
          result.interactions
        );
        // self.state.tempInteractions=[].concat(result.interactions);
        self.setState({ tempInteractions: allinteractions });
        const interactionOrdered = {};
        interactions.forEach((interaction) => {
          if (!interactionOrdered.hasOwnProperty(interaction.interactionId)) {
            interactionOrdered[interaction.interactionId] = [];
          }
          interactionOrdered[interaction.interactionId].push(interaction);
          //console.log(interaction);
        });
        //console.log(interactionOrdered);
        if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
          Object.keys(interactionOrdered).forEach((interactionId) => {
            let mlCompleted = true;
            interactionOrdered[interactionId].forEach((subcase) => {
              if (!subcase.hasOwnProperty("mlResult") || !subcase.mlResult) {
                // console.log(interactionId + "subcase.mlResult ++ " + subcase.mlResult);
                mlCompleted = false;
              }
            });
            if (mlCompleted) {
              delete interactionOrdered[interactionId];
            }
          });
        }
        // if (utils.getSessionItem("role") === RoleConstant.SuperAdmin) {
        //   Object.keys(interactionOrdered).forEach((interactionId) => {
        //     let mlCompleted = true;
        //     interactionOrdered[interactionId].forEach((subcase) => {
        //       if (!subcase.hasOwnProperty('mlResult') || !subcase.mlResult) {
        //         // console.log(interactionId + "subcase.mlResult ++ " + subcase.mlResult);
        //         mlCompleted = false;
        //       }
        //     });
        //     if (mlCompleted) {
        //       delete interactionOrdered[interactionId];
        //     }
        //   });
        // }
        interactions = [];
        Object.keys(interactionOrdered).forEach((interactionId) => {
          interactions = interactions.concat(interactionOrdered[interactionId]);
        });

        // interactions = interactions.concat(self.state.interactions);

        //console.log("interactions ++ " + JSON.stringify(interactions));
        var joined =
          paginate === "back"
            ? interactions
            : self.state.interactions.concat(interactions);
        self.setState({ interactions: joined, interactionsSort: joined });
        // utils.hideLoader();
        if (utils.getSessionItem("role") === RoleConstant.CallCenterAdmin) {
          setTimeout(function () {
            // window.location.hash = path;
            // window.location = path;
            utils.hideLoader();
          }, 200);
        } else {
          self.loading = false;
        }

        // self.loading = false;
        if (result.hasOwnProperty("lastEvaluatedKey")) {
          self.setState({ lastEvaluatedKey: result.lastEvaluatedKey });
        } else {
          self.setState({ lastEvaluatedKey: null }, function () {
            if (paginate) {
              self.updateLastPage();
            }
          });
          if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
            const interactionOrdered1 = {};
            // console.log(self.state.tempInteractions,'self.state.interactions')
            self.state.tempInteractions.forEach((interaction) => {
              if (
                !interactionOrdered1.hasOwnProperty(interaction.interactionId)
              ) {
                interactionOrdered1[interaction.interactionId] = [];
              }
              interactionOrdered1[interaction.interactionId].push(interaction);
              //console.log(interaction);
            });

            Object.keys(interactionOrdered1).forEach((interactionId) => {
              let mlCompleted = true;
              interactionOrdered1[interactionId].forEach((subcase) => {
                if (!subcase.hasOwnProperty("mlResult") || !subcase.mlResult) {
                  // console.log(interactionId + "subcase.mlResult ++ " + subcase.mlResult);
                  mlCompleted = false;
                }
              });
              if (mlCompleted) {
                delete interactionOrdered1[interactionId];
              }
            });
            // console.log(interactionOrdered1,'interactionOrdered1')
            let allInteractions = [];
            Object.keys(interactionOrdered1).forEach((interactionId) => {
              allInteractions = allInteractions.concat(
                interactionOrdered1[interactionId]
              );
            });
            self.setState({ interactions: allInteractions }, () => {
              // self.lastClick();
            });
          }
        }
        // console.log(this.state,'useres')
      }
    );
    // utils.hideLoader();
  }
  searchResults(searchData, filteredvalues) {
    // debugger
    this.setState(
      {
        interactions: searchData,
        currentPage: 1,
        upperPageBound: 3,
        lowerPageBound: 0,
        filteredValues: filteredvalues ? filteredvalues : [],
      },
      () => this.setPrevAndNextBtnClass(this.state.currentPage)
    );
  }
  setSearchData(data) {
    if (
      data.subcaseNumber === "" &&
      data.associatedTeam.length === 0 &&
      data.selected.length === 0 &&
      data.country.length === 0 &&
      data.channel.length === 0 &&
      data.status.length === 0 &&
      data.assignedTo.length === 0 &&
      data.interactionId === ""
    ) {
      this.setState({ searchdata: null });
    } else {
      this.setState({ searchdata: data });
    }
  }
  setLastEvaluatedKey(lastEvaluatedKey) {
    this.setState({ lastEvaluatedKey: lastEvaluatedKey });
  }
  viewInteractionDetails(interactionId, scoreCardId) {
    //  debugger
    let self = this;
    let data = {
      interactionId: interactionId,
    };
    // this.setState({show:true})
    utils.makeRequest(
      this,
      EndpointConstant.GET_INTERACTION_DETAILS,
      data,
      function (result) {
        // debugger
        const interactions = result;
        self.loading = false;
        self.setState({ interactionDetails: interactions });
        self.setState({ reqScoreCardIdforEdit: scoreCardId });
        self.setState({ show: true });
      }
    );
  }
  setShow(type) {
    this.setState({ show: "" });
    if (type === 'new') {
      if (this.state.searchdata) {
        this.search();
        this.setState({showInProgressModal: true})
      } else {
        this.setState({showInProgressModal: true})
        this.getInteractionList();
      }
    }
    // this.getInteractionList('back');
  }
  updateIntercations(details, team) {
    // let changedInteraction=this.state.interactions.filter( function (interaction) {
    //   return interaction.id === id
    // });if
    if (this.state.searchdata !== null) {
      let self = this;
      let data = {};
      if (this.state.searchdata.interactionId !== "") {
        data.caseNumber = this.state.searchdata.interactionId;
      }
      if (this.state.searchdata.subcaseNumber !== "") {
        data.subcaseNumber = this.state.searchdata.subcaseNumber;
      }
      // if(this.state.createdBy!==''){
      //   data.createdBy=this.state.createdBy
      // }
      if (this.state.searchdata.associatedTeam.length !== 0) {
        data.associatedTeam = this.state.searchdata.associatedTeam;
      }
      if (this.state.searchdata.selected.length !== 0) {
        data.datasetId = this.state.searchdata.selected;
      }
      if (this.state.searchdata.country.length !== 0) {
        data.interactionCountry = this.state.searchdata.country;
      }
      if (this.state.searchdata.channel.length !== 0) {
        data.channel = this.state.searchdata.channel;
      }
      if (this.state.searchdata.status.length !== 0) {
        data.status = this.state.searchdata.status;
      }
      if (this.state.searchdata.assignedTo.length !== 0) {
        data.assignedTo = this.state.searchdata.assignedTo;
      }
      utils.makeRequest(
        this,
        EndpointConstant.GET_INTERACTION_LIST_FROM_FILE,
        data,
        function (result) {
          //
          const interactions = result.interactions;
          // self.loading = false;
          self.setState({ interactions: interactions });
          // console.log(this.state,'useres')
        }
      );
    } else {
      for (let i = 0; i < this.state.interactions.length; i++) {
        if (this.state.interactions[i].id === details.id) {
          let stateIntr = this.state.interactions;
          if (
            utils.getSessionItem("role") === RoleConstant.QualityMonitor &&
            team === RoleConstant.CallCenterAdmin
          ) {
            stateIntr[i].assignedTo = details.hasOwnProperty("parentCCATeam")
              ? details.parentCCATeam
              : stateIntr[i].associatedTeam;
          } else {
            stateIntr[i].assignedTo = team;
          }
          stateIntr[i].status = "Assigned";
          if (
            utils.getSessionItem("role") === RoleConstant.CallCenterAdmin ||
            utils.getSessionItem("role") === RoleConstant.TranslationVendor
          ) {
            // delete stateIntr[i];
            stateIntr.splice(i, 1);
          }
          this.setState({
            interactions: stateIntr,
          });
        }
      }
    }
  }
  /*
   * on Sort functionality
   */
  sortInteration(prop, sortId) {
    return (a, b) => {
      if (sortId.sortId === "sort") {
        return a[prop].toString().localeCompare(b[prop].toString());
      } else if (sortId.sortId === "sort-up") {
        return b[prop].toString().localeCompare(a[prop].toString());
      } else if (sortId.sortId === "sort-down") {
        return a;
      }
    };
  }
  onSortInteraction(data, e) {
    e.preventDefault();
    //console.log(e.target.outerHTML);
    let sortId = e.target.id;
    // let className = e.target.className;
    if (sortId === "sort") {
      e.target.id = "sort-up";
      e.target.className = "fas fa-sort-up";
    } else if (sortId === "sort-up") {
      e.target.id = "sort-down";
      e.target.className = "fas fa-sort-down";
    } else if (sortId === "sort-down") {
      e.target.id = "sort";
      e.target.className = "fas fa-sort";
    }
    const { interactionsSort } = this.state;
    switch (data) {
      case "caseno":
        // let interactionId = [...interactionsSort].sort(this.sortInteration('interactionId', { sortId: sortId }));
        this.setState({
          interactions: [...interactionsSort].sort(
            this.sortInteration("interactionId", { sortId: sortId })
          ),
        });
        break;
      case "createdby":
        // let createdBy = [...interactionsSort].sort(this.sortInteration('createdBy', { sortId: sortId }));
        this.setState({
          interactions: [...interactionsSort].sort(
            this.sortInteration("createdBy", { sortId: sortId })
          ),
        });
        break;
      case "assocteam":
        // let associatedTeam = [...interactionsSort].sort(this.sortInteration('associatedTeam', { sortId: sortId }));
        this.setState({
          interactions: [...interactionsSort].sort(
            this.sortInteration("associatedTeam", { sortId: sortId })
          ),
        });
        break;
      case "caseowner":
        // let interactionOwner = [...interactionsSort].sort(this.sortInteration('interactionOwner', { sortId: sortId }));
        this.setState({
          interactions: [...interactionsSort].sort(
            this.sortInteration("interactionOwner", { sortId: sortId })
          ),
        });
        break;
      case "casectry":
        // let interactionCountry = [...interactionsSort].sort(this.sortInteration('interactionCountry', { sortId: sortId }));
        this.setState({
          interactions: [...interactionsSort].sort(
            this.sortInteration("interactionCountry", { sortId: sortId })
          ),
        });
        break;
      case "channel":
        // let channel = [...interactionsSort].sort(this.sortInteration('channel', { sortId: sortId }));
        this.setState({
          interactions: [...interactionsSort].sort(
            this.sortInteration("channel", { sortId: sortId })
          ),
        });
        break;
      case "status":
        // let status = [...interactionsSort].sort(this.sortInteration('status', { sortId: sortId }));
        this.setState({
          interactions: [...interactionsSort].sort(
            this.sortInteration("status", { sortId: sortId })
          ),
        });
        break;
      case "assignto":
        // let assignedTo = [...interactionsSort].sort(this.sortInteration('assignedTo', { sortId: sortId }));
        this.setState({
          interactions: [...interactionsSort].sort(
            this.sortInteration("assignedTo", { sortId: sortId })
          ),
        });
        break;
      default:
        break;
    }
  }
  componentDidMount() {
    if (utils.getSessionItem("role") === RoleConstant.Reviewer) {
      this.props.history.push(RouterConstant.SCORE_CARD_LINK);
    } else {
      this.getScoreCardList();
      this.getInteractionList();
      this.getUserDetails();
    }
  }
  getUserDetails() {
    if (
      utils.getSessionItem("role") === RoleConstant.Admin ||
      utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
      utils.getSessionItem("role") === RoleConstant.SAdmin
    ) {
      let data = {
        deleted: true,
      };
      let self = this;
      utils.makeRequest(
        this,
        EndpointConstant.GET_USER_LIST,
        data,
        function (result) {
          const userList =
            result.users && result.users.length
              ? result.users.filter((val) => val.status !== 4)
              : [];

          let roleArr = {};
          userList.length &&
            userList.map((item) => {
              roleArr = {
                ...roleArr,
                [item.id]: {
                  role: item.role,
                  firstName: item.firstName,
                  lastName: item.lastName,
                },
              };
            });
          roleArr = {
            ...roleArr,
            [utils.getSessionItem("userId")]: {
              role: utils.getSessionItem("role"),
              firstName: utils.getSessionItem("firstName"),
              lastName: utils.getSessionItem("lastName"),
            },
          };

          self.setState({
            userDetails: userList,
            roleArr,
          });
          // self.props.datasetIds(result.datasets);
        }
      );
    }
  }
  add3Dots(string, limit) {
    var dots = "...";
    if (string && string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }
  setLinkActive(activeLink) {
    if (activeLink === "error") {
      this.setState({ link: { errorLink: "active" } });
    } else {
      this.setState({ link: { scorecardLink: "active" } });
    }
  }
  setLinkInactive(activeLink) {
    if (activeLink === "error") {
      this.setState({ link: { errorLink: "" } });
    } else {
      this.setState({ link: { scorecardLink: "" } });
    }
  }

  colorChangeStatus(status) {
    //  const styles = {
    //    Assigned: '#0F9D58',
    //    Unassigned: '#EA4335',

    // }

    switch (status) {
      case "Assigned":
        return { color: "#0F9D58" };
      case "Re-Assigned":
        return { color: "#EA4335" };
      default:
        return {};
    }
  }

  getDatasetListForBioconnect() {
    //  console.log("downn",this.state.sId);
    let data = {};
    if (!this.state.searchdata) {
      data = {};
      data.datasetId = this.state.datasetIds;
    } else {
      if (this.state.searchdata.interactionId !== "") {
        data.caseNumber = this.state.searchdata.interactionId;
      }
      // if(this.state.searchdata.selected.length === 0) {
      //   data.datasetId = this.state.datasetIds;
      // }
      if (this.state.searchdata.subcaseNumber !== "") {
        data.subcaseNumber = this.state.searchdata.subcaseNumber;
      }
      if (
        this.state.searchdata.associatedTeam !== "" &&
        this.state.searchdata.associatedTeam !== undefined
      ) {
        if (this.state.searchdata.associatedTeam.length !== 0) {
          data.associatedTeam = this.state.searchdata.associatedTeam;
        }
      }
      if (this.state.searchdata.interactionOwner !== "") {
        data.interactionOwner = this.state.searchdata.interactionOwner;
      }
      if (
        this.state.searchdata.selected !== "" &&
        this.state.searchdata.selected !== undefined
      ) {
        if (this.state.searchdata.selected.length !== 0) {
          data.datasetId = this.state.searchdata.selected;
        }
      }
      if (
        this.state.searchdata.country !== "" &&
        this.state.searchdata.country !== undefined
      ) {
        if (this.state.searchdata.country.length !== 0) {
          data.interactionCountry = this.state.searchdata.country;
        }
      }
      if (
        this.state.searchdata.channel !== "" &&
        this.state.searchdata.channel !== undefined
      ) {
        if (this.state.searchdata.channel.length !== 0) {
          data.channel = this.state.searchdata.channel;
        }
      }
      if (
        this.state.searchdata.status !== "" &&
        this.state.searchdata.status !== undefined
      ) {
        if (this.state.searchdata.status.length !== 0) {
          data.status = this.state.searchdata.status;
        }
      }
      if (
        this.state.searchdata.assignedTo !== "" &&
        this.state.searchdata.assignedTo !== undefined
      ) {
        if (this.state.searchdata.assignedTo.length !== 0) {
          data.assignedTo = this.state.searchdata.assignedTo;
        }
      }
    }
    data.sId = uuidV4();

    this.setState({ downloadRequest: "Processing", sId: data.sId });
    let self = this;
    utils.makeRequestInBackground(
      this,
      EndpointConstant.DOWNLOAD_INTERACTION_FROM_FILE,
      data,
      function (result) {
        // console.log("downResulttt",result);
        // self.setState({
        //   downloadRequest: "Processing",
        // });
        // if(self.state.showDataSync){
        //   window.open(result.downloadUrl, 'Download');
        // }
        return;
      }
    );
  }

  getProcessingDatasetListForBioconnect() {
    let data = {
      downloadRequest: this.state.downloadRequest,
      sId: this.state.sId,
    };
    let self = this;
    utils.makeRequestInBackground(
      this,
      EndpointConstant.DOWNLOAD_INTERACTION_FROM_FILE,
      data,
      function (result) {
        // console.log("downResulttt", result);
        if (result.downloadRequest == "Completed") {
          self.setState({ 
            downloadRequest: result.downloadRequest,
            showDataSync: false,
            extractSuccess: true,
            extractValue: 0,
            extractResponseMsg: MessageConstant.DOWNLOADED_INTERACTION,
            extractResponseFlagIcon: "success", 
          });
          window.open(result.url, "Download");
          clearInterval(self.interval);
          self.interval = null;
        } else {
          self.setState({ downloadRequest: result.downloadRequest });
        }
      }
    );
  }

  extractDataFromBioConnect(event) {
    event.preventDefault();
    // console.log("extract Data from bio connect");

    clearInterval(this.interval);
    // console.log("this state",this.state);
    this.interval = null;
    this.setState({
      showDataSync: true,
    });
    this.getDatasetListForBioconnect();
    this.interval = setInterval(async () => {
      let val = this.state.extractValue;
      val += Math.floor(Math.random() * 15) + 1;
      if (val >= 99) {
        val = 99;
      }

      this.setState({
        extractValue: val,
      });
      this.state.downloadRequest == "Processing"
        ? this.getProcessingDatasetListForBioconnect()
        : "";
      // console.log("downloadss",this.state.downloadRequest,this.state.downloadRequest === "Processing")
      if (this.state.downloadRequest == "Completed") {
        this.hideDataSyncOnCancel();
        this.setState({
          extractSuccess: true,
          extractValue: 0,
          extractResponseMsg: MessageConstant.DOWNLOADED_INTERACTION,
          extractResponseFlagIcon: "success",
        });

        clearInterval(this.interval);
        this.interval = null;
      }
    }, 10000);
  }

  hideDataSyncOnCancel() {
    //event.preventDefault();
    this.setState({
      showDataSync: false,
      extractValue: 0,
      // downloadRequest: 'Processing',
      downloadUrl: "",
      sId: "",
      // extractSuccess: true,
      // extractResponseMsg: MessageConstant.DOWNLOADED_INTERACTION,
      // extractResponseFlagIcon: 'success'
    });
    clearInterval(this.interval);
    this.interval = null;
  }

  handleExtractSuccessClose() {
    this.setState({
      extractSuccess: false,
    });
  }

  handleInprogressClose() {
    this.setState({
      showInProgressModal: false,
    })
    if (this.state.searchdata) {
      this.search();
    } else {
      this.getInteractionList();
    }
  }

  getdatasetIds(ids) {
    //  console.log("ids",ids);
    let listIds = [];
    if (ids.length > 0) {
      listIds = ids.map((dataset) => {
        return dataset.id;
      });
    }
    //  console.log("listIds",listIds);
    //if(this.state.searchdata.selected.length == 0) {
    this.setState({
      datasetIds: listIds,
    });
    //}
  }
  getName(name) {
    let nameArr = name.includes(",") && name.split(",");
    let newName = "";

    if (Array.isArray(nameArr)) {
      nameArr = nameArr.forEach((ele, i) => {
        if (ele && ele.trim() != "") {
          // console.log(newName,ele.trim())
          newName =
            newName == "" ? `${ele.trim()}` : `${ele.trim()}, ${newName}`;
        }
      });
      return newName;
    } else {
      return name;
    }
  }

  getCreatedName(createdBy, name) {
    let { roleArr } = this.state;
    name = name.includes(",") ? name.split(",") : name;
    let newName =
      roleArr[createdBy] &&
      `${roleArr[createdBy].firstName} ${roleArr[createdBy].lastName}`;
    if (!Array.isArray(name) && newName == name) {
      return `${newName}(${roleArr[createdBy].role})`;
    } else {
      let stringValue = "";
      Array.isArray(name) &&
        name.forEach((item) => {
          if (newName == item) {
            stringValue = stringValue
              ? `${newName}(${roleArr[createdBy].role}), ${stringValue}`
              : `${newName}(${roleArr[createdBy].role})`;
          }
        });
      return stringValue;
    }
  }

  handleinteractions(interactions,scorecards) {
    let int1 = [];
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const interactions1 =
      interactions &&
      interactions.map((data1) => {
        //let assignedScorecard = scorecards.find(card => card.id === data1.scoreCardId);
        if (data1.scoreCardId) {
        var lNewobj = {};
        let ldata = {};
        lNewobj.AssignedTo = data1.assignToFullName ? `${data1.assignToFullName} (${data1.assignedTo})` : `${data1.assignedTo}`;
        // if (assignedScorecard.status == "Completed") {
        //   lNewobj.AssignedTo = assignedScorecard.amgenQCBy ? `${assignedScorecard.amgenQCBy} (${data1.assignedTo})` : `${data1.assignedToName} (${data1.assignedTo})`;
        // } else {
        //   lNewobj.AssignedTo = data1.assignToFullName ? `${data1.assignToFullName} (${data1.assignedTo})` : `${data1.assignedTo}`;
        // }
        //  else if (assignedScorecard.status == "Re-Assigned") {
        //   lNewobj.AssignedTo = assignedScorecard.assignedToAdminName ? `${assignedScorecard.assignedToAdminName} (${data1.assignedTo})` : assignedScorecard.QMAssociate ? `${assignedScorecard.QMAssociate} (${data1.assignedTo})` : `${data1.assignedTo}`;
        // } else {
        //   lNewobj.AssignedTo = assignedScorecard.assignedToAdminName ? `${assignedScorecard.assignedToAdminName} (${data1.assignedTo})` : `${data1.assignedTo}`;
        // }
        //lNewobj.ScoreCardName = assignedScorecard.ScorecardName;
        //lNewobj.status = assignedScorecard.status;
        //lNewobj.scoreCardId = assignedScorecard.id;
        ldata = {
          ...data1,
        };
        let lNEW = Object.assign(ldata, lNewobj);
        int1.push(lNEW);
        // const { roleArr } = this.state;
        // let assignedKeys =
        //   data1.assignedToScorecard && Object.keys(data1.assignedToScorecard);
        // if (assignedKeys && assignedKeys.length) {
        //   let userRoles = ["Reviewer", "Admin", "SuperAdmin"];
        //   assignedKeys.sort().forEach((assignedId, i) => {
        //     let assignedToValue = "";
        //     let stringValue = "";
        //     let idsArr = assignedId.split("*");
        //     if (idsArr[1] && userRoles.includes(idsArr[1])) {
        //       stringValue = stringValue
        //         ? `${stringValue},${idsArr[1]}`
        //         : `${idsArr[1]}`;
        //     } else if (
        //       idsArr[1] &&
        //       idsArr[1].length > 20 &&
        //       roleArr[idsArr[1]]
        //     ) {
        //       stringValue = stringValue
        //         ? `${stringValue},${roleArr[idsArr[1]].firstName} ${
        //             roleArr[idsArr[1]].lastName
        //           } (${roleArr[idsArr[1]].role})`
        //         : ` ${roleArr[idsArr[1]].firstName} ${
        //             roleArr[idsArr[1]].lastName
        //           } (${roleArr[idsArr[1]].role})`;
        //     }
        //     assignedToValue = stringValue;
        //     let ldata = {};
        //     var lNewobj = {};
        //     lNewobj.AssignedTo = assignedToValue;
        //     lNewobj.ScoreCardName =
        //       data1.assignedToScorecard[assignedId].scoreCardName;
        //     lNewobj.status = data1.assignedToScorecard[assignedId].status;
        //     lNewobj.scoreCardId =
        //       data1.assignedToScorecard[assignedId].scoreCardId;
        //     ldata = {
        //       ...data1,
        //     };
        //     let lNEW = Object.assign(ldata, lNewobj);
        //     int1.push(lNEW);
        //     lNEW = [];
        //   });
        } else {
          var lNewobj = {};
          let ldata = {};
          var assValue = data1.assignedToName
            ? `${data1.assignedToName} (${
                siteinfo.AssignedTo[data1.assignedTo] ||
                this.add3Dots(data1.assignedTo, 20)
              })`
            : data1.assignedToAdminName
            ? `${data1.assignedToAdminName}  (${
                siteinfo.AssignedTo[data1.assignedTo] ||
                this.add3Dots(data1.assignedTo, 20)
              })`
            : `${siteinfo.AssignedTo[data1.assignedTo]}`;
          lNewobj.AssignedTo = assValue;
          //lNewobj.ScoreCardName = null;
          lNewobj.status = data1.status;
          lNewobj.scoreCardId = null;
          ldata = {
            ...data1,
          };
          let lNEW = Object.assign(data1, lNewobj);
          int1.push(lNEW);
        }
        //console.log("int1:"+JSON.stringify(int1));
      });
    return int1;
  }
  render() {
    let intercations = this.state.interactions;
    let scorecards = this.state.scorecards;
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    if (utils.getSessionItem("role") === RoleConstant.Reviewer) {
      this.props.history.push(RouterConstant.SCORE_CARD_LINK);
    }
    intercations = this.handleinteractions(intercations,scorecards);
    let int = this.state.filteredValues;
    if (int.hasOwnProperty("status")) {
      intercations = intercations.filter((value) =>
        int.status.some((val) => val == value.status)
      );
      //console.log('intercations',intercations.length)
    }
    if (int.hasOwnProperty("assignedTo")) {
      //console.log("ass:"+ int.assignedTo);

      intercations = intercations.filter((value) =>
        int.assignedTo.some(
          (val) =>
            val == value.AssignedTo ||
            value.AssignedTo.includes("(" + val + ")")
        )
      );
    }
    const {
      currentPage,
      todosPerPage,
      upperPageBound,
      lowerPageBound,
      isPrevBtnActive,
      isNextBtnActive,
    } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    this.state.paginationLength = intercations.length;

    //console.log("in 1016 nNewLength:"+ this.state.paginationLength);

    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos =
      intercations &&
      intercations.length > 0 &&
      intercations.slice(indexOfFirstTodo, indexOfLastTodo);
    let reqinteractions = [];
    reqinteractions =
      currentTodos &&
      currentTodos.map((reqdata, i) => {
        return (
          <tr key={i}>
            {/* <td>{data.interactionId.toUpperCase()}</td> */}
            {/* <td title={data.interactionId}>{data.interactionId}</td> */}
            <td title={reqdata.subcaseNumber}>
              {this.add3Dots(
                reqdata.subcaseNumber && reqdata.subcaseNumber.toUpperCase(),
                16
              )}
            </td>
            {/* <td title={data.interactionCreatedByName}>{this.add3Dots(data.interactionCreatedByName, 10)}</td> */}
            <td title={reqdata.associatedTeam}>
              {this.add3Dots(reqdata.associatedTeam, 15)}
            </td>
            <td title={reqdata.datasetName}>
              {/* {this.add3Dots(reqdata.datasetName, 20)} */}
              {reqdata.datasetName}
            </td>
            <td title={reqdata.product}>{this.add3Dots(reqdata.product, 20)}</td>
            <td title={reqdata.interactionCountry}>
              {this.add3Dots(reqdata.interactionCountry, 16)}
            </td>
            <td title={reqdata.channel}>
              {this.add3Dots(reqdata.channel, 20)}
            </td>
            <td title={reqdata.ScorecardName}>
              {this.add3Dots(reqdata.ScorecardName, 20)}
            </td>
            <td
              title={reqdata.status}
              style={this.colorChangeStatus(reqdata.status)}
            >
              {reqdata.mlResult ? reqdata.status + "(Model)" : reqdata.status}
            </td>
            <td title={reqdata.AssignedTo}>
              {this.add3Dots(reqdata.AssignedTo, 20)}
            </td>

            {/* <td title={data.assignedTo}>{data.assignedToName ? `${data.assignedToName} (${siteinfo.AssignedTo[data.assignedTo] || this.add3Dots(data.assignedTo, 20)})` : `${siteinfo.AssignedTo[data.assignedTo] }`} </td> */}
            {/* <td> <Button variant="secondary" type="submit" className='textcolor' onClick={() => this.viewDetails(data)}>View Details
  </Button></td> */}
            {/* <td> <Button variant="secondary" type="submit" className='textcolor' onClick={()=>this.viewInteractionDetails(data.id)}>View Details
  </Button></td> */}
            <td>
              {" "}
              <a
                className="view-details"
                onClick={() =>
                  this.viewInteractionDetails(reqdata.id, reqdata.scoreCardId)
                }
              >
                <i className="fa fa-eye"></i>&nbsp;View Details
              </a>
            </td>
          </tr>
        );
      });

    const pageNumbers = [];
    if (intercations) {
      for (
        let i = 1;
        i <= Math.ceil(this.state.paginationLength / todosPerPage);
        i++
      ) {
        pageNumbers.push(i);
      }
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      if (number === 1 && currentPage === 1) {
        return (
          <ListGroup.Item
            key={number}
            className="active list-item-padding"
            id={number}
            onClick={this.handleClick}
          >
            <a id={number}>{number}</a>
          </ListGroup.Item>
        );
      } else if (number < upperPageBound + 1 && number > lowerPageBound) {
        return (
          <ListGroup.Item
            key={number}
            className={
              number === currentPage
                ? "active list-item-padding"
                : "list-item-padding"
            }
            id={number}
            onClick={this.handleClick}
          >
            <a id={number}>{number}</a>
          </ListGroup.Item>
        );
      }
      return null;
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = (
        <ListGroup.Item
          className="list-item-padding"
          onClick={this.btnIncrementClick}
        >
          <a> &hellip; </a>
        </ListGroup.Item>
      );
    }
    let pageDecrementBtn = null;

    if (lowerPageBound >= 1) {
      pageDecrementBtn = (
        <ListGroup.Item
          className="list-item-padding"
          onClick={this.btnDecrementClick}
        >
          <a> &hellip; </a>
        </ListGroup.Item>
      );
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === "disabled") {
      renderPrevBtn = (
        <ListGroup.Item className={isPrevBtnActive}>
          <span id="btnPrev">
            {" "}
            <Image
              src="/Images/ico-arrow-left-disabled.png"
              className="arrow-left-right"
            ></Image>{" "}
          </span>
        </ListGroup.Item>
      );
    } else {
      renderPrevBtn = (
        <ListGroup.Item className={isPrevBtnActive} onClick={this.btnPrevClick}>
          <a id="btnPrev">
            <Image
              src="/Images/ico-arrow-left.png"
              className="arrow-left-right"
            ></Image>{" "}
          </a>
        </ListGroup.Item>
      );
    }
    let renderNextBtn = null;
    if (isNextBtnActive === "disabled") {
      renderNextBtn = (
        <ListGroup.Item className={isNextBtnActive}>
          <span id="btnNext">
            {" "}
            <Image
              src="/Images/ico-arrow-right-disabled.png"
              className="arrow-left-right"
            ></Image>{" "}
          </span>
        </ListGroup.Item>
      );
    } else {
      renderNextBtn = (
        <ListGroup.Item className={isNextBtnActive} onClick={this.btnNextClick}>
          <a id="btnNext">
            {" "}
            <Image
              src="/Images/ico-arrow-right.png"
              className="arrow-left-right"
            ></Image>{" "}
          </a>
        </ListGroup.Item>
      );
    }
    let frstpage = null;
    if (isPrevBtnActive === "disabled") {
      frstpage = (
        <ListGroup.Item className={isPrevBtnActive}>
          <span id="btnNext">
            <Image
              src="/Images/ico-arrow-left-end-disabled.png"
              className="arrow-left-right-end"
            ></Image>{" "}
          </span>
        </ListGroup.Item>
      );
    } else {
      frstpage = (
        <ListGroup.Item className={isPrevBtnActive} onClick={this.firstclick}>
          <a>
            {" "}
            <Image
              src="/Images/ico-arrow-left-end.png"
              className="arrow-left-right-end"
            ></Image>{" "}
          </a>
        </ListGroup.Item>
      );
    }
    let lastpage = null;
    if (isNextBtnActive === "disabled") {
      lastpage = (
        <ListGroup.Item className={isNextBtnActive}>
          <span id="btnNext">
            {" "}
            <Image
              src="/Images/ico-arrow-right-end-disabled.png"
              className="arrow-left-right-end"
            ></Image>{" "}
          </span>
        </ListGroup.Item>
      );
    } else {
      lastpage = (
        <ListGroup.Item className={isNextBtnActive} onClick={this.lastClick}>
          <a>
            <Image
              src="/Images/ico-arrow-right-end.png"
              className="arrow-left-right-end"
            ></Image>{" "}
          </a>
        </ListGroup.Item>
      );
    }

    return (
      <div>
        <Header ref={`header`} />
        <div className="sidebar">
          <Sidebar link={this.state.link} />
        </div>
        <div className="menu-content">
          {this.state.show ? (
            ""
          ) : (
            <InteractionSearch
              handleSubmitFromHome={search => this.search = search}
              roleArr={this.state.roleArr}
              parentCallback={this.setLastEvaluatedKey}
              datasetIds={this.getdatasetIds}
              getSearchResult={this.searchResults}
              getInteractionList={this.getInteractionList}
              setSearchData={this.setSearchData}
              searchData={this.state.searchdata}
              setLastEvaluatedKey={this.setLastEvaluatedKey}
              scorecards={this.state.scorecards}
            />
          )}
          {this.state.show ? (
            <ViewDetails
              userDetails={this.state.userDetails}
              interactiondetails={this.state.interactionDetails}
              reqScoreCardIdforEdit={this.state.reqScoreCardIdforEdit}
              hideViewDetails={this.setShow}
              setLinkActive={this.setLinkActive}
              setLinkInactive={this.setLinkInactive}
              updateIntercations={this.updateIntercations}
            />
          ) : (
            ""
          )}
          {this.state.show ? (
            ""
          ) : (
            <div>
              <table>
                <thead>
                  <tr>
                    {/* <th>Master-Case Number</th> */}
                    <th>Sub-Case Number</th>
                    {/* <th onClick={(e) => this.onSortInteraction('createdby', e)}>Created By<i className="fas fa-sort" id="sort"></i></th> */}
                    <th>Created by Team</th>
                    <th>Dataset</th>
                    <th>Product</th>
                    <th>Country</th>
                    <th>Channel</th>
                    <th>Scorecard Name</th>
                    <th>Status</th>
                    <th>Assigned To</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{reqinteractions}</tbody>
              </table>
            </div>
          )}
          {this.state.show ? (
            ""
          ) : (
            <Row className="padding-top">
              <Col>
                <ListGroup className="pageNumbers float-left">
                  {frstpage}
                  {/* {renderfirst} */}
                  {renderPrevBtn}
                  {pageDecrementBtn}
                  {renderPageNumbers}
                  {pageIncrementBtn}
                  {renderNextBtn}
                  {lastpage}
                </ListGroup>
              </Col>
              <Button
                variant="secondary"
                disabled={this.state.interactions.length === 0}
                className=" download-subcases button-color btn btn-primary"
                onClick={this.extractDataFromBioConnect}
              >
                Download Sub-Cases
              </Button>
            </Row>
          )}
        </div>
        <Modal
          show={this.state.showDataSync}
          onHide={this.hideDataSyncOnCancel}
          className="dataset-extract-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>DOWNLOAD SUB-CASES </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              style={{
                height: "10px",
                borderRadius: "5px",
                border: "1px solid #0063C3",
                background: "#F2F2F2",
              }}
              showValue={false}
              value={this.state.extractValue}
            />
            <p className="progressMsg">Downloading is in progress...</p>
            <p className="progressMsg">{this.state.extractValue}% completed</p>
          </Modal.Body>
          <Modal.Footer className="confirm-popup-header">
            <Button
              variant="primary"
              className="clear "
              onClick={this.hideDataSyncOnCancel}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName="roleChangeModal"
          show={this.state.showInProgressModal}
          onHide={this.handleInprogressClose}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header closeButton className='confirmation-header alert-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Alert
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body' style={{'textAlign': 'center'}}> {MessageConstant.INPROGRESS_JSON_UPDATE}</Modal.Body>
          <Modal.Footer className='confirmation-footer' style={{'justifyContent': 'center'}}>
            <Button onClick={this.handleInprogressClose} className='clear'>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <PopupModels
          showPopup={this.state.extractSuccess}
          iconFlag={this.state.extractResponseFlagIcon}
          message={this.state.extractResponseMsg}
          handleClosePopup={this.handleExtractSuccessClose.bind(this)}
        />
      </div>
    );
  }
}

export default Home;
