

import React, { Component } from 'react';
import './DataModelCharts.css';
import DataModelLineCharts from './DataModelLineChart/DataModelLineChart';
//import utils from './../../../utils';
import PropTypes from 'prop-types';

class DataModelCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showWarning: false
    };
  }

  // getPreConfiguredValuesFromSiteInfo() {
  //   const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));

  //   return siteinfo.DataModelReportConfig;
  // }
  render() {
    // console.log("acceptance props", this.props.acceptanceDetails);
    // const preConfiguredValues = this.getPreConfiguredValuesFromSiteInfo();
    let preConfiguredValues = this.props.acceptanceDetails;
    // console.log("preConfiguredValues",preConfiguredValues.sensitivity.AE.upperLimit);
    const data = {
      sensitivity: {
        AE: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
        PC: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
        OSF: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
      },
      // specificity: {
      //   AE: {
      //     values: [],
      //     upperLimit: [],
      //     lowerLimit: [],
      //     median: []
      //   },
      //   PC: {
      //     values: [],
      //     upperLimit: [],
      //     lowerLimit: [],
      //     median: []
      //   },
      //   OSF: {
      //     values: [],
      //     upperLimit: [],
      //     lowerLimit: [],
      //     median: []
      //   },
      // },
      accuracy: {
        AE: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
        PC: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
        OSF: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
      },
      precision: {
        AE: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
        PC: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
        OSF: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
      },
      auc: {
        AE: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
        PC: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
        OSF: {
          values: [],
          upperLimit: [],
          lowerLimit: [],
          median: []
        },
      },
    };

    this.props.data.forEach((item) => {

      if (item.modelType === 'AE' && item.timestamp && preConfiguredValues.hasOwnProperty('sensitivity')) {
        // AE
        // let obj = {
        //   value: item.specificity,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.AE.values.push(obj);
       
        // obj = {
        //   value: preConfiguredValues.specificity.AE.upperLimit,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.AE.upperLimit.push(obj);
        // obj = {
        //   value: preConfiguredValues.specificity.AE.lowerLimit,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.AE.lowerLimit.push(obj);
        // obj = {
        //   value: preConfiguredValues.specificity.AE.median,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.AE.median.push(obj);
        // sensitivity AE
        //data.sensitivity.AE.median.push(obj);
        // console.log("data check",data);
        let obj = {
          value: item.sensitivity,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.AE.values.push(obj);
        obj = {
          value: preConfiguredValues.sensitivity.AE.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.AE.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.sensitivity.AE.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.AE.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.sensitivity.AE.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.AE.median.push(obj);

        // accuracy AE
        obj = {
          value: item.accuracy,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.AE.values.push(obj);
        obj = {
          value: preConfiguredValues.accuracy.AE.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.AE.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.accuracy.AE.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.AE.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.accuracy.AE.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.AE.median.push(obj);

        // precision AE
         obj = {
          value: item.precision,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.AE.values.push(obj);
        obj = {
          value: preConfiguredValues.precision.AE.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.AE.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.precision.AE.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.AE.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.precision.AE.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.AE.median.push(obj);

      //auc AE
     obj = {
          value: item.auc,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.AE.values.push(obj);
        obj = {
          value: preConfiguredValues.auc.AE.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.AE.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.auc.AE.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.AE.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.auc.AE.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.AE.median.push(obj);
      }
      else if (item['modelType'] === 'PC' && item.timestamp && preConfiguredValues.hasOwnProperty('sensitivity')) {
        // let obj = {
        //   value: item.specificity,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.PC.values.push(obj);
        // obj = {
        //   value: preConfiguredValues.specificity.PC.upperLimit,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.PC.upperLimit.push(obj);
        // obj = {
        //   value: preConfiguredValues.specificity.PC.lowerLimit,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.PC.lowerLimit.push(obj);
        // obj = {
        //   value: preConfiguredValues.specificity.PC.median,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.PC.median.push(obj);
         let obj = {
          value: item.sensitivity,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.PC.values.push(obj);
        obj = {
          value: preConfiguredValues.sensitivity.PC.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.PC.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.sensitivity.PC.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.PC.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.sensitivity.PC.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.PC.median.push(obj);
        obj = {
          value: item.accuracy,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.PC.values.push(obj);
        obj = {
          value: preConfiguredValues.accuracy.PC.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.PC.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.accuracy.PC.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.PC.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.accuracy.PC.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.PC.median.push(obj);
        obj = {
          value: item.precision,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.PC.values.push(obj);
        obj = {
          value: preConfiguredValues.precision.PC.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.PC.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.precision.PC.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.PC.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.precision.PC.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.PC.median.push(obj);
      //auc PC
        obj = {
          value: item.auc,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.PC.values.push(obj);
        obj = {
          value: preConfiguredValues.auc.PC.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.PC.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.auc.PC.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.PC.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.auc.PC.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.PC.median.push(obj);
      }
      else if (item.modelType === 'OSF' && item.timestamp && preConfiguredValues.hasOwnProperty('sensitivity')) {
        // let obj = {
        //   value: item.specificity,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.OSF.values.push(obj);
        // obj = {
        //   value: preConfiguredValues.specificity.OSF.upperLimit,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.OSF.upperLimit.push(obj);
        // obj = {
        //   value: preConfiguredValues.specificity.OSF.lowerLimit,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.OSF.lowerLimit.push(obj);
        // obj = {
        //   value: preConfiguredValues.specificity.OSF.median,
        //   version: item.version,
        //   timestamp: item.timestamp
        // };
        // data.specificity.OSF.median.push(obj);
        let obj = {
          value: item.sensitivity,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.OSF.values.push(obj);
        obj = {
          value: preConfiguredValues.sensitivity.OSF.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.OSF.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.sensitivity.OSF.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.OSF.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.sensitivity.OSF.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.sensitivity.OSF.median.push(obj);
        obj = {
          value: item.accuracy,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.OSF.values.push(obj);
        obj = {
          value: preConfiguredValues.accuracy.OSF.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.OSF.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.accuracy.OSF.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.OSF.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.accuracy.OSF.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.accuracy.OSF.median.push(obj);
        obj = {
          value: item.precision,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.OSF.values.push(obj);
        obj = {
          value: preConfiguredValues.precision.OSF.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.OSF.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.precision.OSF.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.OSF.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.precision.OSF.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.precision.OSF.median.push(obj);
        
        //auc OSF
        obj = {
          value: item.auc,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.OSF.values.push(obj);
        obj = {
          value: preConfiguredValues.auc.OSF.upperLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.OSF.upperLimit.push(obj);
        obj = {
          value: preConfiguredValues.auc.OSF.lowerLimit,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.OSF.lowerLimit.push(obj);
        obj = {
          value: preConfiguredValues.auc.OSF.median,
          version: item.version,
          timestamp: item.timestamp
        };
        data.auc.OSF.median.push(obj);
      }
    });

    //console.log(JSON.stringify(data));
    const width = 450, height = 350, margin = 20
    // const data = [
    //     { date: 0.95, value: 1, c:"10" },
    //     { date: 1574219659, value: 3, c:"test" },
    //     { date: 1574354084, value: 5, c:"test" },
    //     { date: 1574291801, value: 9, c:"test" },
    //     { date: 1574780997, value: 12, c:"test" }
    // ]
    return (
      <div className="datamodel-charts">
        <div className="row">
          <div className="col-md-6 chart-pad-right">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">AUC AE</h6>
              <DataModelLineCharts
                id="specificityae"
                data={data.auc.AE.values}
                upperLimit={data.auc.AE.upperLimit}
                lowerLimit={data.auc.AE.lowerLimit}
                median={data.auc.AE.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#04B2D9"
                xAxisText="Trainings" />
            </div>
          </div>
          <div className="col-md-6 chart-pad-left">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">Sensitivity AE</h6>
              <DataModelLineCharts
                id="sensitivityae"
                data={data.sensitivity.AE.values}
                upperLimit={data.sensitivity.AE.upperLimit}
                lowerLimit={data.sensitivity.AE.lowerLimit}
                median={data.sensitivity.AE.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#EA4335"
                xAxisText="Trainings" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 chart-pad-right">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">Accuracy AE</h6>
              <DataModelLineCharts
                id="accuracyae"
                data={data.accuracy.AE.values}
                upperLimit={data.accuracy.AE.upperLimit}
                lowerLimit={data.accuracy.AE.lowerLimit}
                median={data.accuracy.AE.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#0F9D58"
                xAxisText="Trainings"/>
            </div>
          </div>
          <div className="col-md-6 chart-pad-left">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">Precision AE</h6>
              <DataModelLineCharts
                id="precisionae"
                data={data.precision.AE.values}
                upperLimit={data.precision.AE.upperLimit}
                lowerLimit={data.precision.AE.lowerLimit}
                median={data.precision.AE.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#987EAA"
                xAxisText="Trainings" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 chart-pad-right">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">AUC PC</h6>
              <DataModelLineCharts
                id="specificitypc"
                data={data.auc.PC.values}
                upperLimit={data.auc.PC.upperLimit}
                lowerLimit={data.auc.PC.lowerLimit}
                median={data.auc.PC.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#04B2D9"
                xAxisText="Trainings"  />
            </div>
          </div>
          <div className="col-md-6 chart-pad-left">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">Sensitivity PC</h6>
              <DataModelLineCharts
                id="sensitivitypc"
                data={data.sensitivity.PC.values}
                upperLimit={data.sensitivity.PC.upperLimit}
                lowerLimit={data.sensitivity.PC.lowerLimit}
                median={data.sensitivity.PC.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#EA4335"
                xAxisText="Trainings" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 chart-pad-right">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">Accuracy PC</h6>
              <DataModelLineCharts
                id="accuracypc"
                data={data.accuracy.PC.values}
                upperLimit={data.accuracy.PC.upperLimit}
                lowerLimit={data.accuracy.PC.lowerLimit}
                median={data.accuracy.PC.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#0F9D58"
                xAxisText="Trainings" />
            </div>
          </div>
          <div className="col-md-6 chart-pad-left">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">Precision PC</h6>
              <DataModelLineCharts
                id="precisionpc"
                data={data.precision.PC.values}
                upperLimit={data.precision.PC.upperLimit}
                lowerLimit={data.precision.PC.lowerLimit}
                median={data.precision.PC.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#987EAA"
                xAxisText="Trainings" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 chart-pad-right">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">AUC OSF</h6>
              <DataModelLineCharts
                id="specificityosf"
                data={data.auc.OSF.values}
                upperLimit={data.auc.OSF.upperLimit}
                lowerLimit={data.auc.OSF.lowerLimit}
                median={data.auc.OSF.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#04B2D9"
                xAxisText="Trainings" />
            </div>
          </div>
          <div className="col-md-6 chart-pad-left">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">Sensitivity OSF</h6>
              <DataModelLineCharts
                id="sensitivityosf"
                data={data.sensitivity.OSF.values}
                upperLimit={data.sensitivity.OSF.upperLimit}
                lowerLimit={data.sensitivity.OSF.lowerLimit}
                median={data.sensitivity.OSF.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#EA4335"
                xAxisText="Trainings" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 chart-pad-right">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">Accuracy OSF</h6>
              <DataModelLineCharts
                id="accuracyosf"
                data={data.accuracy.OSF.values}
                upperLimit={data.accuracy.OSF.upperLimit}
                lowerLimit={data.accuracy.OSF.lowerLimit}
                median={data.accuracy.OSF.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#0F9D58"
                xAxisText="Trainings" />
            </div>
          </div>
          <div className="col-md-6 chart-pad-left">
            <div className="col-md-12 chart-outline">
              <h6 className="mt-3">Precision OSF</h6>
              <DataModelLineCharts
                id="precisionosf"
                data={data.precision.OSF.values}
                upperLimit={data.precision.OSF.upperLimit}
                lowerLimit={data.precision.OSF.lowerLimit}
                median={data.precision.OSF.median}
                width={width}
                height={height}
                margin={margin}
                stroke="#987EAA"
                xAxisText="Trainings"/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
DataModelCharts.propTypes = {
  data: PropTypes.array,
  acceptanceDetails: PropTypes.object,
}

export default DataModelCharts;