

import React, { Component } from 'react';
import './CountryTotals.css';
import FilterCountryTotals from '../FilterCountryTotals/FilterCountryTotals';
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import { Sidebar, Header } from '..';
// import { DataModelCharts } from '..';
import utils from '../../../utils';
import EndpointConstant from '../../../constants/EndpointConstant';
//import { Popover } from 'react-bootstrap';
// import Pagination from '../Pagination/Pagination';
import Pagination from '../../Pagination/Pagination'
import PropTypes from 'prop-types';
// import DataModelSearch from './DataModelSearch'

class CountryTotals extends Component {

  constructor(props) {
    super(props);
    this.state = {
      countryData: [],
      countryList: [],
      teamNames: [],
      showpagination: false,
      currentTodos: []
    }
    this.getCountryTotals = this.getCountryTotals.bind(this);
    this.countryTotalsData = this.countryTotalsData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onDownloadFilter = this.onDownloadFilter.bind(this);
  }
  componentDidMount() {
    this.getCountryTotals();
  }
  countryTotalsData(result, filter) {
    let countryData = [];
    let countryList = [];
    let teamNames = [];
    //console.log(result, 'result countrytotals')
    for (let i = 0; i < result.countryTotals.length; i++) {
      let countryDataValues = {}
      Object.keys(result.countryTotals[i]).map((country) => {
        //console.log(result.countryTotals[i][country], country, 'yoyo')
        if (country !== 'datasetName') {
          if (countryList.indexOf(country) === -1 && country !== '') {
            countryList.push(country)
          }
          //console.log(Object.keys(result.countryTotals[i][country]), 'Object.keys(result.countryTotals[i][country])')
          Object.keys(result.countryTotals[i][country]).map((teams) => {
            countryDataValues = {}
            //console.log(teams, result.countryTotals[i][country][teams], 'yoyo result')
            countryDataValues.datasetName = result.countryTotals[i].datasetName
            countryDataValues.countryName = country
            countryDataValues.teamName = teams
            countryDataValues.caseCount = result.countryTotals[i][country][teams].caseCount
         
            countryData.push(countryDataValues)
            if (teamNames.indexOf(teams) === -1 && teams !== '') {
              return teamNames.push(teams)
            }
            return null;
          })

          //console.log(countryDataValues, 'countryDataValues')
          //console.log(countryData, 'countryData')

        }
        return null;
      })
    }
    this.setState({ countryData: countryData }, () => this.setState({ showpagination: true }))
    if (filter !== 'filter') {
      this.setState({ countryList: countryList, teamNames: teamNames })
    }
  }
  getCountryTotals() {
    let data = {}
    let self = this
    // utils.makeRequest(this, EndpointConstant.COUNTRY_TOTALS, data, function (result) {
    utils.makeRequest(this, EndpointConstant.COUNTRY_TOTALS_FROM_FILE, data, function (result) {
      //console.log(result.countryTotals, 'result')
      // console.log(Object.keys(result.countryTotals[1]['United Kingdom']['Germany MedInfo Call Center']),'result')
      self.countryTotalsData(result);
    })

  }
  colorChangeStatus(status) {
    const styles = {
      Approved: '#0F9D58',
      Rejected: '#EA4335',
      Processing: '#F4B400'
    }
    switch (status) {
      case ('Approved'):
        return { color: styles.Approved };
      case ('Rejected'):
        return { color: styles.Rejected };
      default:
        return {};
    }
  }
  onChangePage(currentTodos) {
    //console.log(currentTodos, 'currentTodos')
    // if(currentTodos.length>0)
    this.setState({ currentTodos: currentTodos }, () => { this.setState({ showpagination: true }) })
  }

  onDownloadFilter(filteredData) {
    this.props.onDownloadFilter(filteredData);
  }

  resetPagination() {
    // this.refs.pagination.updateCurrentPageNumber();
  }

  render() {
    // const {dataModelReports}=this.state
    const { countryList, teamNames } = this.state
    //const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const dataModelList = this.state.currentTodos.length > 0 && this.state.currentTodos.map((data, index) => {
      
      
      return (
        <tr key={index} className='countryTotalRow'>
          <td>{data.datasetName}</td>
          <td>{data.countryName}</td>
          <td>{data.teamName}</td>
          <td>{data.caseCount}</td>
          
        </tr>
      );
    })

    return (
      <div className="gims-charts">
        <div className="row">
          <div className="col-md-12">
            <FilterCountryTotals resetPagination={this.resetPagination.bind(this)} countryList={countryList} teamNames={teamNames} countryTotalsData={this.countryTotalsData} getCountryTotals={this.getCountryTotals} onDownloadFilter={this.onDownloadFilter} />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th className='width1'>Data Set</th>
              <th>Country</th>
              <th className='width2'>Team Name</th>
              <th>Cases</th>
              
            </tr>
          </thead>
          <tbody>
            {dataModelList}
          </tbody>
        </table>

        {
          // eslint-disable-next-line react/no-string-refs
          this.state.showpagination ? <Pagination ref="pagination" data={this.state.countryData} onChangePage={this.onChangePage} /> : ''
        }
      </div>

    )
  }
}
CountryTotals.propTypes = {
  onDownloadFilter: PropTypes.func,
}

export default CountryTotals;