import React, { Component } from 'react';
import { Sidebar, Header } from '..';
import Moment from 'react-moment';
import { Button, Row, Col, Image } from 'react-bootstrap'
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import Pagination from '../Pagination/Pagination';
import RoleConstant from '../../constants/RoleConstant';
import ScorecardQuestionariesSearch from './ScorecardQuestionariesSearch';
import EditScorecardQuestionaries from './EditScorecardQuestionaries';
import ConfirmationModal from '../PopupModels/ConfirmationModal';
import MessageConstant from './../../constants/MessageConstant';
import PopupModels from '../PopupModels/PopupModels'
import './ScorecardQuestionaries.css';

class ScorecardQuestionaries extends Component {

  constructor(props) {
    super(props);
    this.state = {
        questionariesDetails: [],
        showpagination: false,
        currentTodos: [],
        addEditShow: false,
        selectedQuestionSet: {},
        isViewMode: false,
        showDeleteConfirmation: false,
        qid: '',
        questionariesSuccessModel: false,
        defaultQuestionaries: [],
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.hideViewDetails = this.hideViewDetails.bind(this);
    this.searchResult = this.searchResult.bind(this);
  
  }
  componentDidMount() {
   this.getQuestionariesList();
  }

  getQuestionariesList() {
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.SCORECARD_QUESTIONARIE_LIST, data, function (result) {
      utils.hideLoader();
      if (result.error === false) {
        self.loading = false;
        const questions = result.Questionaries;
        self.setState({
            questionariesDetails: questions,
            defaultQuestionaries: questions
        },() => self.setState({ showpagination: !!result.Questionaries.length }));
      }
    })
  }

  onChangePage(currentTodos) {
    this.setState({ currentTodos: currentTodos }, () => { this.setState({ showpagination: true }) })
  }

  add3Dots(string, limit) {
    var dots = "...";
    if (string && string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }
    return string;
  }

  viewQuestionarieDetails(questionDetails) {
    this.setState({addEditShow: true, selectedQuestionSet: questionDetails, isViewMode: true})
  }

  editQuestionaries(questionDetails) {
    this.setState({addEditShow: true, selectedQuestionSet: questionDetails, isViewMode: false})
  }

  deleteQuestionaries(questionSet) {
    this.setState({qid:  questionSet.id, showDeleteConfirmation:true})
  }

  handleDeleteQuestion(qid) {   
    let data = {id: qid};
    let self = this;
    utils.makeRequest(this, EndpointConstant.DELETE_SCORECARD_QUESTIONARIE, data, function (result) {
      if (result.error === false) {
        self.loading = false;
        const questions = result.Questionaries;
        self.setState({
            questionariesDetails: questions,
            defaultQuestionaries: questions,
            showDeleteConfirmation: false,
            questionariesSuccessModel: true
            
        },() => self.setState({ showpagination: !!result.Questionaries.length }));
      }
    })
 }

  hideViewDetails() {
    this.setState({addEditShow: false})
  }

  hideDeletePopup() {
    this.setState({qid: '', showDeleteConfirmation: false})
    }

    handlePopupClose() {
        this.setState({ questionariesSuccessModel: false })
        // window.location.reload(false)
    }
  
    searchResult(result) {
      this.setState({questionariesDetails: result})
    }

  render() {   
    const QuestionarieList = this.state.currentTodos.map((data,index) => {
      return (
          <tr key={index}>
              <td title={data.questionsetName}>{this.add3Dots(data.questionsetName, 18)}</td>
              <td>
                  <Moment format="MM/D/YYYY">{data.createdAt}</Moment></td>
              <td>{data.createdByName}</td>
              <td>
                  <Row>
              <Col xs lg="4" className='pr-0 pl-2'>
                  <span onClick={() => this.editQuestionaries(data)} className='view-details'>
              <i className="fa fa-eye"></i>&nbsp;View Details</span>
              </Col>
                {/* <Col xs lg="2" className='ml-2 pr-2 pl-0'>
                <span onClick={() => this.editQuestionaries(data)} className='edit-style'>
                <Image src="/Images/ico-edit.png" alt='Amgen' className='edit-user-icon edit-only-user'></Image>Edit</span>
              </Col> */}
              <Col xs lg="4" className='ml-2 pr-2 pl-0'><span onClick={() => this.deleteQuestionaries(data)} className='delete-style'>
                <Image src="/Images/ico-delete.png" className='image-padding'></Image>Delete</span></Col></Row>
              </td>
          </tr>
      );
    });
    return (
        <div>
            <Header />
            <div className="sidebar">
                <Sidebar />
            </div>
            {this.state.addEditShow ? <EditScorecardQuestionaries hideViewDetails={this.hideViewDetails} questionDetails={this.state.selectedQuestionSet} questionSetEditMode={!this.state.isViewMode} scorecardQuestionDetails={this.state.questionariesDetails}/> :
            <div className="menu-content">
                <div>
                    <ScorecardQuestionariesSearch questionDetails={this.state.defaultQuestionaries} searchResult ={this.searchResult}/>
                    <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Questionset</th>
                                <th>Created Date</th>
                                <th>Created By</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {QuestionarieList}
                        </tbody>
                    </table>
                    <Row className='padding-top'>
                <Col>
                 { // eslint-disable-next-line react/no-string-refs
                  this.state.showpagination ? <Pagination ref="pagination" data={this.state.questionariesDetails} onChangePage={this.onChangePage} /> : ''}
                </Col>
                
                <Col className='right-align padding-top'>
                <Button className='button-color download-font-size add-scorecard' onClick={() => this.editQuestionaries({})}>Add Question set</Button>
                  {/* <Button className='button-color download-font-size' onClick={this.downloadScorecard}>Download Question set</Button> */}
                </Col>
              </Row>
              </div>
                </div>
                <PopupModels showPopup={this.state.questionariesSuccessModel} iconFlag="success" message={ MessageConstant.DELETE_QUESTIONSET} handleClosePopup={this.handlePopupClose.bind(this)} />
                <ConfirmationModal showModal={this.state.showDeleteConfirmation}
        handleCloseModal={this.hideDeletePopup.bind(this)}
        message={MessageConstant.QUESTIONSET_DELETE_CONFIRMATION}
        navigate={()=>{this.handleDeleteQuestion(this.state.qid, this.state.questionDetail)}} />
            </div>}
        </div>
    )
  }
}

export default ScorecardQuestionaries;