import React, { Component } from "react";
import "./GimsReports.css";
import { Sidebar, Header } from "..";
import {
  Tab,
  Tabs,
  DropdownButton,
  Dropdown,
  Button,
  Modal,
} from "react-bootstrap";
import GimsReportChartLayout from "./GimsReportChartLayout/GimsReportChartLayout";
import CountryTotals from "./CountryTotals/CountryTotals";
import ScorecardReports from "./ScorecardReports/ScorecardReports";
import EndpointConstant from "../../constants/EndpointConstant";
import utils from "../../utils";
import PopupModels from "../PopupModels/PopupModels";
import MessageConstant from "../../constants/MessageConstant";
import PropTypes from "prop-types";
import RouterConstant from "../../constants/RouterConstant";
import RoleConstant from "./../../constants/RoleConstant";
import { ProgressBar } from "primereact/progressbar";
import { v4 as uuidV4 } from "uuid";

/**
 * A class that generate gims report
 **/
class GimsReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Takes active tab from props if it is defined there
      activeTab: props.activeTab || "1",
      showChecked: false,
      sectionThree: false,
      sectionSix: false,
      selectionData: [],
      showEmailDialog: false,
      popUpFlag: "",
      popUpMessage: "",
      sectionTen: false,
      sectionFive: false,
      sectionFour: false,
      sectionNine: false,
      sectionTwo: false,
      sectionOne: false,
      sectionSeven: false,
      sectionEight: false,
      filterDataforExcelDownload: {},
      downloadRequest: "Processing",
      sId: "",
      downloadUrl: "",
      showDataSync: false,
      extractValue: 0,
      extractSuccess: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCheckToSelect = this.handleCheckToSelect.bind(this);
    this.printAll = this.printAll.bind(this);
    this.downloadAll = this.downloadAll.bind(this);
    this.emailAll = this.emailAll.bind(this);
    this.getCheckboxFromProps = this.getCheckboxFromProps.bind(this);
    this.generatePrint = this.generatePrint.bind(this);
    this.generateCanvas = this.generateCanvas.bind(this);
    this.generateEmail = this.generateEmail.bind(this);
    this.unCheckAll = this.unCheckAll.bind(this);
    this.downloadCountryTotals = this.downloadCountryTotals.bind(this);
    this.onDownloadFilter = this.onDownloadFilter.bind(this);
    this.filteredDataToDownload = {};
    this.CountryTotalSendEmail = this.CountryTotalSendEmail.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
    this.getDatasetListForBioconnect =
      this.getDatasetListForBioconnect.bind(this);
    this.getProcessingDatasetListForBioconnect =
      this.getProcessingDatasetListForBioconnect.bind(this);
    this.interval = null;
    this.hideDataSyncOnCancel = this.hideDataSyncOnCancel.bind(this);
    this.handleExtractSuccessClose = this.handleExtractSuccessClose.bind(this);
  }

  /*
   * Handles selected checkbox to download, email and print
   */
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.activeTab == "3") {
          this.getCheckboxFromProps({
            sectionOne: this.state.sectionOne,
            sectionTwo: this.state.sectionTwo,
            sectionNine: this.state.sectionNine,
            sectionFour: this.state.sectionFour,
            sectionFive: this.state.sectionFive,
            sectionTen: this.state.sectionTen,
            sectionSeven: this.state.sectionSeven,
            sectionEight: this.state.sectionEight,
          });
        } else {
          this.getCheckboxFromProps({
            sectionThree: this.state.sectionThree,
            sectionSix: this.state.sectionSix,
          });
        }
      }
    );
  }

  /*
   * Uncheck all the checked checkbox
   */
  unCheckAll() {
    this.setState({
      sectionThree: false,
      sectionSix: false,
      sectionTen: false,
      sectionFive: false,
      sectionFour: false,
      sectionNine: false,
      sectionTwo: false,
      sectionOne: false,
      sectionSeven: false,
      sectionEight: false,
    });
  }

  /*
   * The active tab must be set into the state so that
   * the Tabs component knows about the change and re-renders.
   */
  handleSelect(selectedTab) {
    let activeTab = this.state.activeTab;
    //console.log("SelectedTab", selectedTab);
    activeTab = selectedTab;
    this.setState({
      activeTab,
    });
  }

  /*
   * Enabling and showing checkbox to
   * select for download, email and print
   */
  handleCheckToSelect(event) {
    event.preventDefault();
    this.setState({
      showChecked: true,
      selectionData: [],
    });
  }

  /*
   * Print All the charts
   */
  printAll(e) {
    e.preventDefault();
    //console.log("dddd", e);
    if (this.state.activeTab == "3") {
      this.getCheckboxFromProps({
        sectionOne: true,
        sectionTwo: true,
        sectionNine: true,
        sectionFour: true,
        sectionFive: true,
        sectionTen: true,
        sectionSeven: true,
        sectionEight: true,
      });
    } else {
      this.getCheckboxFromProps({
        sectionThree: true,
        sectionSix: true,
      });
    }
    setTimeout(() => {
      this.generatePrint();
    }, 10000);
  }

  /*
   * Download All the charts
   */
  downloadAll(event) {
    event.preventDefault();
    if (this.state.activeTab == "3") {
      this.getCheckboxFromProps({
        sectionOne: true,
        sectionTwo: true,
        sectionNine: true,
        sectionFour: true,
        sectionFive: true,
        sectionTen: true,
        sectionSeven: true,
        sectionEight: true,
      });
    } else {
      this.getCheckboxFromProps({
        sectionThree: true,
        sectionSix: true,
      });
    }
    setTimeout(() => {
      this.generateCanvas("download");
    }, 500);
  }

  downloadExcel(event) {
    event.preventDefault();

    clearInterval(this.interval);
    // console.log("this state",this.state);
    this.interval = null;
    this.setState({
      showDataSync: true,
    });
    this.getDatasetListForBioconnect();
    this.interval = setInterval(async () => {
      let val = this.state.extractValue;
      val += Math.floor(Math.random() * 15) + 1;
      if (val >= 99) {
        val = 99;
      }

      this.setState({
        extractValue: val,
      });
      this.state.downloadRequest == "Processing"
        ? this.getProcessingDatasetListForBioconnect()
        : "";
      // console.log("downloadss",this.state.downloadRequest,this.state.downloadRequest === "Processing")
      if (this.state.downloadRequest == "Completed") {
        this.hideDataSyncOnCancel();
        this.setState({
          extractSuccess: true,
          extractValue: 0,
          extractResponseMsg: MessageConstant.DOWNLOADED_MICE_REPORT,
          extractResponseFlagIcon: "success",
        });

        clearInterval(this.interval);
        this.interval = null;
      }
    }, 10000);
  }

  getDatasetListForBioconnect() {
    //  console.log("downn",this.state.sId);
    let data = { ...this.state.filterDataforExcelDownload };
    data.sId = uuidV4();

    this.setState({ downloadRequest: "Processing", sId: data.sId });
    let self = this;
    utils.makeRequestInBackground(
      this,
      EndpointConstant.DOWNLOAD_INTERACTION_FROM_FILE,
      data,
      function (result) {
        return;
      }
    );
  }

  getProcessingDatasetListForBioconnect() {
    let data = {
      downloadRequest: this.state.downloadRequest,
      sId: this.state.sId,
    };
    let self = this;
    utils.makeRequestInBackground(
      this,
      EndpointConstant.DOWNLOAD_INTERACTION_FROM_FILE,
      data,
      function (result) {
        // console.log("downResulttt", result);
        if (result.downloadRequest == "Completed") {
          self.setState({
            downloadRequest: result.downloadRequest,
            extractSuccess: true,
            showDataSync: false,
            extractValue: 0,
            extractResponseMsg: MessageConstant.DOWNLOADED_MICE_REPORT,
            extractResponseFlagIcon: "success",
          });
          window.open(result.url, "Download");
          clearInterval(self.interval);
          self.interval = null;
        } else {
          self.setState({ downloadRequest: result.downloadRequest });
        }
      }
    );
  }

  hideDataSyncOnCancel() {
    this.setState({
      showDataSync: false,
      extractValue: 0,
      downloadUrl: "",
      sId: "",
    });
    clearInterval(this.interval);
    this.interval = null;
  }

  handleExtractSuccessClose() {
    this.setState({
      extractSuccess: false,
    });
  }

  /*
   * Email All the charts
   */
  emailAll(event) {
    event.preventDefault();
    //console.log("Email All");
    event.preventDefault();
    if (this.state.activeTab == "3") {
      this.getCheckboxFromProps({
        sectionOne: true,
        sectionTwo: true,
        sectionNine: true,
        sectionFour: true,
        sectionFive: true,
        sectionTen: true,
        sectionSeven: true,
        sectionEight: true,
      });
    } else {
      this.getCheckboxFromProps({
        sectionThree: true,
        sectionSix: true,
      });
    }
    setTimeout(() => {
      this.generateCanvas("email");
    }, 500);
  }

  /*
   * Getting all the checkbox value from
   * other component as a props
   */
  getCheckboxFromProps(data) {
    const identifiers = Object.keys(data);
    const sectionData = identifiers.filter(function (id) {
      return data[id];
    });
    this.setState({
      selectionData: sectionData,
    });
  }

  /*
   * Calculating total height of selected chart or
   * all chart for applying to generate pdf
   */
  generateTotalHeight() {
    let height = 0;
    this.state.selectionData
      .map((d) => {
        return document.querySelector("#" + d).offsetHeight;
      })
      .map((data) => {
        return (height += data);
      });
    return height;
  }

  /*
   * Generating selected or all charts to
   * convert html dom to canvas image and
   * adding to jspdf for pdf generation
   */
  generateHtml2Canvas(selector, pdf, img, x, y, width, height) {
    window
      .html2canvas(document.querySelector(selector))
      .then(function (canvas) {
        img = canvas.toDataURL("image/jpeg", 1.0);
        pdf.addImage(img, "JPEG", x, y, width, height);
      });
  }

  /*
   * Getting width and height of selected or
   * all charts to set the positions in pdf
   */
  generateWidthHeight(selector) {
    let size = {};
    let width = document.querySelector(selector).offsetWidth;
    let height = document.querySelector(selector).offsetHeight;
    size.width = width;
    size.height = height;
    return size;
  }

  /*
   * Calculate width and height based on selection
   * while particular chart selection
   */

  calculateHeight(selectedValues, currentChart) {
    let chartArray = [
      "sectionOne",
      "sectionTwo",
      "sectionNine",
      "sectionFour",
      "sectionFive",
      "sectionTen",
      "sectionSeven",
      "sectionEight",
    ];
    let currentIndex = chartArray.indexOf(currentChart);
    let height = 0;
    let count = 0;
    for (let i = 0; i < currentIndex; i++) {
      if (selectedValues.indexOf(chartArray[i]) > -1) {
        height += this.generateWidthHeight("#" + chartArray[i]).height;
        count = ++count;
      }
    }
    const yAxis = count ? height + count * 40 : 20;
    return yAxis;
  }

  /*
   * Setting pdf configuration and
   * convert the selected chart or all the chart
   * to base64 and download the pdf or email the base64 data
   */
  generateCanvas(downloadOrEmailArgs) {
    //console.log("download", downloadOrEmailArgs);
    //console.log("SelectionData", this.state.selectionData);
    let pdfWidth = document.getElementById("tabGimsreport").offsetWidth + 500;
    let pdfHeight =
      document.getElementById("tabGimsreport").offsetHeight + 1200;
    var selectedData = this.state.selectionData;
    if (selectedData.length > 0) {
      utils.showLoader();
      var pdf = new window.jsPDF("p", "px", [pdfWidth, pdfHeight]);
      // var pageHeight = pdf.internal.pageSize.height;
      // var pageWidth = pdf.internal.pageSize.width;
      //console.log("pageHeight", pageHeight);
      //console.log("pageWidth", pageWidth);

      //console.log("this.generateTotalHeight", this.generateTotalHeight());
      var myImage = "";
      for (let i = 0; i < selectedData.length; i++) {
        if (selectedData[i] === "sectionThree") {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            10,
            20,
            widHgt.width,
            widHgt.height
          );
          //console.log("1");
        }

        if (selectedData[i] === "sectionSix") {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          let yAxis =
            selectedData.indexOf("sectionThree") >= 0
              ? this.generateWidthHeight("#sectionThree").height + 40
              : 20;
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            10,
            yAxis,
            widHgt.width,
            widHgt.height
          );
          //console.log("1");
        }

        if (selectedData[i] === "sectionOne") {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            10,
            20,
            widHgt.width,
            widHgt.height
          );
        }

        if (selectedData[i] === "sectionTwo") {
          let yAxis = this.calculateHeight(selectedData, "sectionTwo");
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            10,
            yAxis,
            widHgt.width,
            widHgt.height
          );
        }

        if (selectedData[i] === "sectionNine") {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          let yAxis = this.calculateHeight(selectedData, "sectionNine");
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            10,
            yAxis,
            widHgt.width,
            widHgt.height
          );
        }

        if (selectedData[i] === "sectionFour") {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          let yAxis = this.calculateHeight(selectedData, "sectionFour");
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            10,
            yAxis,
            widHgt.width,
            widHgt.height
          );
        }

        if (selectedData[i] === "sectionFive") {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          let yAxis = this.calculateHeight(selectedData, "sectionFive");
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            10,
            yAxis,
            widHgt.width,
            widHgt.height
          );
        }

        if (selectedData[i] === "sectionTen") {
          let yAxis = this.calculateHeight(selectedData, "sectionFive");
          let xAxis =
            selectedData.indexOf("sectionFive") >= 0
              ? this.generateWidthHeight("#sectionFive").width + 30
              : 10;
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            xAxis,
            yAxis,
            widHgt.width,
            widHgt.height
          );
        }

        if (selectedData[i] === "sectionSeven") {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          let yAxis = this.calculateHeight(selectedData, "sectionSeven");
          yAxis = yAxis > 3000 ? 3200 : yAxis;
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            10,
            yAxis,
            widHgt.width,
            widHgt.height
          );
        }

        if (selectedData[i] === "sectionEight") {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          let yAxis = this.calculateHeight(selectedData, "sectionSeven");
          yAxis = yAxis > 3000 ? 3200 : yAxis;
          let xAxis =
            selectedData.indexOf("sectionSeven") >= 0
              ? this.generateWidthHeight("#sectionSeven").width + 30
              : 10;
          this.generateHtml2Canvas(
            `#${selectedData[i]}`,
            pdf,
            myImage,
            xAxis,
            yAxis,
            widHgt.width,
            widHgt.height
          );
        }

        setTimeout(() => {
          if (i === selectedData.length - 1) {
            utils.hideLoader();
            if (downloadOrEmailArgs === "email") {
              const binaryEmail = btoa(pdf.output());
              this.generateEmail(binaryEmail);
            } else {
              pdf.save("download.pdf");
            }
            //console.log("21");
          }
        }, 15000);
      }
    } else {
      alert("Please select to " + downloadOrEmailArgs);
    }

    this.setState({
      showChecked: false,
      selectionData: [],
    });

    this.unCheckAll();
  }

  /*
   * Print selected or all the charts
   */
  generatePrint() {
    var selectedData = this.state.selectionData;
    if (selectedData.length > 0) {
      var printContent = "";
      for (let i = 0; i < selectedData.length; i++) {
        printContent += document.getElementById(`${selectedData[i]}`).innerHTML;
      }
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(printContent);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    } else {
      alert("Please select to print");
    }

    this.setState({
      showChecked: false,
      selectionData: [],
    });
    this.unCheckAll();
  }

  /*
   * Getting base64 data for selected or all the charts to
   * send the data to server for sending reports via email
   */
  generateEmail(basePdf) {
    //console.log("generateEmail", basePdf);
    let data = {};
    let self = this;
    let header = { "Content-Type": "application/json" };
    data.type = "GIMSReport";
    data.data = basePdf;
    data.contentType = "application/pdf";
    utils.makeRequest(
      this,
      EndpointConstant.GIMS_REPORT_SEND_EMAIL,
      data,
      function (result) {
        //console.log("email result", result);
        if (result.error === false) {
          self.setState({
            showEmailDialog: true,
            popUpFlag: "success",
            popUpMessage: MessageConstant.EMAIL_SUCCESS_MESSAGE,
          });
        } else {
          self.setState({
            showEmailDialog: true,
            popUpFlag: "error",
            popUpMessage: MessageConstant.EMAIL_FAILED_MESSAGE,
          });
        }
      },
      header
    );

    this.setState({
      showChecked: false,
      selectionData: [],
    });
    this.unCheckAll();
  }

  /*
   * Handling close the confirmation popup
   */
  handleDeleteClose() {
    this.setState({ showEmailDialog: false });
  }

  /*
   * Country totals searched result
   * data from country total component as props
   */
  onDownloadFilter(filteredData) {
    this.filteredDataToDownload = filteredData;
  }

  /*
   * Download country totals records from webservice api
   */
  downloadCountryTotals(event) {
    event.preventDefault();
    //console.log("downloadcountry total", this.filteredDataToDownload);
    //let self = this;
    let data = this.filteredDataToDownload;
    // utils.makeRequest(this, EndpointConstant.DOWNLOAD_COUNTRY_TOTALS, data, function (result) {
    utils.makeRequest(
      this,
      EndpointConstant.DOWNLOAD_COUNTRY_TOTALS_FROM_FILE,
      data,
      function (result) {
        //console.log("Download country totals result", result);
        window.open(result.downloadUrl, "Download");
      }
    );
  }
  componentDidMount() {
    // if(utils.getSessionItem("role") === RoleConstant.Reviewer){
    //   this.props.history.push(RouterConstant.SCORE_CARD_LINK)
    // }
  }
  /*
   * Email country totals records from webservice api
   */
  CountryTotalSendEmail(event) {
    event.preventDefault();
    let data = {};
    let self = this;
    data.type = "CountryTotals";
    data.contentType = "application/json";
    data.countryTotals = this.filteredDataToDownload;
    utils.makeRequest(
      this,
      EndpointConstant.GIMS_REPORT_SEND_EMAIL,
      data,
      function (result) {
        //console.log("email result", result);
        if (result.error === false) {
          self.setState({
            showEmailDialog: true,
            popUpFlag: "success",
            popUpMessage: MessageConstant.EMAIL_SUCCESS_MESSAGE,
          });
        } else {
          self.setState({
            showEmailDialog: true,
            popUpFlag: "error",
            popUpMessage: MessageConstant.EMAIL_FAILED_MESSAGE,
          });
        }
      }
    );
  }

  /*
   * Rendering component layout
   */
  render() {
    const { activeTab, showChecked } = this.state;
    //console.log("active tab", this.state.activeTab);
    return (
      <div>
        <Header />
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="menu-content tab-content" id="tabGimsreport">
          <Tabs activeKey={activeTab} onSelect={this.handleSelect}>
            <Tab eventKey={1} title="MICE REPORTS">
              <GimsReportChartLayout
                showChecked={showChecked}
                state={this.state}
                handleChange={this.handleChange}
                setDataForReportGeneration={(filterData) =>
                  this.setState({ filterDataforExcelDownload: filterData })
                }
              />
            </Tab>
            <Tab eventKey={2} title="COUNTRY TOTALS">
              <CountryTotals onDownloadFilter={this.onDownloadFilter} />
            </Tab>
            <Tab eventKey={3} title="SCORECARD REPORTS">
              <ScorecardReports
                showChecked={showChecked}
                state={this.state}
                handleChange={this.handleChange}
              />
            </Tab>
          </Tabs>
          {activeTab === "1" || activeTab === "3" ? (
            <div className="chart-operations">
              {!showChecked ? (
                <React.Fragment>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      <span>
                        <img src="/Images/ico-print.png" alt="Print" />
                        Print
                      </span>
                    }
                  >
                    <Dropdown.Item onClick={this.printAll}>
                      Print All
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleCheckToSelect}>
                      Print Selected
                    </Dropdown.Item>
                  </DropdownButton>
                  <DropdownButton
                    id="dropdown-basic-button1"
                    title={
                      <span>
                        <img
                          src="/Images/ico-download-all.png"
                          alt="Download"
                        />
                        Download
                      </span>
                    }
                  >
                    <Dropdown.Item onClick={this.downloadAll}>
                      Download All
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleCheckToSelect}>
                      Download Selected
                    </Dropdown.Item>
                    {this.state.activeTab == "1" && (
                      <Dropdown.Item onClick={this.downloadExcel}>
                        Download as Excel
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                  <DropdownButton
                    id="dropdown-basic-button2"
                    title={
                      <span>
                        <img src="/Images/ico-email-all.png" alt="Email" />
                        Email
                      </span>
                    }
                  >
                    <Dropdown.Item onClick={this.emailAll}>
                      Email All
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleCheckToSelect}>
                      Email Selected
                    </Dropdown.Item>
                  </DropdownButton>
                </React.Fragment>
              ) : (
                <div className="btn-operation">
                  <Button
                    variant="secondary"
                    className="clear"
                    onClick={this.generatePrint}
                  >
                    <span>
                      <img src="/Images/ico-print.png" alt="Print" />
                      Print
                    </span>
                  </Button>
                  <Button
                    variant="secondary"
                    className="clear"
                    onClick={() => this.generateCanvas("download")}
                  >
                    <span>
                      <img src="/Images/ico-download-all.png" alt="Download" />
                      Download
                    </span>
                  </Button>
                  <Button
                    variant="secondary"
                    className="clear"
                    onClick={() => this.generateCanvas("email")}
                  >
                    <span>
                      <img src="/Images/ico-email-all.png" alt="Email" />
                      Email
                    </span>
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className="chart-operations">
              <div className="btn-operation">
                {/*  <Button variant="secondary" className='clear' onClick={this.generatePrint} ><span><img src='/Images/ico-print.png' alt="Print" />Print</span></Button> */}
                <Button
                  variant="secondary"
                  className="clear"
                  onClick={this.downloadCountryTotals}
                >
                  <span>
                    <img src="/Images/ico-download-all.png" alt="Download" />
                    Download
                  </span>
                </Button>
                <Button
                  variant="secondary"
                  className="clear"
                  onClick={this.CountryTotalSendEmail}
                >
                  <span>
                    <img src="/Images/ico-email-all.png" alt="Email" />
                    Email
                  </span>
                </Button>
              </div>
            </div>
          )}
          <Modal
            show={this.state.showDataSync}
            onHide={this.hideDataSyncOnCancel}
            className="dataset-extract-dialog"
          >
            <Modal.Header closeButton>
              <Modal.Title>DOWNLOAD MICE REPORT </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProgressBar
                style={{
                  height: "10px",
                  borderRadius: "5px",
                  border: "1px solid #0063C3",
                  background: "#F2F2F2",
                }}
                showValue={false}
                value={this.state.extractValue}
              />
              <p className="progressMsg">Downloading is in progress...</p>
              <p className="progressMsg">
                {this.state.extractValue}% completed
              </p>
            </Modal.Body>
            <Modal.Footer className="confirm-popup-header">
              <Button
                variant="primary"
                className="clear "
                onClick={this.hideDataSyncOnCancel}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <PopupModels
            showPopup={this.state.extractSuccess}
            iconFlag={this.state.extractResponseFlagIcon}
            message={this.state.extractResponseMsg}
            handleClosePopup={this.handleExtractSuccessClose.bind(this)}
          />
          <PopupModels
            showPopup={this.state.showEmailDialog}
            iconFlag={this.state.popUpFlag}
            message={this.state.popUpMessage}
            handleClosePopup={this.handleDeleteClose.bind(this)}
          />
        </div>
      </div>
    );
  }
}
GimsReports.propTypes = {
  activeTab: PropTypes.string,
};

export default GimsReports;
