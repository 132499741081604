import React, { Component } from 'react';
import {Header} from '../';
import './PageNotFound.css';

class PageNotFound extends Component {

  render() {
    return (
      <div>
        <Header hideWelcomeMsg={'true'}/>
        <div className="unauthorized-content">
          <center><h1>Unauthorized</h1></center>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
