var config = {};
config.env = 'int';

//AWS
config.aws = {};
// config.aws.apiRoot = "https://int-api-gimsmedinfo.amgen.com/";
config.aws.apiRoot = "https://int-api-mice.amgen.com/";

//SSO
config.sso = {};
config.sso.url = "https://dev-630954.okta.com/home/selfdev630954_demookta_2/0oa1ihwzcwpC2mKfu357/aln1iiaocr7jdzWe1357";

//api-key
config.api = {};
config.api.key = '30izS1wYKP9Xd3Z5V316O48fLP2srq3J8K2aHjIx';

export default config;
