

import React, { Component } from 'react';
import './DataModelReports.css';
import { Sidebar, Header } from '..';
import { DataModelCharts } from '..';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
// import { Image } from 'react-bootstrap';
import Pagination from '../Pagination/Pagination';
import DataModelSearch from './DataModelSearch';
import Moment from 'react-moment';
import DataModelCurrentAcceptanceCriteria from './DataModelCurrentAcceptanceCriteria';


class DataModelReports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataModelReports: [],
      graphReport: [],
      dataModelReportsSort: [],
      currentTodos: [],
      showpagination: false,
      showChart: false,
      displayWarning: false,
      latestTrainedVersion: null,
      versions: [],
      acceptanceDetails:{},
    };
    this.getModelReports = this.getModelReports.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.searchResults = this.searchResults.bind(this);
    this.acceptanceGraph = this.acceptanceGraph.bind(this);
  }
  componentDidMount() {
    this.getModelReports();
  }
  searchResults(dataModelReports) {
    // debugger
    this.setState({ dataModelReports: dataModelReports }, () => { this.setState({ showpagination: true }) })
  }
  getModelReports() {
    let data = {};
    let self = this;
    let versions = {};
    utils.makeRequest(this, EndpointConstant.GET_DATA_MODEL_REPORTS, data, function (result) {
      if (result.error === 'false' || result.error === false) {
        let dataModelReports = result.dataModelReports;
        dataModelReports = dataModelReports.sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
        dataModelReports.forEach((dataModel) => {
          versions[dataModel.version] = 1;
        });
        // let errors = [];
        self.loading = false;
        if (dataModelReports.length === 0) {
          return;
        }
        self.setState({ versions: Object.keys(versions), dataModelReports: dataModelReports, dataModelReportsSort: dataModelReports, showChart: true, latestTrainedVersion: dataModelReports[0].version, graphReport: dataModelReports }, () => {
          self.setState({ showpagination: true })
        })
      }

    }
    )
  }
  sortDataModel(prop, sortId) {
    return (a, b) => {
      if (sortId.sortId === 'sort') {
        return a[prop].toString().localeCompare(b[prop].toString());
      }
      else if (sortId.sortId === 'sort-up') {
        return b[prop].toString().localeCompare(a[prop].toString());
      }
      else if (sortId.sortId === 'sort-down') {
        return a;
      }
    }
  }
  onSortDataModel(data, e) {
    e.preventDefault();
    //console.log(e.target.outerHTML);
    let sortId = e.target.id;
    // let className = e.target.className;
    if (sortId === 'sort') {
      e.target.id = 'sort-up';
      e.target.className = 'fas fa-sort-up';
    }
    else if (sortId === 'sort-up') {
      e.target.id = 'sort-down';
      e.target.className = 'fas fa-sort-down';
    }
    else if (sortId === 'sort-down') {
      e.target.id = 'sort';
      e.target.className = 'fas fa-sort';
    }
    const { dataModelsSort } = this.state;
    switch (data) {
      case 'dataModelid':
        // let dataModelId = [...dataModelsSort].sort(this.sortdataModel('dataModelId', { sortId: sortId }));
        this.setState({
          dataModels: [...dataModelsSort].sort(this.sortDataModel('dataModelId', { sortId: sortId }))
        });
        break;
      case 'caseno':
        // let interactionId = [...dataModelsSort].sort(this.sortDataModel('interactionId', { sortId: sortId }));
        this.setState({
          dataModels: [...dataModelsSort].sort(this.sortDataModel('interactionId', { sortId: sortId }))
        });
        break;
      case 'dataModeltype':
        // let dataModelType = [...dataModelsSort].sort(this.sortDataModel('dataModelType', { sortId: sortId }));
        this.setState({
          dataModels: [...dataModelsSort].sort(this.sortDataModel('dataModelType', { sortId: sortId }))
        });
        break;
      case 'country':
        // let country = [...dataModelsSort].sort(this.sortDataModel('country', { sortId: sortId }));
        this.setState({
          dataModels: [...dataModelsSort].sort(this.sortDataModel('country', { sortId: sortId }))
        });
        break;
      case 'modifiedon':
        // let modifiedOn = [...dataModelsSort].sort(this.sortDataModel('modifiedOn', { sortId: sortId }));
        this.setState({
          dataModels: [...dataModelsSort].sort(this.sortDataModel('modifiedOn', { sortId: sortId }))
        });
        break;
      case 'associatedTeam':
        // let associatedTeam = [...dataModelsSort].sort(this.sortDataModel('associatedTeam', { sortId: sortId }));
        this.setState({
          dataModels: [...dataModelsSort].sort(this.sortDataModel('associatedTeam', { sortId: sortId }))
        });
        break;
      case 'status':
        // let status = [...dataModelsSort].sort(this.sortDataModel('status', { sortId: sortId }));
        this.setState({
          dataModels: [...dataModelsSort].sort(this.sortDataModel('status', { sortId: sortId }))
        });
        break;
      default:
        break;
    }
  }
  colorChangeStatus(status) {
    const styles = {
      Approved: '#0F9D58',
      Rejected: '#EA4335',
      Processing: '#F4B400',
      Active: '#0f9d58',
      Deployed:'#ffc107',
    }
    switch (status) {
      case ('active'):
          return {color: styles.Active,textTransform: 'capitalize'};
      case ('deployed'):
        return { color: styles.Deployed,textTransform: 'capitalize'};
      case ('Rejected'):
        return { color: styles.Rejected,textTransform: 'capitalize'};
      default:
        return {};
    }
  }
  onChangePage(currentTodos) {
    this.setState({ currentTodos: currentTodos }, () => { this.setState({ showpagination: true }) })
  }
  acceptanceGraph(data) {
    this.setState({acceptanceDetails: data });
    // console.log("stste check",this.state.acceptanceDetails);
  }
  showWarning() {
    this.setState({ displayWarning: false });
  }
  render() {
    // const {dataModelReports}=this.state
    const dataModelList = this.state.currentTodos.map((data) => {
      return (
        <tr key={data.id}>
          <td>{data['modelType']}</td>
          <td>{data['auc']}</td>
          <td>{data['version']}</td>
          <td>{data['precision']}</td>
          <td>{data['accuracy']}</td>
          <td>{data['sensitivity']}</td>
          <td>{data['specificity']}</td>
          <td>{data['threshold']}</td>
          <td><Moment unix format="MM/D/YYYY">{data['timestamp']}</Moment></td>
          <td>{data['falseNegatives']}</td>
          <td>{data['falsePositives']}</td>
          <td>{data['trueNegatives']}</td>
          <td>{data['truePositives']}</td>
          <td>{data['recordCount']}</td>
          <td style={this.colorChangeStatus(data['modelState'])}>{data['modelState']}</td>
        </tr>
      );
    });
    let chart = null;
    if (this.state.showChart) {
      chart=<DataModelCharts acceptanceDetails={this.state.acceptanceDetails} self={this} data={this.state.graphReport} showWarning={this.showWarning.bind(this)} />;
    }
    return (
      <div>
        <Header ref={`header`} />
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="menu-content">
        <DataModelCurrentAcceptanceCriteria details={this.acceptanceGraph}/>
          <DataModelSearch data={this.state.dataModelReports} getModelReports={this.getModelReports} versions={this.state.versions} latestTrainedVersion={this.state.latestTrainedVersion} self={this} getSearchResult={this.searchResults} displayWarning={this.state.displayWarning} />
         
          <table>
            <thead>
              <tr>
                <th onClick={(e) => this.onSortDataModel('modelType', e)}>Type</th>
                <th onClick={(e) => this.onSortDataModel('auc', e)}>AUC</th>
                <th onClick={(e) => this.onSortDataModel('Version', e)}>Version</th>
                <th onClick={(e) => this.onSortDataModel('precision', e)}>Precision</th>
                <th onClick={(e) => this.onSortDataModel('accuracy', e)}>Accuracy</th>
                <th onClick={(e) => this.onSortDataModel('sensitivity', e)}>Sensitivity</th>
                <th onClick={(e) => this.onSortDataModel('specificity', e)}>Specificity</th>
                <th onClick={(e) => this.onSortDataModel('threshold', e)}>Threshold</th>
                <th onClick={(e) => this.onSortDataModel('trainedOn', e)}>Trained On</th>
                <th onClick={(e) => this.onSortDataModel('falseNegative', e)}>False Negatives</th>
                <th onClick={(e) => this.onSortDataModel('falsePositive', e)}>False Positives</th>
                <th onClick={(e) => this.onSortDataModel('trueNegative', e)}>True Negatives</th>
                <th onClick={(e) => this.onSortDataModel('truePositive', e)}>True Positives</th>
                <th onClick={(e) => this.onSortDataModel('recordAcoount', e)}>Record Count</th>
                <th onClick={(e) => this.onSortDataModel('recordAcoount', e)}>Status</th>
              </tr>
            </thead>
            <tbody>
              {dataModelList}
            </tbody>
          </table>
          {this.state.showpagination ? <Pagination data={this.state.dataModelReports} onChangePage={this.onChangePage} /> : ''}
          {chart}
        </div>
      </div>
    )
  }
}

export default DataModelReports;