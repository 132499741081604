import React, { Component, Fragment } from "react";
import { Accordion, Card, Button, Form, Col, Image } from "react-bootstrap";
import MultiSelect from "@khanacademy/react-multi-select";
import CommonService from "../../../commonService/CommonService";
import PropTypes from "prop-types";
import utils from "../../../utils";
import EndpointConstant from "../../../constants/EndpointConstant";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import moment from "moment";
import PopupModels from "../../PopupModels/PopupModels";
import "../ScorecardReports/ScorecardReports.css";

/**
 * A class that filter gims report
 **/
class ScorecardReportFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconName: "icon-drop-up",
      selected: [],
      ccAdmin: [],
      ccAdminSelected: [],
      assocTeam: [],
      assocTeamSelected: [],
      datasets: [],
      datasetsSelected: [],
      questionset: [],
      questionsetSelected: [],
      channel: [],
      channelSelected: [],
      dataSet: [],
      amgenQc: [],
      amgenQcSelected: [],
      qmAssociate: [],
      qmAssociateSelected: [],
      startDate: "",
      endDate: "",
      datePopup: false,
      errorMsg: "",
      country: [],
      countrySelected: [],
    };
    this.iconChange = this.iconChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.getDatasetList = this.getDatasetList.bind(this);
    this.getQuestionariesList = this.getQuestionariesList.bind(this);
    this.getOtherDetails = this.getOtherDetails.bind(this);
    this.getUserList = this.getUserList.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleValidationClose = this.handleValidationClose.bind(this);
    this.checkDateValidation = this.checkDateValidation.bind(this);
  }

  componentDidMount() {
    //if(utils.getSessionItem("role") !== 'Reviewer') {
    this.getDatasetList();
    this.getQuestionariesList();
    this.getOtherDetails();
    this.getUserList();
    //}
  }

  /*
   * Toggling functionality for changing icon
   * in search header while showing and hiding search option
   */
  iconChange() {
    if (this.state.iconName === "icon-drop-up") {
      this.setState({ iconName: "icon-drop-down" });
    } else {
      this.setState({ iconName: "icon-drop-up" });
    }
  }

  /*
   * Handle search conditions
   */

  handleSearch(event) {
    event.preventDefault();
    const { startDate, endDate } = this.state;
    const filterData = {};
    const unixStartdate =
      startDate && moment(new Date(startDate), "DD/MM/YYYY hh:mm:ss").unix();
    const unixEnddate =
      endDate && moment(new Date(endDate), "DD/MM/YYYY hh:mm:ss").unix();
    if (unixStartdate !== "" && unixEnddate === "") {
      this.setState({
        validation: true,
        errMsg: "End date should not be empty",
      });
    } else if (unixStartdate === "" && unixEnddate !== "") {
      this.setState({
        validation: true,
        errMsg: "Start date should not be empty",
      });
    } else if (startDate && endDate && unixStartdate > unixEnddate) {
      //  console.log("start date should not greater than end date");
      this.setState({
        validation: true,
        errMsg: "Start date should not be greater than End date",
      });
    } else {
      if (this.state.selected.length > 0) {
        filterData.product = this.state.selected;
      }
      if (this.state.assocTeamSelected.length > 0) {
        filterData.associatedTeam = this.state.assocTeamSelected;
      }
      if (this.state.ccAdminSelected.length > 0) {
        filterData.callcenterAdmin = this.state.ccAdminSelected;
      }
      if (this.state.datasetsSelected.length > 0) {
        filterData.datasets = this.state.datasetsSelected;
      }
      if (this.state.questionsetSelected.length > 0) {
        filterData.questionset = this.state.questionsetSelected;
      }
      if (this.state.channelSelected.length > 0) {
        filterData.channel = this.state.channelSelected;
      }
      if (this.state.amgenQcSelected.length > 0) {
        filterData.amgenQc = this.state.amgenQcSelected;
      }
      if (this.state.qmAssociateSelected.length > 0) {
        filterData.qmAssociate = this.state.qmAssociateSelected;
      }
      if (this.state.countrySelected.length > 0) {
        filterData.country = this.state.countrySelected;
      }
      if (startDate && endDate) {
        filterData.startDate = moment(
          new Date(startDate),
          "DD/MM/YYYY hh:mm:ss"
        );
        filterData.endDate =
          moment(new Date(endDate)).format("YYYY-MM-DD") + "T23:59:000Z";
      }
      this.props.getScorecardReportFilter(filterData);
    }
  }

  /*
   * Reset all the search filter and
   * call search api to get all the data
   */
  handleOnClear(event) {
    event.preventDefault();
    this.setState(
      {
        selected: [],
        datasetsSelected: [],
        amgenQcSelected: [],
        questionsetSelected: [],
        channelSelected: [],
        ccAdminSelected: [],
        assocTeamSelected: [],
        qmAssociateSelected: [],
        countrySelected: [],
        startDate: "",
        endDate: "",
      },
      () => {
        this.handleSearch(event);
      }
    );
  }

  /*
   * Get DatasetList details
   */

  getDatasetList() {
    let data = {};
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.GET_DATASET_LIST,
      data,
      function (result) {
        //console.log("get dataset", result);
        const options = [];
        if (result.datasets && result.datasets.length) {
          result.datasets.map((dataset) => {
            return options.push({
              id: dataset.id,
              label: dataset.datasetName && dataset.datasetName.split(".")[0],
              value: dataset.id,
            });
          });
        }
        options.sort(self.compareDataset);
        self.setState({
          dataSet: result.datasets.length ? result.datasets : [],
          datasets: options,
        });
      }
    );
  }

  getQuestionariesList() {
    let data = {};
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARD_QUESTIONARIE_LIST,
      data,
      function (result) {
        if (result.error === false) {
          self.loading = false;
          const questions = result.Questionaries;
          const questionList = [];
          questions.forEach((val) => {
            questionList.push({
              id: val.id,
              label: val.questionsetName,
              value: val.id,
            });
          });
          questionList.sort(self.compareDataset);

          self.setState({
            questionset: questionList,
          });
        }
      }
    );
  }

  getOtherDetails() {
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const teams = [];
    // console.log('siteInfo', siteinfo)

    Object.keys(siteinfo.CallCenterTeam).map((team, i) => {
      teams.push({ id: i, value: team, label: siteinfo.CallCenterTeam[team] });
      return null;
    });
    teams.sort(this.compare);

    const teamOptions = [];
    teams.map((teams) => {
      // console.log("dataset", teams)
      return teamOptions.push({
        id: teams.id,
        label: teams.label,
        value: teams.value,
      });
    });
    teamOptions.sort(this.compare);

    const channelOptions = [];
    siteinfo.Channel.map((channel) => {
      // console.log("dataset", channel)
      return channelOptions.push({
        id: channel,
        label: channel,
        value: channel,
      });
    });
    channelOptions.sort(this.compare);
    const countryOptions = [];
    siteinfo.Countries.map((country) => {
      return countryOptions.push({
        id: country,
        label: country,
        value: country,
      });
    });
    countryOptions.sort(this.compare);

    this.setState({
      // ccAdmin: teams,
      assocTeam: teamOptions,
      channel: channelOptions,
      country: countryOptions,
    });
    let data = { datasetId: "All" };
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARDS_LISTS_FROM_DATASET,
      data,
      async function (result) {
        utils.showLoader();
          await fetch(result.scorecards, {
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            method: "GET",
            body: null,
          })
            .then((response) => response.json())
            .then((s3ResponseJson) => {
              let scorecards = s3ResponseJson.map((val) => {
                return {
                  id: val.questionsetid,
                  label: val.questionsetName,
                  value: val.questionsetid,
                };
              });
              scorecards = scorecards.filter(
                (v, i, a) => a.findIndex((t) => t.id === v.id) === i
              );
              scorecards = scorecards.filter(
                (val) => !self.state.questionset.some((val1) => val1.id === val.id)
              );
              const questionsetName = [...self.state.questionset, ...scorecards];
              self.setState({ questionset: questionsetName });
              utils.hideLoader();
            })
            .catch((error) => {
              let er;
              // console.error(error);
              // console.log("Testing 12345 Error");
            });
      }
    );
  }

  getUserList() {
    let data = { isget: true };
    let self = this;
    // utils.showLoader();
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARD_REPORT_FROM_FILE,
      data,
      function (result) {
        // utils.hideLoader();
        const QmAssociate = [];
        const AmgenQc = [];
        const ccAdmin = [];
        result.scorecards.forEach((val) => {
          if (!QmAssociate.some((name) => name.label === val.QMAssociate)) {
            QmAssociate.push({
              id: val.id,
              label: val.QMAssociate,
              value: val.QMAssociate,
            });
          }
          if (
            val.amgenQCBy &&
            !AmgenQc.some((name) => name.label === val.amgenQCBy)
          ) {
            AmgenQc.push({
              id: val.id,
              label: val.amgenQCBy,
              value: val.amgenQCBy,
            });
          }
        });
        result.interactions.forEach((val, index) => {
          if (!ccAdmin.some((name) => name.label === val) && val) {
            ccAdmin.push({ id: index, label: val, value: val });
          }
        });
        //    QmAssociateQmAssociate.sort(self.compare);
        //     AmgenQc.sort(self.compare);
        self.setState({
          amgenQc: AmgenQc,
          qmAssociate: QmAssociate,
          ccAdmin: ccAdmin,
        });
      }
    );
  }

  handleOnChange(event) {
    //console.log("onchange", event);
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  // compare data to sort
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const valueA = a.value.toUpperCase();
    const valueB = b.value.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  compareDataset(a, b) {
    const valueA = a.label.toUpperCase();
    const valueB = b.label.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  checkDateValidation(val) {
    const unixStartdate = moment(
      new Date(this.state.startDate),
      "DD/MM/YYYY hh:mm:ss"
    ).unix();
    const unixEnddate = moment(
      new Date(this.state.endDate),
      "DD/MM/YYYY hh:mm:ss"
    ).unix();
    let dd, mm, yyyy, dd1, mm1, yyyy1;
    if (this.state.startDate !== "") {
      dd = new Date(this.state.startDate).getDate();
      mm = new Date(this.state.startDate).getMonth() + 1;
      let dateValue = "";
      if (typeof this.state.startDate == "object") {
        dateValue = moment(new Date(this.state.startDate))
          .format("DD/MM/Y")
          .split("/");
      } else {
        dateValue = this.state.startDate.split("/");
      }
      yyyy = dateValue[2];
    }
    if (this.state.endDate !== "") {
      dd1 = new Date(this.state.endDate).getDate();
      mm1 = new Date(this.state.endDate).getMonth() + 1;
      let dateValue = "";
      if (typeof this.state.endDate == "object") {
        dateValue = moment(new Date(this.state.endDate))
          .format("DD/MM/Y")
          .split("/");
      } else {
        dateValue = this.state.endDate.split("/");
      }

      yyyy1 = dateValue[2];
    }

    let sDate = this.state.startDate;
    let eDate = this.state.endDate;
    if (unixStartdate == 0 || unixEnddate == 0) {
      return false;
    } else if (
      this.state.startDate &&
      (dd.toString().length < 1 ||
        mm.toString().length < 1 ||
        yyyy > 2100 ||
        yyyy < 2000 ||
        isNaN(yyyy))
    ) {
      return false;
    } else if (
      (this.state.endDate &&
        (dd1.toString().length < 1 ||
          mm1.toString().length < 1 ||
          yyyy1 > 2100 ||
          yyyy1 < 2000)) ||
      isNaN(yyyy1)
    ) {
      return false;
    } else if (
      this.state.startDate !== "" &&
      this.state.endDate !== "" &&
      (isNaN(unixEnddate) || isNaN(unixStartdate))
    ) {
      this.setState({
        validation: true,
        errMsg: "Enter valid date",
      });
      return false;
    } else if (unixStartdate && unixEnddate && unixStartdate > unixEnddate) {
      this.setState({
        validation: true,
        errMsg: "Start date should not be greater than End date",
        startDate: val == 1 ? "" : sDate,
        endDate: val == 2 ? "" : eDate,
      });
      return false;
    }
    // console.log('true', dd, dd.toString().length < 1, mm,mm.toString().length < 1, parseInt(yyyy),  parseInt(yyyy) > 2000 || parseInt(yyyy) < 2100  )
    return true;
  }
  handleValidationClose() {
    this.setState({
      validation: false,
      errMsg: "",
    });
  }
  customFilter = (options, filter) => {
    const optionIncludesText = (option) => {
        const label = option.label.replace(/ /g, "") || "";
        const searchData = filter.toLowerCase().replace(/ /g, "") || "";
        return label.toLowerCase().startsWith(searchData);
    };
    return options.filter(optionIncludesText);
  };
  render() {
    const { selected } = this.state;
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const productOptions =
      siteinfo.Product &&
      Object.keys(siteinfo.Product)
        .sort()
        .map((product, index) => {
          return {
            id: index,
            label: product,
            value: siteinfo.Product[product],
          };
        });
    productOptions.push({ id: "n/a", label: "N/A", value: "N/A" });

    let countryList = [];

    //   this.props.countryList.sort().map((country, i) => {
    //       return countryList.push({ id: country, label: country, value: country });

    //     });
    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const startYear = new Date("01/01/2000");

    return (
      <Fragment>
        <PopupModels
          showPopup={this.state.validation}
          iconFlag="error"
          message={this.state.errMsg}
          handleClosePopup={this.handleValidationClose.bind(this)}
        />
        <Accordion defaultActiveKey="0" className="cardPadding">
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              onClick={this.iconChange}
              className="cardBodyStyle"
            >
              <span className="search-title">SCORECARD REPORT</span>{" "}
              <Image
                src="/Images/ico-drop-up.png"
                className={this.state.iconName}
              ></Image>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="cardBodyStyle multiSelheight">
                <Form onSubmit={this.handleSearch} className="searchForm">
                  <Form.Row>
                    <Col>
                      <Form.Label>Dataset</Form.Label>
                      <MultiSelect
                        options={this.state.datasets}
                        selected={this.state.datasetsSelected}
                        disableSearch={false}
                        hasSelectAll={true}
                        filterOptions={this.customFilter}
                        onSelectedChanged={(selected) => {
                          this.setState({ datasetsSelected: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Questionset</Form.Label>
                      <MultiSelect
                        options={this.state.questionset}
                        selected={this.state.questionsetSelected}
                        disableSearch={false}
                        hasSelectAll={true}
                        filterOptions={this.customFilter}
                        onSelectedChanged={(selected) => {
                          this.setState({ questionsetSelected: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Product</Form.Label>
                      <MultiSelect
                        options={productOptions}
                        selected={selected}
                        disableSearch={false}
                        hasSelectAll={true}
                        filterOptions={this.customFilter}
                        onSelectedChanged={(selected) => {
                          this.setState({ selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Channel</Form.Label>
                      <MultiSelect
                        options={this.state.channel}
                        selected={this.state.channelSelected}
                        disableSearch={false}
                        hasSelectAll={true}
                        filterOptions={this.customFilter}
                        onSelectedChanged={(selected) => {
                          this.setState({ channelSelected: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Call Center Agent</Form.Label>
                      <MultiSelect
                        options={this.state.ccAdmin}
                        selected={this.state.ccAdminSelected}
                        disableSearch={false}
                        hasSelectAll={true}
                        filterOptions={this.customFilter}
                        onSelectedChanged={(selected) => {
                          this.setState({ ccAdminSelected: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Created by Team</Form.Label>
                      <MultiSelect
                        options={this.state.assocTeam}
                        selected={this.state.assocTeamSelected}
                        disableSearch={false}
                        hasSelectAll={true}
                        filterOptions={this.customFilter}
                        onSelectedChanged={(selected) => {
                          this.setState({ assocTeamSelected: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>QMAssociate</Form.Label>
                      <MultiSelect
                        options={this.state.qmAssociate}
                        selected={this.state.qmAssociateSelected}
                        disableSearch={false}
                        hasSelectAll={true}
                        filterOptions={this.customFilter}
                        onSelectedChanged={(selected) => {
                          this.setState({ qmAssociateSelected: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Amgen QC By</Form.Label>
                      <MultiSelect
                        options={this.state.amgenQc}
                        selected={this.state.amgenQcSelected}
                        disableSearch={false}
                        hasSelectAll={true}
                        filterOptions={this.customFilter}
                        onSelectedChanged={(selected) => {
                          this.setState({ amgenQcSelected: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={2}>
                      <Form.Label>Country</Form.Label>
                      <MultiSelect
                        options={this.state.country}
                        selected={this.state.countrySelected}
                        disableSearch={false}
                        hasSelectAll={true}
                        filterOptions={this.customFilter}
                        onSelectedChanged={(selected) => {
                          this.setState({ countrySelected: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Form.Label>Start Date</Form.Label>
                      <Calendar
                        className="calender-styles wdt"
                        maxDate={today}
                        value={this.state.startDate}
                        onChange={(e) => {
                          this.setState(
                            {
                              startDate: e.value,
                              isExtractButtonDisable: false,
                            },
                            () => {
                              this.checkDateValidation(1);
                            }
                          );
                        }}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="2000:2100"
                        showIcon={true}
                        placeholder="mm/dd/yyyy"
                        minDate={startYear}
                      />
                    </Col>
                    <Col md={2}>
                      <Form.Label>End Date</Form.Label>
                      <Calendar
                        className="calender-styles wdt"
                        maxDate={today}
                        value={this.state.endDate}
                        onChange={(e) => {
                          this.setState(
                            { endDate: e.value, isExtractButtonDisable: false },
                            () => {
                              this.checkDateValidation(2);
                              // console.log('end', this.state.endDate + ' |||||' + e.value)
                            }
                          );
                        }}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="2000:2100"
                        showIcon={true}
                        placeholder="mm/dd/yyyy"
                        minDate={startYear}
                      />
                    </Col>
                    <Col>
                      <Button
                        variant="secondary"
                        type="submit"
                        className="scorecardSearch hgt button-color mgt-24 float-left"
                      >
                        Search
                      </Button>
                      <Button
                        variant="secondary"
                        className="clear hgt mgt-24 float-left"
                        onClick={this.handleOnClear}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Form.Row>

                  {/* <Col></Col> */}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Fragment>
    );
  }
}

ScorecardReportFilter.propTypes = {
  getScorecardReportFilter: PropTypes.func,
};

export default ScorecardReportFilter;
