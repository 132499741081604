import React, { Component } from "react";
import "./ViewDetails.css";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import utils from "./../../utils";
import EndpointConstant from "./../../constants/EndpointConstant";
//import { FileUploader } from '..';
import RoleConstant from "./../../constants/RoleConstant";
import MessageConstant from "./../../constants/MessageConstant";
import PropTypes from "prop-types";
import PopupModels from "./../PopupModels/PopupModels";
import ConfirmationModal from "./../PopupModels/ConfirmationModal";
import AgentNotesPopup from "./AgentNotesPopup";
import EditErrorDetails from "../ErrorList/EditErrorDetails";
import EditScoreCard from "../ScoreCard/EditScoreCard";
import RouterConstant from "../../constants/RouterConstant";
import Moment from "react-moment";
import MultiSelect from "@khanacademy/react-multi-select";
import moment from "moment";
// import { Header } from '../Header/Header';
// import {Header } from '..';

// function goBack(props) {
//     console.log(props);
//      props.history.push('/home');

// }
/**
 * A class that can return some html
 *@return {Object} .
 *@param {object} props
 */
class ViewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.interactiondetails[0],
      subcaseNumber: this.props.interactiondetails[0].subcaseNumber,
      reqScoreCardIdforEdit: this.props.reqScoreCardIdforEdit,
      AE_Cap_IRPC_Match: "",
      AE_Cap_IRPC_Match_QualityMonitorQC: "",
      AEID_Q2_AE_Missed: "",
      AEID_Q2_AE_Missed_QualityMonitorQC: "",
      AE_Q2_HCP: "",
      AE_Q2_HCP_QualityMonitorQC: "",
      AE_Q3_IPRC: "",
      AE_Q3_IPRC_QualityMonitorQC: "",
      PCID_Q2_Missed: "",
      PCID_Q2_Missed_QualityMonitorQC: "",
      AE_Cap_IRPC_Match_Amgen: "",
      AE_Cap_IRPC_Match_Error_Reconciliation: "",
      AEID_Q2_AE_Missed_Amgen: "",
      AEID_Q2_AE_Error_Reconciliation: "",
      AE_Q2_HCP_Amgen: "",
      AE_Q2_HCP_Error_Reconciliation: "",
      AE_Q3_IPRC_Amgen: "",
      AE_Q3_IPRC_Error_Reconciliation: "",
      PCID_Q2_Missed_Error_Reconciliation: "",
      PC_Q1_IPRC_Match_Amgen: "",
      PCID_Q2_Missed_Amgen: "",
      comments: "",
      qualityMonitorQCBy: "",
      QMAssociate: "",
      ScorecardName: "",
      show: false,
      team: "",
      addTeamModel: false,
      routeForReviewModel: false,
      routeToCallCenterModel: false,
      translateModel: false,
      createError: false,
      addScoreCard: false,
      caseType: this.props.interactiondetails[0].caseType,
      editscorecard: false,
      scorecardDetails: {},
      viewAttachment: this.props.interactiondetails[0].attachments,
      viewStatus: this.props.interactiondetails[0].status,
      assignTeam: this.props.interactiondetails[0].assignedTo,
      hide: true,
      showAddUserMsg: false,
      errorDetails: {},
      editError: false,
      agentNotesPopupModel: false,
      translateConfirmation: false,
      deleteConfirmation: false,
      deleteModel: false,
      adminConfirmation: false,
      routeConfirmation: false,
      translate: false,

      showDatasetNameInput: false,
      datasetName: [],
      datasetType: "",
      role: "",
      messageForAttachment: "",
      translationType: false,
      anChecked: false,
      attachmentChkd: false,
      selected: [],
      attachmentData: [],
      scorecardQuestionDetails: [],
      questionsets: [],
      scorecards: [],
      userDetails: this.props.userDetails,
      roleArr: {},
    };
    this.isFormValid = false;
    this.goBack = this.goBack.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.changeAssociatedTeam = this.changeAssociatedTeam.bind(this);
    this.handleAddTeamShow = this.handleAddTeamShow.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.showScorecardTable = this.showScorecardTable.bind(this);
    this.showDatasetNameInput = this.showDatasetNameInput.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleDatasetNameInputClose =
      this.handleDatasetNameInputClose.bind(this);
    this.performFileUpload = this.performFileUpload.bind(this);
    this.hideDatasetNameInput = this.hideDatasetNameInput.bind(this);

    this.onTranslate = this.onTranslate.bind(this);
    this.onRouteForReview = this.onRouteForReview.bind(this);
    this.showCreateError = this.showCreateError.bind(this);
    this.showAddScoreCard = this.showAddScoreCard.bind(this);
    this.showEditScoreCard = this.showEditScoreCard.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.showEditError = this.showEditError.bind(this);
    this.hideEditError = this.hideEditError.bind(this);
    this.handleAgentNotes = this.handleAgentNotes.bind(this);
    // this.showAgentHtmlText = this.showAgentHtmlText.bind(this);
    this.handleAdminConfirmation = this.handleAdminConfirmation.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleRouteConfirmation = this.handleRouteConfirmation.bind(this);
    this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.handleTranslationTypeClose =
      this.handleTranslationTypeClose.bind(this);
    this.onTranslationConfirm = this.onTranslationConfirm.bind(this);
    this.onSelectTypeChanged = this.onSelectTypeChanged.bind(this);
    this.viewScoreCardDetails = this.viewScoreCardDetails.bind(this);
    this.getName = this.getName.bind(this);
    this.getUserRole = this.getUserRole.bind(this);
    this.getCreatedName = this.getCreatedName.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.goBackToHome = this.goBackToHome.bind(this);
  }

  handleAgentNotes() {
    this.setState({
      agentNotesPopupModel: true,
    });
  }
  goBack(type) {
    this.props.hideViewDetails(type);
    this.props.setLinkInactive();
    //window.location.reload();
  }
  downloadDataset(name, filename) {
    const data = {
      attachmentName: name,
      filename: filename,
    };
    utils.makeRequest(
      this,
      EndpointConstant.DOWNLOAD_ATTACHMENT,
      data,
      function (result) {
        //console.log(JSON.stringify(result));

        window.open(result.url, "Download");
      }
    );
  }

  deleteAttachment(attachmentId) {
    const data = {
      id: attachmentId,
    };
    const { viewAttachment } = this.state;
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.DELETE_ATTACHMENT,
      data,
      function () {
        //console.log(result);
        // if(result.error===false)
        // {
        //   let attachments=result.attachmentList
        //   self.setState({viewAttachment:attachments});

        // }
        let attachments = viewAttachment.filter(
          (attachment) => attachment.id !== attachmentId
        );
        self.setState({
          viewAttachment: attachments,
          deleteConfirmation: false,
          deleteModel: true,
        });
      }
    );
  }

  handlesamplingClose() {
    this.setState({ agentNotesPopupModel: false });
  }

  changeAssociatedTeam(event) {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    if (value !== "") this.isFormValid = true;
    else this.isFormValid = false;
  }

  handleClose() {
    this.setState({
      show: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
      adminConfirmation: false,
    });
  }

  handleAdminConfirmation(id) {
    this.setState({ adminConfirmation: !this.state.adminConfirmation, id: id });
  }

  redirectToBioConnect() {
    window.location =
      "https://amgenbioconnect.lightning.force.com/lightning/r/Case/" +
      this.state.caseid +
      "/view";
  }

  handleAddTeamShow(event) {
    event.preventDefault();
    let self = this;
    let viewData = this.props.interactiondetails[0];
    let role = this.state.role;
    let data = {
      id: viewData.id,
    };
    if (role === "CallCenterAdmin") {
      data.type = "ADMIN_ASSIGN_TO_CALLCENTERADMIN";
      data.assignedTo = this.state.team;
    }
    if (role === "QualityMonitor") {
      data.type = "ADMIN_ASSIGN_TO_QUALITYMONITOR";
      data.assignedTo = this.state.role;
    }

    //console.log("viewData", data);
    utils.makeRequest(
      this,
      EndpointConstant.UPDATE_INTERACTION,
      data,
      function (result) {
        if (
          result.hasOwnProperty("errorCode") &&
          result.errorCode === "CCU_400"
        ) {
          self.setState({ showAddUserMsg: true });
          return;
        }
        //console.log("resultsss", result);
        if (result.error === false) {
          // self.refs.header.showAlertDialog(MessageConstant.USER_NOTIFICATION, popupTitle);
          self.setState(
            {
              show: false,
              addTeamModel: true,
              viewStatus: "Assigned",
              assignTeam: data.assignedTo,
            },
            () => {
              self.props.updateIntercations(
                viewData,
                self.state.team || self.state.role
              );
            }
          );
        }
      }
    );
  }

  handleAddTeamClose(event) {
    event.preventDefault();
    let viewData = { ...this.state.viewData };
    viewData.assignedTo = this.state.team;
    this.setState({
      addTeamModel: false,
      viewData,
    });
  }

  onDrop(result) {
    //console.log("onDrop", result);
    this.setState({ viewAttachment: result });
  }

  changeHandler(e, i) {
    e.preventDefault();
    let datasetName = this.state.datasetName;
    datasetName[i] = e.target.value;
    this.setState({ datasetName });
  }
  showDatasetNameInput(id, files, fileType) {
    let fileName = [];
    if (fileType) {
      fileName = files.map((data) => {
        return data.name.split(".")[0];
      });
      this.setState({
        datasetName: fileName,
        showDatasetNameInput: true,
        currentDatasetInput: "upload",
        files: files,
        fileType,
        id: id,
      });
    } else {
      this.setState({
        datasetName: files.split(".")[0],
        datasetType: files.split(".")[1],
        showDatasetNameInput: true,
        currentDatasetInput: "editName",
        datasetId: id,
      });
    }
  }

  handleDatasetNameInputClose() {
    let self = this;
    let fileUpload = true;
    // datasetName = this.state.datasetName;
    this.state.datasetName.forEach((emptyFile) => {
      if (emptyFile.length === 0 || emptyFile === undefined) {
        // self.refs.header.showAlertDialog("Please enter File name");
        alert("Please enter File name");
        fileUpload = false;
        return;
      }
    });

    let datasetNameExist = false;
    if (fileUpload) {
      this.state.viewAttachment.forEach((data) => {
        // datasetName.push(data.attachmentPath.split('.')[0]);
        const datasetName = data.attachmentName;
        self.state.datasetName.forEach((datasetDetails) => {
          if (
            datasetName.toString().toLowerCase() ===
            datasetDetails.toLowerCase()
          ) {
            datasetNameExist = true;
          }
        });
      });
    }
    if (datasetNameExist) {
      // self.refs.header.showAlertDialog("File name already exist!");
      alert("File name already exist!");
      return;
    }
    if (this.state.currentDatasetInput === "upload") {
      if (fileUpload) {
        this.performFileUpload(
          this.state.files,
          this.state.fileType,
          this.state.id
        );
      }
    } else {
      const data = {
        id: this.state.datasetId,
        name: this.state.datasetName + "." + this.state.datasetType,
      };
      this.setState({
        showDatasetNameInput: false,
      });
      utils.makeRequest(
        this,
        EndpointConstant.UPDATE_DATASET_NAME,
        data,
        function (result) {
          //console.log(JSON.stringify(result));
          self.onDrop(result.attachmentList);
          self.setState({
            successDialog: true,
            successMessageContent:
              "Your Dataset name has been updated successfully",
            datasetName: "",
          });
        }
      );
    }
  }

  performFileUpload(files, fileType, id) {
    let self = this;
    this.setState({
      showDatasetNameInput: false,
    });
    files.forEach((fileDetails, index) => {
      fileType.forEach((fileTypesData, i) => {
        if (i === index) {
          utils.uploadFiles(
            this,
            fileDetails,
            fileTypesData,
            id,
            function (result) {
              // console.log("viewdetailsattachment",result);
              self.onDrop(result.attachmentList);
              if (i === fileType.length - 1) {
                self.setState({
                  successDialog: true,
                  successMessageContent: "Your File is uploaded successfully",
                });
              }
            },
            this.state.datasetName[i]
          );
        }
      });
    });
  }

  hideDatasetNameInput() {
    this.setState({ showDatasetNameInput: false });
  }

  closeDialogBox() {
    this.setState({
      successDialog: false,
    });
  }

  onTranslationConfirm(event) {
    event.preventDefault();
    this.setState({
      translateConfirmation: false,
      translationType: true,
    });
  }

  onTranslate(event) {
    //console.log("on translate");
    event.preventDefault();
    let self = this;
    let viewData = this.props.interactiondetails[0];
    const { viewAttachment, attachmentData } = this.state;
    let attachedData = [];
    if (viewAttachment.length > 0) {
      viewAttachment.forEach((data) => {
        if (attachmentData.indexOf(data.id) > -1) {
          attachedData.push(data);
        }
      });
    }
    let data = {
      id: viewData.id,
      type: "QUALITYMONITOR_ASSIGN_TO_TRANSLATIONVENDOR",
      assignedTo: RoleConstant.TranslationVendor,
      isTranslationNeeded: true,
      translationList: {
        isAgentNotes: this.state.anChecked,
        attachments: attachedData,
      },
    };
    // console.log("viewData", data);
    utils.makeRequest(
      this,
      EndpointConstant.UPDATE_INTERACTION,
      data,
      function (result) {
        //console.log("resultsss", result);
        if (result.error === false) {
          self.setState(
            {
              translationType: false,
              translateModel: true,
              translateConfirmation: false,
              assignTeam: data.assignedTo,
            },
            () => {
              self.props.updateIntercations(viewData, self.state.assignTeam);
            }
          );
        }
      }
    );
  }

  handleConfirmation(id) {
    this.setState({
      translateConfirmation: !this.state.translateConfirmation,
      id: id,
    });
  }

  handleDeleteConfirmation(id) {
    this.setState({
      deleteConfirmation: !this.state.deleteConfirmation,
      id: id,
    });
  }

  handleTranslationTypeClose() {
    this.setState({
      translationType: !this.state.translationType,
      anChecked: false,
      attachmentChkd: false,
    });
  }

  handleRouteConfirmation(id) {
    this.setState({ routeConfirmation: !this.state.routeConfirmation, id: id });
  }

  handleDeleteClose() {
    this.setState({ errorDeleteSuccess: false });
  }

  handleTranslateClose(event) {
    event.preventDefault();
    /* let viewData = { ...this.state.viewData };
    viewData.assignedTo = this.state.team; */
    this.setState({
      translateModel: false,
    });
  }

  onSelectTypeChanged(event) {
    //event.preventDefault();
    const { name, checked } = event.target;
    // console.log("event",event.target.checked)
    this.setState(
      {
        [name]: checked,
      },
      () => {
        let data;
        // console.log("after update state",this.state.anChecked, this.state);
      }
    );
  }

  handleDeletionClose(event) {
    event.preventDefault();
    this.setState({
      deleteModel: false,
    });
  }

  onRouteForReview(event) {
    //console.log("on route for review");
    //CALLCENTERADMIN_ASSIGN_TO_QUALITYMONITOR
    //QUALITYMONITOR_ASSIGN_TO_TRANSLATIONVENDOR
    //TRANSLATIONVENDOR_ASSIGN_TO_QUALITYMONITOR
    event.preventDefault();
    let self = this;
    let viewData = this.props.interactiondetails[0];
    let data = {
      id: viewData.id,
      // type: utils.getSessionItem('role') === RoleConstant.CallCenterAdmin ? 'CALLCENTERADMIN_ASSIGN_TO_QUALITYMONITOR' : 'TRANSLATIONVENDOR_ASSIGN_TO_QUALITYMONITOR',
      // assignedTo: RoleConstant.QualityMonitor
    };
    if (utils.getSessionItem("role") === RoleConstant.CallCenterAdmin) {
      data.type = "CALLCENTERADMIN_ASSIGN_TO_QUALITYMONITOR";
      data.assignedTo = RoleConstant.QualityMonitor;
    }
    if (utils.getSessionItem("role") === RoleConstant.TranslationVendor) {
      data.type = "TRANSLATIONVENDOR_ASSIGN_TO_QUALITYMONITOR";
      data.assignedTo = RoleConstant.QualityMonitor;
    }
    if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
      data.type = "QUALITYMONITOR_ASSIGN_TO_CALLCENTERADMIN";
      data.assignedTo = RoleConstant.CallCenterAdmin;
    }
    // console.log("viewData", data);
    utils.makeRequest(
      this,
      EndpointConstant.UPDATE_INTERACTION,
      data,
      function (result) {
        //console.log("resultsss", result);
        if (result.error === false) {
          self.setState(
            {
              routeForReviewModel: true,
              routeConfirmation: false,
              hide: false,
              assignTeam: data.assignedTo,
            },
            () => {
              self.props.updateIntercations(viewData, self.state.assignTeam);
            }
          );
        }

        if (result.error === true && result.errorCode === "CCU_400") {
          self.setState({
            routeToCallCenterModel: true,
            routeConfirmation: false,
            messageForAttachment: MessageConstant.CCU_400,
          });
        } else if (
          result.error === true &&
          result.errorCode === "C_NO_ATTACHMENT_PERMISSION"
        ) {
          self.setState({
            routeToCallCenterModel: true,
            routeConfirmation: false,
            messageForAttachment: MessageConstant.NO_ATTACH_PERMISSION,
          });
        }
      }
    );
  }

  handleRouteReviewClose(event) {
    event.preventDefault();
    /* let viewData = { ...this.state.viewData };
    viewData.assignedTo = this.state.team; */
    this.setState({
      routeForReviewModel: false,
    });
  }

  handleCallCenterClose() {
    /* let viewData = { ...this.state.viewData };
    viewData.assignedTo = this.state.team; */
    this.setState({
      routeToCallCenterModel: false,
    });
  }

  hideEditError() {
    this.setState({ editError: false }, () => {
      this.props.setLinkInactive("error");
    });
  }
  showCreateError() {
    this.setState({ createError: !this.state.createError }, () => {
      if (this.state.createError) {
        this.props.setLinkActive("error");
      } else {
        this.props.setLinkInactive("error");
      }
    });
  }
  showAddScoreCard() {
    let data = {};
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARD_QUESTIONARIE_LIST,
      data,
      function (result) {
        utils.hideLoader();
        if (result.error === false) {
          self.loading = false;
          self.setState({
            scorecardQuestionDetails: result.Questionaries,
          });
        }
        self.setState({ addScoreCard: !self.state.addScoreCard }, () => {
          if (self.state.addScoreCard) {
            self.props.setLinkActive("scorecard");
          } else {
            self.props.setLinkInactive("scorecard");
          }
        });
      }
    );
  }
  showUserRegistration() {
    let addUserURL = utils.getURL(RouterConstant.USERS_LINK) + "?showAddUser";
    window.location.hash = addUserURL;
  }
  showDetails() {
    this.setState({ editscorecard: !this.state.editscorecard });
    this.props.setLinkInactive();
  }
  showEditScoreCard(scorecardId) {
    let data = {
      id: scorecardId,
    };
    let self = this;
    // this.setState({show:true})scorecardId
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARD_DETAILS,
      data,
      function (result) {
        const scorecardDetails = result.scorecardDetails[0];
        scorecardDetails.datasetName = self.state.datasetNameView;
        // self.loading = false;
        // self.showAddScoreCard();
        self.setState(
          { scorecardDetails: scorecardDetails, editscorecard: true },
          () => self.props.setLinkActive()
        );
      }
    );
    // this.setState({editscorecard:!this.state.editscorecard})
  }
  showEditError() {
    let self = this;
    let data = {
      id: this.state.errorId,
    };
    // this.setState({show:true})
    utils.makeRequest(
      this,
      EndpointConstant.ERROR_DETAILS,
      data,
      function (result) {
        const errors = result.errorsDetails[0];
        self.loading = false;
        self.setState({ errorDetails: errors }, () => {
          self.props.setLinkActive("error");
          self.setState({ editError: true });
        });
      }
    );
  }
  componentDidMount() {
    let viewData = this.props.interactiondetails[0];
    // console.log("viewData in 645",viewData);
    this.getScoreCardList();
    this.getUserRole();
    // if(viewData.agentNotes!==''){
    // var iframeAgentNotes = viewData.agentNotes === 'null' ? '' : viewData.agentNotes
    // document.querySelector('iframe')
    // .contentDocument.write(iframeAgentNotes);
    // var iframeStyle = document.getElementById('style-iframe');
    // iframeStyle.contentDocument.body.style.fontFamily = "Helvetica";
    // iframeStyle.contentDocument.body.style.fontSize = "15px";
    // iframeStyle.contentDocument.body.style.color= '#333333';
    // }
  }
  getScoreCardList() {
    let data = {
      datasetId: "All",
    };
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARDS_LISTS_FROM_DATASET,
      data,
      async function (result) {
        utils.showLoader();
        await fetch(result.scorecards, {
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          method: "GET",
          body: null,
        })
          .then((response) => response.json())
          .then((s3ResponseJson) => {
            const scorecards = s3ResponseJson;
            // console.log('scorecard 1',scorecards[0])
            self.loading = false;
            // console.log('scorecards',scorecards)
            self.setState({ scorecards: scorecards }, () =>
              self.setState({ showpagination: true })
            );
            utils.hideLoader();
          })
          .catch((error) => {
            let er;
            // console.error(error);
            // console.log("Testing 12345 Error");
          });
      }
    );
  }
  viewScoreCardDetails(id, datasetName) {
    //  debugger
    let self = this;
    let data = {
      id: id,
    };
    // this.setState({show:true})
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARD_DETAILS,
      data,
      function (result) {
        const scorecardDetails = result.scorecardDetails[0];
        scorecardDetails.datasetName = self.state.datasetNameView;
        // self.loading = false;
        // self.showAddScoreCard();
        // console.log("in 688:"+scorecardDetails);

        self.setState(
          {
            scorecardDetails: scorecardDetails,
            editscorecard: true,
            showScorecardModal: false,
          },
          () => self.props.setLinkActive()
        );
      }
    );
  }
  // getScoreCardQuestionarieList() {
  //   let data = {};
  //   let self = this;
  //   utils.makeRequest(this, EndpointConstant.SCORECARD_QUESTIONARIE_LIST, data, function (result) {
  //     if (result.error === false) {
  //       self.loading = false;
  //       // const questions = result.Questionaries.length === 0 ? self.state.defaultQuesionDetails : self.getQuestions(result.Questionaries);
  //       console.log(result.Questionaries,'result.Questionaries');
  //       self.setState({
  //         questionsets: result.Questionaries
  //       });
  //     }
  //   })
  // }
  add3Dots(string, limit) {
    var dots = "...";
    if (string && string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }
  showScorecardTable(hasOneScorecard, scorecard, reqScoreCardIdforEdit) {
    this.viewScoreCardDetails(reqScoreCardIdforEdit, scorecard.datasetName);
    /* if(hasOneScorecard==1){
      this.viewScoreCardDetails(scorecard.id,scorecard.datasetName);
    }else{
      this.setState({
        showScorecardModal: true
      })
    } */
  }
  handleClosePopup() {
    this.setState({ showScorecardModal: false });
  }
  goBackToHome(type) {
    this.setState({ addScoreCard: false });
    this.goBack(type);
  }
  getName(name) {
    let nameArr = name.includes(",") && name.split(",");
    let newName = "";

    if (Array.isArray(nameArr)) {
      nameArr = nameArr.forEach((ele, i) => {
        if (ele && ele.trim() != "") {
          newName =
            newName == "" ? `${ele.trim()}` : `${ele.trim()}, ${newName}`;
        }
      });
      return newName;
    } else {
      return name;
    }
  }
  getUserRole() {
    let roleArr = {};
    this.state.userDetails &&
      this.state.userDetails.map((item) => {
        roleArr = {
          ...roleArr,
          [item.id]: {
            role: item.role,
            firstName: item.firstName,
            lastName: item.lastName,
          },
        };
      });
    roleArr = {
      ...roleArr,
      [utils.getSessionItem("userId")]: {
        role: utils.getSessionItem("role"),
        firstName: utils.getSessionItem("firstName"),
        lastName: utils.getSessionItem("lastName"),
      },
    };

    this.setState({ roleArr });
  }
  getCreatedName(createdBy, name) {
    let { roleArr } = this.state;
    name = name.includes(",") ? name.split(",") : name;
    let newName =
      roleArr[createdBy] &&
      `${roleArr[createdBy].firstName} ${roleArr[createdBy].lastName}`;
    if (!Array.isArray(name) && newName == name) {
      return `${newName}(${roleArr[createdBy].role})`;
    } else {
      let stringValue = "";
      Array.isArray(name) &&
        name.forEach((item) => {
          if (newName == item) {
            stringValue = stringValue
              ? `${newName}(${roleArr[createdBy].role}), ${stringValue}`
              : `${newName}(${roleArr[createdBy].role})`;
          }
        });
      return stringValue;
    }
  }
  render() {
    const { role, showScorecardModal } = this.state;
    const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    //const currentUserId = utils.getSessionItem("userId");
    const isoLangs = siteInfo.isoLangs;
    //let predictedLanguageName = '';
    const teamOptions = Object.keys(
      siteInfo.CallCenterTeamWithUploadAttachPermission
    ).map((team, i) => {
      return (
        <option
          key={i}
          value={siteInfo.CallCenterTeamWithUploadAttachPermission[team]}
        >
          {siteInfo.CallCenterTeamWithUploadAttachPermission[team]}
        </option>
      );
    });
    const roleOptions = Object.keys(siteInfo.UserRoles)
      .sort()
      .map((role, i) => {
        return role === "CallCenterAdmin" || role === "QualityMonitor" ? (
          <option key={i} value={role}>
            {siteInfo.UserRoles[role]}
          </option>
        ) : (
          ""
        );
      });
    let fileNames;
    // if(this.state.fileType.length>0 && this.state.datasetName) {
    fileNames = this.state.datasetName.map((data, index) => {
      return (
        <div className="dataset-name-entry-content scrollFileNames" key={index}>
          <label>Files Name{index + 1}</label>
          <br />
          <input
            type="text"
            className="datasetname-input"
            name="datasetName"
            onChange={() => this.changeHandler(event, index)}
            value={data}
          ></input>
        </div>
      );
    });
    // }
    // console.log("filename",this.state.datasetName);
    //console.log("isoLang",siteInfo.isoLangs);
    // console.log("filename",this.props.interactiondetails);
    let isEmpty = Object.keys(this.props.interactiondetails).length;
    if (isEmpty !== 0) {
      const viewData = this.state;

      const { roleArr } = this.state;
      let assignedNameValue;
      let subcaseStatus;
      let ScorecardName;
      if (viewData.scorecardExist && this.props.reqScoreCardIdforEdit != null) {
        let userRoles = ["Reviewer", "Admin", "SuperAdmin"];
        let stringValue = "";
        let assignedKeys = Object.keys(viewData.assignedToScorecard);

        assignedKeys.forEach((assignedId) => {
          if (
            viewData.assignedToScorecard[assignedId].scid ==
            this.props.reqScoreCardIdforEdit
          ) {
            let idsArr = assignedId.split("*");
            if (idsArr[1] && userRoles.includes(idsArr[1])) {
              stringValue = stringValue
                ? `${stringValue}, ${idsArr[1]}`
                : `${idsArr[1]}`;
              subcaseStatus = subcaseStatus
                ? `${subcaseStatus}, ${viewData.assignedToScorecard[assignedId].status}`
                : viewData.assignedToScorecard[assignedId].status;
            } else if (
              idsArr[1] &&
              idsArr[1].length > 20 &&
              roleArr[idsArr[1]]
            ) {
              stringValue = stringValue
                ? `${stringValue}, ${roleArr[idsArr[1]].firstName} ${
                    roleArr[idsArr[1]].lastName
                  } (${roleArr[idsArr[1]].role})`
                : ` ${roleArr[idsArr[1]].firstName} ${
                    roleArr[idsArr[1]].lastName
                  } (${roleArr[idsArr[1]].role})`;

              subcaseStatus = subcaseStatus
                ? `${subcaseStatus}, ${viewData.assignedToScorecard[assignedId].status}`
                : viewData.assignedToScorecard[assignedId].status;
            }
            ScorecardName =
              viewData.assignedToScorecard[assignedId].ScorecardName;
          }
        });
        assignedNameValue = stringValue;
      } else {
        /* assignedNameValue = viewData.assignedToName ? `${viewData.assignedToName} (${siteInfo.AssignedTo[viewData.assignedTo]})` : viewData.assignedToAdminName ? `${viewData.assignedToAdminName}  (${siteInfo.AssignedTo[viewData.assignedTo] })` : `${siteInfo.AssignedTo[viewData.assignedTo] }`; */
        assignedNameValue = viewData.assignedToName
          ? `${viewData.assignedToName} (${viewData.assignedTo})`
          : viewData.assignedToAdminName
          ? `${viewData.assignedToAdminName}  (${viewData.assignedTo})`
          : `${viewData.assignedTo}`;
        subcaseStatus = viewData.status;

        ScorecardName = "-";
      }
      //console.log("getViewDetails", viewData);
      if (viewData.hasOwnProperty("predictedLanguage")) {
        if (isoLangs.hasOwnProperty(viewData.predictedLanguage)) {
        } else {
        }
      }
      const attachmentOptions = [];
      if (this.state.viewAttachment.length > 0) {
        this.state.viewAttachment.map((attachment) => {
          // console.log("attachmet", attachment)
          return attachmentOptions.push({
            id: attachment,
            label: attachment.attachmentName,
            value: attachment.id,
          });
        });
      }
      if (
        viewData.hasOwnProperty("isTranslationNeeded") &&
        viewData.hasOwnProperty("translationList")
      ) {
        if (viewData.isTranslationNeeded) {
          if (viewData.translationList.attachments.length > 0) {
          }
        }
      }

      let countScoreCardAssigned = 0;
      let scoreCardData = {};
      let scorecardstatus = null
      const scorecardList =
        this.state.scorecards.length > 0 &&
        this.state.scorecards.map((data, index) => {
          // condition when subcase is reassigned before/without scorecard is created/ for scorecards which are created before implementing adding multiple score cards.
          if (data.interactionId == viewData.id && viewData.scorecardExist) {
            if (this.state.reqScoreCardIdforEdit == null) {
              this.state.reqScoreCardIdforEdit = data.id;
            }
          }
          let hasScorecard =
            data.interactionId === viewData.id &&
            this.state.reqScoreCardIdforEdit === data.id &&
            (((data.stage === "otherAdmin" ||
              data.stage === "Admin" ||
              data.stage === "User") &&
              data.status === "Assigned" &&
              data.createdBy !== utils.getSessionItem("userId") &&
              (data.assignedToAdmin === utils.getSessionItem("userId") ||
                data.assignedToAdmin === utils.getSessionItem("role"))) ||
              (data.createdBy === utils.getSessionItem("userId") &&
                data.status === "Re-Assigned") ||
              (data.createdBy === utils.getSessionItem("userId") &&
                data.status === "Completed"));

          if (hasScorecard) {
            countScoreCardAssigned = countScoreCardAssigned + 1;
            scoreCardData = data;
            scorecardstatus = data.status;
          }
          return (
            hasScorecard && (
              <tr key={index}>
                <td>
                  {data.subcaseNumber && data.subcaseNumber.toUpperCase()}
                </td>
                {/* <td>{data.IFRType}</td> */}
                <td title={data.datasetName}>
                  {this.add3Dots(data.datasetName, 18)}
                </td>
                <td>{data.channel}</td>
                {/* for camel case letter */}
                <td>
                  {data.questionStatus.charAt(0).toUpperCase() +
                    data.questionStatus.slice(1)}
                </td>
                {/* <td>{data.attachmentIncluded}</td> */}
                <td>{data.QMAssociate}</td>
                {/* <td>{data.qualityMonitorQCBy}</td> */}
                {/* <td>{data.selectedForQCBy}</td> */}
                <td>{data.amgenQCBy ? data.amgenQCBy : "-"}</td>
                <td>
                  {" "}
                  <a
                    className="view-details"
                    onClick={() =>
                      this.viewScoreCardDetails(data.id, data.datasetName)
                    }
                  >
                    <i className="fa fa-eye"></i>&nbsp;View Details
                  </a>
                </td>
              </tr>
            )
          );
        });
      // console.log('scorecardList',scorecardList && scorecardList)
      // let status = viewData.status.includes(',') ? viewData.status.includes('Assigned') && viewData.stage === 'otherAdmin'
      //   ? true : false : viewData.status.includes('Assigned');
      const editButtonShow = countScoreCardAssigned > 0 ? true : false;
      const editButtonShowForCompletedScorecard =
        this.props.interactionDetails &&
        this.props.interactionDetails.scorecardId &&
        scorecardstatus === "Completed";
      //viewData.stage === 'otherAdmin' ? (viewData.scorecardExist  &&  viewData.stage !== 'Completed' &&  viewData.createdBy !== utils.getSessionItem('userId') &&  (utils.getSessionItem("role") === RoleConstant.SuperAdmin || utils.getSessionItem("role") === RoleConstant.SAdmin ) && (viewData.assignedToAdmin ===  utils.getSessionItem('userId') || viewData.assignedToAdmin === utils.getSessionItem("role"))) :  ((viewData.stage === utils.getSessionItem("role")||  utils.getSessionItem("role") ==='SuperAdmin') &&  viewData.stage !== 'Completed' &&  (viewData.createdBy !== utils.getSessionItem('userId') && viewData.status !== 'Re-Assigned'  && (viewData.assignedToAdmin ===  utils.getSessionItem('userId') || viewData.assignedToAdmin === utils.getSessionItem("role")))|| (viewData.createdBy === utils.getSessionItem('userId') && viewData.status === 'Re-Assigned'));
      // console.log("scorecardDetails",this.state.scorecardDetails)
      return (
        <div>
          {/* <Header ref={`header`} /> */}
          {/* <Header />
      <div className="sidebar">
      <Sidebar />
      </div> */}

          {/* {this.state.createError || this.state.addScoreCard || this.state.editError ? '' : <Row>
            <Col md={12}><h3 className="heading-interaction">Case Details</h3></Col>
          </Row>} */}
          <div className="content-layout interaction-details-screen">
            {this.state.addScoreCard ? (
              <EditScoreCard
                details={viewData}
                scoreCard="new"
                hideDetails={this.showAddScoreCard}
                questionaries={this.state.scorecardQuestionDetails}
                goBackToHome={this.goBackToHome}
              />
            ) : (
              <div>
                {this.state.createError ? (
                  <EditErrorDetails
                    errorDetails={viewData}
                    error="new"
                    showEditError={this.showCreateError}
                  />
                ) : (
                  <div>
                    {this.state.editscorecard ? (
                      <EditScoreCard
                        scoreCard="edit"
                        goBackToHome={this.goBackToHome}
                        interactionDetail={viewData}
                        details={this.state.scorecardDetails}
                        hideDetails={this.showDetails}
                        questionaries={this.state.scorecardDetails}
                      />
                    ) : (
                      <div>
                        {this.state.editError ? (
                          <EditErrorDetails
                            error="edit"
                            errorDetails={this.state.errorDetails}
                            datasetName={viewData}
                            hideEditError={this.hideEditError}
                          />
                        ) : (
                          <div>
                            <div className="details-layout">
                              <div className="details-row">
                                <Row>
                                  <Col
                                    md={{ span: 6, offset: 6 }}
                                    className="text-center"
                                  >
                                    <a
                                      className="bio-connect-link"
                                      href={
                                        "https://amgenbioconnect.lightning.force.com/lightning/r/Case/" +
                                        this.state.caseid +
                                        "/view"
                                      }
                                      target="_blank"
                                    >
                                      Open this case in Bioconnect
                                    </a>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={4}>
                                    <label>Master Case Number</label>
                                    <span>
                                      {viewData.interactionId.toUpperCase()}
                                    </span>
                                  </Col>
                                  {/*  */}
                                  <Col md={4}>
                                    <label> Country</label>
                                    <span>{viewData.interactionCountry}</span>
                                  </Col>
                                  <Col md={4}>
                                    <label>Created On</label>
                                    <span>
                                      {moment
                                        .utc(viewData.interactionCreatedOn)
                                        .format("MM/DD/YYYY")}
                                      {/* <Moment format="MM/DD/YYYY">
                                        {viewData.interactionCreatedOn}
                                      </Moment> */}
                                    </span>
                                  </Col>
                                  {/* <Col md={4} ><label>Contact Preferred</label><span>{viewData.contactPreferredLanguage}</span></Col>
                                   */}
                                </Row>
                                <Row>
                                  <Col md={4}>
                                    <label>Sub-Case Number</label>
                                    <span>
                                      {viewData.subcaseNumber.toUpperCase()}
                                    </span>
                                  </Col>
                                  <Col md={4}>
                                    <label>Modified On</label>
                                    <span>
                                      {moment
                                        .utc(viewData.interactionModifiedOn)
                                        .format("MM/DD/YYYY HH:mm A")}
                                      {/* <Moment format="MM/D/YYYY HH:mm A">
                                        {viewData.interactionModifiedOn}
                                      </Moment> */}
                                    </span>
                                  </Col>
                                  <Col md={4}>
                                    <label>Created By</label>
                                    <span>
                                      {viewData.interactionCreatedByName}
                                    </span>
                                  </Col>
                                  {/*  <Col md={4} ><label>Contact Country Default</label><span>{viewData.contactCountryDefaultLanguage}</span></Col>  */}
                                </Row>
                                {/*<Col md={4} ><label>Final Team</label><span>{viewData.finalTeam}</span></Col>*/}

                                {/*   <Col md={4} ><label>External Reference Id</label><span>{viewData.externalInteractionId}</span></Col> */}

                                <Row>
                                  {/* <Col md={4} ><label>Case Type</label><span>{viewData.caseType}</span></Col> */}
                                  <Col md={4}>
                                    <label>Status</label>
                                    <span className="status">
                                      {subcaseStatus}
                                    </span>
                                  </Col>
                                  <Col md={4}>
                                    <label>Case Owner</label>
                                    <span>{viewData.interactionOwner}</span>
                                  </Col>

                                  <Col md={4}>
                                    <label>Channel</label>
                                    <span>{viewData.channel}</span>
                                  </Col>
                                  {/*  */}
                                  {/* <Col md={4} className="col-row-2"><label>AEID</label><span>{viewData.AEID}</span></Col> */}
                                </Row>
                                <Row>
                                  {/* <Col md={4} ><label>Channel</label><span>{viewData.channel}</span></Col> */}
                                  {/*  */}
                                  {/* <Col md={4} className="col-row-2"><label>PCMRID</label><span>{viewData.PCMRID}</span></Col> */}
                                  <Col md={4}>
                                    <label>Assigned To</label>
                                    <span>{assignedNameValue}</span>
                                  </Col>
                                  <Col md={4}>
                                    <label>Created by Team</label>
                                    <span>{viewData.associatedTeam}</span>
                                  </Col>
                                  <Col md={4}>
                                    <label>Team Assignment</label>
                                    <span>{viewData.interactionOwnerTeam}</span>
                                  </Col>
                                  {/*   <Col md={4} ><label>Contact</label><span>{viewData.IRPCContactName}</span></Col> */}
                                </Row>
                                <Row>
                                  {/* <Col md={4} ><label>Contact</label><span>{viewData.IRPCContactName}</span></Col> 
                          <Col md={4} ><label>Study Protocol Number</label><span>{viewData.studyProtocolNumber}</span></Col> */}

                                  <Col md={4}>
                                    <label>Dataset</label>
                                    <span>{viewData.datasetNameView}</span>
                                  </Col>
                                  <Col md={4}>
                                    <label>Product</label>
                                    <span>{viewData.product}</span>
                                  </Col>
                                  <Col md={4}>
                                    <label>Scorecard Name</label>
                                    <span>{ScorecardName}</span>
                                  </Col>
                                </Row>

                                {/* <Col md={4} className="col-row-1"><label>Case Owner</label><span>{viewData.interactionOwner}</span></Col> */}
                                {/* <Col md={4} ><label>Dataset</label><span>{viewData.datasetNameView}</span></Col> */}
                                {/*<Col md={4} ><label>Language</label><span>{predictedLanguageName}</span> </Col>*/}
                                {/* <Col md={4} className="col-row-2"><label>REFID</label><span>{viewData.REFID}</span></Col> */}
                              </div>
                              <div className="layout-2">
                                {/* <Row>
                          <Col md={12} sm={12}><label className="mb-2 text-left">Agent Notes</label>
                          <Button className="expand-agentNotes"  onClick={() => this.handleAgentNotes()}><img className="expand-action" src="/Images/expand.png" alt="expand"/></Button>
                            <div className="agent-notes">                            
                           <iframe id="style-iframe" className="agentNotes-iframe"></iframe>
                            </div>
                          </Col>
                        </Row> */}

                                <Row>
                                  <Col md={12} sm={12}>
                                    <label className="mb-2 text-left">
                                      MIR Description
                                    </label>
                                    <div className="mir-desc">
                                      <span className="description">
                                        {viewData.MIRDescription}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-12 col-md-12">
                                {/* <span>
                        {(utils.getSessionItem("role") === RoleConstant.CallCenterAdmin || utils.getSessionItem("role") === RoleConstant.TranslationVendor) &&
                          this.state.hide && <button className="interactionSearch button-color btn btn-secondary mt-4 mb-4 pull-right" onClick={this.handleRouteConfirmation}>Route for Review</button>
                        }
                      </span> */}
                                {viewData.createdBy ===
                                  utils.getSessionItem("userId") && (
                                  <span>
                                    {/* {utils.getSessionItem("role") === RoleConstant.QualityMonitor && */}
                                    <div>
                                      <button
                                        className=" button-color mt-4 follow-up-back mr-2 p-2 btn btn-secondary pull-right"
                                        onClick={this.showAddScoreCard}
                                      >
                                        Add Score Card
                                      </button>
                                    </div>
                                  </span>
                                )}
                                {editButtonShow && (
                                  <button
                                    className=" button-color mt-4 follow-up-back mr-2 p-2 btn btn-secondary pull-right"
                                    onClick={() =>
                                      this.showScorecardTable(
                                        countScoreCardAssigned,
                                        scoreCardData,
                                        this.state.reqScoreCardIdforEdit
                                      )
                                    }
                                  >
                                    Edit Score Card
                                  </button>
                                )}
                                {editButtonShowForCompletedScorecard && (
                                  <button
                                    className=" button-color mt-4 follow-up-back mr-2 p-2 btn btn-secondary pull-right"
                                    onClick={() =>
                                      this.showScorecardTable(
                                        countScoreCardAssigned,
                                        scoreCardData,
                                        this.state.reqScoreCardIdforEdit
                                      )
                                    }
                                  >
                                    Edit Score Card
                                  </button>
                                )}
                                <button
                                  className="mt-4 clear follow-up-back mr-2 btn btn-secondary pull-right"
                                  onClick={this.goBack}
                                >
                                  <img
                                    src="Images/icon-arrow-left.png"
                                    className="back_btn"
                                    alt="Amgen"
                                  />
                                  Back
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          <ConfirmationModal
            showModal={this.state.adminConfirmation}
            handleCloseModal={this.handleAdminConfirmation.bind(this)}
            message={siteInfo.ConfirmationText.AdminToCallCenterTeam}
            navigate={this.handleShow}
          />

          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            className="model-content-assign"
          >
            <Modal.Header closeButton className="add-user-header">
              ASSIGN TO
            </Modal.Header>
            <Modal.Body>
              <Form.Row>
                <Form.Group controlId="team" className="team-form-group">
                  <Form.Label className="align-lable">Role</Form.Label>
                  <Form.Control
                    as="select"
                    name="role"
                    value={role}
                    onChange={(e) => {
                      this.setState({ role: e.target.value });
                    }}
                  >
                    {role === "QualityMonitor"
                      ? (this.isFormValid = true)
                      : this.state.team
                      ? (this.isFormValid = true)
                      : (this.isFormValid = false)}
                    <option value="">--- Select ---</option>
                    {roleOptions}
                  </Form.Control>
                </Form.Group>
                {role === "CallCenterAdmin" ? (
                  <Form.Group controlId="team" className="team-form-group">
                    <Form.Label className="align-lable">Team</Form.Label>
                    <Form.Control
                      as="select"
                      name="team"
                      value={this.state.team}
                      onChange={this.changeAssociatedTeam}
                    >
                      <option value="">--- Select Team ---</option>
                      {teamOptions}
                    </Form.Control>
                  </Form.Group>
                ) : (
                  ""
                )}
                {this.state.showAddUserMsg ? (
                  <Form.Group className="team-form-group">
                    <div className="no-team-available-msg">
                      <img src="Images/ico-info.png" alt="" />
                      &nbsp;&nbsp;No user associated to this team.
                      <span onClick={this.showUserRegistration.bind(this)}>
                        Click here
                      </span>{" "}
                      to add OKTA user.
                    </div>
                  </Form.Group>
                ) : null}
                <Form.Group controlId="btnaction" className="team-form-button">
                  <Button
                    variant="secondary"
                    type="submit"
                    className="text-btn-color"
                    disabled={!this.isFormValid}
                    onClick={this.handleAddTeamShow}
                  >
                    Assign
                  </Button>
                </Form.Group>
              </Form.Row>
            </Modal.Body>
            {/* <Registration adminRegister={true} getUser={this.getUsersList} closePopup={this.handleClose}/> */}
          </Modal>
          <PopupModels
            showPopup={this.state.addTeamModel}
            iconFlag="success"
            message={
              role === "CallCenterAdmin"
                ? MessageConstant.TEAM_ASSIGN_SUCCESS
                : MessageConstant.QM_ASSIGN_SUCCESS
            }
            handleClosePopup={this.handleAddTeamClose.bind(this)}
          />
          <PopupModels
            showPopup={this.state.routeToCallCenterModel}
            iconFlag="error"
            message={
              utils.getSessionItem("role") === RoleConstant.QualityMonitor
                ? this.state.messageForAttachment
                : this.state.messageForAttachment
            }
            handleClosePopup={this.handleCallCenterClose.bind(this)}
          />

          <Modal
            dialogClassName="deletionModal"
            show={this.state.successDialog}
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header className="confirmation-header"></Modal.Header>
            <Modal.Body className="confirmation-body">
              <Image
                src="/Images/ico-success.png"
                className="success-img"
              ></Image>
              <div className="success-styles">Success!</div>
              {this.state.successMessageContent}
            </Modal.Body>
            <Modal.Footer className="alert-confirmation-footer">
              <Button
                active
                onClick={this.closeDialogBox.bind(this)}
                className="user-deletion-cancel"
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.showDatasetNameInput}
            onHide={this.hideDatasetNameInput}
            className="dataset-list-screen-dialog"
          >
            <Modal.Header closeButton>
              <Modal.Title>Files</Modal.Title>
            </Modal.Header>

            <Modal.Body className="scrollFileNames">{fileNames}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={this.handleDatasetNameInputClose}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          <ConfirmationModal
            showModal={this.state.translateConfirmation}
            handleCloseModal={this.handleConfirmation.bind(this)}
            message={
              siteInfo.ConfirmationText.QualityMonitorToTranslationVendor
            }
            navigate={this.onTranslationConfirm}
          />

          <PopupModels
            showPopup={this.state.translateModel}
            iconFlag="success"
            message={MessageConstant.QUALITYMONITOR_ASSIGN_TO_TRANSLATIONVENDOR}
            handleClosePopup={this.handleTranslateClose.bind(this)}
          />

          <Modal
            show={this.state.translationType}
            onHide={this.handleTranslationTypeClose}
            className="model-content-translate"
          >
            <Modal.Header
              closeButton
              className="add-user-header translate-header"
            >
              Translation Type
            </Modal.Header>
            <Modal.Body>
              <Form.Row>
                <Form.Group className="team-form-group">
                  <strong>Select the type of translation:</strong>
                </Form.Group>
                <Form.Group className="team-form-group selectType">
                  <Form.Label>
                    <input
                      type="checkbox"
                      name="anChecked"
                      onChange={this.onSelectTypeChanged}
                      defaultChecked={this.state.anChecked}
                    />
                    <span>Agent Notes</span>
                  </Form.Label>
                  <Form.Label>
                    <input
                      type="checkbox"
                      name="attachmentChkd"
                      onChange={this.onSelectTypeChanged}
                      defaultChecked={this.state.attachmentChkd}
                      disabled={this.state.viewAttachment.length === 0}
                    />
                    <span>Attachments</span>
                  </Form.Label>
                </Form.Group>
                {this.state.attachmentChkd ? (
                  this.state.viewAttachment.length > 0 ? (
                    <Form.Group className="team-form-group col-md-11">
                      <Form.Label>Attachment List</Form.Label>
                      <MultiSelect
                        options={attachmentOptions}
                        selected={this.state.attachmentData}
                        disableSearch={true}
                        hasSelectAll={true}
                        onSelectedChanged={(selected) => {
                          // console.log("selected values", selected);
                          this.setState({ attachmentData: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Form.Group>
                  ) : (
                    <strong className="mb-2">No attachment Found</strong>
                  )
                ) : null}
                <Form.Group
                  controlId="btnaction"
                  className="team-form-button col-md-9"
                >
                  <Button
                    variant="secondary"
                    type="submit"
                    className="text-btn-color"
                    disabled={
                      (!this.state.anChecked && !this.state.attachmentChkd) ||
                      (this.state.attachmentChkd &&
                        this.state.viewAttachment.length === 0) ||
                      (this.state.attachmentChkd &&
                        this.state.attachmentData.length === 0)
                    }
                    onClick={this.onTranslate}
                  >
                    Translate
                  </Button>
                </Form.Group>
              </Form.Row>
            </Modal.Body>
            {/* <Registration adminRegister={true} getUser={this.getUsersList} closePopup={this.handleClose}/> */}
          </Modal>

          <ConfirmationModal
            showModal={this.state.deleteConfirmation}
            handleCloseModal={this.handleDeleteConfirmation.bind(this)}
            message={MessageConstant.DELETE_CONFIRMATION}
            navigate={() => this.deleteAttachment(this.state.id)}
          />

          <PopupModels
            showPopup={this.state.deleteModel}
            iconFlag="success"
            message={MessageConstant.ATTACHMENT_DELETED}
            handleClosePopup={this.handleDeletionClose.bind(this)}
          />

          <ConfirmationModal
            showModal={this.state.routeConfirmation}
            handleCloseModal={this.handleRouteConfirmation.bind(this)}
            message={
              utils.getSessionItem("role") === RoleConstant.QualityMonitor
                ? siteInfo.ConfirmationText.QualityMonitorToCallCenter
                : utils.getSessionItem("role") === RoleConstant.CallCenterAdmin
                ? siteInfo.ConfirmationText.CallCenterAdminToQualityMonitor
                : siteInfo.ConfirmationText.TranslationVendorToQualityMonitor
            }
            navigate={this.onRouteForReview}
          />

          <Modal
            dialogClassName="samplingPopupModal"
            show={showScorecardModal}
            onHide={this.handleClosePopup}
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header
              closeButton
              className="samplingConfirm-popup-header samplingDataset-header"
            >
              Scorecard
            </Modal.Header>

            <Modal.Body
              style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
              className="samplingDataset-confirm-popup-body"
            >
              <table>
                <thead>
                  <tr>
                    <th>Sub-Case Number</th>
                    <th>Dataset</th>
                    <th>Channel</th>
                    <th>Question Status</th>
                    <th>QM Associate</th>
                    <th>Amgen QC By</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>{scorecardList}</tbody>
              </table>
            </Modal.Body>
          </Modal>

          <PopupModels
            showPopup={this.state.routeForReviewModel}
            iconFlag="success"
            message={
              utils.getSessionItem("role") === RoleConstant.QualityMonitor
                ? MessageConstant.QUALITYMONITOR_ASSIGN_TO_CALLCENTERADMIN
                : MessageConstant.ASSIGN_TO_QUALITYMONITOR
            }
            handleClosePopup={this.handleRouteReviewClose.bind(this)}
          />

          {this.state.agentNotesPopupModel ? (
            <AgentNotesPopup
              showPopup={this.state.agentNotesPopupModel}
              viewData={viewData}
              handleClosePopup={this.handlesamplingClose.bind(this)}
            />
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <h5>Details not available</h5>
            <button
              className="col-md-1 offset-11 btn-goback mt-4 mb-4 pull-right"
              onClick={this.goBack}
            >
              <img
                src="Images/icon-arrow-left.png"
                className="back_btn"
                alt="Amgen"
              />
              Back
            </button>
          </div>
        </div>
      );
    }
  }
}
ViewDetails.propTypes = {
  interactiondetails: PropTypes.array,
  reqScoreCardIdforEdit: PropTypes.string,
  hideViewDetails: PropTypes.func,
  setLinkInactive: PropTypes.func,
  setLinkActive: PropTypes.func,
};
export default ViewDetails;
