
import React, { Component } from 'react';
import './ErrorList.css';
import utils from './../../utils';
import { Row, Col, Button, Modal, Form, Card } from 'react-bootstrap'
import EditErrorDetails from './EditErrorDetails';
import RoleConstant from "./../../constants/RoleConstant";
import PropTypes from 'prop-types';
import MessageConstant from './../../constants/MessageConstant';
import RouterConstant from './../../constants/RouterConstant';
import { withRouter } from "react-router-dom";
import EndpointConstant from './../../constants/EndpointConstant';
import PopupModels from '../PopupModels/PopupModels';
import Moment from 'react-moment';
import CommentHistory from './CommentHistory';

/**
* A class that can return some html
*@return {Object} .
*@param {object} props
*/
class ErrorListViewDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      followUp: false,
      editError: false,
      errorDetails: this.props.errorDetails,
      comments: this.props.errorDetails.comments,
      editComment: false,
      commentSuccess: false,
      latestComments:null,
      // commentHist:[{
      //   updatedBy:'ABC',
      //   updatedAt:'05/01/2020',
      //   comment:"Testing ABC"
      //     },
      //     {
      //         updatedBy:'DEF',
      //         updatedAt:'05/02/2020',
      //         comment:"Testing DEF"
      //     },
      //     {
      //         updatedBy:'GHI',
      //         updatedAt:'05/03/2020',
      //         comment:"Testing GHI"
      //     }
      // ],
      showComment: false
    }
    // console.log("comments",this.props.errorDetails.comments)
    this.goBack = this.goBack.bind(this);
    this.showFollowUp = this.showFollowUp.bind(this);
    this.showEditError = this.showEditError.bind(this);
    this.hideEditError = this.hideEditError.bind(this);
    this.editComment = this.editComment.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.showErrorDetails1 = this.showErrorDetails1.bind(this);
    this.showHistory = this.showHistory.bind(this);
    this.hideHistory = this.hideHistory.bind(this);
  }
  goBack() {
    if (this.props.error === 'created') {
      const path = this.props.location.pathname.slice(1);
      if (path === RouterConstant.HOME_LINK.slice(1)) {
        utils.gotoPage(RouterConstant.ERROR_LIST_LINK)
      } else {
        window.location.reload(false)
      }
    } else {
      window.location.reload(false)
    }
    // window.location.reload(false)
  }
  colorChangeStatus(status) {
    const styles = {
      Completed: '#0F9D58',
      Pending: '#EA4335',
      Processing: '#F4B400'
    }
    switch (status) {
      case ('2'):
        return { color: styles.Completed };
      case ('0'):
        return { color: styles.Pending };
      case ('1'):
        return { color: styles.Processing };
      default:
        return {};
    }
  }
  showFollowUp() {
    this.setState({ followUp: !this.state.followUp })
  }
  showEditError() {
    this.setState({ editError: !this.state.editError })
  }
  hideEditError(errors) {

    // utils.makeRequest(this, EndpointConstant.ERROR_DETAILS, data, function (result) {
    //   debugger
    //   const errors = result.errorsDetails[0];
    //   self.loading = false;
    this.setState({ errorDetails: errors, editError: false })
    // self.setState({ show: true })

    // }) 
  }
  handleCommentChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  updateAdminComment(){
    let self = this
    let data = {
      id: this.state.errorDetails.id,
      comments: this.state.latestComments
    }
    utils.makeRequest(this, EndpointConstant.UPDATE_ERROR, data, function (result) {
      if (result.error === false || result.error === "false") {
        self.loading = false;
        self.setState({ editComment: false, commentSuccess: true,id: result.errorId});
        let data={
          id:result.errorId
        }
        utils.makeRequest(this, EndpointConstant.ERROR_DETAILS, data, function (result) {
          const errors = result.errorsDetails[0];
          self.loading = false;
          self.setState({ errorDetails: errors });
          self.setState({ show: true, latestComments:null });
        });
      }
    });
  }
  updateComment() {
    // let self = this
   
    if(this.state.latestComments=== '' || this.state.latestComments=== null) {
      this.setState({ editComment: false });
      // this.setState({ editComment: false })
    }
    else {
      this.updateAdminComment();
    }
  }
  showErrorDetails1() {
    this.setState({ commentSuccess: false })
  }
  editComment() {
    this.setState({ editComment: true })
  }
  hideEditComment() {
    this.setState({ editComment: false, comments: this.props.errorDetails.comments })
  }
  doMailto() {
    let sMailto="mailto:"
    window.open(sMailto);
 }
 showHistory() {
    this.setState({showComment: true});
  }
  hideHistory() {
    this.setState({showComment:false});
  }
  render() {
    const { editError } = this.state
    let errorDetails = this.state.errorDetails;

  // console.log("errorlistviewdetails",errorDetails);
    const role = utils.getSessionItem('role');
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const followUpButton = <span className='a_followup'><a href="mailto:" className='followupLink mt-4 clear mr-2 rounded-lg follow-up-back'>Follow Up</a></span>
    const editErrorButton = (this.state.errorDetails.status !== '2') ? <Button type="submit" onClick={this.showEditError} className={role === RoleConstant.QualityMonitor ? 'mt-4 button-color follow-up-back' : 'mt-4 clear mr-2 follow-up-back'}>Edit Error</Button> : ''
    let commentHist = this.state.errorDetails.comments;
    // let commentHist = this.state.latestComments;
    // console.log("sort",commentHist);
    // const commentEditData = this.state.errorDetails.comments[0].comments;
    let commentEditData;
    if(Array.isArray(commentHist) && commentHist.length > 2) {
    commentHist.sort(function (a, b) {
      return b.modifiedAt - a.modifiedAt;
    });
    // console.log("sort",commentHist);
  }
    let commentHistory = '';
    if(Array.isArray(commentHist) && commentHist.length > 0){
      [commentHist[commentHist.length-1],commentHist[commentHist.length-2]]=[commentHist[commentHist.length-2],commentHist[commentHist.length-1]]
      commentHistory = commentHist.forEach(function (data, index) {
          // console.log(index, 'index');
          if (index < 3) {
            return (
              <Card className="mb-2 commentHistory">
                <div className="cmt">
                  <span className="updatedBy">Updated By: {data.modifiedBy}</span>
                  <span className="updatedAt">Updated At: <Moment unix format="MM/D/YYYY">{data['modifiedAt']}</Moment></span>
                </div>
                <Card.Body>{data.comments}</Card.Body>
              </Card>
            );
          }
        }); 
    } else if (commentHist.length > 0){
      commentEditData = commentHist;
      // console.log("commentEditData",commentEditData)
    }
    else {
        commentHistory = (
            <Card className="mb-2">
                <Card.Body>Comments Not Available</Card.Body>
            </Card>
        );
    }
    return (
      <span>
        {editError ? <EditErrorDetails showEditError={this.hideEditError} errorDetails={errorDetails} close={this.goBack} /> : <div className="content-layout">
          <div className='error-details-size'>Error Details</div>
          <div className="details-layout">
            <div className="details-row error-details-row">
              <Row>
                <Col md={4}><label>Error ID</label><span>{errorDetails.errorId.toUpperCase()}</span></Col>
                <Col md={4}><label>Created On</label><span><Moment unix format="MM/D/YYYY HH:mm A">{errorDetails.createdAt}</Moment></span></Col>
                <Col md={4}><label>Dataset</label><span>{errorDetails.datasetName}</span></Col>
              </Row>
              <Row>
              <Col md={4}><label>Associated Team</label><span>{errorDetails.associatedTeam}</span></Col>
                <Col md={4}><label>Sub-Case Number</label><span>{errorDetails.subcaseNumber.toUpperCase()}</span></Col>
                <Col md={4}><label>Modified On</label><span><Moment unix format="MM/D/YYYY HH:mm A">{errorDetails.modifiedAt}</Moment></span></Col>                
              </Row>
              <Row>
                <Col md={4}><label>Error Type</label><span>{siteinfo.ErrorTypes[errorDetails.errorType]}</span></Col>
                <Col md={4}><label>Country</label><span>{errorDetails.country}</span></Col>
                <Col md={4}><label>Created By</label><span>{errorDetails.createdBy}</span></Col>                
              </Row>
              <Row>
              <Col md={4}><label>Error Criticality</label><span>{siteinfo.ErrorCriticality[errorDetails.errorCriticality]}</span></Col>
                <Col md={4}><label>Owner</label><span>{errorDetails.owner}</span></Col>
                <Col md={4}><label>Modified By</label><span>{errorDetails.modifiedBy}</span></Col>                
              </Row>
              <Row>
                <Col md={4}><label>Status</label><span style={this.colorChangeStatus(errorDetails.status)}>{siteinfo.ErrorStatus[errorDetails.status]}</span></Col>
                <Col md={4}></Col>
                <Col md={4}></Col>
              </Row>
            </div>
            <div className='padding-left-right'>
              <Row>
                <Col md={12} sm={12}><label className="mb-2 text-left">Error Description</label>
                  <div className="mir-desc">
                    <span className='description'>{errorDetails.errorDescription}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12}><label className="mb-2 text-left">Comments
                {errorDetails.status !== '2' && role === RoleConstant.Admin &&
                    <img className="edit-case-owner" onClick={this.editComment} src="/Images/ico-edit.png" alt='Amgen' />}
                </label>
                
                 
              <div className='agent-notes'>{!Array.isArray(commentHist) && <span className='scroll'>{commentEditData}</span>}</div>
              {/* <div className='agent-notes'>{Array.isArray(commentHist) && this.state.comments.length==2 && <span className='scroll'>{this.state.comments[1]}</span>}</div> */}
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12}>
                  {commentHistory}
                  {Array.isArray(commentHist) && commentHist.length >=3 && <span onClick={this.showHistory} className="showCmt">Show Comment History</span>}
                </Col>
              </Row>
             
            </div>

          </div>
          <div className="row pull-right buttongroup"><div className="buttongroup col-xs-12 col-md-12 ">
            <Button variant="secondary" className="mt-4 clear follow-up-back mr-2" onClick={this.goBack}>
              <img src="Images/icon-arrow-left.png" className="btn-back" alt='Amgen' />Back</Button>
            {/* <Button type="submit" onClick={this.showFollowUp} className='mt-4 button-color follow-up-back'>Follow Up</Button> */}
            {role === RoleConstant.Admin && errorDetails.status !== '2' ?
              // <Button type="submit" className='mt-4 button-color follow-up-back'>
                <span className='a_followup'><a href="mailto:" className='followupLink mt-4 button-color follow-up-back'>Follow Up</a></span>
                // </Button> 
                : ''
                }
            {role === RoleConstant.CallCenterAdmin && errorDetails.status !== '2' ?
              <span>{editErrorButton}</span> : ''}
            {role === RoleConstant.QualityMonitor && errorDetails.status !== '2' ? <span>{followUpButton}{editErrorButton}</span> : ''}
          </div>
          </div>
        </div>}
        <PopupModels showPopup={this.state.commentSuccess} iconFlag="success" message={MessageConstant.COMMENT_EDIT_SUCCESS} handleClosePopup={this.showErrorDetails1} />
        <Modal
          dialogClassName="rejectionCommentModal"
          show={this.state.editComment}
          onHide={this.hideEditComment.bind(this)}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header closeButton className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Add Comment
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Form.Group controlId="rejectionComment">
              <Form.Label>Comment</Form.Label>
              {/* {Array.isArray(this.state.comments) && <Form.Control as='textarea' name='comments' value={this.state.comments.length===2?this.state.comments[1].comments:this.state.comments[0].comments} onChange={this.handleCommentChange} />} */}
              { <Form.Control as='textarea' name='latestComments' value={this.state.latestComments} onChange={this.handleCommentChange} />}
              {/* {!Array.isArray(this.state.comments) && <Form.Control as='textarea' name='comments' value={this.state.comments} onChange={this.handleCommentChange} />} */}
              {/* {this.state.errorMessage.length > 0 && 
                <div className="error"><span>{this.state.errorMessage}</span></div>} */}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.updateComment}>
              Ok
          </Button>
          </Modal.Footer>
        </Modal>
        <Modal
        size="lg"
        show={this.state.showComment}
        onHide={this.hideHistory}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Comment History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollcommentpopup">
          <CommentHistory commentHist={commentHist}/>
        </Modal.Body>
      </Modal>
      </span>
    )
  }
}
ErrorListViewDetails.propTypes = {
  hideViewDetails: PropTypes.func,
  errorDetails: PropTypes.JSON,
  error: PropTypes.string,
  location: PropTypes.object
}
export default withRouter(ErrorListViewDetails);

