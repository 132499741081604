import React, { Component } from 'react';
import './EditScorecardQuestionaries.css';
import { Sidebar, Header } from '..';
import EndpointConstant from './../../constants/EndpointConstant';
import MessageConstant from './../../constants/MessageConstant';
import utils from './../../utils';
import CommonService from '../../commonService/CommonService';
import PopupModels from '../PopupModels/PopupModels';
import { Accordion, Card, Form, Col } from 'react-bootstrap';
import ConfirmationModal from '../PopupModels/ConfirmationModal';
import PropTypes from 'prop-types';

class EditScorecardQuestionaries extends Component {
  constructor() {
    super();
    this.state = {
      scorecardQuestionDetails: [],
      defaultQuesionDetails: [{ id: 1, question: '', options: {}, type: 'Y/N', isEditable: false, isNew: true }],
      extractValidation: false,
      extractErrMsg: '',
      accordionCollapse: "0",
      textMaxLength: '500',
      questionariesSuccessModel: false,
      isEdit: false,
      isDelete: false,
      activeAccordion: 0,
      addClass: false,
      classId: '',
      isEditable: false,
      show: false,
      qid: '',
      questionDetail: '',
      showDeleteConfirmation: false,
      questionSetName: '',
      questionSetDetails: {},
      isNewSet: false,
      questionsetShow: false,
      isEditQuestionsetSuccess: false,
      isEditClick: false,
      isQuestionNew: false,
    }

    this.getScoreCardQuestionarieList = this.getScoreCardQuestionarieList.bind(this);
    this.addQuestionOptions = this.addQuestionOptions.bind(this);
    this.updateOptionsValue = this.updateOptionsValue.bind(this);
    this.handleDeleteOption = this.handleDeleteOption.bind(this);
    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    // this.handleEditQuestion = this.handleEditQuestion.bind(this);
    this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
    // this.handleSubmitQuestion = this.handleSubmitQuestion.bind(this);
    this.handleExtractValidationClose = this.handleExtractValidationClose.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
    this.saveId = this.saveId.bind(this);
    this.deletePopup = this.deletePopup.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleSubmitQuestionSet = this.handleSubmitQuestionSet.bind(this);
  }
  componentDidMount() {
    if (Object.keys(this.props.questionDetails).length) {
      this.setState({
        scorecardQuestionDetails: this.getQuestions(this.props.questionDetails.questionaries), questionSetName: this.props.questionDetails.questionsetName,
        questionSetDetails: this.props.questionDetails,
        questionsetShow: true
      });
    } else {
      this.setState({
        scorecardQuestionDetails: this.state.defaultQuesionDetails,
        isQuestionNew: true
      })
    }
  }

  getScoreCardQuestionarieList() {
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.SCORECARD_QUESTIONARIE_LIST, data, function (result) {
      utils.hideLoader();
      if (result.error === false) {
        self.loading = false;
        const questions = result.Questionaries.length === 0 ? self.state.defaultQuesionDetails : self.getQuestions(result.Questionaries);
        self.setState({
          scorecardQuestionDetails: questions,
        });
      }
    })
  }

  getQuestions(questionaries) {
    return questionaries.map((val, i) => {
      return ({
        id: val.id ? val.id : ++i,
        question: val.question,
        options: val.options ? val.options : {},
        type: val.type,
        weightage: val.weightage ? val.weightage : '',
        isEditable: true,
        isNew: false
      })
    })

  }

  updateQuestion(isQuestion, questionId, e) {
    const questions = this.state.scorecardQuestionDetails;
    if (isQuestion) {
      questions[questionId].question = e.target.value;
    } else {
      if (e.target.name === 'weightage') {
        questions[questionId].weightage = e.target.value;
      } else if (e.target.value === 'Text' || e.target.value === 'Y/N') {
        questions[questionId].type = e.target.value;
        questions[questionId].options = {};
      } else {
        questions[questionId].type = e.target.value;
        questions[questionId].options = {};
      }
    }
    this.setState({
      scorecardQuestionDetails: questions
    })
  }

  addQuestionOptions(QuestionRowId) {
    let options = this.state.scorecardQuestionDetails;
    // eslint-disable-next-line
    const optionsLength = Object.keys(options[QuestionRowId].options).length ? parseInt(Object.keys(options[QuestionRowId].options).pop()) : Object.keys(options[QuestionRowId].options).length;
    options[QuestionRowId].options = { ...options[QuestionRowId].options, [optionsLength + 1]: '' };
    this.setState({
      scorecardQuestionDetails: [...options]
    })
  }

  updateOptionsValue(e, questionIndex, optionIndex) {
    let optionDetails = this.state.scorecardQuestionDetails;
    optionDetails[questionIndex].options[optionIndex] = e.target.value;
    this.setState({
      scorecardQuestionDetails: optionDetails
    });
  }

  handleDeleteOption(questionRow, deleteOption) {
    const optionDetail = this.state.scorecardQuestionDetails;
    delete optionDetail[questionRow].options[deleteOption];
    this.setState({
      scorecardQuestionDetails: optionDetail
    });
  }

  handleAddQuestion(index) {
    let existingQuestions = this.state.scorecardQuestionDetails;
    const newQuestion = {
      id: existingQuestions.length + 1,
      isEditable: false,
      question: '',
      options: {},
      type: "Y/N",
      isNew: true,
    };
    existingQuestions.splice(++index, 0, newQuestion)
    // existingQuestions = [...existingQuestions, newQuestion];
    this.setState({
      scorecardQuestionDetails: existingQuestions
    })
    this.toggle(true)
  }
  deletePopup(qid, questionDetail) {
    this.setState({ qid: qid, questionDetail: questionDetail, showDeleteConfirmation: true })
  }
  hideDeletePopup() {
    this.setState({ qid: '', questionDetail: '', showDeleteConfirmation: false })
  }
  handleDeleteQuestion(qid, questionDetail) {
    const existingQuestions = this.state.scorecardQuestionDetails;
    if (!questionDetail.isNew) {
      // apicall
      let data = {};
      let self = this;
      let newQuestions = [];
      const requesData = this.state.scorecardQuestionDetails;
      // const question =  requesData.map(({ isEditable,isNew, ...item }) => item);
      const question = [];
      requesData.splice(qid, 1);
      requesData.forEach((val, i) => {
        if (val.isNew) {
          // if (i + 1 === qid + 1) {
          //   question.push({ options: val.options, question: val.question, type: val.type });
          // } else {
            newQuestions.push({id: i+1, question: val})
          // }
        } else {
          question.push({ id: val.id, options: val.options, question: val.question, type: val.type, weightage: val.weightage });
        }
      });
      
      data = { id: this.state.questionSetDetails.id, questionsetName: this.state.questionSetName, questionaries: question };
      utils.makeRequest(this, EndpointConstant.EDIT_SCORECARD_QUESTIONARIE, data, function (result) {
        if (result.error === false) {
          self.loading = false;
          const questionSetId = data.id ? data.id : result.Questionaries[0].id;
          const getCurrentQuestionSet = result.Questionaries.length ? result.Questionaries.filter(val => val.id === questionSetId) : [];
          const questions = getCurrentQuestionSet.length === 0 ? self.state.defaultQuesionDetails : self.getQuestions(getCurrentQuestionSet[0].questionaries);
          newQuestions.forEach(val => {
            questions.splice((val.id-1),0, val.question)
          })
          self.setState({
            showDeleteConfirmation: false,
            isDelete: true,
            questionariesSuccessModel: true,
            scorecardQuestionDetails: questions,
            // show: true,
            classId: 1,
            isNewSet: !Object.keys(self.props.questionDetails).length ? true : false,
            questionSetDetails: !Object.keys(self.props.questionDetails).length ? result.Questionaries[0] : self.props.questionDetails,
            isQuestionNew: false
          })
        }

      })
    } else {
      existingQuestions.splice(qid, 1);
      this.setState({
        scorecardQuestionDetails: existingQuestions,
        isDelete: true,
        showDeleteConfirmation: false,
        questionariesSuccessModel: true,
        // show: true,
      })
      this.toggle(true)
    }

  }

  handleEditQuestion() {
    const existingQuestion = [];
    this.state.scorecardQuestionDetails.forEach(val => {
      val.isEditable = false;
      existingQuestion.push(val);
    })
    // existingQuestion[qid].isEditable = false;
    this.setState({
      scorecardQuestionDetails: existingQuestion,
      isEditable: !this.state.isEditable,
      // classId: '',
      // show: false,
      // show: true,
      isEditClick: true,
      isQuestionNew: true
    })
    this.toggle(true)
  }

  handleExtractValidationClose() {
    this.setState({
      extractValidation: false,
      extractErrMsg: ''
    })
  }

  handleSubmitQuestion() {
    let data = {};
    let endpointName = '';
    let self = this;
    const question = [];
    let isError = false;
this.state.scorecardQuestionDetails.forEach((questionDetail, qid) => {
    if (this.checkValidation(qid, questionDetail)) {
      // question.push({ options: questionDetail.options, question: questionDetail.question, type: questionDetail.type });

      if (questionDetail.isNew) {
        question.push({ options: questionDetail.options, question: questionDetail.question, type: questionDetail.type, weightage: questionDetail.weightage });
      } else {
        question.push({ id: questionDetail.id, options: questionDetail.options, question: questionDetail.question, type: questionDetail.type, weightage: questionDetail.weightage });
      }

      // let data = {}
      // let endpointName = '';
      // let self = this;
      // let newQuestions = [];
      // const requesData = this.state.scorecardQuestionDetails;
      // const question = [];
      // requesData.forEach((val, i) => {
      //   if (val.isNew) {
      //     if (i + 1 === qid + 1) {
      //       question.push({ options: val.options, question: val.question, type: val.type });
      //     } else {
      //       newQuestions.push({id: i+1, question: val})
      //     }
      //   } else {
      //     question.push({ id: val.id, options: val.options, question: val.question, type: val.type });
      //   }
      // });
      // if (Object.keys(this.props.questionDetails).length) {
      //   //call Edit
      //   data = { id: this.state.questionSetDetails.id, questionsetName: this.state.questionSetName, questionaries: question };
      //   endpointName = EndpointConstant.EDIT_SCORECARD_QUESTIONARIE;
      // } else if (!this.state.isNewSet) {
      //   // call add
      //   data = { questionsetName: this.state.questionSetName, questionaries: question };
      //   endpointName = EndpointConstant.ADD_SCORECARD_QUESTIONARIE;
      // } else {
      //   data = { id: this.state.questionSetDetails.id, questionsetName: this.state.questionSetName, questionaries: question };
      //   endpointName = EndpointConstant.EDIT_SCORECARD_QUESTIONARIE;
      // }
    //   utils.makeRequest(this, endpointName, data, function (result) {
    //     if (result.error === false) {
    //       self.loading = false;
    //       const questionSetId = data.id ? data.id : result.Questionaries[0].id;
    //       const getCurrentQuestionSet = result.Questionaries.length ? result.Questionaries.filter(val => val.id === questionSetId) : [];
    //       const questions = getCurrentQuestionSet.length === 0 ? self.state.defaultQuesionDetails : self.getQuestions(getCurrentQuestionSet[0].questionaries);
    //       newQuestions.forEach(val => {
    //         questions.splice((val.id-1), 0, val.question)
    //       })

    //       self.setState({
    //         scorecardQuestionDetails: questions,
    //         questionSetName: getCurrentQuestionSet.length ? getCurrentQuestionSet[0].questionsetName : self.state.questionSetName,
    //         questionariesSuccessModel: true,
    //         show: true,
    //         classId: 1,
    //         isEdit: endpointName === EndpointConstant.ADD_SCORECARD_QUESTIONARIE ? true : false,
    //         isNewSet: !Object.keys(self.props.questionDetails).length ? true : false,
    //         questionSetDetails: !Object.keys(self.props.questionDetails).length ? result.Questionaries[0] : self.props.questionDetails,
    //         isDelete: false,
    //         questionsetShow: true
    //       })
    //     }
    //   })
    } else {
      isError = true
    }
  });
  if(!isError) {
    if (Object.keys(this.props.questionDetails).length) {
        //call Edit
        data = { id: this.state.questionSetDetails.id, questionsetName: this.state.questionSetName, questionaries: question };
        endpointName = EndpointConstant.EDIT_SCORECARD_QUESTIONARIE;
      } else if (!this.state.isNewSet) {
        // call add
        data = { questionsetName: this.state.questionSetName, questionaries: question };
        endpointName = EndpointConstant.ADD_SCORECARD_QUESTIONARIE;
      } else {
        data = { id: this.state.questionSetDetails.id, questionsetName: this.state.questionSetName, questionaries: question };
        endpointName = EndpointConstant.EDIT_SCORECARD_QUESTIONARIE;
      }
      utils.makeRequest(this, endpointName, data, function (result) {
        if (result.error === false) {
          self.loading = false;
          const questionSetId = data.id ? data.id : result.Questionaries[0].id;
          const getCurrentQuestionSet = result.Questionaries.length ? result.Questionaries.filter(val => val.id === questionSetId) : [];
          const questions = getCurrentQuestionSet.length === 0 ? self.state.defaultQuesionDetails : self.getQuestions(getCurrentQuestionSet[0].questionaries);
          self.setState({
            scorecardQuestionDetails: questions,
            questionSetName: getCurrentQuestionSet.length ? getCurrentQuestionSet[0].questionsetName : self.state.questionSetName,
            questionariesSuccessModel: true,
            // show: true,
            isEdit: endpointName === EndpointConstant.ADD_SCORECARD_QUESTIONARIE ? true : false,
            isNewSet: !Object.keys(self.props.questionDetails).length ? true : false,
            questionSetDetails: !Object.keys(self.props.questionDetails).length ? result.Questionaries[0] : self.props.questionDetails,
            isDelete: false,
            questionsetShow: true,
            isQuestionNew: false
          })
        }
      })
  }
  }

  checkValidation(qid, questionDetail) {
    if (questionDetail.type === 'SingleSelect' || questionDetail.type === 'MultiSelect') {
      let checkDuplicateOption = Object.values(questionDetail.options);
      if (checkDuplicateOption.some((val, index) => checkDuplicateOption.indexOf(val) !== index)) {
        this.setState({
          extractValidation: true,
          extractErrMsg: 'Options should not contain duplicate values'
        });
        return false;
      } else if (!Object.keys(questionDetail.options).length || !questionDetail.options[Object.keys(questionDetail.options).shift()] || checkDuplicateOption.some((val) => !val)) {
        this.setState({
          extractValidation: true,
          extractErrMsg: 'Options should not be empty'
        });
        return false;
      }
    }
   
    if (this.state.scorecardQuestionDetails.some((val) => val.question.length && questionDetail.question.length && questionDetail.question.toLowerCase() === val.question.toLowerCase() && val.id !== questionDetail.id)) {
      this.setState({
        extractValidation: true,
        extractErrMsg: 'Question name already exist. please enter unique question name'
      });
      return false;
    }
    if (!questionDetail.question.length) {
      this.setState({
        extractValidation: true,
        extractErrMsg: 'Question field should not be empty'
      });
      return false;
    }
    if(!questionDetail.weightage) {
      this.setState({
        extractValidation: true,
        extractErrMsg: 'Weightage field should not be empty'
      });
      return false;
    }
    return this.checkQuestionsetValidation();
  }

  checkQuestionsetValidation() {
    if (!this.state.questionSetName.length) {
      this.setState({
        extractValidation: true,
        extractErrMsg: 'Questionset name field should not be empty'
      });
      return false;
    }

    if (this.props.scorecardQuestionDetails.length && this.props.scorecardQuestionDetails.some(val => (val.questionsetName.toLowerCase() === this.state.questionSetName.toLowerCase()) && (val.id !== this.state.questionSetDetails.id))) {
      this.setState({
        extractValidation: true,
        extractErrMsg: 'Questionset name already exist.Please enter unique questionset name'
      });
      return false;
    }
    return true;
  }

  handlePopupClose() {
    this.setState({
      isEdit: false,
      questionariesSuccessModel: false,
      isEditable: !this.state.isEditable,
      isEditQuestionsetSuccess: false,
      classId: 1,
      // show: true
    })
    this.toggle(true)
  }
  toggle(show= false) {
    // this.setState({addClass: !this.state.addClass});
    let isShow = (typeof show === 'boolean' && show) ? show : this.state.show;
    if (this.state.classId !== '' && isShow) {
      const existingQuestion = this.state.scorecardQuestionDetails;
      // console.log(existingQuestion[this.state.classId].isEditable, 'existingQuestion[this.state.classId].isEditable')
      // console.log(existingQuestion, this.state);
      // if (this.state.classId !== '' && existingQuestion[this.state.classId - 1] && existingQuestion[this.state.classId - 1].isEditable) {
      if (this.state.classId !== '' && existingQuestion[this.state.classId - 1]) {
        var element = document.getElementById(this.state.classId);
        if (element && Object.values(element.classList).includes('show')) {
          element.classList.remove("show");
        }
      }
    }
  }

  saveId(e, isEditable) {
    // console.log('e', e)
    this.setState({ classId: e, isEditable: isEditable })
  }

  goBack() {
    // this.props.hideViewDetails();
    window.location.reload(false)
  }

  handleOnChangeInput(e) {
    e.preventDefault();
    this.setState({
      questionSetName: e.target.value
    })
  }

  handleSubmitQuestionSet(value='') {
    let data = {};
    const self =this;
    if(value === 'edit') {
      this.setState({questionsetShow: false})
    } else {
      const requesData = this.state.scorecardQuestionDetails;
      const question = [];
      if(!this.checkQuestionsetValidation()) {
        return;
      }
      requesData.length && requesData.forEach((val) => {
        if (!val.isNew) {
          question.push({ id: val.id, options: val.options, question: val.question, type: val.type, weightage: val.weightage  });
        }
      });
       //call Edit
       data = { id: this.state.questionSetDetails.id, questionsetName: this.state.questionSetName, questionaries: question };
       let endpointName = EndpointConstant.EDIT_SCORECARD_QUESTIONARIE;
       utils.makeRequest(this, endpointName, data, function (result) {
        if (result.error === false) {
          self.loading = false;
          const getCurrentQuestionSet = result.Questionaries.length ? result.Questionaries.filter(val => val.id ===  self.state.questionSetDetails.id) : [];
          self.setState({
            questionsetShow: true,
            questionSetName: getCurrentQuestionSet[0].questionsetName,
            questionariesSuccessModel: true,
            isEditQuestionsetSuccess: true,
          })
        }
      })
    }
  }

  add3Dots(string, limit) {
    string = string.filter(val => !!val).join(',')
    string = string.length ? string : 'Click here to add options'; 
    var dots = "...";
    if (string && string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }
    return string;
  }

  render() {
    let questionsetInfo;
    if (this.props.questionSetEditMode) {
      if ((Object.keys(this.props.questionDetails).length ||  this.state.scorecardQuestionDetails.length ) && this.state.questionsetShow) {
        questionsetInfo = <div className="col-sm-12">
          <label className="" title={this.state.questionSetName}>{this.state.questionSetName}</label><span className="pl-2" onClick={() => this.handleSubmitQuestionSet('edit')}><img src="/Images/ico-edit.png" alt='Amgen' className = 'pb-2'/>
        </span></div>
      } else if (Object.keys(this.props.questionDetails).length || this.state.isNewSet) {
        questionsetInfo = <div className="col-sm-12 row am-questions am-options">
          <Form.Control size="sm" type="text" name='questionSetName' value={this.state.questionSetName} onChange={this.handleOnChangeInput} disabled={this.state.questionsetShow} placeholder="Please enter name here" className="col-sm-3 am-question" />
          <span className="pl-2" onClick={() => this.handleSubmitQuestionSet()} > <img src="/Images/ico-approve.png" alt='Amgen' /></span>
        </div>
      } else {
        questionsetInfo =   <Form.Control size="sm" type="text" name='questionSetName' value={this.state.questionSetName} onChange={this.handleOnChangeInput} disabled={!this.props.questionSetEditMode} placeholder="Please enter name here" className="col-md-4 am-options" />
      }
    } else {
      questionsetInfo = <label>{this.state.questionSetName}</label>
    }

    return (
      <div>
        <Header />
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="menu-content" onClick={this.toggle.bind(this)}>
          <div className="row">
            <label className="question-style col-sm-12">Questionset</label>
            {questionsetInfo}
          </div>
          <label className="question-style" >Questionnaires</label>

          {
            this.state.scorecardQuestionDetails.map((questionRow, index) => {
              return (
                <div key={questionRow.id} className="row am-questions">
                  <Form.Control size="sm" as="textarea" disabled={questionRow.isEditable ? true : false} placeholder="Please enter the question here" name="question" defaultValue={questionRow.question} className="col-md-3 am-question" rows={2}  onChange={(e) => this.updateQuestion(true, index, e)} />
                  <Form.Control as='select' name='type' size="sm" placeholder="Choose question type" className="col-md-2 am-question" disabled={questionRow.isEditable ? true : false} value={questionRow.type} onChange={(e) => this.updateQuestion(false, index, e)}>
                    {CommonService.questionType.map((type) => <option key={type.id} value={type.value}>{type.name}</option>)}
                  </Form.Control>
                  {questionRow.type === "SingleSelect" || questionRow.type === "MultiSelect" ?
                    <Accordion defaultActiveKey={this.state.activeAccordion} className="col-md-3 am-padding" onClick={() => this.saveId(index + 1, questionRow.isEditable)}>
                      <Card className='radius accordion-height' >
                        <Accordion.Toggle as={Card.Header} eventKey="0" className='noColor' >
                      {Object.keys(questionRow.options).length ? this.add3Dots(Object.values(questionRow.options), 30) : 'Click here to add options'} <i className= "fas fa-chevron-down downIcon"></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" id={index + 1} className={'am-option-accordion'} >
                          <Card.Body className='cardBodyStyle noColor' >
                            <Form className="optionsForm">
                              {questionRow.isEditable ? '' : <Form.Row className='mb-2'>
                                <Col md={{ span: 3, offset: 9 }} className="text-center">
                                  <a onClick={() => this.addQuestionOptions(index)}><i className="fa fa-plus" ></i>&nbsp; Add</a>
                                </Col>
                              </Form.Row>
                              }
                              {
                                Object.keys(questionRow.options).map((k) => {

                                  return (<Form.Row key={k} className="am-options">
                                    <Col md={10}>
                                      <Form.Control type="text" value={questionRow.options[k]} disabled={questionRow.isEditable ? true : false} onChange={(e) => this.updateOptionsValue(e, index, k)} maxLength={this.state.textMaxLength}></Form.Control>
                                    </Col>
                                    {questionRow.isEditable ? '' :
                                      <Col md={2}><span className='delete-style am-questions' onClick={() => this.handleDeleteOption(index, k)} >
                                        <img src="/Images/ico-delete.png" alt="delete-icon" className='image-padding' /></span>
                                      </Col>
                                    }
                                  </Form.Row>);
                                })
                              }
                            </Form>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                    </Accordion>
                    :
                    questionRow.type === 'Y/N' ?
                      <Form.Control as="select" className="col-md-3 am-question" size="sm">
                        {CommonService.yesNoOption.map((type) => <option key={type.id} value={type.value}>{type.name}</option>)}
                      </Form.Control>
                      :
                      <Form.Control type="text" readOnly className="col-md-3 am-question" size="sm"></Form.Control>
                  }

                  <Form.Control as='select' name='weightage' size="sm" placeholder="Choose weightage type" className="col-md-2 am-question" disabled={questionRow.isEditable ? true : false} value={questionRow.weightage ? questionRow.weightage : ''} onChange={(e) => this.updateQuestion(false, index, e)}>
                    {CommonService.weightage.map((val) => <option key={val.id} value={val.value}disabled={val.value === ''} hidden={val.value === ''}>{val.name}</option>)}
                  </Form.Control>
                  {/* { !this.props.questionSetEditMode ? ''

                    : this.state.isEditClick ? <div className="noPadding text-center icon-color"> */}
                      {/* <span className='am-question' onClick={() => this.handleSubmitQuestion(index, questionRow)} > */}
                         {/* <img src="/Images/ico-approve.png" className="add-btn-icon" alt='Amgen' /> */}
                      {/* <span className='am-question' onClick={() => this.handleAddQuestion(index)} >
                         <i className="fa fa-lg fa-plus-circle" aria-hidden="true"></i>
                         </span>
                      { this.state.scorecardQuestionDetails.length > 1 ? <span className='delete-style am-question' onClick={() => this.deletePopup(index, questionRow)} > <img src="/Images/ico-delete.png" className="add-btn-icon" alt='Amgen' /></span> : '' }
                    </div>
                      : */}
                     { (this.state.isQuestionNew ? <div className="noPadding text-center icon-color">
                        <span className='am-question' onClick={() => this.handleAddQuestion(index)} > 
                        <i className="fa fa-lg fa-plus-circle" aria-hidden="true"></i>
                        {/* <img src="/Images/ico-edit.png" className="add-btn-icon" alt='Amgen' /> */}
                        </span>
                        { this.state.scorecardQuestionDetails.length > 1 ? <span className='delete-style am-question' onClick={() => this.deletePopup(index, questionRow)} > <img src="/Images/ico-delete.png" className="add-btn-icon" alt='Amgen' /></span> : '' }
                      </div> : ' ')
                  }
                </div>
              )
            })
          }

          <div className="row">
            <div className="col-md-4 offset-md-8 text-center">
              <div className="row"><button className="mt-4 clear follow-up-back mr-2 btn btn-secondary" onClick={this.goBack}>
                <img src="Images/icon-arrow-left.png" className="back_btn" alt='Amgen' />Back</button>
              

              {/* {this.props.questionSetEditMode ? <button className="mt-4 p-2 button-color btn-extract col-md-4" onClick={this.handleAddQuestion}><span>Add Question</span></button>
                : ''} */}
                 {this.props.questionSetEditMode ? ((this.state.isQuestionNew) ? <button className="mt-4 p-2 button-color btn-extract col-md-4" onClick={this.handleSubmitQuestion.bind(this)}><span>Save</span></button>
                : <button className="mt-4 p-2 button-color btn-extract col-md-4" onClick={this.handleEditQuestion.bind(this)}><span>Edit</span></button> ): ''}
                </div>
            </div>
          </div>
          <PopupModels showPopup={this.state.questionariesSuccessModel} iconFlag="success" message={this.state.isEditQuestionsetSuccess? MessageConstant.EDIT_QUESTIONSET : this.state.isEdit ? MessageConstant.ADD_QUESTIONARIES : (this.state.isDelete ? MessageConstant.DELETE_QUESTIONARIES : MessageConstant.EDIT_QUESTIONARIES)} handleClosePopup={this.handlePopupClose} />
          <PopupModels showPopup={this.state.extractValidation} iconFlag="error" message={this.state.extractErrMsg} handleClosePopup={this.handleExtractValidationClose.bind(this)} />
          <ConfirmationModal showModal={this.state.showDeleteConfirmation}
            handleCloseModal={this.hideDeletePopup.bind(this)}
            message={MessageConstant.QUESTIONARIE_DELETE_CONFIRMATION}
            navigate={() => { this.handleDeleteQuestion(this.state.qid, this.state.questionDetail) }} />
        </div>
      </div>
    )
  }
}

EditScorecardQuestionaries.propTypes = {
  questionDetails: PropTypes.object,
  hideViewDetails: PropTypes.func,
  questionSetEditMode: PropTypes.bool,
  scorecardQuestionDetails: PropTypes.array
}

export default EditScorecardQuestionaries;