import React, { Component } from 'react';
import './SamplingDataset.css';
import PropTypes from 'prop-types';
import {Modal } from 'react-bootstrap';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
class SamplingDataset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            samplingData:[],
            samplingDatasetId:[],
            showSampleSize: true,
            showPopup: false
        }
        this.handleClosePopup = this.handleClosePopup.bind(this);
    }
    componentDidMount() {
        utils.getSessionItem("role") !== 'Reviewer' && this.getSamplingDataset();
      }
      
    getSamplingDataset(){
    let data = {};
    let self = this;
    //console.log('samplingDataset',this.props.myProp);
    let datasetId = this.props.myProp;
    data.id = this.props.myProp;
    utils.showLoader()
    utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
        // console.log("samplingData",result);
        if (result.error === false) {
            let samplingData = result.datasets;
            // console.log("samplingData",samplingData);
           
            let sampleTableData = [];
            samplingData.forEach(async (sample)=>{
                if(datasetId===sample.id) {
                    // console.log("samplingData",sample.sampleTable);
                    if(sample.hasOwnProperty('extractCount')) {
                        self.setState({showSampleSize: false})
                    }
                    if(sample.sampleTable.url){
                        await fetch(sample.sampleTable.url, {
                            mode: "cors",
                            headers: {
                              "Access-Control-Allow-Origin": "*",
                            },
                            method: "GET",
                            body: null,
                          })
                            .then((response) => response.json())
                            .then((result) => {
                              if(result) {
                                result.sample.length && result.sample.forEach((data)=>{
                                    sampleTableData.push(data);
                                })
                              }
                              return sampleTableData;
                            }).then(()=>{
                                utils.hideLoader();
                                self.setState({ samplingData: sampleTableData, showPopup: true })
                            })
                            .catch((error) => {
                                utils.hideLoader();
                              console.error(error);
                            });
                    } 
                }                           
            });
            
                   
        }
        });
    }
    add3Dots(string, limit) {

        var dots = "...";
        if (string && string.length > limit) {
          // you can also use substr instead of substring
          string = string.substring(0, limit) + dots;
        }   
        return string;
      }
    
      handleClosePopup() {
          this.setState({showPopup: false})
        this.props.handleClosePopup();
      }
    render(){
        let sampleTableData = this.state.samplingData;
        // this.state.samplingData.length>0 && this.state.samplingData.forEach((data)=>{
        //     // console.log("data",data);
        //     // data.team=Array.from(new Set(data.team));
        //     sampleTableData.push(data);
        // })
        const headingArr= [];
        const heading =[];
        const details = this.state.samplingData.length>0 && sampleTableData.map((result,index)=>{
            if (result.team && !heading.includes('Team')) {
                heading.push('Team');
                headingArr.push(<th>{'Team'}</th>);
            }
            if (result.ccuser && !heading.includes('Call Center User')) {
                heading.push('Call Center User');
                headingArr.push(<th>{'Call Center User'}</th>);
            }
            if (result.caseNumber && !heading.includes('Case Number')) {
                heading.push('Case Number');
                headingArr.push(<th>{'Case Number'}</th>);
            }
            if (result.channel && !heading.includes('Channel')) {
                heading.push('Channel');
                headingArr.push(<th>{'Channel'}</th>);
            }
            if (result.country && !heading.includes('Country')) {
                heading.push('Country');
                headingArr.push(<th>{'Country'}</th>);
            }
            if (result.createdByName && !heading.includes('Created By')) {
                heading.push('Created By');
                headingArr.push(<th>{'Created By'}</th>);
            }
                return (
                    <tr key={index}>
                       {result.team && <td>{result.team}</td>}
                       {result.ccuser && <td>{result.ccuser}</td>}
                       {result.caseNumber && <td>{result.caseNumber}</td>}
                       {result.channel && <td>{result.channel}</td>}
                       {result.country && <td>{result.country}</td>}
                       {result.createdByName && <td>{result.createdByName}</td>}
                        <td >{result.totalCount}</td>
                        {this.state.showSampleSize && <td>{result.sampleSize}</td> }
                        <td>{result.sampleCount}</td>
                    </tr>
                )
            });
            
            // console.log("details",details);
        return (
           
            <React.Fragment>
                <Modal
                    dialogClassName="samplingPopupModal"
                    show={this.state.showPopup}
                    onHide={this.handleClosePopup}
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton className='samplingConfirm-popup-header samplingDataset-header'>
                    Sampling
                    </Modal.Header>

                    <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}} className='samplingDataset-confirm-popup-body'>
                     <table>
                         <thead>
                                <tr>
                                    {headingArr}
                                    <th>Population Size</th>
                                    {this.state.showSampleSize && <th>Sample Size (%)</th> }
                                    <th>Sampled Cases</th>
                                </tr>
                            </thead>
                        
                        <tbody>
                            {details}
                        
                        </tbody>
                    </table>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
SamplingDataset.propTypes = {
    handleClosePopup: PropTypes.func,
    showPopup:PropTypes.bool,
    myProp: PropTypes.string
  
  }
export default SamplingDataset;