import React, { Component } from 'react';
import './UserSearch.css';
import { Accordion, Card, Button, Form, Col, Image } from 'react-bootstrap'
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import PropTypes from 'prop-types';
import MultiSelect from "@khanacademy/react-multi-select";
/**
* A class that can return some html
*@return {Object} .
*@param {object} props
*/
// function UserSearch(props) {
class UserSearch extends Component {
  constructor(props) {
    super(props);
    //  this.users = [];
    this.state = {
      name: '',
      email: '',
      role: '',
      status: '',
      searchResult: [],
      iconName: 'icon-drop-up'
    }
    this.iconChange = this.iconChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clear = this.clear.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    // console.log(name)
    // console.log(props.userData)
    // filterUsers(name)
    // console.log(searchresult)
    let self = this
    let data = {}
    if (this.state.name !== '') {
      data.name = this.state.name
    }
    if (this.state.email !== '') {
      data.email = this.state.email
    }
    if (this.state.status.length!==0) {
      data.status = this.state.status
    }
    if (this.state.role.length!==0) {
      data.role = this.state.role
    }
    // console.log("user search",data);
    utils.makeRequest(this, EndpointConstant.FILTER_USERS, data, function (result) {
      // const status = result;
      // console.log(status,'status')

      self.setState({ searchResult: result })
      // self.setState({showDeleteConfirmation:false,deleted:true})
      self.props.searchData(result.users);


    })
  }
  clear() {
    // e.preventDefault();
    this.setState({ name: '', email: '', role: '', status: '' })
  }

  iconChange() {
    if (this.state.iconName === 'icon-drop-up') {
      this.setState({ iconName: 'icon-drop-down ' })
    } else {
      this.setState({ iconName: 'icon-drop-up' })
    }
  }
  customFilter = (options, filter) => {
    const optionIncludesText = (option) => {
        const label = option.label.replace(/ /g, "") || "";
        const searchData = filter.toLowerCase().replace(/ /g, "") || "";
        return label.toLowerCase().startsWith(searchData);
    };
    return options.filter(optionIncludesText);
  };

  render() {
    const {name, email, status}=this.state
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    let statusNewOption = siteinfo.UserStatus;
    delete statusNewOption[3];
    delete statusNewOption[4];
   
    // const statusOptions = Object.keys(statusNewOption).sort().map((status, i) => {
    //   return (
    //     <option key={i} value={status}>{siteinfo.UserStatus[status]}</option>
    //   )
    // })
    // console.log("statusNewOption", status);
    let statusOptions = [];
    Object.keys(statusNewOption).sort().map((status, i) => {
      return statusOptions.push({ id: status, label: siteinfo.UserStatus[status], value: status });
      });
    // const rolesOtions = Object.keys(siteinfo.UserRoles).sort().map((role, i) => {
    //   return (
    //     <option key={i} value={role}>{siteinfo.UserRoles[role]}</option>
    //   );
    // });
    let rolesOtions = [];
    Object.keys(siteinfo.UserRoles).sort().map((role, i) => {
      return rolesOtions.push({ id: role, label: role, value: role });
      });
    return (
      <Accordion defaultActiveKey="0" className='cardPadding'>
        <Card >
          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle search-header'>
            SEARCH USER
       <Image src="/Images/ico-drop-up.png" className={this.state.iconName}></Image>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='cardBodyStyle'>
              <Form onSubmit={this.handleSubmit}>
                {/* <Form> */}
                <Form.Row>
                  <Form.Group as={Col} md="2" controlId="name">
                    <Form.Label className='lable-names'>Name</Form.Label>
                    <Form.Control type='text' name='name' value={name} onChange={e => { this.setState({ name: e.target.value }) }} />
                  </Form.Group>

                  <Form.Group as={Col} md="2" controlId="email">
                    <Form.Label className='lable-names'>Email</Form.Label>
                    <Form.Control type='email' name='email' value={email} onChange={e => { this.setState({ email: e.target.value }) }} />
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="role">
                    <Form.Label className='lable-names'>Role</Form.Label>
                    {/* <Form.Control as='select' name='role' value={role} onChange={e => { this.setState({ role: e.target.value }) }}>
                      <option value=''>--Select--</option>
                      {rolesOtions}
                    </Form.Control> */}
                    <MultiSelect
                      options={rolesOtions}
                      selected={this.state.role}
                      disableSearch={false}
                      hasSelectAll={true}
                      filterOptions={this.customFilter}
                      onSelectedChanged={selected => {
                        // console.log("selected values", selected);
                        this.setState({ role: selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="2" controlId="status">
                    <Form.Label className='lable-names'>Status</Form.Label>
                    {/* <Form.Control as='select' name='status' value={status} onChange={e => { this.setState({ status: e.target.value }) }}>
                      <option value="">--Select--</option>
                      {statusOptions}
                    </Form.Control> */}
                    <MultiSelect
                      options={statusOptions}
                      selected={this.state.status}
                      disableSearch={false}
                      hasSelectAll={true}
                      filterOptions={this.customFilter}
                      onSelectedChanged={selected => {
                        // console.log("selected values", selected);
                        this.setState({ status: selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="1.5" controlId="status">
                    {/* <Form.Label>Status</Form.Label> */}
                    <Button type="submit" className='userSearch button-color'>
                      Search
  </Button>

                  </Form.Group>
                  <Form.Group as={Col} md="1" controlId="status">
                    {/* <Form.Label>Status</Form.Label> */}
                    <Button type="submit" className='userSearch clear' onClick={this.clear}>  Clear
  </Button>

                  </Form.Group>
                </Form.Row>
              </Form></Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
  }
}
UserSearch.propTypes = {
  searchData: PropTypes.func

}
export default UserSearch;

