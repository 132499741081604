import React, { Component } from 'react';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';

class DownloadAttachment extends Component {
  constructor(props) {
    super(props);
    // console.log("download attachment",props.match);
    this.state = {
        downloadMsg: ''
     };
    this.handleClose = this.handleClose.bind(this);
  }
 componentDidMount() {
    const path = this.props.match.url.split('/')[2];
    // console.log('path',path);
    if(utils.hasSessionItem('userId')){
        // console.log("has session");
        utils.removeSessionItem("currentUrl");
        this.downloadUrl(path);
    }
    else{
        // console.log("no session redirect me");
        utils.setSessionItem("currentUrl", this.props.match.url);
        utils.gotoPage('/login');
    }
 }
 downloadUrl(name) {
    const data = {
      attachmentName: name
    };
    let self = this;
    utils.makeRequest(this, EndpointConstant.DOWNLOAD_ATTACHMENT, data, function (result) {

      //console.log(JSON.stringify(result));
      self.setState({
          downloadMsg:'Attachment downloaded successfully'
      })
      window.open(result.url, 'Download');
        //window.top.close();
        window.close();
    });
  }
  handleClose() {
      window.close();
  }
  render() {
    return (
        <div>
            {/* <button onClick={this.handleClose}>Close</button> */}
            <h3 style={{color:'green', fontSize:'16px', fontWeight:'bold', padding:'8px'}}>{this.state.downloadMsg}</h3>
        </div>
        
    )
  }
}

export default DownloadAttachment;