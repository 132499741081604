const CommonService = {
	viewDetails: {},


	setViewDetails: (data) => {
		CommonService.viewDetails = data;
	},
	getViewDetails: () => {
		return CommonService.viewDetails;
	}
}
CommonService.roles = [{ name: '-- Select Role --', id: 0, value: '' },
{ name: 'Admin', id: 1, value: 'Admin' },
{ name: 'Call Center Admin', id: 2, value: 'Call Center Admin' },
{ name: 'Data Scientist', id: 3, value: 'Data Scientist' },
{ name: 'Quality Monitor', id: 4, value: 'Quality Monitor' },
{ name: 'Reviewer', id: 5, value: 'Reviewer' },
{ name: 'RWS-translation Vendor', id: 6, value: 'RWS-translation Vendor' }];

CommonService.interactionStatus = [
{ name: 'Assigned', id: 0, value: 'Assigned' },
{ name: 'Completed', id: 1, value: 'Completed' },
{ name: 'Re-Assigned', id: 2, value: 'Re-Assigned' }]

CommonService.assignedTo = [{ name: '--Select--', id: 0, value: '' },
{ name: 'Admin', id: 1, value: 'Admin' },
{ name: 'Call Center Admin', id: 2, value: 'Call Center Admin' },
{ name: 'Model', id: 3,value:'Model' },
{ name: 'Quality Monitor', id: 4, value: 'Quality Monitor' },
{ name: 'Translation Vendor', id: 5, value: 'Translation Vendor' }]

CommonService.associatedTeam = [{ name: '--Select--', id: 0, value: '' },
{ name: 'Team 1', id: 1, value: 'Team 1' },
{ name: 'Team 2', id: 2, value: 'Team 2' },
{ name: 'Global Safty', id: 3, value: 'Global Safty' },
{ name: 'Team 4', id: 4, value: 'Team 4' },
{ name: 'Team 5', id: 5, value: 'Team 5' }];

CommonService.questionType = [
	{name: "Single Select", id: 1, value: "SingleSelect"},
	{name: "Multi Select", id: 2, value: "MultiSelect"},
	{name: "Yes / No", id: 3, value: "Y/N"},
	{name: "Text", id: 4, value: "Text"}
];

CommonService.yesNoOption = [
	{name: 'Yes', id: 1, value: "Yes"},
	{name: 'No', id:2, value: "No"},
	{name: 'N/A', id:3, value: "N/A"}
];

CommonService.weightage = [
	{name: '--Select Weightage--', id: 0, value: ''},
	{name: 'Ok', id: 1, value: "Ok"},
	{name: 'Critical', id: 2, value: "Critical"},
	{name: 'Uncritical', id: 3, value: 'Uncritical'},
	{name: 'N/A', id: 4, value: 'N/A'}
];

CommonService.amgenProducts = [
	{name: 'Acetato de ciproterona', id: 1, value: 'Acetato de ciproterona'},
	{name: 'Acetato de desmopressina', id: 2, value: 'Acetato de desmopressina'},
	{name: 'Actiparin', id: 3, value: 'Actiparin'},
	{name: 'adalimumab', id: 4, value: 'adalimumab'},
	{name: 'Aimovig', id: 5, value: 'aimovig'},
	{name: 'Aimovig Migraine Tracker', id: 6, value: 'Aimovig Migraine Tracker'},
	{name: 'Alfaestradiol', id: 7, value: 'Alfaestradiol'},
	{name: 'Alozex', id: 8, value: 'Alozex'},	
	{name: 'Amgevita', id: 9, value: 'Amgevita'},
	{name: 'Amgevita Medication Reminder', id: 10, value: 'Amgevita Medication Reminder'},
	{name: 'Amjevita', id: 11, value: 'Amjevita'},
	{name: 'Aranesp', id: 12, value: 'aranesp'},
	{name: 'Avsola', id: 13, value: 'avsola'},
	{name: 'Blincyto', id: 14, value: 'blincyto'},
	{name: 'Bortezomib', id: 15, value: 'Bortezomib'},
	{name: 'Botulift', id: 16, value: 'Botulift'},
	{name: 'Botulinum toxin type A-AXP1003', id: 17, value: 'Botulinum toxin type A-AXP1003'},	
	{name: 'Brodalumab', id: 18, value: 'Brodalumab'},																																																													
	{name: 'Blinatumomab', id: 19, value: 'Blinatumomab'},
	{name: 'Bromovent', id: 20, value: 'Bromovent'},
	{name: 'Bemarituzumab', id: 21, value: 'Bemarituzumab'},
	{name: 'Carfilzomib', id: 22, value: 'Carfilzomib'},
	{name: 'Cefalexina', id: 23, value: 'Cefalexina'},
	{name: 'CHAT', id: 24, value: 'CHAT'},
	{name: 'Citoplax', id: 25, value: 'Citoplax'},
	{name: 'Carlanor', id: 26, value: 'carlanor'},
	{name: 'Cross Product', id: 27, value: 'Cross Product'},
	{name: 'CV Check', id: 28, value: 'CV Check'},
	{name: 'Dacarbazina', id: 29, value: 'Dacarbazina'},
	{name: 'Daflubin', id: 30, value: 'Daflubin'},
	{name: 'Denosumab', id: 31, value: 'Denosumab'},
	{name: 'Embark App', id: 32, value: 'Embark App'},
	{name: 'Enbrel', id: 33, value: 'enbrel'},
	{name: 'Enbrel - Modern Journal System', id: 34, value: 'Enbrel - Modern Journal System'},
	{name: 'Epogen', id: 35, value: 'epogen'},
	{name: 'Etanercept', id: 36, value: 'Etanercept'},
	{name: 'Evenity', id: 37, value: 'evenity'},
	{name: 'Evenity Medication Reminder', id: 38, value: 'Evenity Medication Reminder'},
	{name: 'Evolocumab', id: 39, value: 'Evolocumab'},
	{name: 'Femigestrol', id: 40, value: 'Femigestrol'},
	{name: 'FH Score', id: 41, value: 'FH Score'},
	{name: 'FH Score Webservice', id: 42, value: 'FH Score Webservice'},
	{name: 'Fideine', id: 43, value: 'Fideine'},
	{name: 'Fluoruracila', id: 44, value: 'Fluoruracila'},
	{name: 'Ganitumab', id: 45, value: 'Ganitumab'},
	{name: 'Gencix', id: 46, value: 'Gencix'},
	{name: 'Gonadopin', id: 47, value: 'Gonadopin'},
	{name: 'Gotezla', id: 48, value: 'Gotezla'},
	{name: 'Hemitartarato de rivastigmina', id: 49, value: 'Hemitartarato de rivastigmina'},
	{name: 'Hemoprex', id: 50, value: 'Hemoprex'},
	{name: 'Hormotrop', id: 51, value: 'Hormotrop'},
	{name: 'Imlygic', id: 52, value: 'imlygic'},
	{name: 'ImmunoVEX', id: 53, value: 'ImmunoVEX'},
	{name: 'Inofungin', id: 54, value: 'Inofungin'},
	{name: 'Ivabradine', id: 55, value: 'Ivabradine'},
	{name: 'Kanjinti', id: 56, value: 'kanjinti'},
	{name: 'Kyprolis', id: 57, value: 'kyprolis'},
	{name: 'Kyprolis-Clinical', id: 58, value: 'Kyprolis-Clinical'},
	{name: 'Leucin', id: 59, value: 'Leucin'},
	{name: 'Lorelin', id: 60, value: 'Lorelin'},
	{name: 'Lumakras', id: 61, value: 'Lumakras'},
	{name: 'Medicine administration kit', id: 62, value: 'Medicine administration kit'},
	{name: 'Mimpara', id: 63, value: 'Mimpara'},
	{name: 'Motesanib', id: 64, value: 'Motesanib'},
	{name: 'Multi product', id: 65, value: 'Multi product'},
	{name: 'Mvasi', id: 66, value: 'mvasi'},
	{name: 'Myograf', id: 67, value: 'Myograf'},
	{name: 'NAMP', id: 68, value: 'NAMP'},
	{name: 'Neulasta', id: 69, value: 'neulasta'},
	{name: 'Neupogen', id: 70, value: 'neupogen'},
	{name: 'Non-Product', id: 71, value: 'Non-Product'},
	{name: 'Nplate', id: 72, value: 'nplate'},
	{name: 'Nuovodox', id: 73, value: 'Nuovodox'},
	{name: 'Omecamtiv Mecarbil', id: 74, value: 'Omecamtiv Mecarbil'},
	{name: 'Oncoplatin',id: 75, value: 'Oncoplatin'},
	{name: 'OncoVEX', id: 76, value: 'OncoVEX'},
	{name: 'ONX0914', id: 77, value: 'ONX0914'},
	{name: 'Oprozomib', id: 78, value: 'Oprozomib'},	
	{name: 'Otezla', id: 79, value: 'otezla'},
	{name: 'Other', id: 80, value: 'Other'},
	{name: 'Other-contingency', id: 81, value: 'Other-contingency'},
	{name: 'Other-MedInfo Website', id: 82, value: 'Other-MedInfo Website'},
	{name: 'Oxaliplatina', id: 83, value: 'Oxaliplatina'},
	{name: 'Pamigraf', id: 84, value: 'Pamigraf'},
	{name: 'Parsabiv', id: 85, value: 'parsabiv'},
	{name: 'Patient Engagement App', id: 86, value: 'Patient Engagement App'},
	{name: 'Pegfilgrastim', id: 87, value: 'Pegfilgrastim'},
	{name: 'Pipeline Products', id: 88, value: 'Pipeline Products'},
	{name: 'Placebo', id: 89, value: 'Placebo'},
	{name: 'Plaquemax', id: 90, value: 'Plaquemax'},
	{name: 'Primacef', id: 91, value: 'Primacef'},
	{name: 'Procrit', id: 92, value: 'Procrit'},
	{name: 'Prolia', id: 93, value: 'prolia'},
	{name: 'Prolia Medication Reminder', id: 94, value: 'Prolia Medication Reminder'},
	{name: 'Repatha', id: 95, value: 'repatha'},
	{name: 'Repatha Medication Reminder', id: 96, value: 'Repatha Medication Reminder'},
	{name: 'Riabni', id: 97, value: 'riabni'},
	{name: 'Rilotumumab', id: 98, value: 'Rilotumumab'},
	{name: 'Ristempa', id: 99, value: 'Ristempa'},
	{name: 'Romosozumab', id: 100, value: 'Romosozumab'},
	{name: 'Rubidox', id: 101, value: 'Rubidox'},
	{name: 'Seacalcit', id: 102, value: 'Seacalcit'},
	{name: 'Sensipar', id: 103, value: 'sensipar'},
	{name: 'Solupren', id: 104, value: 'Solupren'},
	{name: 'Solustrep', id: 105, value: 'Solustrep'},
	{name: 'Solymbic', id: 106, value: 'Solymbic'},
	{name: 'Somatropin-AXP1006', id:107, value: 'Somatropin-AXP1006'},
	{name: 'Sotorasib', id: 108, value: 'Sotorasib'},
	{name: 'Talimogene Laherparepvec', id: 109, value: 'Talimogene Laherparepvec'},
	{name: 'Talimogene Laherparepvec Commercial', id: 110, value: 'Talimogene Laherparepvec Commercial'},
	{name: 'Taxilan', id: 111, value: 'Taxilan'},
	{name: 'Temolida', id: 112, value: 'Temolida'},
	{name: 'Tezepelumab', id: 113, value: 'Tezepelumab'},
	{name: 'Tezmota', id: 114, value: 'Tezmota'},
	{name: 'Thermometer kit', id: 115, value: 'Thermometer kit'},
	{name: 'trastuzumab', id: 116, value: 'trastuzumab'},
	{name: 'Trebananib', id: 117, value: 'Trebananib'},	
	{name: 'Trebyxan', id: 118, value: 'Trebyxan'},
	{name: 'Vectibix', id: 119, value: 'vectibix'},
	{name: 'Velcalcetide', id: 120, value: 'Velcalcetide'},	
	{name: 'Vincristina', id: 121,value: 'Vincristina'},
	{name: 'Xgeva', id: 122, value: 'xgeva'},
	{name: 'Zydena', id: 123, value: 'Zydena'}
];

CommonService.sortRole = (a, b) => {
	if (a.props.children < b.props.children) {
		return -1;
	}
	if (a.props.children > b.props.children) {
		return 1;
	}
	return 0;
}
CommonService.ifrType = [
	{ value: 'AE', id: 1 },
	{ value: 'MI', id: 2 },
	{ value: 'PC', id: 3 },
	{ value: 'RF', id: 4 }]
CommonService.channel = [
	{ value: 'Align', id: 1 },
	{ value: 'Astellas', id: 2 },
	{ value: 'Congress', id: 3 },
	{ value: 'Email', id: 4 },
	{ value: 'eMIR', id: 5 },
	{ value: 'Face-to-Face', id: 6 },
	{ value: 'Fax', id: 7 },
	{ value: 'Mobile Field App', id: 8 },
	{ value: 'Out of Hours', id: 9 },
	{ value: 'PDF Form', id: 10 },
	{ value: 'Phone', id: 11 },
	{ value: 'RAVE', id: 12 },
	{ value: 'Safety Reporting Portal', id: 13 },
	{ value: 'Web Form', id: 14 }]
CommonService.modelTypes = [
	{ value: 'AE', id: 1 },
	{ value: 'OSF', id: 2 },
	{ value: 'PC', id: 3 }]
CommonService.dataModelStatus = [
	{ value: 'active', id: 1 },
	{ value: 'deployed', id: 2 }]
CommonService.countries = [
	{ value: 'Australia', id: 1 },
	{ value: 'Brazil', id: 2 },
	{ value: 'Canada', id: 3 },
	{ value: 'Colombia', id: 4 },
	{ value: 'France', id: 5 },
	{ value: 'Germany', id: 6 },
	{ value: 'Mexico', id: 7 },
	{ value: 'Netherlands', id: 8 },
	{ value: 'Puerto Rico', id: 9 },
	{ value: 'Singapore', id: 10 },
	{ value: 'Spain', id: 11 },
	{ value: 'United Kingdom', id: 12 },
	{ value: 'United States of America', id: 13 },]
export default CommonService;