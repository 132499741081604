/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './Registration.css';
import logoImg from './../../assets/images/Logo.svg';
import { Col, Form, Button, Card, Modal } from 'react-bootstrap';
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import Search from '../Search/Search';
import MessageConstant from './../../constants/MessageConstant';
import CommonService from '../../commonService/CommonService';
//import Multiselect from "@khanacademy/react-multi-select";
import RoleConstant from '../../constants/RoleConstant';
import { v4 as uuidV4 } from 'uuid';



/* eslint "require-jsdoc": ["error", {
    "require": {
        "FunctionDeclaration": true,
        "MethodDefinition": true,
        "ClassDeclaration": true,
        "ArrowFunctionExpression": true,
        "FunctionExpression": true
    }
}]*/

/**
 * A class that can return some html
 */
class Registration extends Component {
  // state={show:false}
  /**
 * @constructor
 * @param {void} props  */
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: utils.hasSessionItem('registerE') ? utils.getSessionItem('registerE') : '',
      role: 'User',
      justification: '',
      team: [],
      userId: '',
      sessionToken: '',
      regShow: false,
      userRegSuccess: false,
      userRegError: false,
      showForm: true,
      formValid: false,
      errorCount: null,
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        team: '',
        justification: ''
      },
      showList: false,
      searchList: {},
      isFormShow: false,
      errorCode: '',
      isFormSubmit: false,
      scorecardExist: false,
      forRoleReviewer: false,
      userStatusRequest: 'Processing',
      showConfirmationModal: false
    };
    this.isFormValid = false;
    this.changeHandler = this.changeHandler.bind(this);
    this.register = this.register.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleRegClose = this.handleRegClose.bind(this);
    /* eslint-disable-next-line */
    this.validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    this.validateForm = this.validateForm.bind(this);
    this.countErrors = this.countErrors.bind(this);
    this.errMsg = this.errMsg.bind(this);
    this.validateRegex = this.validateRegex.bind(this);
    this.handleShowList = this.handleShowList.bind(this);
    this.selectUsersFromSearch = this.selectUsersFromSearch.bind(this);
    this.handleSelectedChanged = this.handleSelectedChanged.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.checkScorecardExist = this.checkScorecardExist.bind(this);
    this.handleChangeClose = this.handleChangeClose.bind(this);
    this.handleChangeRole = this.handleChangeRole.bind(this);
    this.handleUserRole = this.handleUserRole.bind(this);
  }


  /**
  * Validate Forms
  */
  validateForm(errors) {
    //console.log(errors);
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }
  /**
  * Validate Regex
  */
  validateRegex(event) {
    let name = event.target.name;
    if (name === 'firstName' || name === 'lastName') {
      event.target.value = event.target.value.replace(/[^\w\s\][]/gi, '')
    }
    return event;
  }
  /**
  * Count Error
  */
  countErrors(errors) {
    let count = 0;
    Object.values(errors).forEach((val) => {
      if (val.length > 0) {
        count += 1;
      }
    });
    return count;
  }
  /**
  * Error Message On Submit
  */
  errMsg(state) {
    const { firstName, lastName, email, justification, errors } = state;
    if (firstName === '') {
      errors.firstName = MessageConstant.REG_FIRSTNAME_VALIDATION;
    }
    if (lastName === '') {
      errors.lastName = MessageConstant.REG_LASTNAME_VALIDATION;
    }
    if (email === '') {
      errors.email = MessageConstant.REG_EMAIL_VALIDATION;
    }

    if (justification === '') {
      errors.justification = MessageConstant.REG_JUSTIFICATION_VALIDATION;
    }
    return errors;
  }
  /**
    * @param {Object} e .
    */
  handleShow() {
    this.props.closePopup();
    this.setState({
      regShow: true
    });
  }
  /**
    * @param {Object} e .
    */
  handleClose() {
    this.setState({
      regShow: false
    });
  }

  /**
    * @param {Object} e The new state.
    */
  changeHandler(event) {
    //this.setState({ [e.target.name]: e.target.value });
    event.preventDefault();
    //let validate = this.validateRegex(event);
    let { name, value } = event.target;
    let errors = this.state.errors;
    let firstName, lastName;
    switch (name) {
      case 'firstName':
        if (value.includes(" ")) {
          const arr = value.split(" ");
          for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          }
          const str2 = arr.join(" ");
          firstName = str2;
        } else {
          firstName = value.charAt(0).toUpperCase() + value.slice(1);
        }
        errors.firstName = value === '' ? MessageConstant.REG_FIRSTNAME_VALIDATION : '';
        this.setState({ firstName });
        break;
      case 'lastName':
        lastName = value.charAt(0).toUpperCase() + value.slice(1);
        errors.lastName = value === '' ? MessageConstant.REG_LASTNAME_VALIDATION : '';
        this.setState({ lastName });
        break;
      case 'email':
        errors.email = this.validEmailRegex.test(value) ? '' : MessageConstant.REG_EMAIL_NOTVALID;
        break;
      // case 'role':
      //   errors.role = value === '' ? MessageConstant.REG_ROLE_VALIDATION : '';
      //   break;
      case 'team':
        errors.team = value === '' ? MessageConstant.REG_TEAM_VALIDATION : '';
        break;
      case 'justification':
        errors.justification = value === '' ? MessageConstant.REG_JUSTIFICATION_VALIDATION : '';
        break;
      default:
        break;
    }

    // console(errors);console(name);console(value);
    // if(name == 'role') {
    //   if(value == 'on') {
    //     value = RoleConstant.SuperAdmin
    //   } else {
    //     value = RoleConstant.Admin
    //   }
    // }
    if (name == 'firstName' || name == 'lastName') {
      this.setState({ errors, isFormSubmit: false });
    } else {
      this.setState({ errors, [name]: value, isFormSubmit: false });
    }
  }
  /**
    * @param {Object} e The new state.
    */
  handleRegClose() {
    this.props.closePopup();
  }
  /**
    * @param {Object} e .
    */
  register(e) {

    e.preventDefault();
    let errorsMsg = this.errMsg(this.state);
    this.setState({ errors: errorsMsg });
    this.setState({ formValid: this.validateForm(this.state.errors) });
    this.setState({ errorCount: this.countErrors(this.state.errors) });
    this.setState({ isFormSubmit: true })
    let self = this;
    self.refs.btn.setAttribute("disabled", "disabled");
    setTimeout(() => {
      const { formValid } = this.state;
      if (formValid) {
        //  let self = this;
        if (this.props.adminRegister) {
          let adminDetails = {
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.email,
            role: this.state.role,
            justification: this.state.justification,
            userId: this.state.userId,
            sessionToken: this.state.sessionToken
          }
          if (adminDetails.role === '') {
            adminDetails.role = RoleConstant.Admin;
          }
          // console.log(adminDetails)
          utils.makeRequest(this.props.self, EndpointConstant.ADD_USER, adminDetails, function (result) {
            self.props.getUser();
            // window.alert('added user')
            // utils.gotoPage(RouterConstant.ADMIN_REG_SUCCESS);
            // utils.gotoPage(RouterConstant.USERS_LINK)   
            if (result.status === 'success') {
              //self.setState({regShow:true});
              let success = MessageConstant.ADMIN_REGISTRATION_MESSAGE(self.state.firstName);
              self.props.closePopup(success, 'Registration', true);
              //alert("User Added successfully");
            }
            else {
              let error = MessageConstant.USER_ALREADY_EXISTS(self.state.firstName);
              self.props.closePopup(error, 'Registration', true);
              //alert("User already exist");
            }
          });

        }
        else {
          const idToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
          // console.log("add user nonadmin",idToken);
          let token = null;
          if (idToken != null) {
            token = idToken.accessToken.accessToken
          }
          let nonadminDetails = {
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.email,
            role: this.state.role,
            justification: this.state.justification,
            token: token
          }

          if (nonadminDetails.role === '') {
            nonadminDetails.role = RoleConstant.Admin;
          }
          utils.makeRequest(this, EndpointConstant.ADD_USER, nonadminDetails, function (result) {
            //console.log(result)
            if (result.error === false) {
              self.setState({
                userRegSuccess: true,
                showForm: false,
                userRegError: false
              });
            }
            else {
              self.setState({
                userRegSuccess: false,
                errorCode: result.errorCode
              }, () => self.setState({ userRegError: true }))
            }

          });

        }
      }
      else {
        //console.log("Invalid fields");
      }
    }, 500);
  }

  /**
 * handle user role update
 */
  handleUserRole() {
    clearInterval(this.interval);
    //  console.log("this state",this.state);
    this.interval = null;
    let sId = uuidV4();
    this.updateUser(sId);
    this.interval = setInterval(async () => {
      let val = this.state.extractValue;
      val += Math.floor(Math.random() * 10) + 1;
      if (val >= 100) {
        val = 100;
      }

      this.setState({
        extractValue: val
      });
      (this.state.userStatusRequest === 'Processing') && this.updateUser(sId, this.state.userStatusRequest);
      //  console.log("downloadss",this.state.userStatusRequest,this.state.userStatusRequest === "Completed")
      if (this.state.userStatusRequest === "Completed") {
        this.props.getUser();
        let success = MessageConstant.UPDATE_USER_SUCCESS;
        this.props.closePopup(success, 'Update User', true);
        utils.hideLoader();
        clearInterval(this.interval);
        this.interval = null;
      }

    }, 10000);
  }

  /**
   * @param {Object} e .
   */
  updateUser(uuid, userStatusRequest) {
    let self = this;
    // console.log("updateUser state", this.state);
    // console.log("updateUser current user", this.props.currentUser);
    let updateDetails = {
      role: this.state.role,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      justification: this.state.justification,
      id: this.props.currentUser.id,
      sId: uuid
    }
    if (userStatusRequest === 'Processing') {
      updateDetails.userStatusRequest = userStatusRequest;
    }
    if (updateDetails.role === 'CallCenterAdmin') {
      updateDetails.team = this.state.team;
    }
    utils.makeRequest(this.props.self, EndpointConstant.USER_STATUS_UPDATE, updateDetails, function (result) {

      // self.props.getUser();

      // window.alert('added user')
      // utils.gotoPage(RouterConstant.ADMIN_REG_SUCCESS);
      // utils.gotoPage(RouterConstant.USERS_LINK) 
      // console.log("resultsss", result);
      if (result.error !== true) {
        self.setState({ userStatusRequest: result.userStatusRequest })

        // let success = MessageConstant.UPDATE_USER_SUCCESS;
        // self.props.closePopup(success, 'Update User', true);
        //alert("User Added successfully");
      }
      else {
        let error = MessageConstant.UPDATE_USER_ERROR;
        self.props.closePopup(error, 'Update User', true);
        //alert("User already exist");
      }
    });
  }

  /**
   * @param {Object} e The new state.
   */
  checkScorecardExist(event) {
    event.preventDefault();
    let self = this;
    if (this.props.currentUser.role === RoleConstant.Reviewer) {
      utils.makeRequest(this, EndpointConstant.GET_SCORECARD_USER, { id: this.props.currentUser.id }, function (result) {
        if (!result.error && result.isExist) {
          self.setState({ scorecardExist: true, forRoleReviewer: true });
        } else {
          self.setState({ showConfirmationModal: true })
        }
      });
    }
    else if (this.state.role === RoleConstant.Admin) {
      utils.makeRequest(this, EndpointConstant.GET_SCORECARD_USER, { id: this.props.currentUser.id }, function (result) {
        if (!result.error && result.isExist) {
          self.setState({ scorecardExist: true, forRoleReviewer: false });
        } else {
          self.setState({ showConfirmationModal: true })
        }
      });
    } else {
      this.setState({ showConfirmationModal: true })
    }
  }

  /**
   * @param {Object} e The new state 
   */
  handleChangeClose() {
    this.setState({ scorecardExist: false });
    this.props.closePopup()
  }

  /**
   * 
   * @param {*} e 
   */
  handleChangeRole() {
    this.setState({ scorecardExist: false }, () => this.handleUserRole());
  }

  /**
   * @param {Object} e The new state.
   */
  handleShowList(e) {
    const { results, showList } = e;
    //console.log("handleShowList", e);
    this.setState({
      showList,
      searchList: results
    });
    //console.log("state", this.state);
  }
  /**
    * @param {Object} e The new state.
    */
  selectUsersFromSearch(data, event) {
    event.preventDefault();
    const { firstName, lastName, email } = data
    //console.log("selectUsersFromSearch", data);
    this.setState({
      firstName, lastName, email,
      showList: false,
      isFormShow: true
    })
  }
  /**
    * @param {Object} e The new state.
    */
  onKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\||1|2|3|4|5|6|7|8|9|0/.test(keyValue)) {
      event.preventDefault();
    }
  }
  /**
   * @param {Object} e The new state.
   */
  isValidForm() {
    //    this.isFormValid = true;
    const { firstName, lastName, email, role, team, justification } = this.state;
    if (firstName && lastName && email && justification) {
      if (role === 'CallCenterAdmin' && team.length === 0 || this.state.isFormSubmit) {
        this.isFormValid = false;
      }
      else {
        this.isFormValid = true;
      }
    }
    else {
      this.isFormValid = false;
    }
  }
  /**
    * @param {Object} selected.
    */
  handleSelectedChanged(selected) {
    this.setState({ team: selected });
    // if(selected.length === 0) {
    let errors = this.state.errors;
    // let errors = this.state.errors;
    // errors.team = MessageConstant.REG_TEAM_VALIDATION;
    let errormsg = errors.team = selected.length === 0 ? MessageConstant.REG_TEAM_VALIDATION : ''
    this.setState({ errors: { team: errormsg } });
    // }
  }
  /**
   *renders the ui
   *@return {Object} .
    */
  componentDidMount() {
    if (this.props.adminUpdate) {
      const { firstName, lastName, email, role, team, justification } = this.props.currentUser;
      this.setState({
        firstName, lastName, email, role, team, justification,
        showList: false,
        isFormShow: true,
      })
    }
  }
  /**
   * 
   * @param {a} a 
   * @param {a} b
   * @return boolean 
   */
  compare(b, a) {
    if (a.value < b.value) {
      return 1;
    }
    if (a.value > b.value) {
      return -1;
    }
    return 0;
  }
  /**
   *renders the ui
   *@return {Object} .
    */
  render() {
    this.isValidForm();
    const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));

    //console.log("siteInfo  " + JSON.stringify(siteInfo.UserRoles));

    {
      this.props.adminUpdate ?
        this.props.currentUser != undefined ?
          this.props.currentUser.role === RoleConstant.Reviewer ? '' : delete siteInfo.UserRoles.Reviewer : "" : ""
    }


    const rolesOtions = Object.keys(siteInfo.UserRoles).sort().map((role, i) => {
      return (
        <option key={i} value={role}>{siteInfo.UserRoles[role]}</option>
      );
    });

    // rolesOtions.sort(CommonService.sortRole);

    const associatedTeamsArray = Object.entries(siteInfo.CallCenterTeam);
    const associatedTeams = [];
    associatedTeamsArray.forEach(function (team) {
      associatedTeams.push({
        label: team[1], value: team[0]
      })
    });
    associatedTeams.sort(this.compare);
    // const teamOptions = Object.keys(siteInfo.CallCenterTeam).map((team, i) => {
    //   return (
    //     <option key={i} value={team}>{siteInfo.CallCenterTeam[team]}</option>
    //   );
    // });

    const { errors, errorCode } = this.state;

    return (
      <div >
        {this.state.userRegSuccess ? <Card className="alert-box-error">
          <div className="card-header-title">
            <Card.Img variant="top" className="logo-size" src="/Images/Logo.svg" />
            <Card.Text><h4 className="logo-title">MedInfo Case Evaluation</h4></Card.Text>
          </div>
          <Card.Body className="msg-body">{MessageConstant.REG_MESSAGE_FOR_USER}</Card.Body>
        </Card> : ''}
        {this.state.userRegError ? <Card className="alert-box-user-exist">
          <Card.Body>
            <img className="error-already-exist" src="/Images/ico-error.png" alt='Amgen' />
            <span className="error-msg-exist col-md-11">{errorCode === 'U100' ? MessageConstant.USER_ALREADY_EXISTS_NON_ADMIN : MessageConstant.USER_DOESNT_EXISTS_NON_ADMIN}</span>
          </Card.Body>
        </Card> : ''}
        {!this.state.userRegSuccess &&
          <div className={this.props.adminRegister ? '' : 'content-cneter'}>

            {!this.props.adminRegister && this.state.showForm &&
              <div className="register-header no-padding">
                {/* <div class="logo"></div> */}
                <div className="reg-logo-wrapper mx-auto text-center">
                  <img src={logoImg} alt="Amgen" />
                  <h4 className="logo">MedInfo Case Evaluation</h4>
                </div>
              </div>
            }
            <div className="row">
              <div className="col"><hr className="no-margin" /></div>
            </div>
            <div className="registration-form">
              {/* <h5 className={this.state.showForm?'mb-4 text-center':'fromDispaly mb-4 text-center'}>{this.props.adminRegister?'Add User':'Registration'}</h5> */}

              {(this.props.adminRegister && !this.props.adminUpdate) ? <Search showList={this.state.showList} handleShowList={this.handleShowList} /> : ''}
              {this.state.showList && <div>
                <div className="list-group search-list-group">
                  {this.state.searchList.users.map((data) => {
                    return <a key='' href="" onClick={(e) => this.selectUsersFromSearch(data, e)} className="list-group-item list-group-item-action search-list-item">{data.firstName + ' ' + data.lastName}</a>
                  })
                  }
                </div>
              </div>}
              <div className={this.state.showForm ? '' : 'fromDispaly'}>
                {((this.props.adminRegister && this.state.isFormShow) || (!this.props.adminRegister)) && <Form onSubmit={this.props.adminUpdate ? this.checkScorecardExist : this.register} className='reg-form' noValidate>
                  <Form.Row>
                    <Form.Group as={Col} controlId="firstName">
                      <Form.Label className='align-lable'>First Name</Form.Label>
                      <Form.Control type='text' name='firstName' value={this.state.firstName} onKeyPress={this.onKeyPress.bind(this)}
                        onChange={this.changeHandler} disabled={this.props.adminRegister} />
                      {errors.firstName && errors.firstName.length > 0 &&
                        <div className="error"><span>{errors.firstName}</span></div>}
                    </Form.Group>

                    <Form.Group as={Col} controlId="lastName">
                      <Form.Label className='align-lable'>Last Name</Form.Label>
                      <Form.Control type='text' name='lastName' value={this.state.lastName} onKeyPress={this.onKeyPress.bind(this)}
                        onChange={this.changeHandler} disabled={this.props.adminRegister} />
                      {errors.lastName && errors.lastName.length > 0 &&
                        <div className="error"><span>{errors.lastName}</span></div>}
                    </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="email">
                    <Form.Label className='align-lable'>Email</Form.Label>
                    <Form.Control type='text' name='email' value={this.state.email}
                      onChange={this.changeHandler} disabled={this.props.adminRegister} />
                    {errors.email && errors.email.length > 0 &&
                      <div className="error"><span>{errors.email}</span></div>}
                  </Form.Group>

                  <Form.Group controlId="role">
                    <Form.Label className='align-lable'>Role</Form.Label>
                    {/* <Form.Control as='checkbox' name='role' value={RoleConstant.SuperAdmin}
                      onChange={this.changeHandler}> */}
                    {/* <Form.Check type="checkbox" label={RoleConstant.SuperAdmin} name='role' value={RoleConstant.SuperAdmin} onChange={(e)=>{
                           if(e.target.checked ){
                           this.setState({role:RoleConstant.SuperAdmin})
                           } else {
                            this.setState({role:RoleConstant.Admin})
                           }
                         }} className='checkbox-margin' checked={this.state.role===RoleConstant.SuperAdmin ? true : false} /> */}
                    {/* <label>
                    Is going:
                         <input
                      name="role"
                      type="checkbox"
                     
                      checked={this.state.role == RoleConstant.SuperAdmin ? true : false} 
                      onChange={this.changeHandler} />
                  </label> */}
                    <Form.Control as='select' name='role' value={this.state.role} onChange={this.changeHandler}>
                      {rolesOtions}
                    </Form.Control>
                    {/* {errors.role && errors.role.length > 0 &&
                      <div className="error"><span>{errors.role}</span></div>} */}
                  </Form.Group>

                  <Form.Group controlId="justification">
                    <Form.Label className='align-lable'>{this.props.adminUpdate ? 'Justification' : this.props.adminRegister ? 'Invitation' : 'Justification'}</Form.Label>
                    <Form.Control as="textarea" rows="3" name='justification' value={this.state.justification} maxLength={MessageConstant.REG_MAX_LENGTH_TEXTAREA}
                      onChange={this.changeHandler} />
                    {errors.justification && errors.justification.length > 0 &&
                      <div className="error"><span>{errors.justification}</span></div>}
                  </Form.Group>
                  <Form.Group controlId="btnaction" className="row padding-form">
                    <div className="col-md-6 padding-left">
                      {!this.props.adminRegister && <Button variant="secondary" className='margin mr-4 btn-block cancel' onClick={() => { this.props.adminRegister ? this.props.closePopup() : utils.gotoPage('/') }}>
                        Cancel
                      </Button>}
                    </div>
                    <div className="col-md-6 padding-right">
                      {
                        this.props.adminUpdate ?
                          (<Button variant="secondary" type="submit" className='textcolor btn-block register' disabled={!this.isFormValid}>
                            Update
                          </Button>)

                          : (<Button ref="btn" variant="secondary" type="submit" className='textcolor btn-block register' disabled={!this.isFormValid}>
                            {this.props.adminRegister ? 'Add' : 'Register'}
                          </Button>)
                      }
                    </div>
                  </Form.Group>
                </Form>}
              </div>

            </div>
          </div>
        }
        <Modal
          dialogClassName="roleChangeModal"
          show={this.state.scorecardExist}
          onHide={this.handleChangeClose}
          aria-labelledby="example-custom-modal-styling-title">
          {this.state.forRoleReviewer ?
            <Modal.Header closeButton className='confirmation-header alert-header'>
              <Modal.Title id="example-modal-sizes-title-sm">
                Alert
              </Modal.Title>
            </Modal.Header> :
            <Modal.Header closeButton className='confirmation-header'>
              <Modal.Title id="example-modal-sizes-title-sm">
                Confirmation
              </Modal.Title>
            </Modal.Header>
          }


          {this.state.forRoleReviewer ?
            <Modal.Body className='confirmation-body'> {MessageConstant.SCORECARD_EXISTREVIEWER}</Modal.Body>
            : <Modal.Body className='confirmation-body'> {MessageConstant.SCORECARD_EXIST}</Modal.Body>

          }
          <Modal.Footer className='confirmation-footer'>
            <Button onClick={this.handleChangeClose} className='clear'>
              {this.state.forRoleReviewer ? "Ok" : "No"}
            </Button>
            {this.state.forRoleReviewer ? "" :
              <Button active onClick={() => { this.setState({ showConfirmationModal: true }) }}>
                Yes
              </Button>
            }
          </Modal.Footer>
        </Modal>
        {this.props.currentUser && <Modal
          dialogClassName="roleChangeModal"
          show={this.state.showConfirmationModal}
          onHide={() => { this.setState({ showConfirmationModal: false }) }}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton className='confirmation-header' style={{justifyContent: 'center'}}>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body' style={{display: 'flex', justifyContent: 'center'}}> {MessageConstant.USER_EDIT(this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName)}</Modal.Body>
          <Modal.Footer className='confirmation-footer'>
            <Button onClick={() => { this.setState({ showConfirmationModal: false }) }} className='clear'>
              No
            </Button>
            <Button active onClick={this.handleChangeRole}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>}
      </div>
    );
  }
}

export default Registration;
